import { CheckCircleOutline } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { Avatar, styled } from '@mui/material'

import pluralize from 'pluralize'

import { useDispatch } from 'actions/store'
import { HbText } from 'components/HbComponents/Text/HbText'
import { LinkButton } from 'components/library/LinkButton'
import { getDateFilter } from 'dashboards/automationRuleExecutions/getDateFilter'
import { dashboardActionsMap } from 'reducers/dashboards/dashboards.actions'
import { DashboardTypeEnum } from 'types/api'

const StyledAvatar = styled(Avatar)<{ isPreview: boolean }>(({ theme }) => ({
  backgroundColor: theme.palette.styleguide.successLight,
  color: theme.palette.styleguide.successDark,
  width: '32px',
  height: '32px',
  svg: {
    width: '19px',
    height: '19px',
  },
}))

const Root = styled('div')(({ theme }) => ({
  height: theme.spacing(9),
  width: '100%',
}))

const MainText = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

const ManualRunsText = styled(HbText)(({ theme }) => ({
  marginLeft: theme.spacing(5),
}))

export const ActivityLogItem = ({
  date,
  noun = 'event',
  runs,
  manualRuns,
  openTable,
  type,
}: {
  date: string
  noun?: string
  runs: number
  manualRuns?: number
  openTable: () => void
  type: 'ScheduleAutomationRule' | 'TriggerAutomationRule' | 'ScheduleWithTriggerAutomationRule' | 'BlankAutomationRule'
}) => {
  const dispatch = useDispatch()
  const actions = dashboardActionsMap[DashboardTypeEnum.AutomationExecutions]

  const onClick = () => {
    const filters = getDateFilter(date)
    openTable()
    dispatch(actions.filters.createComponentById.action(filters))
  }

  const linkCopy =
    type === 'ScheduleAutomationRule' ? (
      <>
        <LinkButton onClick={onClick} label={`${runs} scheduled ${pluralize('rule', runs)}`} /> executed
      </>
    ) : (
      <>
        <LinkButton onClick={onClick} label={`${runs} ${pluralize(noun, runs)}`} /> met the conditions
      </>
    )

  return (
    <Root>
      <MainText>
        <StyledAvatar isPreview={!manualRuns}>
          <CheckCircleOutline />
        </StyledAvatar>
        <HbText block>{linkCopy}</HbText>
      </MainText>
      {manualRuns && <ManualRunsText block>{`${manualRuns} ${pluralize('manual run', manualRuns)}`}</ManualRunsText>}
    </Root>
  )
}
