import { useCallback, useDebugValue, useEffect, useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { keyBy, orderBy, uniqBy } from 'lodash'

import { useDispatch, useSelector } from 'actions/store'
import { useDashboardUserSettingsActions } from 'actions/userSettingsActions'
import { ChangedColumns } from 'components/library/Table/Table'

import {
  GetSavedSearchQueryQuery,
  GetSavedSearchQueryQueryVariables,
} from 'dashboards/reviews/gql/__generated__/searchReviews.queries.generated'
import { GET_SAVED_SEARCH_QUERY } from 'dashboards/reviews/gql/searchReviews.queries'
import { useDashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import { getCurrentAccount } from 'helpers/stateHelpers'
import { titleCase } from 'helpers/uiHelpers'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'

import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'

import { DashboardColumn } from 'reducers/reviewsReducer'
import { DashboardTypeEnum, SavedSearchRequest, TermsTypeEnum } from 'types/api'

import { normalizeRequest } from 'utils/query/normalization'

import { useDashboardConfig } from '../react/dashboards.config'
import { useDashboardFilters } from '../react/getNamedQueryGroups'
import { OtherInfoApiName, isOtherInfoColumn } from '../react/isOtherInfoColumn'
import { useDashboardImplicitFilters } from '../react/useDashboardImplicitFilters'

export type OtherInfoDashboardColumn = DashboardColumn & { apiName: OtherInfoApiName }
const batchColumn: DashboardColumn = {
  sortable: false,
  isDragDisabled: true,
  isResizeDisabled: true,
  title: 'batch',
  apiName: 'batch',
  hidden: false,
  valuePath: [],
  attribute: '',
  type: TermsTypeEnum.String,
}

export function useFetchDashboardCustomViews() {
  useDebugValue('useFetchDashboardCustomViews')
  const dispatch = useDispatch()
  const dashboardType = useDashboardContext()
  const currentAccount = useSelector(getCurrentAccount)

  const dashboardsSelectors = useDashboardSelectors()
  const dashboardsActions = useDashboardActions()
  const customViews = useSelector(dashboardsSelectors.customViews)

  const { data } = useQuery<GetSavedSearchQueryQuery, GetSavedSearchQueryQueryVariables>(GET_SAVED_SEARCH_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { dashboardType },
  })

  const rawCustomViews = data?.currentAccount.savedSearchRequests.nodes

  useEffect(() => {
    if (rawCustomViews && currentAccount.token && !customViews) {
      const newCustomViews = rawCustomViews as SavedSearchRequest[]
      dispatch(dashboardsActions.customViews.set(newCustomViews))
    }
  }, [dispatch, rawCustomViews, customViews, currentAccount.token, dashboardsActions])

  return customViews
}

const preserveOtherInfoFilterKeyCasing = (column: Pick<OtherInfoDashboardColumn, 'valuePath' | 'apiName'>) => {
  return `otherInfo.${(column?.valuePath ?? column?.apiName.split('.'))?.[1] ?? ''}`
}
const checkIsOtherInfoColumn = (
  column: Pick<DashboardColumn, 'valuePath' | 'apiName'>
): column is Pick<OtherInfoDashboardColumn, 'valuePath' | 'apiName'> => !!isOtherInfoColumn(column.apiName || '')

export const checkIsColumnNonHideable = (column: DashboardColumn) => column.hideable === false

// This function purposefully handles strange input to avoid changing behavior for anything other than
// other info.
export const calculateApiName = (
  column: Pick<DashboardColumn, 'valuePath' | 'apiName'> | undefined | null,
  apiName: string
) => {
  if (!apiName || !column) {
    return apiName
  }
  if (checkIsOtherInfoColumn(column)) {
    return preserveOtherInfoFilterKeyCasing(column)
  }
  return column.apiName
}

export const renamedCustomFieldLabelColumns = (columns: DashboardColumn[], dashboardType: DashboardTypeEnum) => {
  if (dashboardType !== DashboardTypeEnum.CustomFields) return columns
  const labelColumnIndex = columns.findIndex((col) => col.apiName === 'label')
  if (labelColumnIndex === -1) return columns
  const labelRenamedFilteredColumns = [...columns]
  labelRenamedFilteredColumns.splice(labelColumnIndex, 1, { ...columns[labelColumnIndex], title: 'Name' })
  return labelRenamedFilteredColumns
}

export function useDashboardColumns() {
  const dashboardType = useDashboardContext()

  useDebugValue('useDashboardColumns')
  const dispatch = useDispatch()
  const { table, filters } = useDashboardConfig()
  const { defaultSorts } = useDashboardFilters()
  const {
    forcedVisibilityColumnDisplayNames,
    forcedVisibilityColumns,
    batchColumnEnabled,
    forcedVisibilityColumnsFilterability,
    forcedVisibilityColumnsReorderability,
  } = table
  const { selectMetadataV2, columnsV2Query } = filters

  const { implicitFilter: implicitFilterInput, nonFilterableColumns } = useDashboardImplicitFilters()

  const appliedFilters = implicitFilterInput ? normalizeRequest(implicitFilterInput) : implicitFilterInput

  const { data: columnsData, loading: loadingColumns } = useQuery(columnsV2Query, {
    fetchPolicy: 'cache-and-network',
    variables: { search: appliedFilters, includeCustomColumns: false },
  })
  const { data: metadata, loading: loadingMetadata } = useQuery(columnsV2Query, {
    fetchPolicy: 'cache-and-network',
    variables: { search: appliedFilters, includeCustomColumns: true },
  })

  const dashboardsSelectors = useDashboardSelectors()
  const dashboardsActions = useDashboardActions()
  const { updateDashboardUserSettings } = useDashboardUserSettingsActions()

  const _staticColumns = selectMetadataV2(columnsData)?.staticColumns
  const rawColumns = selectMetadataV2(metadata)?.columns
  const isBatchColumnEnabled = batchColumnEnabled
  const sorts = useSelector(dashboardsSelectors.sorts.valid)

  // Explicitly cast column to make TS happy. Remove when making rawColumns and rawFilters type safe.
  const _columns = useMemo(
    () =>
      (rawColumns ?? []).map<DashboardColumn>((column: NonNullable<typeof rawColumns>[number]) => {
        const copy = {
          ...column,
          attribute: column.apiName,
          isDragDisabled: forcedVisibilityColumnsReorderability[column.apiName] === -1,
          isReorderable: forcedVisibilityColumnsReorderability[column.apiName] !== -1,
          isResizeDisabled: forcedVisibilityColumnsReorderability[column.apiName] === -1,
        }
        const maybeApiName = calculateApiName(column, column.apiName)
        if (maybeApiName) {
          copy.apiName = maybeApiName
        }
        return copy
      }),
    [rawColumns, forcedVisibilityColumnsReorderability]
  )

  /**
   * TODO:
   * Update the `label` value directly in the [config](https://github.com/Hummingbird-RegTech/hummingbird-rails/blob/f49438ecc15d7abb7045faa863c2d0972484751a/config/locales/custom_fields_dashboard.en.yml#L8)
   * https://thecharm.atlassian.net/browse/PROD-18955
   */
  const columns = useMemo(() => {
    return renamedCustomFieldLabelColumns(_columns, dashboardType)
  }, [_columns, dashboardType])

  const staticColumns = useMemo(
    () =>
      (_staticColumns ?? []).map<DashboardColumn>((column: NonNullable<typeof rawColumns>[number]) => ({
        ...column,
        attribute: column.apiName,
        isDragDisabled: forcedVisibilityColumnsReorderability[column.apiName] === -1,
        isReorderable: forcedVisibilityColumnsReorderability[column.apiName] !== -1,
        isResizeDisabled: forcedVisibilityColumnsReorderability[column.apiName] === -1,
      })),
    [_staticColumns, forcedVisibilityColumnsReorderability]
  )
  const staticColumnsByApiName = useMemo(() => keyBy(staticColumns, (column) => column.apiName), [staticColumns])

  const columnsByApiName = useMemo(() => keyBy(columns, (column) => column.apiName), [columns])

  const _dashboardColumns = useSelector(dashboardsSelectors.dashboardColumns)

  const toDashboardColumn = useCallback(
    (apiName: string): DashboardColumn => {
      const staticColumn = staticColumnsByApiName[apiName]
      if (staticColumn) {
        return staticColumn
      }
      if (apiName === batchColumn.apiName) {
        return batchColumn
      }
      const column = columnsByApiName[apiName]

      const valuePath = column?.valuePath ?? apiName.split('.')
      const otherInfoKey = valuePath[1]
      return {
        attribute: apiName,
        apiName: calculateApiName(column, apiName),
        isDragDisabled: false,
        isReorderable: true,
        isResizeDisabled: false,
        hidden: false,
        sortable: true,
        title: titleCase(otherInfoKey || ''),
        valuePath,
        filterPath: valuePath,
        type: TermsTypeEnum.String,
      }
    },
    [columnsByApiName, staticColumnsByApiName]
  )

  const dashboardColumns = useMemo(() => {
    const maybeColumns = (_dashboardColumns || []).filter((column) => !column.hidden).map((column) => column.apiName)
    // If we don't have any columns in the user settings, display the defaults
    if (!maybeColumns.length) {
      return columns.filter((c) => !c.hidden)
    }
    return maybeColumns.map(toDashboardColumn)
  }, [_dashboardColumns, columns, toDashboardColumn])

  const handleColumnOrderChange = useCallback(
    (changedColumns: ChangedColumns) => {
      dispatch(
        updateDashboardUserSettings({
          dashboardColumns: changedColumns.map((c) => ({ apiName: c.value, hidden: false })),
        })
      )
    },
    [dispatch, updateDashboardUserSettings]
  )

  const handleColumnSelectionChange = useCallback(
    (selectedColumns: ChangedColumns) => {
      dispatch(
        updateDashboardUserSettings({
          dashboardColumns: selectedColumns.map((c) => ({ apiName: c.value, hidden: false })),
        })
      )
    },
    [dispatch, updateDashboardUserSettings]
  )

  const dashboardColumnsList = useMemo(() => {
    const list = dashboardColumns.map((column) => column.apiName)
    forcedVisibilityColumns.forEach((forcedColumn) => {
      if (!isBatchColumnEnabled && forcedColumn === 'batch') return
      if (!list.includes(forcedColumn)) {
        list.unshift(forcedColumn)
      }
    })
    return list
  }, [dashboardColumns, forcedVisibilityColumns, isBatchColumnEnabled])

  const dashboardColumnsListIndex = useMemo(() => {
    return dashboardColumnsList.reduce<{ [key: string]: number }>((acc, cur, index) => {
      acc[cur] = index
      return acc
    }, {})
  }, [dashboardColumnsList])

  const userSettingColumnRank = useCallback(
    (column) => {
      const columnPosition = dashboardColumnsListIndex[column.apiName]
      return columnPosition !== undefined ? columnPosition : -1
    },
    [dashboardColumnsListIndex]
  )

  const forcedVisibilityColumnRank = useCallback(
    (c: DashboardColumn) => {
      const columnPosition = dashboardColumnsListIndex[c.apiName]
      const forcedVisibilityPosition = forcedVisibilityColumnsReorderability[c.apiName]
      return forcedVisibilityPosition !== undefined ? forcedVisibilityPosition : columnPosition
    },
    [dashboardColumnsListIndex, forcedVisibilityColumnsReorderability]
  )

  const filteredColumns = useMemo(() => {
    // Order the columns according to the stored user settings (dashboardColumns[dashboardType])
    return [
      ...(isBatchColumnEnabled ? [batchColumn] : []),
      ...orderBy(dashboardColumns, [forcedVisibilityColumnRank, userSettingColumnRank], 'asc').filter(
        (column) => dashboardColumnsList.indexOf(column.attribute) !== -1
      ),
    ]
  }, [dashboardColumns, dashboardColumnsList, forcedVisibilityColumnRank, isBatchColumnEnabled, userSettingColumnRank])

  const orderedColumns = useMemo(() => {
    return orderBy(columns, [forcedVisibilityColumnRank, userSettingColumnRank], 'asc')
  }, [columns, forcedVisibilityColumnRank, userSettingColumnRank])

  /*  In the case that a user has previously selected a column that is no longer possible to select,
      we still want it to be de-selectable. This is possible if:

      1.  The user has selected other info columns on any dashboard, then switched to another org.
      2.  More likely - the user has selected other info columns on the transactions dashboard, then switched
          to a case that doesn't have those other info columns present on any transactions.
  */
  const columnsWithUserSelectedColumnsPreserved = useMemo(
    () => uniqBy([...orderedColumns, ...dashboardColumns], (c) => c.apiName).filter((c) => c.attribute),
    [orderedColumns, dashboardColumns]
  )

  const selectableColumns = useMemo(
    () =>
      columnsWithUserSelectedColumnsPreserved.filter(
        (c) => forcedVisibilityColumns.indexOf(c.attribute) === -1 && !checkIsColumnNonHideable(c)
      ),
    [columnsWithUserSelectedColumnsPreserved, forcedVisibilityColumns]
  )

  const filterableColumns = useMemo(
    () =>
      columnsWithUserSelectedColumnsPreserved.filter((c) => {
        if (nonFilterableColumns && nonFilterableColumns.includes(c.apiName)) {
          return false
        }
        return forcedVisibilityColumnsFilterability[c.attribute] !== false
      }),
    [columnsWithUserSelectedColumnsPreserved, forcedVisibilityColumnsFilterability, nonFilterableColumns]
  )

  const selectedColumns = useMemo(() => filteredColumns.filter((c) => c.attribute), [filteredColumns])

  const selectedColumnsMap = useMemo(
    () =>
      selectedColumns.reduce(
        (acc, next) => {
          acc[next.apiName] = next
          return acc
        },
        {} as Record<string, DashboardColumn>
      ),
    [selectedColumns]
  )

  const handleSingleColumnSelectionChange = useCallback(
    (column: DashboardColumn) => {
      const existingColumn = filteredColumns.find((c) => c.apiName === column.apiName)
      let newColumns = filteredColumns
        .map((c) => ({ display: c.title, value: c.apiName }))
        .filter((c) => c.value !== column.apiName)

      forcedVisibilityColumns.forEach((forcedColumn) => {
        if (!newColumns.some((col) => col.value === forcedColumn)) {
          newColumns = [
            ...newColumns.slice(0, 1),
            { display: forcedVisibilityColumnDisplayNames[forcedColumn], value: forcedColumn },
            ...newColumns.slice(1),
          ]
        }
      })

      if (existingColumn && !forcedVisibilityColumns.includes(existingColumn.apiName) && column.hideable !== false) {
        // If already added, remove the column, unless it's a forced visibility column
        handleColumnSelectionChange(newColumns)
        const columnSortMatcher = (col: { sortField: string }) => col.sortField === column.apiName
        const sortsWithColumnRemoved = sorts?.filter((sort) => !columnSortMatcher(sort)) ?? []
        const isRemovedColumnInDefaultSorts = defaultSorts.some(columnSortMatcher)
        const shouldUseDefaultSorts = sortsWithColumnRemoved.length > 0 && !isRemovedColumnInDefaultSorts
        dispatch(dashboardsActions.sorts.set.action(shouldUseDefaultSorts ? defaultSorts : sortsWithColumnRemoved))
      } else {
        handleColumnSelectionChange([
          ...newColumns.slice(0, 2),
          { display: column.title, value: column.apiName },
          ...newColumns.slice(2),
        ])
      }
    },
    [
      filteredColumns,
      forcedVisibilityColumns,
      forcedVisibilityColumnDisplayNames,
      handleColumnSelectionChange,
      dispatch,
      dashboardsActions.sorts.set,
      sorts,
      defaultSorts,
    ]
  )

  return {
    columns,
    columnsWithUserSelectedColumnsPreserved,
    loadingColumns,
    loadingMetadata,
    initialLoadComplete: true,
    handleColumnOrderChange,
    handleColumnSelectionChange,
    handleSingleColumnSelectionChange,
    filteredColumns,
    batchColumnEnabled: isBatchColumnEnabled,
    filterableColumns,
    selectedColumns,
    selectedColumnsMap,
    selectableColumns,
    metadata,
  }
}
