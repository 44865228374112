import React, { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Link } from 'react-router-dom'

import { useDispatch } from 'actions/store'

import { Theme } from 'types/hb'
import { navigateToCompiledUrl } from 'utils/navigationHelpers'

// Other components still use this
export const useLinkStyles = makeStyles((theme: Theme) => ({
  root: ({ size }: { size: 'md' | 'sm' }) => ({
    cursor: 'pointer',
    textDecoration: 'none',
    ...theme.typography[size],

    '&:hover, &:focus-visible': {
      textDecoration: 'underline',
    },

    '&, &:link, &:visited, &:active, &:hover': {
      color: theme.palette.action.active,
    },
  }),
  disabled: ({ size }: { size: 'md' | 'sm' }) => ({
    cursor: 'unset',
    color: theme.palette.action.disabled,
    ...theme.typography[size],
  }),
}))

interface StyledLinkProps {
  size: 'md' | 'sm'
  disabled?: boolean
}

const StyledLink = styled(Link)<StyledLinkProps>(({ theme, size, disabled }) => ({
  cursor: 'pointer',
  textDecoration: 'none',
  ...theme.typography[size],

  '&:hover, &:focus-visible': {
    textDecoration: 'underline',
  },

  '&, &:link, &:visited, &:active, &:hover': {
    color: theme.palette.action.active,
  },

  ...(disabled
    ? {
        cursor: 'unset',
        color: theme.palette.action.disabled,
        ...theme.typography[size],
      }
    : {}),
}))

export interface Props {
  className?: string
  title: ReactNode
  to: string
  size?: 'md' | 'sm'
  openInNewTab?: boolean
}

export function InternalLink({ className, title, to, size = 'md', openInNewTab }: Props) {
  const dispatch = useDispatch()

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(navigateToCompiledUrl(e, to, openInNewTab))
  }

  return (
    <StyledLink className={className} size={size} to={to} onClick={onClick}>
      {title}
    </StyledLink>
  )
}
