import { MenuItem, Select } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { FieldPathByValue, Controller } from 'react-hook-form'
import invariant from 'tiny-invariant'

import Loader from 'components/library/Loader'
import { Theme } from 'types/hb'

import { FormSchema, TriggerSchemaReturnType } from '../formSchema'

import { DottedLineController, DottedLineRoot } from './DottedLines'
import LogicalExpressionEditor from './LogicalExpressionEditor'

import { VariableEditor } from './VariableEditor'
import {
  AutomationDomain,
  fieldSpecConfigForVariablePath,
  operatorLabels,
  useDomainFieldSpecs,
  type FieldSpec,
} from './fieldConfig'
import { useThinControlStyles } from './styles'

import type { StrictExpressionValue, VariableNode, Operator } from 'types/automations'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  variableEditorContainer: {
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
  },
  logicalExpressionContainer: {
    paddingLeft: theme.spacing(9 + 1 + 3),
    marginRight: theme.spacing(-6), // Terrible and awful? Or mad genius?
    position: 'relative',
  },
  operator: {
    flexShrink: 1,
  },
  right: {
    flexGrow: 1,
  },
}))

export type FieldPath = FieldPathByValue<FormSchema, StrictExpressionValue>

interface Props {
  name: FieldPath
  domain: AutomationDomain
  form: TriggerSchemaReturnType
  parentVariableNode?: VariableNode | null
  onChangeToListExpression: (newPath: string[], defaultPath: string[] | null | undefined) => void
  onChangeToLinkedTrigger: (newPath: string[], subDomain: AutomationDomain) => void
  onChangeToBinaryExpression: (defaultPath: string[] | null | undefined) => void
  onChangeToTypeCast: (newPath: string[], lastFieldSpec: FieldSpec, newFieldSpec: FieldSpec) => void
  onChangeFromTypeCast: (newPath: string[], lastFieldSpec: FieldSpec, newFieldSpec: FieldSpec) => void
}

export default function ListExpressionEditor({
  name,
  domain,
  form,
  parentVariableNode,
  onChangeToListExpression,
  onChangeToLinkedTrigger,
  onChangeToBinaryExpression,
  onChangeToTypeCast,
  onChangeFromTypeCast,
}: Props) {
  const styles = useStyles()
  const controlStyles = useThinControlStyles()
  const { watch } = form
  const node = watch(name)

  invariant(node.type === 'list_expression' || node.type === 'linked_trigger')

  const lastFieldName = node.value.path.at(-1)

  const { loading, getDomainFieldSpec } = useDomainFieldSpecs()

  if (loading) {
    return <Loader variant="global" />
  }

  const fieldConfig = fieldSpecConfigForVariablePath(
    domain,
    getDomainFieldSpec,
    (parentVariableNode?.path || []).concat(node.value.path)
  )
  invariant(lastFieldName && !!fieldConfig[lastFieldName].fieldSpec, `Expected field spec for ${lastFieldName}`)

  const lastFieldConfig = fieldConfig[lastFieldName]
  const lastFieldSpec = lastFieldConfig.fieldSpec

  const handleChangeToTypeCast = (newPath: string[], newFieldSpec: FieldSpec) => {
    onChangeToTypeCast(newPath, lastFieldSpec, newFieldSpec)
  }

  const handleChangeFromTypeCast = (newPath: string[], newFieldSpec: FieldSpec) => {
    onChangeFromTypeCast(newPath, lastFieldSpec, newFieldSpec)
  }

  const showEditor = ['field', 'fieldList', 'nestedList', 'linkedField'].includes(lastFieldSpec.type)
  // TODO(ali): This is a hack to show singular relationships (e.g. review -> case)
  // We want the same UI and logical expression as a list operator
  // but just dont want to show the operator selector
  const showOperatorEditor = lastFieldSpec.type !== 'linkedField' || !lastFieldSpec.singular

  // TODO(ali): This is really bad, we have control flow logic for adding expressions
  // that are using null vs undefined. We should clean this up.
  const defaultPath =
    lastFieldSpec.type === 'nestedList'
      ? [Object.keys(lastFieldSpec.values)[0]]
      : lastFieldSpec.type === 'linkedField'
        ? undefined
        : null

  const subParentVariableNode = lastFieldSpec.type === 'linkedField' ? null : node.value
  const subDomain =
    lastFieldSpec.type === 'linkedField' ? { type: lastFieldSpec.domainType, datasourceToken: null } : domain

  return (
    <DottedLineController>
      <div className={styles.root}>
        <DottedLineRoot transform={(rect) => ({ x: rect.x + 90, y: rect.y + rect.height })}>
          {(dottedLineRootRef) => (
            <div className={styles.variableEditorContainer} ref={dottedLineRootRef}>
              {showEditor && showOperatorEditor && (
                <Controller
                  name={`${name}.operator`}
                  render={({ field }) => (
                    <Select
                      className={classNames(styles.operator, controlStyles.control)}
                      required
                      inputProps={field}
                      variant="outlined"
                      data-testid="list-expression-operator"
                    >
                      {['any', 'all', 'none'].map((o: Operator) => (
                        <MenuItem key={o} value={o}>
                          {operatorLabels[o]}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )}
              <VariableEditor
                config={fieldConfig}
                name={`${name}.value`}
                domain={domain}
                onChangeToBinaryExpression={onChangeToBinaryExpression}
                onChangeToLinkedTrigger={onChangeToLinkedTrigger}
                onChangeToListExpression={onChangeToListExpression}
                onChangeToTypeCast={handleChangeToTypeCast}
                onChangeFromTypeCast={handleChangeFromTypeCast}
              />
            </div>
          )}
        </DottedLineRoot>
        <div className={styles.logicalExpressionContainer}>
          {showEditor && (
            <LogicalExpressionEditor
              name={`${name}.expression`}
              domain={subDomain}
              form={form}
              parentVariableNode={subParentVariableNode}
              defaultPath={defaultPath}
              nested
            />
          )}
        </div>
      </div>
    </DottedLineController>
  )
}
