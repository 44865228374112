import { useEffect } from 'react'

// eslint-disable-next-line no-restricted-imports
import { Typography, styled } from '@mui/material'

import classnames from 'classnames'
import { useFormikContext } from 'formik'
import { get } from 'lodash'

import { useSelector } from 'actions/store'
import { HbText } from 'components/HbComponents/Text/HbText'
import { getLegacyColorByName } from 'components/colors'

import SARFieldAdornment from 'components/library/SARFieldAdornment'
import {
  LabeledField,
  TextField,
  IDNumberAndTypeField,
  CountryAndStateField,
  CountryFieldOld,
} from 'components/material/Form'

import { SparseDateField } from 'components/material/Form/SparseDateInput'
import { opacify } from 'helpers/colors'
import { getCountryByCode } from 'helpers/stateHelpers'
import { displayPartialDate } from 'helpers/uiHelpers'
import { IdentityDocumentTypeEnums, IdentityDocumentTypeStrings } from 'strings/strings'
import { IdentityDocumentTypeEnum, LibraryIdentityDocument, Occupation } from 'types/api'

import { createInfoFieldSet, revisionUpdateHighlightColors, EditProps, InfoFieldProps } from '../InformationFields'
import { RevisionProps } from '../InformationFields.types'

import { useInlineFieldStyles, validateSparseDateField } from './FieldEdit'

export const OccupationFieldSet = createInfoFieldSet<Occupation>(
  (o) => o.company ?? 'Unknown company',
  (o) => [o.occupation ?? ''],
  (o) => [{ title: o.industry }, { title: o.naicsCode }, { title: o.companyWebsite, externalLink: true }]
)

export function OccupationFieldSetEdit({ name }: EditProps) {
  const { root, fill, second } = useInlineFieldStyles()

  return (
    <>
      <LabeledField
        adornment={<SARFieldAdornment field="10: Occupation or type of business" />}
        label="Occupation"
        name={name('occupation')}
      />
      <LabeledField label="Company" name={name('company')} />
      <div className={root}>
        <LabeledField classes={{ root: fill }} label="Industry" name={name('industry')} />
        <LabeledField
          classes={{ root: classnames(fill, second) }}
          adornment={<SARFieldAdornment field="10a: NAICS Code" />}
          label="NAICS Code"
          name={name('naicsCode')}
        />
      </div>
      <LabeledField FieldComponent={TextField} label="Company Website" name={name('companyWebsite')} />
    </>
  )
}

const IdDocumentItem = styled(HbText)(() => ({}))

const IdDocumentFieldsetContainer = styled('div')<RevisionProps>(
  ({ hasDeletedDataInRevision, hasUpdatedDataInRevision, theme }) => ({
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: 'auto auto auto',
    gridRowGap: theme.spacing(2),
    gridColumnGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(4),
    padding: theme.spacing(2.5, 3),
    borderRadius: theme.shape.largeContainer.borderRadius,
    backgroundColor: hasDeletedDataInRevision
      ? theme.palette.styleguide.backgroundLight
      : hasUpdatedDataInRevision
        ? revisionUpdateHighlightColors.background
        : opacify(getLegacyColorByName('darkGreen'), 0.12),

    [`& ${IdDocumentItem}`]: hasDeletedDataInRevision
      ? {
          color: theme.palette.styleguide.textGreyDark,
          textDecoration: 'line-through',
        }
      : hasUpdatedDataInRevision
        ? {
            color: revisionUpdateHighlightColors.color,
            fontWeight: theme.fontWeight.bold,
          }
        : {},
  })
)

const IdDocumentItemTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.sizes.s,
  color: theme.palette.styleguide.textGreyDark,
}))

interface IdentityDocumentItemProps {
  title: string
  value?: string | null
}

function IdentityDocumentItem(props: IdentityDocumentItemProps) {
  const { title, value } = props

  return (
    <div>
      <IdDocumentItemTitle variant="body2">{title}</IdDocumentItemTitle>
      <IdDocumentItem>{value}</IdDocumentItem>
    </div>
  )
}

export const getIdentityDocumentDiffKey = (value: LibraryIdentityDocument) =>
  Object.values(value).filter(Boolean).join('_') ?? ''

export function IdentityDocumentFieldSet({
  data,
  hasDeletedDataInRevision,
  hasUpdatedDataInRevision,
}: InfoFieldProps<LibraryIdentityDocument>) {
  const country = useSelector((state) =>
    data.issuingCountryCode ? getCountryByCode(state, data.issuingCountryCode) : null
  )

  const nationalityCountry = useSelector((state) =>
    data.nationalityCountryCode ? getCountryByCode(state, data.nationalityCountryCode) : null
  )

  return (
    <IdDocumentFieldsetContainer
      hasDeletedDataInRevision={hasDeletedDataInRevision}
      hasUpdatedDataInRevision={hasUpdatedDataInRevision}
    >
      <IdentityDocumentItem title="ID Type" value={data.type && IdentityDocumentTypeStrings[data.type]} />
      {data.type === IdentityDocumentTypeEnum.Other ? (
        <IdentityDocumentItem title="ID Type Other" value={data.typeOther} />
      ) : null}
      <IdentityDocumentItem title="Number" value={data.number} />
      <IdentityDocumentItem title="Issuing Country" value={country?.name} />
      <IdentityDocumentItem
        title="Issuing State"
        value={country?.subdivisions.find((s) => s.value === data.issuingStateCode)?.display}
      />
      {data.type === IdentityDocumentTypeEnum.Other ? (
        <IdentityDocumentItem title="Nationality" value={nationalityCountry?.name} />
      ) : null}
      <IdentityDocumentItem title="Exp. date" value={data.expiresOn && displayPartialDate(data.expiresOn)} />
    </IdDocumentFieldsetContainer>
  )
}

export function IdentityDocumentFieldSetEdit({ name }: EditProps) {
  const { values, setFieldValue } = useFormikContext()
  const nationalityName = name('nationalityCountryCode')

  const idTypeName = name('type')
  const idTypeValue = get(values, idTypeName)

  useEffect(() => {
    if (idTypeValue !== 'OTHER') {
      setFieldValue(nationalityName, undefined)
    }
  }, [idTypeValue, nationalityName, setFieldValue])

  return (
    <>
      <IDNumberAndTypeField
        typeAdornment={<SARFieldAdornment field="18: Form of identification for subject - type" />}
        numberAdornment={<SARFieldAdornment field="18: Form of identification for subject - number" />}
        typeLabel="ID Type"
        typeName={name('type')}
        numberLabel="Number"
        numberName={name('number')}
        typeOptions={IdentityDocumentTypeEnums}
        withOther
        allowAsterisk
      />
      <CountryAndStateField
        countryAdornment={<SARFieldAdornment field="18: Form of identification for subject - country" />}
        stateAdornment={<SARFieldAdornment field="18: Form of identification for subject - issuing state" />}
        countryLabel="Issuing Country"
        countryName={name('issuingCountryCode')}
        stateLabel="Issuing State"
        stateName={name('issuingStateCode')}
      />
      {idTypeValue === 'OTHER' ? <CountryFieldOld name={nationalityName} label="Nationality" /> : null}
      <LabeledField
        label="Expiration Date"
        name={name('expiresOn')}
        FieldComponent={SparseDateField}
        validate={validateSparseDateField}
      />
    </>
  )
}
