import { ChangeEvent, useCallback, useMemo, useEffect, useState } from 'react'

import { MenuItem, Select, Switch, FormControl, FormHelperText, FormControlLabel } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { capitalize } from 'lodash'
import pluralize from 'pluralize'
import { Controller, UseFormWatch } from 'react-hook-form'

import NumberFormat from 'react-number-format'
import invariant from 'tiny-invariant'

import { useSelector } from 'actions/store'

import { HbCheckbox } from 'components/HbComponents/Form/HbCheckbox'
import HbTextInput from 'components/HbComponents/Form/HbInputs/HbTextInput/HbTextInput'
import { HbText } from 'components/HbComponents/Text/HbText'
import { useQueues } from 'components/cases/reviews/queues/useQueues'
import { Option, QueueOrAssigneeAutocomplete } from 'components/library/QueueOrAssigneeAutocomplete'

import { getAccountReviewTypes, getOrganizationTeammates } from 'helpers/stateHelpers'

import { Theme } from 'types/hb'

import { useIsAutomationRule } from '../AutomationRuleOrTemplateContext'
import { useThinControlStyles } from '../TriggerFilterEditor/styles'

import ActivityTagsByTask from './ActivityTagsByTask'
import NarrativePrepopulation from './components/NarrativePrepopulation'
import OtherInfo from './components/OtherInfo'
import ToggleSection from './components/ToggleSection'
import { DATE_UNIT_SHORT_TO_LONG, reviewCanonicalTypeOptions } from './shared'
import { useReviewTypePrepopulatedFields } from './useReviewTypePrepopulatedFields'

import type { FormSchemaReturnType, FormSchema, FormSchemaForRule } from '../formSchema'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
  },
  dueDateInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr  2fr 2fr',
    gap: theme.spacing(),
    alignItems: 'baseline',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  label: {
    paddingBottom: theme.spacing(1),
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  assignField: {
    paddingBottom: theme.spacing(1),
    borderRadius: theme.shape.largeContainer.borderRadius,
    '&:disabled': {
      backgroundColor: theme.palette.background.medium,
      color: theme.palette.grey,
    },
  },
  transferAssigneeButton: {
    display: 'flex',
    gap: theme.spacing(1),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectReviewType: {
    paddingTop: theme.spacing(1),
  },
  grayOut: {
    color: theme.palette.styleguide.textGreyLight,
  },
}))

const CREATE_REVIEW_PARAMS_PATH = 'actionParams.createReviewParams'

const useClasses = () => {
  const classes = useStyles()
  const controlClasses = useThinControlStyles()
  const selectClasses = {
    className: controlClasses.control,
    classes: { root: controlClasses.selectRoot },
  }
  return { classes, controlClasses, selectClasses }
}

function CustomizeCreateReviewRule({ form }: { form: FormSchemaReturnType }) {
  const { classes, selectClasses, controlClasses } = useClasses()
  const reviewTypes = useSelector(getAccountReviewTypes)
  const accounts = useSelector(getOrganizationTeammates)
  const { queues: queuesData } = useQueues()

  const isRule = useIsAutomationRule()

  const queues = useMemo(
    () => queuesData.map((queue) => ({ color: queue.color, name: queue.name, token: queue.token })),
    [queuesData]
  )

  const options = useMemo(() => reviewCanonicalTypeOptions(reviewTypes), [reviewTypes])
  const { setValue, control, watch } = form
  const { queueToken, assigneeToken, reviewTypeCanonicalId, transferReviewAssignee } = (
    watch as UseFormWatch<FormSchemaForRule>
  )(CREATE_REVIEW_PARAMS_PATH)

  const domainType = watch('domain.type')

  const token = queueToken ?? assigneeToken ?? undefined
  const tokenType = queueToken ? 'queue' : assigneeToken ? 'account' : null
  const { dueDateRelativeIncrementValue: dueDateValue } = watch(CREATE_REVIEW_PARAMS_PATH) || {}
  const { openAtRelativeIncrementValue: openAtValue } = watch(CREATE_REVIEW_PARAMS_PATH) || {}

  const [_showDueDate, setShowDueDate] = useState<boolean>()
  const showDueDate = !!dueDateValue || _showDueDate

  const [_showOpenAt, setShowOpenAt] = useState<boolean>()
  const showOpenAt = !!openAtValue || _showOpenAt

  const { tagggingTasks, hasNarrative: showNarrative } = useReviewTypePrepopulatedFields({
    canonicalId: reviewTypeCanonicalId,
  })

  const handleChange = useCallback(
    (_e: ChangeEvent, option: Option | null) => {
      if (!option) return
      if (option.custom.type === 'account') {
        setValue(`${CREATE_REVIEW_PARAMS_PATH}.assigneeToken`, option.value)
        setValue(`${CREATE_REVIEW_PARAMS_PATH}.queueToken`, null, { shouldDirty: true })
      } else {
        setValue(`${CREATE_REVIEW_PARAMS_PATH}.queueToken`, option.value)
        setValue(`${CREATE_REVIEW_PARAMS_PATH}.assigneeToken`, null, { shouldDirty: true })
      }
    },
    [setValue]
  )

  useEffect(() => {
    if (!showNarrative) {
      setValue(`${CREATE_REVIEW_PARAMS_PATH}.narrative`, null)
    }
  }, [setValue, showNarrative])

  useEffect(() => {
    if (domainType === 'case') {
      setValue(`${CREATE_REVIEW_PARAMS_PATH}.transferReviewAssignee`, false)
    }
  }, [domainType, setValue])

  const isReviewTriggered = domainType === 'review'

  if (!options) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.subcontainer}>
        <Controller<FormSchema>
          name={`${CREATE_REVIEW_PARAMS_PATH}.reviewTypeCanonicalId`}
          render={({ field: { name, value, onChange, ...inputProps }, fieldState }) => (
            <FormControl error={fieldState.invalid}>
              <HbText size="s" bold color="secondary">
                Select Review Type
              </HbText>
              <div className={classes.selectReviewType}>
                <Select
                  disabled={!isRule}
                  name={name}
                  value={value}
                  {...selectClasses}
                  variant="outlined"
                  onChange={(e) => {
                    const newToken = e.target.value
                    const reviewType = options.find((def) => def.value === newToken)
                    invariant(reviewType, 'Review type not found')
                    setValue('actionText', `Create review of type ${reviewType.display}`)
                    onChange(reviewType.value)
                  }}
                  inputProps={inputProps}
                >
                  {options.map((type) => (
                    <MenuItem value={type.value} key={type.value}>
                      {type.display}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </div>
            </FormControl>
          )}
        />
      </div>
      <div className={classes.subcontainer}>
        <FormControlLabel
          checked={!!showOpenAt}
          label="Open at date"
          control={
            <HbCheckbox
              value={showOpenAt}
              onChange={() => {
                if (showOpenAt) {
                  setShowOpenAt(false)
                  setValue(`${CREATE_REVIEW_PARAMS_PATH}.openAtRelativeIncrementValue`, null, { shouldDirty: true })
                  setValue(`${CREATE_REVIEW_PARAMS_PATH}.openAtRelativeIncrementUnit`, null, { shouldDirty: true })
                } else {
                  setShowOpenAt(true)
                }
              }}
            />
          }
        />
        {showOpenAt && (
          <div className={classes.dueDateInfo}>
            <Controller
              name={`${CREATE_REVIEW_PARAMS_PATH}.openAtRelativeIncrementValue`}
              control={control}
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  inputContainerClassName={controlClasses.numberFormatRoot}
                  customInput={HbTextInput}
                  defaultValue={0}
                  label=""
                  onChange={() => null}
                  onValueChange={({ floatValue }) => {
                    field.onChange(floatValue)
                  }}
                />
              )}
            />
            <Controller<FormSchema>
              name={`${CREATE_REVIEW_PARAMS_PATH}.openAtRelativeIncrementUnit`}
              control={control}
              render={({ field }) => (
                <Select {...selectClasses} inputProps={field} variant="outlined">
                  {DATE_UNIT_SHORT_TO_LONG.map((unit) => (
                    <MenuItem value={unit} key={unit}>
                      {`${pluralize(capitalize(unit), openAtValue || 0)}`}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <HbText>after the review is created</HbText>
          </div>
        )}
      </div>
      <div className={classes.subcontainer}>
        <FormControlLabel
          checked={!!showDueDate}
          label="Due date"
          control={
            <HbCheckbox
              value={showDueDate}
              onChange={() => {
                if (showDueDate) {
                  setShowDueDate(false)
                  setValue(`${CREATE_REVIEW_PARAMS_PATH}.dueDateRelativeIncrementValue`, null, { shouldDirty: true })
                  setValue(`${CREATE_REVIEW_PARAMS_PATH}.dueDateRelativeIncrementUnit`, null, { shouldDirty: true })
                } else {
                  setShowDueDate(true)
                }
              }}
            />
          }
        />
        {showDueDate && (
          <div className={classes.dueDateInfo}>
            <Controller
              name={`${CREATE_REVIEW_PARAMS_PATH}.dueDateRelativeIncrementValue`}
              control={control}
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  inputContainerClassName={controlClasses.numberFormatRoot}
                  customInput={HbTextInput}
                  defaultValue={0}
                  label=""
                  onChange={() => null}
                  onValueChange={({ floatValue }) => {
                    field.onChange(floatValue)
                  }}
                />
              )}
            />
            <Controller<FormSchema>
              name={`${CREATE_REVIEW_PARAMS_PATH}.dueDateRelativeIncrementUnit`}
              control={control}
              render={({ field }) => (
                <Select {...selectClasses} inputProps={field} variant="outlined">
                  {DATE_UNIT_SHORT_TO_LONG.map((unit) => (
                    <MenuItem value={unit} key={unit}>
                      {`${pluralize(capitalize(unit), dueDateValue || 0)}`}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <HbText>after the review is {showOpenAt ? 'opened' : 'created'}</HbText>
          </div>
        )}
      </div>
      <div className={classes.subcontainer}>
        <HbText size="s" bold color="secondary">
          Assign To
        </HbText>
        <QueueOrAssigneeAutocomplete
          disabled={!isRule || transferReviewAssignee}
          className={classes.assignField}
          accounts={accounts}
          handleChange={handleChange}
          queues={queues}
          value={{ type: tokenType, value: token }}
        />
        <Controller
          name={`${CREATE_REVIEW_PARAMS_PATH}.transferReviewAssignee`}
          render={({ field }) => (
            <FormControlLabel
              className={classes.transferAssigneeButton}
              control={
                <Switch
                  disabled={!isReviewTriggered}
                  onChange={(_, val) => {
                    field.onChange(val)
                    setValue(`${CREATE_REVIEW_PARAMS_PATH}.queueToken`, null)
                    setValue(`${CREATE_REVIEW_PARAMS_PATH}.assigneeToken`, null)
                  }}
                  onBlur={() => field.onBlur()}
                  checked={field.value}
                />
              }
              labelPlacement="start"
              label={
                <HbText bold color="secondary" className={!isReviewTriggered ? classes.grayOut : ''}>
                  Keep existing assignee
                </HbText>
              }
            />
          )}
        />
        {tokenType === 'queue' && <HbText size="s">Note: Auto-assign rules will still apply.</HbText>}
      </div>
      <HbText size="s" bold color="secondary">
        Review Information
      </HbText>
      <ToggleSection
        toggle={<HbText>Custom Fields</HbText>}
        content={<OtherInfo paramsPath={CREATE_REVIEW_PARAMS_PATH} form={form} />}
        id="otherInfo"
      />
      {showNarrative ? (
        <ToggleSection
          toggle={<HbText>Narrative</HbText>}
          content={<NarrativePrepopulation paramsPath={CREATE_REVIEW_PARAMS_PATH} form={form} />}
          id="narrative"
        />
      ) : null}
      <Controller
        name={`${CREATE_REVIEW_PARAMS_PATH}.activityTags`}
        control={control}
        defaultValue={[]}
        render={({ field }) => {
          const handleTagChange = field.onChange
          return <ActivityTagsByTask tagggingTasks={tagggingTasks} value={field.value} onChange={handleTagChange} />
        }}
      />
    </div>
  )
}

export default function CustomizeCreateReview({ form }: { form: FormSchemaReturnType }) {
  return <CustomizeCreateReviewRule form={form} />
}
