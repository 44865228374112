import { MutationOptions, gql } from '@apollo/client'

import { GraphQLErrors } from '@apollo/client/errors'

import { isArray, isEmpty, isString, isObject, isUndefined } from 'lodash'

import moment from 'moment'
import { sentenceCase } from 'sentence-case'

import { setFlashError } from 'actions/errorActions'
import { loadInvestigation } from 'actions/investigationsActions'

import { setLoading, closeActiveImportForm, addPendingOperation, removePendingOperation } from 'actions/viewActions'

import { GET_CASE_FILES_FRAGMENTS } from 'components/cases/Tabs/Files/CaseFilesTab.queries'
import { getCaseLock } from 'components/cases/hooks/useCaseLock'
import { UPSERT_LIBRARY_ENTITY_QUERY } from 'components/cases/information/LibraryData/LibraryNewForm.queries'
import {
  UpsertLibraryEntityMutation,
  UpsertLibraryEntityMutationVariables,
} from 'components/cases/information/LibraryData/__generated__/LibraryNewForm.queries.generated'
import { isEmptyObject } from 'helpers/objHelpers'
import { getOrganizationFeatureFlag } from 'helpers/stateHelpers'
import { tokenize } from 'helpers/uiHelpers'

import { FeatureFlag, LibraryTypeEnum } from 'types/api'

import {
  CreateAttachmentsMutation,
  CreateAttachmentsMutationVariables,
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables,
  RenameAttachmentMutation,
  RenameAttachmentMutationVariables,
} from './__generated__/importingActions.generated'
import { enqueueReduxSnackbar } from './applicationActions'
import { uploadFile } from './parsingActions'
import { AsyncThunk } from './store'

export function formatFormikObject(object: any, skipKeys?: string[]) {
  if (isObject(object) && moment.isMoment(object)) {
    return object.format()
  }

  if (isArray(object)) {
    return object.reduce((accum, entry) => {
      const formatted = formatFormikObject(entry, skipKeys)
      if (isEmptyObject(formatted, ['token', 'type'])) {
        return accum
      }
      accum.push(formatted)
      return accum
    }, [])
  }

  if (isObject(object)) {
    return Object.keys(object).reduce(
      (accum, key) => {
        // These values are added to non-CRM
        if (skipKeys?.includes(key)) {
          return accum
        }

        accum[key] = formatFormikObject((object as any)[key], skipKeys)
        return accum
      },
      {} as Record<string, unknown>
    )
  }

  if (isString(object)) {
    const trimmedValue = object.trim()
    return isEmpty(trimmedValue) ? null : trimmedValue
  }

  if (isUndefined(object)) {
    return null
  }

  return object
}

export function getAttachmentUploadOpKey(ownerToken: string) {
  return `${ownerToken}_attachment_upload`
}

export interface FileUpload {
  type: string
  name: string
  contents: ArrayBuffer
}

export const CREATE_ATTACHMENTS_MUTATION = gql`
  mutation CreateAttachments($input: CreateAttachmentsInput!) {
    createAttachments(input: $input) {
      investigation {
        token
      }
    }
  }
`

export function saveFileData(
  investigationToken: string,
  fileData: FileUpload[],
  { onSuccess, onFailure }: { onSuccess?: () => void; onFailure?: () => void } = {}
): AsyncThunk<void> {
  return async (dispatch, _getState, { api, gqlClient }) => {
    const { isLocked } = await getCaseLock(gqlClient, investigationToken)

    if (isLocked) {
      return
    }

    const apiToken = `${investigationToken}-file-${fileData[0].name}`
    const opKey = getAttachmentUploadOpKey(investigationToken)
    dispatch(addPendingOperation(opKey))

    await api.debounce(apiToken, async (resolve) => {
      const onError = (error: string) => {
        dispatch(removePendingOperation(opKey))

        if (onFailure) {
          onFailure()
        }
        if (error) {
          dispatch(setFlashError(error))
        }
        resolve(false as any)
      }

      const fileTokens = await Promise.all(
        fileData.map(async (file) => {
          const uploadToken = await dispatch(uploadFile('attachments', file.contents))
          if (!uploadToken) {
            return null
          }

          return {
            name: file.name,
            contentType: file.type,
            uploadToken,
          }
        })
      )

      // If any of the uploads failed, show an error
      if (fileTokens.some((fileObj) => fileObj === null || !fileObj.uploadToken)) {
        onError('File upload failed')
        return
      }

      const { errors } = await gqlClient.mutate<CreateAttachmentsMutation, CreateAttachmentsMutationVariables>({
        mutation: CREATE_ATTACHMENTS_MUTATION,
        variables: {
          input: {
            token: investigationToken,
            attachments: fileTokens as { name: string; contentType: string; uploadToken: string }[],
          },
        },
        errorPolicy: 'all',
      })

      if (errors) {
        onError(errors.join(' '))
      } else {
        if (onSuccess) {
          onSuccess()
        }
        if (api.callbackStillRegistered(apiToken)) {
          dispatch(removePendingOperation(opKey))
          dispatch(closeActiveImportForm(investigationToken))
          resolve(true as any)
        } else {
          dispatch(removePendingOperation(opKey))
          resolve(true as any)
        }
      }
    })
  }
}

export function saveLibraryFileData(
  ownerToken: string,
  ownerType: LibraryTypeEnum,
  fileData: FileUpload[],
  refetchQueries: MutationOptions['refetchQueries'],
  { onSuccess, onFailure }: { onSuccess?: () => void; onFailure?: () => void } = {}
): AsyncThunk<void> {
  return async (dispatch, _getState, { api, gqlClient }) => {
    if (!fileData[0] || !fileData[0].name) {
      return
    }

    const apiToken = `${ownerToken}-${ownerType}-file-${fileData[0].name}`
    const opKey = getAttachmentUploadOpKey(ownerToken)
    dispatch(addPendingOperation(opKey))

    await api.debounce(apiToken, async (resolve) => {
      const onError = (error: string) => {
        dispatch(removePendingOperation(opKey))
        if (onFailure) {
          onFailure()
        }
        if (error) {
          dispatch(setFlashError(error))
        }
        resolve(false as any)
      }

      const fileTokens = await Promise.all(
        fileData.map(async (file) => {
          const uploadToken = await dispatch(uploadFile('attachments', file.contents))
          if (!uploadToken) {
            return null
          }

          return {
            name: file.name,
            contentType: file.type,
            uploadToken,
          }
        })
      )

      // If any of the uploads failed, show an error
      if (fileTokens.some((fileObj) => fileObj === null || !fileObj.uploadToken)) {
        onError('File upload failed')
        return
      }

      const attachments = fileTokens.map((fileToken) => {
        return {
          owner: { type: ownerType, key: { token: ownerToken } },
          attributes: {
            filename: fileToken?.name || '',
            contentType: fileToken?.contentType || '',
            uploadToken: fileToken?.uploadToken || '',
          },
        }
      })

      const input = { attachments }
      const { errors } = await gqlClient.mutate<UpsertLibraryEntityMutation, UpsertLibraryEntityMutationVariables>({
        refetchQueries,
        mutation: UPSERT_LIBRARY_ENTITY_QUERY,
        variables: { input },
        errorPolicy: 'all',
      })

      if (errors) {
        onError(errors.join(' '))
      } else {
        if (onSuccess) {
          onSuccess()
        }
        if (api.callbackStillRegistered(apiToken)) {
          dispatch(removePendingOperation(opKey))
          resolve(true as any)
        } else {
          dispatch(removePendingOperation(opKey))
          resolve(true as any)
        }
      }
    })
  }
}

type MakeEditOrDeleteAsyncThunkProps = {
  caseToken: string
  token: string | null
  itemType: string
  actionType: 'edit' | 'delete'
  url: string
  data?: Record<string, unknown>
}

const makeEditOrDeleteAsyncThunk: (props: MakeEditOrDeleteAsyncThunkProps) => AsyncThunk<void> =
  (props) =>
  async (dispatch, getState, { api, gqlClient }): Promise<void> => {
    const { caseToken, token, itemType, actionType, url, data } = props

    const { isLocked } = await getCaseLock(gqlClient, caseToken)

    if (isLocked) {
      return
    }

    const urlParams: Partial<{
      investigationToken: string
      token: string
    }> = { investigationToken: caseToken }

    if (token) {
      urlParams.token = token
      dispatch(setLoading(true, token))
    }

    const apiToken = tokenize(caseToken, itemType, token ?? '')

    const payload = {
      urlParams,
      query: {
        investigationToken: caseToken,
      },
      ...(data ? { data } : {}),
    }

    const handleResponse = (json: any, resolve: (d: null) => void) => {
      if (token) {
        dispatch(setLoading(false, token))
      }

      if (json.success) {
        dispatch(enqueueReduxSnackbar(`${sentenceCase(itemType)} ${actionType === 'delete' ? 'deleted' : 'updated'}`))
        dispatch(loadInvestigation({ token: caseToken })).then(() => {
          resolve(true as any)
        })
      } else {
        if (json.error?.message) {
          dispatch(setFlashError(json.error.message))
        }
        resolve(null)
      }
    }

    await api.debounce(apiToken, (resolve) =>
      actionType === 'delete'
        ? api.delete(url, payload).then((json) => handleResponse(json, resolve))
        : api.put(url, payload).then((json) => handleResponse(json, resolve))
    )
  }

const deleteItemAction = (
  caseToken: string,
  token: string | null,
  {
    itemType,
    url,
  }: {
    itemType: string
    url: string
  }
) =>
  makeEditOrDeleteAsyncThunk({
    caseToken,
    token,
    itemType,
    url,
    actionType: 'delete',
  })

const RENAME_ATTACHMENT_MUTATION = gql`
  mutation RenameAttachment($input: RenameAttachmentInput!) {
    renameAttachment(input: $input) {
      attachment {
        token
        filename
      }
    }
  }
`

export function renameAttachment(caseToken: string, token: string, name: string): AsyncThunk<void> {
  return async (dispatch, getState, { api, gqlClient }): Promise<void> => {
    const itemType = 'attachment'

    const { isLocked } = await getCaseLock(gqlClient, caseToken)

    if (isLocked) {
      return
    }

    dispatch(setLoading(true, token))

    const apiToken = tokenize(caseToken, itemType, token ?? '')

    const handleResponse = (errors: GraphQLErrors | undefined, resolve: () => void) => {
      dispatch(setLoading(false, token))

      if (errors) {
        dispatch(setFlashError(errors.map((e) => e.message).join(', ')))
      } else {
        dispatch(enqueueReduxSnackbar(`${sentenceCase(itemType)} updated`))
      }

      resolve()
    }

    await api.debounce(apiToken, async (resolve) => {
      const { errors } = await gqlClient.mutate<RenameAttachmentMutation, RenameAttachmentMutationVariables>({
        mutation: RENAME_ATTACHMENT_MUTATION,
        variables: {
          input: {
            token,
            filename: name,
            investigationToken: caseToken,
          },
        },
        errorPolicy: 'all',
      })

      return handleResponse(errors, resolve)
    })
  }
}

const DELETE_ATTACHMENT_MUTATION = gql`
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $cursor: String
    $enableAttachmentSummarization: Boolean!
    $includeMalwareScanStatus: Boolean!
  ) {
    deleteAttachment(input: $input) {
      investigation {
        token
        ...GetCaseFilesFragment
      }
    }
  }

  ${GET_CASE_FILES_FRAGMENTS}
`

export function deleteAttachment(caseToken: string, token: string): AsyncThunk<void> {
  return async (dispatch, getState, { api, gqlClient }): Promise<void> => {
    const itemType = 'attachment'

    const { isLocked } = await getCaseLock(gqlClient, caseToken)

    if (isLocked) {
      return
    }

    dispatch(setLoading(true, token))

    const apiToken = tokenize(caseToken, itemType, token ?? '')

    const enableAttachmentSummarization = getOrganizationFeatureFlag(
      getState(),
      FeatureFlag.EnableAttachmentSummarization
    )

    const includeMalwareScanStatus = getOrganizationFeatureFlag(getState(), FeatureFlag.MalwareScanningUi)

    const handleResponse = (errors: GraphQLErrors | undefined, resolve: () => void) => {
      dispatch(setLoading(false, token))

      if (errors) {
        dispatch(setFlashError(errors.map((e) => e.message).join(', ')))
      } else {
        dispatch(enqueueReduxSnackbar(`${sentenceCase(itemType)} deleted`))
      }

      resolve()
    }

    await api.debounce(apiToken, async (resolve) => {
      const { errors } = await gqlClient.mutate<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>({
        mutation: DELETE_ATTACHMENT_MUTATION,
        variables: {
          input: {
            token,
            investigationToken: caseToken,
          },
          includeMalwareScanStatus,
          enableAttachmentSummarization,
          cursor: null,
        },
        errorPolicy: 'all',
      })

      return handleResponse(errors, resolve)
    })
  }
}

export function deletePersonEntity(caseToken: string, token: string) {
  return deleteItemAction(caseToken, token, {
    itemType: 'individual',
    url: 'apiIndividualEntityPath',
  })
}

export function deleteBusinessEntity(caseToken: string, token: string) {
  return deleteItemAction(caseToken, token, {
    itemType: 'business',
    url: 'apiBusinessEntityPath',
  })
}

export function deleteDevice(caseToken: string, token: string) {
  return deleteItemAction(caseToken, token, {
    itemType: 'device',
    url: 'apiDevicePath',
  })
}

export function deleteProduct(caseToken: string, productToken: string) {
  return deleteItemAction(caseToken, productToken, {
    itemType: 'product',
    url: 'apiProductPath',
  })
}

export function deleteBankAccount(caseToken: string, token: string) {
  return deleteItemAction(caseToken, token, {
    itemType: 'bank_account',
    url: 'apiBankAccountPath',
  })
}

export function deletePaymentCard(caseToken: string, token: string) {
  return deleteItemAction(caseToken, token, {
    itemType: 'payment_card',
    url: 'apiPaymentCardPath',
  })
}

export function deleteCryptoAddress(caseToken: string, token: string) {
  return deleteItemAction(caseToken, token, {
    itemType: 'crypto_address',
    url: 'apiCryptoAddressPath',
  })
}

export function deleteInstitution(caseToken: string, token: string) {
  return deleteItemAction(caseToken, token, {
    itemType: 'institution',
    url: 'apiFinancialInstitutionPath',
  })
}

export function deleteTransactionBlock(caseToken: string) {
  return deleteItemAction(caseToken, null, {
    itemType: 'transactionImports',
    url: 'deleteAllApiTransactionsPath',
  })
}
