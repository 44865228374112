import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { HbText } from 'components/HbComponents/Text/HbText'
import { SkeletonText } from 'components/HbComponents/Text/SkeletonText'
import { Theme } from 'types/hb'

interface StyleProps {
  marginX?: number
  marginTop?: number
  marginBottom?: number
}

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.styleguide.mediumGray2,
  },
  wrapper: (props: StyleProps) => ({
    marginLeft: theme.spacing(props.marginX ?? 0),
    marginRight: theme.spacing(props.marginX ?? 0),
    marginBottom: theme.spacing(props.marginBottom ?? 2),
    marginTop: theme.spacing(props.marginTop ?? 0),
  }),
}))

export const ValueText = styled(HbText)(() => ({}))

export interface LabeledValueProps extends StyleProps {
  label: ReactNode
  children: ReactNode
  testId?: string
  className?: string
  loading?: boolean
}

export const LabeledValue = ({ label, loading, children, testId, className, ...rest }: LabeledValueProps) => {
  const classes = useStyles(rest)
  const classnames = classNames(classes.wrapper, className)
  return (
    <div className={classnames}>
      <HbText size="s" block className={classes.label}>
        {label}
      </HbText>
      <div data-testid={testId || label}>
        {loading ? <SkeletonText /> : <ValueText size="md">{children}</ValueText>}
      </div>
    </div>
  )
}

export default LabeledValue
