import { ReactNode, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'
import { DatePicker, DatePickerProps, DateTimePicker, DateValidationError, TimePicker } from '@mui/x-date-pickers'

import classnames from 'classnames'
import { useField } from 'formik'
import { isNil, omit } from 'lodash'
import moment, { Moment } from 'moment-timezone'

import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'

import { getInputTestId } from 'components/HbComponents/Form/Inputs/getInputTestId'
import { FormikDatePicker, FormikDatePickerProps } from 'components/material/Form'
import { formatDateTimeValidationError } from 'helpers/uiHelpers'

import { useHbFormikContext } from '../useHbFormikContext'

import { InputContainer, getHelperTextId } from './InputContainer'
import { InputResponse } from './InputResponse'
import { InputProps } from './InputTypes'
import { useIsErroneous } from './useIsErroneous'

const useStyles = makeStyles(() => ({
  redesignDatePickerRoot: {
    margin: 0,
  },
}))

/**
 * @description This is a Formik-specific date input
 */
export const DateInput = (props: InputProps & FormikDatePickerProps & { useContainer?: boolean }) => {
  const {
    readOnly,
    label,
    sublabel,
    name,
    disabled,
    autosave,
    isErroneousChecker,
    errors,
    testId,
    inputContainerClassName,
    className: classNameProp,
    useContainer = true,
    hideLabel = false,
    ...rest
  } = props
  const styles = useStyles()
  const [field, meta] = useField(name)
  const form = useHbFormikContext({ autosave })
  const { isErroneous, apiErrors, clientError } = useIsErroneous({ name, autosave, errors, isErroneousChecker })
  const inputTestId = getInputTestId(label, testId)

  if (readOnly) {
    return (
      <InputResponse label={label} className={classNameProp}>
        {isNil(field.value) ? '-' : moment(field.value).format('LL')}
      </InputResponse>
    )
  }

  const restProps = omit(rest, ['type', 'questionStyle', 'multivalued', 'required', 'number_props'])

  const className = classnames(classNameProp, styles.redesignDatePickerRoot)

  const datePicker = (
    <FormikDatePicker
      field={field}
      form={form}
      meta={meta}
      slotProps={{
        textField: {
          error: isErroneous,
          id: name,
          'aria-describedby': getHelperTextId(name),
          margin: 'normal',
          size: 'small',
          variant: 'outlined',
        },
      }}
      name={name}
      testId={inputTestId}
      dateOnly
      disabled={disabled}
      className={className}
      variant="outlined"
      {...restProps}
    />
  )

  if (!useContainer) return datePicker

  return (
    <InputContainer
      clientError={clientError}
      isErroneous={isErroneous}
      label={label}
      sublabel={sublabel}
      apiError={apiErrors}
      className={inputContainerClassName}
      htmlFor={name}
      testId={testId}
      hideLabel={hideLabel}
    >
      {datePicker}
    </InputContainer>
  )
}

export const BaseDateInput = (
  props: Omit<DatePickerProps<Moment>, 'onError'> & {
    onError?: (onErrorProps: { value: Moment | null; error: string | null; muiError: DateValidationError }) => void
  }
) => {
  const { label, onError, name = '', ...rest } = props
  const [readableError, setReadableError] = useState<string | null>(null)

  return (
    <InputContainer clientError={readableError || ''} isErroneous={!!readableError} label={label} htmlFor={name}>
      <DatePicker
        {...rest}
        name={name}
        onError={(muiError, value) => {
          const error = formatDateTimeValidationError(muiError, name || (typeof label === 'string' ? label : ''))
          setReadableError(error)
          if (onError) {
            onError({ value, error, muiError })
          }
        }}
      />
    </InputContainer>
  )
}

const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY'

export const StyledDateInputContainer = styled(InputContainer)(() => ({}))

interface HbRHFDateInputProps<
  FormValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
> {
  className?: string
  control: Control<FormValues>
  format?: string
  label: ReactNode
  name: FieldName
  timezone?: string
}

export const HbRHFDateInput = <
  FormValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
>({
  control,
  className,
  format = DEFAULT_DATE_FORMAT,
  label,
  name,
  timezone,
}: HbRHFDateInputProps<FormValues, FieldName>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: _field, fieldState }) => {
        const { onBlur: handleInputBlur, ref: inputRef, ...field } = _field
        return (
          <StyledDateInputContainer
            className={className}
            clientError={fieldState.error?.message}
            isErroneous={!!fieldState.error?.message}
            htmlFor={name}
            label={label}
          >
            <DatePicker<Moment>
              {...field}
              inputRef={inputRef}
              format={format}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  onBlur: handleInputBlur,
                },
              }}
              timezone={timezone}
            />
          </StyledDateInputContainer>
        )
      }}
    />
  )
}

const DEFAULT_TIME_FORMAT = 'HH:mm a'

export const StyledTimeInputContainer = styled(InputContainer)(() => ({}))

interface HbRHFTimeInputProps<
  FormValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
> {
  className?: string
  control: Control<FormValues>
  format?: string
  hideLabel?: boolean
  label: ReactNode
  name: FieldName
  timezone?: string
}

export const HbRHFTimeInput = <
  FormValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
>({
  className,
  control,
  format = DEFAULT_TIME_FORMAT,
  hideLabel,
  label,
  name,
  timezone,
}: HbRHFTimeInputProps<FormValues, FieldName>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: _field, fieldState }) => {
        const { onBlur: handleInputBlur, ref: inputRef, ...field } = _field
        return (
          <StyledTimeInputContainer
            className={className}
            clientError={fieldState.error?.message}
            htmlFor={name}
            isErroneous={!!fieldState.error?.message}
            label={label}
            hideLabel={hideLabel}
          >
            <TimePicker<Moment>
              {...field}
              inputRef={inputRef}
              format={format}
              slotProps={{
                textField: {
                  onBlur: handleInputBlur,
                  variant: 'outlined',
                },
              }}
              timezone={timezone}
            />
          </StyledTimeInputContainer>
        )
      }}
    />
  )
}

interface HbRHFDateTimeInputProps<
  FormValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
> {
  className?: string
  control: Control<FormValues>
  format?: string
  label: ReactNode
  name: FieldName
  timezone?: string
}

/**
 * Use when the time input is required for the date to be valid
 */
export const HbRHFDateTimeInput = <
  FormValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
>({
  className,
  control,
  format = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`,
  label,
  name,
  timezone,
}: HbRHFDateTimeInputProps<FormValues, FieldName>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: _field, fieldState }) => {
        const { onBlur: handleInputBlur, ref: inputRef, ...field } = _field
        return (
          <InputContainer
            className={className}
            clientError={fieldState.error?.message}
            htmlFor={name}
            isErroneous={!!fieldState.error?.message}
            label={label}
          >
            <DateTimePicker<Moment>
              {...field}
              inputRef={inputRef}
              format={format}
              slotProps={{
                textField: {
                  onBlur: handleInputBlur,
                  variant: 'outlined',
                },
              }}
              value={field.value}
              timezone={timezone}
            />
          </InputContainer>
        )
      }}
    />
  )
}

const DateAndTimeInputsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  columnGap: theme.spacing(2),
}))

interface HbRHFDateAndTimeInputProps<
  FormValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
> {
  className?: string
  control: Control<FormValues>
  dateFormat?: string
  dateLabel: ReactNode
  dateName: FieldName
  timeFormat?: string
  timeLabel: ReactNode
  timeLabelHidden?: boolean
  timeName: FieldName
  timezone?: string
}

/**
 * Use when the time input is optional
 */
export const HbRHFDateAndTimeInput = <
  FormValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
>({
  className,
  control,
  dateFormat = DEFAULT_DATE_FORMAT,
  dateLabel,
  dateName,
  timeFormat = DEFAULT_TIME_FORMAT,
  timeLabel,
  timeLabelHidden,
  timeName,
  timezone,
}: HbRHFDateAndTimeInputProps<FormValues, FieldName>) => {
  return (
    <DateAndTimeInputsContainer className={className}>
      <HbRHFDateInput control={control} format={dateFormat} label={dateLabel} name={dateName} timezone={timezone} />
      <HbRHFTimeInput
        control={control}
        format={timeFormat}
        hideLabel={timeLabelHidden}
        label={timeLabel}
        name={timeName}
        timezone={timezone}
      />
    </DateAndTimeInputsContainer>
  )
}
