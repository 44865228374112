import { useRef, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { useRouteMatch } from 'react-router-dom'

import { useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { NewAutomationModal } from 'components/pages/automations/NewAutomationModal'
import { NewTemplateButton } from 'components/pages/automations/NewTemplateButton'
import { OrganizationSelectDialog } from 'components/pages/automations/editor/AutomationEditorSteps/OrganizationSelectComponents'
import { useAutomationAdminAccountCheck } from 'components/pages/automations/hooks/AutomationAdminHelpers'
import { useCreateAutomationRule } from 'components/pages/automations/hooks/useCreateAutomationRule'
import { TabsNav } from 'components/pages/templates/PageWithTabs'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { getCurrentOrganizationToken, hasPermission } from 'helpers/stateHelpers'
import { useFeatureFlag, useToggle } from 'hooks'
import AutomationsIcon from 'icons/Automations'
import { BadgePermissionsEnum, FeatureFlag } from 'types/api'

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 3, 2, 3),
}))

const HeaderLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center',
}))

const IconWrapper = styled('span')(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  borderRadius: '50%',
  backgroundColor: theme.palette.background.medium,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const StyledTabsNav = styled(TabsNav)(() => ({
  margin: `0 !important`,
}))

export function AutomationsHeaderNav() {
  const [createMenuOpen, setCreateMenuOpen] = useState(false)
  const menuTrigger = useRef<HTMLButtonElement | null>(null)
  const hasManageAutomationsPermission = useSelector((state) =>
    hasPermission(state, BadgePermissionsEnum.ManageAutomations)
  )

  const hasViewAutomationsPermission = useSelector((state) =>
    hasPermission(state, BadgePermissionsEnum.ViewAutomations)
  )

  const hasAdminAutomationsPermission = useSelector((state) =>
    hasPermission(state, BadgePermissionsEnum.AdminManageAutomations)
  )
  const orgSelectToggle = useToggle(false)
  const currentOrgToken = useSelector(getCurrentOrganizationToken)
  const [selectedOrgToken, setSelectedOrgToken] = useState<string | null>(null)
  const hasAdminPermissions = useAutomationAdminAccountCheck()

  const getAutomationRulePath = (automationRuleOrgToken: string, ruleToken: string) => {
    return `/automations/${
      hasAdminPermissions && currentOrgToken !== automationRuleOrgToken ? `${automationRuleOrgToken}/` : ''
    }${ruleToken}`
  }

  const { createAutomationRule, createAutomationRuleAsAdmin } = useCreateAutomationRule(null, {
    selectedOrgToken,
    getAutomationRulePath,
  })

  const canViewCreditUsageDashboard = useFeatureFlag(FeatureFlag.EnableTaskPricingAnalytics)

  const tabs = hasViewAutomationsPermission
    ? [
        { title: 'Home', href: '/automations' },
        { title: 'Explore', href: '/automations/explore' },
        {
          title: 'Usage',
          href: canViewCreditUsageDashboard ? '/dashboard/analytics/automated_tasks' : '/automations/usage',
        },
        { title: 'Activity Log', href: '/automations/activity_log' },
        ...(hasAdminPermissions ? [{ title: 'Recipe Templates (HB Admin Only)', href: '/automations/recipes' }] : []),
      ]
    : [{ title: 'Explore', href: '/automations/explore' }]

  const isTemplatesRoute = useRouteMatch('/automations/recipes')

  const usage = useUsage()

  return (
    <>
      {hasAdminPermissions && createAutomationRuleAsAdmin && (
        <OrganizationSelectDialog
          open={orgSelectToggle.value}
          onSelect={(orgToken) => setSelectedOrgToken(orgToken)}
          onConfirm={() => {
            orgSelectToggle.toggle()
            setCreateMenuOpen(false)
            createAutomationRuleAsAdmin()
          }}
          onClose={() => orgSelectToggle.toggle()}
        />
      )}
      <NewAutomationModal
        open={createMenuOpen}
        onClose={() => setCreateMenuOpen(false)}
        onCreateFromBlank={() => {
          if (hasAdminPermissions) {
            orgSelectToggle.toggle()
          } else {
            createAutomationRule()
          }
        }}
      />
      <Header>
        <HeaderLeft>
          <IconWrapper role="presentation">
            <AutomationsIcon />
          </IconWrapper>
          <HbText size="xl" bold tag="h1">
            Automations
          </HbText>
        </HeaderLeft>
        {hasAdminAutomationsPermission && isTemplatesRoute && <NewTemplateButton />}
        {hasManageAutomationsPermission && !isTemplatesRoute && (
          <HbButton
            label="Create Automation"
            variant="secondary"
            onClick={() => setCreateMenuOpen(true)}
            ref={menuTrigger}
          />
        )}
      </Header>
      <StyledTabsNav
        tabs={tabs}
        onClick={({ title }: { title: string }) => {
          usage.logEvent({
            name: 'automations:tabNav:clicked',
            data: {
              tab: title,
            },
          })
        }}
      />
    </>
  )
}
