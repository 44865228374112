import {
  Checkbox as BaseCheckbox,
  FormControlLabel,
  CheckboxProps as MaterialCheckboxProps,
  // eslint-disable-next-line no-restricted-imports
  styled,
} from '@mui/material'

import { Field, FieldAttributes } from 'formik'
import {
  Checkbox as BaseFormikCheckbox,
  CheckboxWithLabel as BaseFormikCheckboxWithLabel,
  CheckboxProps as BaseFormikCheckboxProps,
  CheckboxWithLabelProps as BaseFormikCheckboxWithLabelProps,
} from 'formik-mui'

import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'

import { BUTTON_OUTLINE_STYLE } from 'components/themeRedesign'

import { Theme } from 'types/hb'

import { Size } from '../HbComponents.types'

type UncheckedBackgroundColorTypes = 'light' | 'contrastMedium' | 'contrastLight'
type HbCheckboxSize = Extract<Size, 's' | 'md'>

type StyleProps = {
  error?: boolean
  size?: HbCheckboxSize
  uncheckedBackgroundColor?: UncheckedBackgroundColorTypes
}

const sizeMap: Record<HbCheckboxSize, number> = {
  s: 14,
  md: 18,
}

const DEFAULT_SIZE = 'md'

const getDimension = (size: HbCheckboxSize = DEFAULT_SIZE) => sizeMap[size]

const getBackgroundColor = ({
  error,
  theme,
  uncheckedBackgroundColor,
}: {
  error?: boolean
  theme: Theme
  uncheckedBackgroundColor?: UncheckedBackgroundColorTypes
}) => {
  if (error) {
    if (uncheckedBackgroundColor !== 'light') {
      return theme.palette.styleguide.lightRed
    }
    return theme.palette.background.light
  }
  if (uncheckedBackgroundColor === 'contrastMedium') {
    return theme.palette.background.contrastMedium
  }
  if (uncheckedBackgroundColor === 'contrastLight') {
    return theme.palette.background.contrastLight
  }
  return theme.palette.background.light
}

interface BaseStyledCheckboxIconProps {
  error?: boolean
  size?: HbCheckboxSize
  uncheckedBackgroundColor?: UncheckedBackgroundColorTypes
}

const BaseStyledCheckboxIcon = styled('span')<BaseStyledCheckboxIconProps>(
  ({ error, size = DEFAULT_SIZE, theme, uncheckedBackgroundColor }) => {
    const backgroundColor = getBackgroundColor({ error, theme, uncheckedBackgroundColor })

    const dimension = getDimension(size)

    return {
      borderRadius: theme.shape.smallContainer.borderRadius,
      borderWidth: '1px',
      borderStyle: 'solid',
      width: dimension,
      height: dimension,
      borderColor: error ? theme.palette.error.main : theme.palette.styleguide.greyD,
      backgroundColor,
      '.Mui-focusVisible &': {
        outline: BUTTON_OUTLINE_STYLE,
      },
    }
  }
)

type StyledUncheckedCheckboxProps = BaseStyledCheckboxIconProps

const UncheckedCheckboxIcon = styled(BaseStyledCheckboxIcon)<StyledUncheckedCheckboxProps>(({ error, theme }) => ({
  'input:hover ~ &': {
    backgroundColor: theme.palette.styleguide.greyF,
    borderColor: error ? theme.palette.error.main : theme.palette.background.dark,
  },
  'input:disabled ~ &': {
    backgroundColor: theme.palette.input.disabled,
    borderColor: theme.palette.input.disabled,
  },
}))

type StyledCheckboxWithBackgroundProps = BaseStyledCheckboxIconProps

const StyledCheckboxWithBackground = styled(BaseStyledCheckboxIcon)<StyledCheckboxWithBackgroundProps>(
  ({ size = DEFAULT_SIZE, theme }) => {
    const dimension = getDimension(size)

    return {
      'input ~ &': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      'input:hover ~ &': {
        backgroundColor: `${theme.palette.background.dark} !important`,
        borderColor: theme.palette.background.dark,
      },
      'input:disabled ~ &': {
        backgroundColor: `${theme.palette.input.disabled} !important`,
        borderColor: theme.palette.input.disabled,
      },
      '&:before': {
        display: 'block',
        width: dimension,
        height: dimension,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        content: '""',
      },
    }
  }
)

const checkedBackgroundImage =
  "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='10' height='8' viewBox='0 0 10 8' %3E%3Cpath" +
  " d='M3.18182 6.32836L0.795455 3.8209L0 4.65672L3.18182 8L10 0.835821L9.20455 0L3.18182 6.32836Z' " +
  " fill='white'/%3E%3C/svg%3E\")"

const CheckedCheckboxIcon = styled(StyledCheckboxWithBackground)(() => ({
  '&:before': {
    backgroundImage: checkedBackgroundImage,
  },
}))

const indeterminateBackgroundImage =
  "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='8' height='3' viewBox='0 0 8 3' %3E%3Crect" +
  " y='0.75' width='8' height='1.5' fill='white' " +
  '/%3E%3C/svg%3E")'

const IndeterminateCheckboxIcon = styled(StyledCheckboxWithBackground)(() => ({
  '&:before': {
    backgroundImage: indeterminateBackgroundImage,
  },
}))

export interface CheckboxProps extends Omit<MaterialCheckboxProps, 'size'>, StyleProps {}

const getSharedCheckboxStyles = ({ theme }: { theme: Theme }) => ({
  // TODO: consider removing this since adding margins to base components
  // make them harder to use in different contexts
  marginLeft: theme.spacing(0.5),
})

const StyledBaseCheckbox = styled(BaseCheckbox)(getSharedCheckboxStyles)

function useSharedCheckboxProps<T>(props: Pick<CheckboxProps, 'error' | 'uncheckedBackgroundColor' | 'size'> & T) {
  const { error, uncheckedBackgroundColor, size, ...rest } = props

  const iconProps = {
    error,
    size,
    uncheckedBackgroundColor,
  }

  return {
    color: 'primary' as CheckboxProps['color'],
    icon: <UncheckedCheckboxIcon {...iconProps} />,
    checkedIcon: <CheckedCheckboxIcon {...iconProps} />,
    indeterminateIcon: <IndeterminateCheckboxIcon {...iconProps} />,
    disableRipple: true,
    ...rest,
  }
}

// Can remove hackAwayLeftMargin when getSharedCheckboxStyles no longer adds it
export const HbCheckbox = (props: CheckboxProps & { hackAwayLeftMargin?: boolean }) => {
  const checkboxProps = useSharedCheckboxProps(props)
  const { hackAwayLeftMargin } = props
  if (hackAwayLeftMargin) {
    return <BaseCheckbox {...checkboxProps} />
  }

  return <StyledBaseCheckbox {...checkboxProps} />
}

type FormikCheckboxProps = BaseFormikCheckboxProps & StyleProps

function transformFormikCheckboxFieldValue<T extends FormikCheckboxProps>(props: T): T {
  // If no initial value is provided to a Formik checkbox, weird behavior ensues:
  // https://github.com/stackworx/formik-mui/issues/305
  // This function resolves the weird behavior but we should follow up to resolve
  // the root issue: https://thecharm.atlassian.net/browse/PROD-13553
  const { value, ...restField } = props.field
  const isEmptyValueArray = !value || (Array.isArray(value) && !value.length)
  return {
    ...props,
    field: {
      ...restField,
      value: isEmptyValueArray ? false : value,
    },
  }
}

const StyledBaseFormikCheckbox = styled(BaseFormikCheckbox)(getSharedCheckboxStyles)

export const FormikCheckbox = (props: FormikCheckboxProps) => {
  const checkboxProps = transformFormikCheckboxFieldValue(useSharedCheckboxProps(props))
  return <StyledBaseFormikCheckbox {...checkboxProps} />
}

export const CheckboxField = (props: FieldAttributes<any>) => (
  <Field indeterminate={false} {...props} type="checkbox" component={FormikCheckbox} />
)
type CheckboxWithLabelProps = BaseFormikCheckboxWithLabelProps & StyleProps

const StyledBaseFormikCheckboxWithLabel = styled(BaseFormikCheckboxWithLabel)(getSharedCheckboxStyles)

export const CheckboxWithLabel = (props: CheckboxWithLabelProps) => {
  const checkboxProps = transformFormikCheckboxFieldValue(useSharedCheckboxProps(props))
  return <StyledBaseFormikCheckboxWithLabel {...checkboxProps} />
}

export const CheckboxWithLabelField = (props: FieldAttributes<any>) => {
  return <Field indeterminate={false} {...props} type="checkbox" component={CheckboxWithLabel} />
}

export function HbRHFCheckboxWithLabel<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  rules,
  onChange,
  label,
  ...checkboxProps
}: UseControllerProps<TFieldValues, TName> & CheckboxProps & { label: string }) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        return (
          <FormControlLabel
            {...field}
            checked={field.value && field.value === checkboxProps.value}
            control={
              <HbCheckbox
                {...field}
                {...checkboxProps}
                onChange={(event) => {
                  field.onChange(event)
                  if (onChange) {
                    onChange(event, event.target.checked)
                  }
                }}
              />
            }
            label={label}
          />
        )
      }}
    />
  )
}
