import { ReactNode, useEffect } from 'react'

import { ApolloError, gql, useQuery } from '@apollo/client'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { startCase, replace, upperCase } from 'lodash'
import pluralize from 'pluralize'
import { match as IMatch } from 'react-router-dom'

import { waitForDocumentReady, DOCUMENT_LOADING_TOKEN, pollForDocument } from 'actions/generateDocActions'
import { useSelector, useDispatch } from 'actions/store'
import { setLoading } from 'actions/viewActions'
import { GQLError } from 'components/GQLError'
import {
  InvestigationDocumentDownloadQuery,
  InvestigationDocumentDownloadQueryVariables,
} from 'components/cases/report/summary/__generated__/GenerateDocument.generated'
import { InformationRequestResponseCopyDocument } from 'components/external/information_requests/__generated__/InformationRequestConfirmation.generated'
import { RequestForInformationRecipientCopyDocument } from 'components/external/rfi/__generated__/RequestForInformationConfirmation.generated'

import Loader from 'components/library/Loader'
import { UsageArea, useUsage } from 'helpers/SessionTracking/UsageTracker'
import { getLoadingBool } from 'helpers/stateHelpers'

import { useEffectOnce } from 'hooks'

import { DocError, State as ReducerState } from 'reducers/generateDocReducer'

import IconSheet from '../shared/IconSheet'

const StyledRoot = styled('div')(({ theme }) => ({
  background: 'white',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(6, 3, 3, 3),
}))

/** Contains the error that occurred while making a request to GraphQL. */
interface StateErrorGQL {
  status: 'ERROR'
  downloadURL: undefined
  error: ApolloError
}

type State = ReducerState | StateErrorGQL

const readableDocumentName = (slug: string) => {
  switch (slug) {
    case 'investigation-documents':
      return 'files'
    case 'review-documents':
      return 'documents'
    case 'transactions-csv-all':
      return 'Transaction CSV'
    default:
      // Capitalize specific phrases within the document name.
      return replace(startCase(slug), /\b(?:xml|csv|pdf|sar|ctr|json|str|fintrac)\b/gi, upperCase)
  }
}

const documentVerb = (slug: string) => pluralize('is', pluralize.isPlural(slug) ? 2 : 1)

function gqlState(error?: ApolloError, downloadURL?: string): State {
  if (error) {
    return {
      status: 'ERROR',
      downloadURL: undefined,
      error,
    }
  }
  if (downloadURL) {
    return { status: 'READY', downloadURL, error: undefined }
  }
  return { status: 'PENDING', downloadURL: undefined, error: undefined }
}

function ErrorUI(props: { error: ApolloError | DocError; noun: string }) {
  const { error, noun } = props
  if (error instanceof ApolloError) {
    return <GQLError error={error} />
  }

  const { type, messages } = error
  if (type === 'TIMEOUT') {
    return <IconSheet icon="time" title="This is taking a moment" status="pending" subtext={messages.join('\n')} />
  }
  return (
    <IconSheet icon="error" title={`Error generating your ${noun}.`} status="error" subtext={messages.join('\n')} />
  )
}

export function GenerateDocumentUIWrapper({ loading, children }: { loading: boolean; children: ReactNode }) {
  return (
    <StyledRoot>
      {loading && <Loader variant="local" />}
      {children}
    </StyledRoot>
  )
}

type GenerateDocumentUIProps = State & {
  documentName: string
  loading: boolean
  onClick?: (url: string) => void
  formatDocumentName?: boolean
}

function GenerateDocumentUI(props: GenerateDocumentUIProps) {
  const { documentName, downloadURL, error, loading, status, onClick, formatDocumentName = true } = props
  const noun = formatDocumentName ? readableDocumentName(documentName) : documentName
  const verb = documentVerb(documentName)

  return (
    <GenerateDocumentUIWrapper loading={loading}>
      {status === 'PENDING' && (
        <IconSheet
          icon="access_time"
          title={
            documentName === 'investigation-documents'
              ? 'Your files are being packaged for download.'
              : `Your ${noun} ${verb} generating and will be ready soon.`
          }
          subtext="This page will update when your download is ready."
        />
      )}
      {status === 'ERROR' && <ErrorUI error={error} noun={noun} />}
      {status === 'READY' && (
        <IconSheet
          icon="check_circle"
          title={`Your ${noun} ${verb} ready for download.`}
          actionButton={{
            url: downloadURL,
            text: 'Download Now',
            onClick: onClick ? () => onClick(downloadURL) : undefined,
          }}
        />
      )}
    </GenerateDocumentUIWrapper>
  )
}

interface GenerateDocumentProps {
  match: IMatch<{ reviewToken: string; file: string }>
}

export function GenerateDocument(props: GenerateDocumentProps) {
  const {
    match: {
      params: { reviewToken, file: documentName },
    },
  } = props
  const dispatch = useDispatch()
  const loading = useSelector((state) => getLoadingBool(state, DOCUMENT_LOADING_TOKEN))
  const docState = useSelector((state) => state.generateDoc)

  useEffectOnce(() => {
    dispatch(waitForDocumentReady(reviewToken, documentName))
  })

  return <GenerateDocumentUI documentName={documentName} loading={loading} {...docState} />
}

interface GenerateRFIResponseDocumentProps {
  match: IMatch<{ externalToken: string }>
}

export function GenerateRFIResponseDocument(props: GenerateRFIResponseDocumentProps) {
  const {
    match: {
      params: { externalToken },
    },
  } = props
  const query = useQuery(RequestForInformationRecipientCopyDocument, {
    variables: { externalToken },
    pollInterval: 1000,
  })
  const downloadURL = query.data?.requestForInformationRecipientCopy?.downloadUrl ?? undefined
  useEffect(() => {
    if (downloadURL) {
      query.stopPolling()
    }
  }, [downloadURL, query])

  const usage = useUsage()

  const handleClick = (url: string) => {
    usage.logEvent({
      name: 'informationRequests:responseDownload:clicked',
      data: { url },
    })
  }

  return (
    <GenerateDocumentUI
      documentName="Request for Information Response"
      loading={query.loading}
      onClick={handleClick}
      {...gqlState(query.error, downloadURL)}
    />
  )
}

interface GenerateInfoRequestResponseDocumentProps {
  match: IMatch<{ token: string }>
}

export function GenerateInformationRequestResponseDocument(props: GenerateInfoRequestResponseDocumentProps) {
  const {
    match: {
      params: { token },
    },
  } = props
  const query = useQuery(InformationRequestResponseCopyDocument, {
    variables: { informationRequestResponseToken: token },
    pollInterval: 1000,
  })
  const downloadURL = query.data?.informationRequestResponseCopy?.downloadUrl ?? undefined

  useEffect(() => {
    if (downloadURL) {
      query.stopPolling()
    }
  }, [downloadURL, query])

  return (
    <UsageArea eventPrefix="InformationRequestDocumentDownload">
      <GenerateDocumentUI
        documentName="Request for Information Response"
        loading={query.loading}
        {...gqlState(query.error, downloadURL)}
      />
    </UsageArea>
  )
}

export const INVESTIGATION_DOCUMENT_DOWNLOAD_QUERY = gql`
  query InvestigationDocumentDownload($token: ID!) {
    investigationDocumentDownload(token: $token) {
      filename
      presignedHeadUrl
      presignedGetUrl
    }
  }
`

interface InvestigationDownloadDocumentProps {
  match: IMatch<{ token: string }>
}

export function InvestigationDownloadDocument(props: InvestigationDownloadDocumentProps) {
  const {
    match: {
      params: { token },
    },
  } = props

  const dispatch = useDispatch()
  const loading = useSelector((state) => getLoadingBool(state, DOCUMENT_LOADING_TOKEN))
  const docState = useSelector((state) => state.generateDoc)

  useEffectOnce(() => {
    dispatch(setLoading(true, DOCUMENT_LOADING_TOKEN))
  })

  const { data } = useQuery<InvestigationDocumentDownloadQuery, InvestigationDocumentDownloadQueryVariables>(
    INVESTIGATION_DOCUMENT_DOWNLOAD_QUERY,
    {
      variables: { token },
    }
  )

  const investigationDocumentDownload = data?.investigationDocumentDownload

  useEffect(() => {
    if (!investigationDocumentDownload) return

    const { presignedHeadUrl, presignedGetUrl, filename } = investigationDocumentDownload

    dispatch(pollForDocument(presignedHeadUrl, presignedGetUrl, filename))
  }, [dispatch, investigationDocumentDownload])

  return <GenerateDocumentUI documentName="investigation-documents" loading={loading} {...docState} />
}
