import React, { ForwardedRef, forwardRef, isValidElement, MouseEventHandler, ReactNode } from 'react'

import { Close } from '@mui/icons-material'
import { Chip, ChipProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { CSSProperties, makeStyles } from '@mui/styles'

import classNames from 'classnames'
import pluralize from 'pluralize'

import { HbColorsByHue, HBGREYS, Hue } from 'components/colors'
import { BadgeColors } from 'components/settings/Badges/BadgePage/badgeHelpers'
import { mergeOverrideStyles } from 'components/utils/styles'
import { ColorNameEnum } from 'types/api'
import { KeyboardOrMouseEvent, Theme } from 'types/hb'

import { Placement, HbTooltip } from './HbTooltip'

type Variant = 'fill' | 'outline'

export const TAG_HEIGHT = 24
export const ICON_SIZE = TAG_HEIGHT / 2

interface StyleProps {
  tagColor?: string
  themeColor?: Hue | null
  isOutline?: boolean
  isNumeric?: boolean
  shouldTruncateTags?: boolean
}

/**
 * The db still stores legacy tag colors as hex values.
 * This function converts them to the new hues.
 * There are more legacy than new colors, so the mapping isn't one to one.
 */
export function covertLegacyToNewTagColor(color: string): Hue | null {
  switch (color.toUpperCase()) {
    case HBGREYS.gray300: // override
      return null
    case '#3787FB':
    case '#044E95':
    case HbColorsByHue.sea.medium:
      return 'sea'
    case '#09A9BE':
    case '#EBF3FF':
    case '#99DCFF':
    case HbColorsByHue.cyan.medium:
      return 'cyan'
    case '#3B9D3F':
    case '#0A655E':
    case '#50E3C2':
    case HbColorsByHue.mint.medium:
      return 'mint'
    case '#A90F89':
    case '#3F1D72':
    case HbColorsByHue.plum.medium:
      return 'plum'
    case '#DB2004':
    case HbColorsByHue.coral.medium:
      return 'coral'
    case '#F57C00':
    case HbColorsByHue.salmon.medium:
      return 'salmon'
    case '#F1C714':
    case HbColorsByHue.lemon.medium:
      return 'lemon'
    case '#DAA16B':
    case '#8B572A':
    case HbColorsByHue.linen.medium:
      return 'linen'
    case '#FFA1F4':
    case HbColorsByHue.rose.medium:
      return 'rose'
    case '#263238':
    case '#6D7878':
    case HbColorsByHue.ivory.medium:
      return 'ivory'
    default:
      return 'ivory'
  }
}

export const LegacyToNewBadgeColorsMap: Record<ColorNameEnum, BadgeColors> = {
  [ColorNameEnum.Green]: 'mint',
  [ColorNameEnum.DarkGreen]: 'mint',
  [ColorNameEnum.Teal]: 'mint',
  [ColorNameEnum.Brown]: 'linen',
  [ColorNameEnum.Tan]: 'linen',
  [ColorNameEnum.Pink]: 'rose',
  [ColorNameEnum.Aqua]: 'cyan',
  [ColorNameEnum.LightBlue]: 'cyan',
  [ColorNameEnum.Blue]: 'sea',
  [ColorNameEnum.DarkBlue]: 'sea',
  [ColorNameEnum.Orange]: 'salmon',
  [ColorNameEnum.Red]: 'coral',
  [ColorNameEnum.Magenta]: 'plum',
  [ColorNameEnum.DarkPurple]: 'plum',
  [ColorNameEnum.Yellow]: 'lemon',
}

export function isColorNameEnum(color: string): color is ColorNameEnum {
  return !!LegacyToNewBadgeColorsMap[color as ColorNameEnum]
}

const getHue = (theme: Theme, tagColor?: string, themeColor?: Hue | null, isOutline?: boolean) => {
  if (isOutline) {
    return theme.palette.hues.ivory
  }
  if (tagColor) {
    if (isColorNameEnum(tagColor)) {
      return theme.palette.hues[LegacyToNewBadgeColorsMap[tagColor]]
    }

    const hue = covertLegacyToNewTagColor(tagColor)
    return hue ? theme.palette.hues[hue] : null
  }
  if (themeColor) {
    return theme.palette.hues[themeColor] ?? theme.palette.hues.ivory
  }
  return theme.palette.hues.ivory
}

const TRUNCATED_MAX_TAG_WIDTH = 140

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ tagColor, themeColor, isOutline, isNumeric }: StyleProps) => {
    const hue = getHue(theme, tagColor, themeColor, isOutline)
    let styles: CSSProperties = {}
    if (hue) {
      styles = {
        backgroundColor: hue.light,
        color: hue.dark,
      }
    } else {
      styles = {
        backgroundColor: tagColor,
        color: theme.palette.text.primary,
      }
    }

    if (isOutline) {
      styles = {
        border: `1.5px solid ${theme.palette.styleguide.borderLight}`,
        backgroundColor: theme.palette.styleguide.backgroundLight,
      }
    }

    return {
      ...styles,
      ...theme.typography.sm,
      boxSizing: 'border-box',
      width: 'fit-content',
      padding: isNumeric ? theme.spacing(0.75) : theme.spacing(0.75, 1),
      height: TAG_HEIGHT,
      minWidth: isNumeric ? TAG_HEIGHT : undefined,
      fontWeight: 500,
      borderRadius: isNumeric ? TAG_HEIGHT / 2 : 8,
    }
  },
  icon: ({ tagColor, themeColor }: StyleProps) => ({
    height: ICON_SIZE,
    width: ICON_SIZE,
    '&&': {
      color: getHue(theme, tagColor, themeColor)?.dark,
    },
  }),
  deleteIcon: ({ tagColor, themeColor }: StyleProps) => ({
    height: ICON_SIZE,
    width: ICON_SIZE,
    '&&': {
      color: getHue(theme, tagColor, themeColor)?.dark,
    },
  }),
  label: (props: StyleProps) => ({
    padding: 0,
    fontVariantNumeric: props.isNumeric ? 'tabular-nums' : undefined,
    maxWidth: props.shouldTruncateTags ? TRUNCATED_MAX_TAG_WIDTH : undefined,
  }),
}))

export type HbTagStyleOverrides = Partial<ReturnType<typeof useStyles>>

interface BaseProps {
  className?: string
  classes?: HbTagStyleOverrides
  color?: string
  variant?: Variant
  disabled?: boolean
  icon?: ChipProps['icon']
  onClick?: MouseEventHandler<HTMLDivElement>
  onDelete?: (e: KeyboardOrMouseEvent) => void
  themeColor?: Hue
}

export interface PropsWithLabel extends BaseProps {
  count?: undefined
  label: ReactNode
  maxCount?: undefined
  shouldTruncateTags?: boolean
}

export interface PropsWithCount extends BaseProps {
  label?: undefined
  count: number
  maxCount?: number
  shouldTruncateTags?: boolean
}

export type Props = PropsWithLabel | PropsWithCount

const getCountLabel = (count: number, maxCount = 99): string => {
  return `${count > maxCount ? `${maxCount}+` : count}`
}

/**
 * Prefer to use `themeColor` prop which accepts a Hue that corresponds to the new HB color system.
 *
 * Supports legacy HB colors via the `color` prop.
 *
 * Use `HbTagList` or `HbTagListWithOverflow` for showing lists of HbTags
 */
export const HbTag = forwardRef(
  (
    {
      color: tagColor,
      variant = 'fill',
      classes: overrideClasses,
      themeColor,
      label: _label,
      count,
      maxCount,
      shouldTruncateTags,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const isOutline = variant === 'outline'
    const baseClasses = useStyles({
      tagColor,
      themeColor,
      isOutline,
      isNumeric: count !== undefined,
      shouldTruncateTags,
    })
    const classes = mergeOverrideStyles(baseClasses, overrideClasses)

    const label = count !== undefined ? getCountLabel(count, maxCount) : _label

    return <Chip classes={classes} deleteIcon={<Close />} ref={ref} label={label} {...rest} />
  }
)

const useListStyles = makeStyles((theme: Theme) => ({
  list: {
    display: 'flex',
    gap: theme.spacing(),
    flexFlow: 'row wrap',
  },
  listItem: {
    maxWidth: '100%',
  },
}))

interface ListProps {
  classes?: Partial<ReturnType<typeof useListStyles>>
  children: React.ReactNode
}

/**
 * Can be wrapped around a list of HbTags and automatically
 * apply the correct spacing and wrap each HbTag in a `li` tag
 * for better semantic HTML.
 *
 * See `HbTag.stories.tsx` for an example
 */
export const HbTagList = ({ children, classes: overrideClasses }: ListProps) => {
  const baseClasses = useListStyles()
  const classes = mergeOverrideStyles(baseClasses, overrideClasses)
  return (
    <ul className={classes.list} role="group">
      {React.Children.map(children, (child) => {
        if (isValidElement(child)) {
          return <li className={classes.listItem}>{child}</li>
        }
        return child
      })}
    </ul>
  )
}

const useListWithOverflowStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: theme.spacing(0, 1),
  },
  listItem: {},
  tooltip: {
    backgroundColor: theme.palette.background.light,
    borderRadius: theme.shape.largeContainer.borderRadius,
    boxShadow: '0px 3px 26px 0px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    gap: theme.spacing(),
  },
  overflowCount: {
    backgroundColor: theme.palette.background.contrastLight,
    color: theme.palette.text.secondary,
  },
  tag: {
    maxWidth: '100%',
  },
}))

export type BasicTagProps = {
  color: string
  token: string
} & Props

interface ListWithOverflowProps {
  classes?: Partial<ReturnType<typeof useListWithOverflowStyles>>
  max?: number
  overflowSuffix?: string
  shouldPluralizeOverflowSuffix?: boolean
  shouldShowLabel?: boolean
  shouldTruncateTags?: boolean
  tags: BasicTagProps[]
}

/**
 * For rendering a list of tags with an overflow on hover
 */
export const HbTagListWithOverflow = ({
  classes: overrideClasses,
  max = 0,
  overflowSuffix = 'tag',
  shouldPluralizeOverflowSuffix = true,
  shouldShowLabel = true,
  shouldTruncateTags,
  tags,
}: ListWithOverflowProps) => {
  const baseClasses = useListWithOverflowStyles()
  const classes = mergeOverrideStyles(baseClasses, overrideClasses)
  const remainingCount = tags.length - max
  return (
    <HbTagList classes={{ list: classes.list, listItem: classes.listItem }}>
      {tags.slice(0, max).map(({ className, ...tag }) => (
        <HbTag
          key={tag.token}
          shouldTruncateTags={shouldTruncateTags}
          className={classNames(classes.tag, className)}
          {...tag}
        />
      ))}
      {remainingCount > 0 && (
        <HbTooltip
          classes={{ tooltip: classes.tooltip }}
          title={tags.slice(max).map((tag) => <HbTag key={tag.token} {...tag} />)}
          placement={Placement.Top}
        >
          <HbTag
            className={classes.overflowCount}
            label={`+${remainingCount}${
              shouldShowLabel
                ? ` ${shouldPluralizeOverflowSuffix ? pluralize(overflowSuffix, remainingCount) : overflowSuffix}`
                : ''
            }`}
          />
        </HbTooltip>
      )}
    </HbTagList>
  )
}
