// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/system'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import TrashOutlineIcon from 'icons/TrashOutlineIcon'

import { Panel } from './Panel'

const DeleteButton = styled(HbButton)(({ theme }) => ({
  color: theme.palette.styleguide.errorMedium,
  border: `1px solid ${theme.palette.styleguide.errorMedium}`,
  '&:hover, &:focus': {
    color: theme.palette.styleguide.errorMedium,
    border: `1px solid ${theme.palette.styleguide.errorMedium}`,
  },
}))

export const DeleteTemplatePanel = ({ loading, deleteTemplate }: { loading: boolean; deleteTemplate: () => void }) => {
  return (
    <Panel>
      <HbText>Are you sure you want to delete this recipe?</HbText>
      <DeleteButton
        label="Delete recipe"
        onClick={deleteTemplate}
        disabled={loading}
        variant="secondary"
        fullWidth
        Icon={TrashOutlineIcon}
      />
    </Panel>
  )
}
