// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { HbTag } from 'components/HbComponents/HbTag'
import { BUTTON_OUTLINE_STYLE } from 'components/themeRedesign'

export const Card = styled('button')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.styleguide.borderLight}`,
  borderRadius: theme.spacing(1),
  '&:hover': {
    boxShadow: '0px 8px 12px 0px rgba(0, 0, 0, 0.05)',
  },
  '&:focus-visible': {
    border: `1px solid ${theme.palette.action.focus}`,
    outline: BUTTON_OUTLINE_STYLE,
  },
}))

export const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
}))

export const Recipe = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  '& > svg': {
    color: theme.palette.styleguide.textGreyLight,
  },
}))

export const Description = styled('p')(({ theme }) => ({
  margin: 0,
  '& > svg': {
    color: theme.palette.styleguide.textGreyLight,
    display: 'inline',
    verticalAlign: 'middle',
  },
}))

export const Tags = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: theme.spacing(1),
}))

export const StyledHbTag = styled(HbTag)(({ theme }) => ({
  backgroundColor: `${theme.palette.styleguide.backgroundMedium} !important`,
}))
