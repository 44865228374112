import { useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/styles'
// eslint-disable-next-line no-restricted-imports
import { Box, styled } from '@mui/system'

import HbTextInput from 'components/HbComponents/Form/HbInputs/HbTextInput/HbTextInput'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { StyledSearchIcon } from 'components/pages/automations/editor/TriggerTemplateSelector.styles'

const StyledList = styled('ul')(({ theme }) => ({
  listStyleType: 'disc',
  padding: theme.spacing(1, 0, 3, 2),
  margin: 0,
  '& li': {
    marginBottom: theme.spacing(1),
  },
}))

export const StyledLink = styled(
  ({ children, href, className }: { children: string; href: string; className?: string }) => (
    <a href={href} target="_blank" rel="noreferrer" className={className}>
      {children}
    </a>
  )
)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  fontWeight: 500,
}))

export const HelpAutomationPanel = () => {
  const theme = useTheme()
  const [search, setSearch] = useState('')
  return (
    <Box sx={{ px: 2 }}>
      <HbTextInput
        name="Search"
        label="Search"
        hideLabel
        InputProps={{
          startAdornment: <StyledSearchIcon sx={{ margin: 0 }} />,
          placeholder: 'Search help articles',
          onKeyDown: (e) => {
            if (e.key === 'Enter') {
              window.open(`https://beta.docs.hummingbird.co/using-hummingbird/features-in-beta?q=${search}`, '_blank')
            }
          },
        }}
        onChange={(e) => setSearch(e.target.value)}
      />
      <HbText bold css={{ marginTop: theme.spacing(4) }} block>
        Learn about
      </HbText>
      <StyledList>
        <li>
          <StyledLink href="https://beta.docs.hummingbird.co/using-hummingbird/features-in-beta/automations#adding-editing-and-enabling-an-automation-recipe">
            Adding, Editing and Enabling an Automation Recipe
          </StyledLink>
        </li>
        <li>
          <StyledLink href="https://beta.docs.hummingbird.co/using-hummingbird/features-in-beta/automations#adding-a-custom-automation">
            Adding a custom automation
          </StyledLink>
        </li>
        <li>
          <StyledLink href="https://beta.docs.hummingbird.co/using-hummingbird/features-in-beta/automations/using-smart-values-in-your-automations">
            Smart values
          </StyledLink>
        </li>
      </StyledList>
      <HbButton
        label="Help Center"
        variant="secondary"
        href="https://beta.docs.hummingbird.co/"
        fullWidth
        target="_blank"
      />
    </Box>
  )
}
