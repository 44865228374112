import { AnyAction } from 'redux'

import { clearAllFormErrors, dismissFormErrorMessage, setFlashError } from 'actions/errorActions'
import { DISPLAY_TYPES, SnakeDataTypeKey, SnakeLibraryDataTypeKey } from 'actions/importingFields.types'
import { resetParsedData, resetParsingOptions, resetPasteText } from 'actions/parsingActions'
import { getCaseLock } from 'components/cases/hooks/useCaseLock'
import {
  FileUploadQuestionFileUrlQuery,
  FileUploadQuestionFileUrlQueryVariables,
} from 'components/cases/report/survey/questions/__generated__/queries.generated'
import { DOWNLOAD_SURVEY_FILE_UPLOAD_QUERY } from 'components/cases/report/survey/questions/queries'
import { getImportableTypes } from 'helpers/stateHelpers'
import { UrlResolverTokenData } from 'helpers/urlResolver'
import { ImportableType } from 'reducers/applicationReducer'
import { InvestigationAttachment } from 'reducers/investigationsReducer.types'
import { openTab } from 'reducers/tabReducer'
import { CaseImportCategory, ShrineUploadedFile } from 'types/api'
import { KeyboardOrMouseEvent } from 'types/hb'
import { navigateToInternalUrl } from 'utils/navigationHelpers'

import { AsyncThunk, State, Thunk } from './store'

export const SET_ACTIVE_IMPORT_FORM = 'SET_ACTIVE_IMPORT_FORM'
export const SET_ACTIVE_ENTITY_TYPE = 'SET_ACTIVE_ENTITY_TYPE'
export const SET_LOADING = 'SET_LOADING'
export const SET_ACTIVE = 'SET_ACTIVE'
export const SET_SHOW_DISPLAY = 'SET_SHOW_DISPLAY'
export const INCR_PENDING_OP = 'INCR_PENDING_OP'
export const DECR_PENDING_OP = 'DECR_PENDING_OP'
export const SET_UPPY_MODAL_OPEN = 'SET_UPPY_MODAL_OPEN'

export enum DownloadableFileType {
  Attachment = 'ATTACHMENT',
  InvestigationDocuments = 'INVESTIGATION_DOCUMENTS',
  RequestForInformationResponse = 'REQUEST_FOR_INFORMATION_RESPONSE',
  InformationRequestResponse = 'INFORMATION_REQUEST_RESPONSE',
  SurveyUpload = 'SURVEY_UPLOAD',
  Summary = 'SUMMARY',
}

export interface DownloadableFile {
  urlName: string
  downloadFileType: DownloadableFileType
  tokenData?: UrlResolverTokenData
}

export interface SurveyFileUpload {
  type: 'surveyFileUpload'
  filename: string
  contentType: string
  url: string
  fileSizeBytes?: number | null
  token: undefined
}

// TODO(jsu): this is incomplete, we'd need a more elaborate
// modal infra to track these fully
export function isModalOpen(state: State) {
  return !!state.view.activeForm || !!state.view.uppyModalOpen
}

export function addPendingOperation(key: string) {
  return { type: INCR_PENDING_OP, key }
}

export function removePendingOperation(key: string) {
  return { type: DECR_PENDING_OP, key }
}

export function setActiveImportForm(activeForm: string | null) {
  return { type: SET_ACTIVE_IMPORT_FORM, activeForm }
}

export function setActiveEntityType(entityType: SnakeDataTypeKey | null) {
  return { type: SET_ACTIVE_ENTITY_TYPE, entityType }
}

export function setLoading(loading: boolean, token: string) {
  return { type: SET_LOADING, loading, token }
}

export function setActive(token: string, active: boolean) {
  return { type: SET_ACTIVE, token, active }
}

export function setUppyModalOpen(uppyModalOpen: boolean) {
  return { type: SET_UPPY_MODAL_OPEN, uppyModalOpen }
}

export function setShowDisplay(
  dataType: string,
  showDisplay: string,
  category: CaseImportCategory | '' = ''
): AnyAction {
  return { type: SET_SHOW_DISPLAY, dataType, showDisplay, category }
}

function getActiveFormType(state: State) {
  const activeFormType = state.view.activeForm
  return activeFormType
}

export function clearActiveEntities(): Thunk<void> {
  return (dispatch) => {
    dispatch(setActiveEntityType(null))
    dispatch(clearAllFormErrors())
  }
}

export const LOADING_SLOW_IMPORT = 'slowImport'

export function closeActiveImportForm(investigationToken: string): Thunk<void> {
  return (dispatch, getState, { api }) => {
    const activeFormType = getActiveFormType(getState())

    dispatch(setLoading(false, LOADING_SLOW_IMPORT))
    if (activeFormType) {
      dispatch(resetParsingOptions(activeFormType))
      dispatch(setShowDisplay(activeFormType, DISPLAY_TYPES.EMPTY))
      dispatch(resetPasteText(activeFormType))
    }
    dispatch(clearActiveEntities())
    dispatch(setActiveImportForm(null))
    dispatch(resetParsedData())
    if (activeFormType) {
      api.clearCallbacks(`${investigationToken}-${activeFormType}`)
    }
  }
}

export function closeCaseImport(): Thunk<Promise<boolean>> {
  return (dispatch) => {
    dispatch(setShowDisplay('case_import', DISPLAY_TYPES.EMPTY))
    dispatch(resetPasteText('case_import'))
    dispatch(resetParsingOptions('case_import'))
    dispatch(resetParsedData())
    dispatch(setLoading(false, LOADING_SLOW_IMPORT))

    return Promise.resolve(true)
  }
}

export function forceCloseActiveForm(investigationToken: string): Thunk<void> {
  return (dispatch, getState) => {
    const activeFormType = getActiveFormType(getState())
    if (!activeFormType) {
      return
    }
    dispatch(dismissFormErrorMessage(`${activeFormType}-validations`))
    dispatch(closeActiveImportForm(investigationToken))
  }
}

export function openNewLibraryRecordForm(
  investigationToken: string,
  formType: SnakeLibraryDataTypeKey
): AsyncThunk<void> {
  return async (dispatch, _getState, { gqlClient }) => {
    const { isLocked } = await getCaseLock(gqlClient, investigationToken)

    if (isLocked) return

    dispatch(openTab({ tab: { type: 'libraryCreate', entityType: formType } }))
  }
}

export function openNewImportForm(investigationToken: string, formType: ImportableType): AsyncThunk<void> {
  return async (dispatch, getState, { api, gqlClient }) => {
    const { isLocked } = await getCaseLock(gqlClient, investigationToken)

    if (isLocked) {
      return
    }

    const importableTypes = getImportableTypes(getState()) || []
    if (importableTypes.includes(formType)) {
      dispatch(setShowDisplay(formType, DISPLAY_TYPES.RAW))
      dispatch(resetPasteText(formType))
      dispatch(clearActiveEntities())
      dispatch(setActiveImportForm(formType))
      api.clearCallbacks(`${investigationToken}-${formType}`)
    }
  }
}

export function resolveSurveyFileUploadUrl(file: DownloadableFile): AsyncThunk<ShrineUploadedFile> {
  return async (_dispatch, _getState, { gqlClient }) => {
    if (!file.tokenData) {
      return null
    }

    const result = await gqlClient.query<FileUploadQuestionFileUrlQuery, FileUploadQuestionFileUrlQueryVariables>({
      query: DOWNLOAD_SURVEY_FILE_UPLOAD_QUERY,
      variables: {
        questionToken: file.tokenData.questionToken,
        clientId: file.tokenData.clientId,
      },
      fetchPolicy: 'no-cache',
    })

    return result.data.surveyFileUpload
  }
}

export function resolveFileDownloadUrl(file: DownloadableFile): AsyncThunk<string> {
  return async (_dispatch, _getState, { api }) => {
    const { success, result } = await api.post(file.urlName, { urlParams: file.tokenData })

    if (success) return result.presignedGetUrl

    return null
  }
}

export function downloadFile(file: DownloadableFile): AsyncThunk<void> {
  return async (dispatch, getState, { usage }) => {
    let url = undefined
    usage.logEvent({ name: 'case:downloadFile:clicked', data: { downloadableFileType: file.downloadFileType } })

    switch (file.downloadFileType) {
      case DownloadableFileType.SurveyUpload:
        if (file.tokenData) {
          const fileData = await dispatch(resolveSurveyFileUploadUrl(file))
          url = fileData?.url
        }
        break
      case DownloadableFileType.RequestForInformationResponse:
        url = file.tokenData
          ? `/dashboard/reviews/${file.tokenData.reviewToken}/documents/generate-rfi-response/${file.tokenData.externalToken}`
          : null
        break
      case DownloadableFileType.InformationRequestResponse:
        url = file.tokenData
          ? `/dashboard/cases/${file.tokenData.caseToken}/documents/generate-information-request-response/${file.tokenData.token}`
          : null
        break
      case DownloadableFileType.Attachment:
      case DownloadableFileType.InvestigationDocuments:
      case DownloadableFileType.Summary:
        url = file.tokenData ? await dispatch(resolveFileDownloadUrl(file)) : file.urlName
        break
      default:
        throw new Error(`Unknown file.downloadFileType '${file.downloadFileType}'`)
    }

    if (url) {
      window.open(url, '_blank') // eslint-disable-line security/detect-non-literal-fs-filename
    } else {
      dispatch(setFlashError('Failed to download file'))
    }
  }
}

export function resolveAttachmentDownloadUrl(file: InvestigationAttachment): AsyncThunk<string> {
  return resolveFileDownloadUrl({
    downloadFileType: DownloadableFileType.Attachment,
    urlName: 'prepareDownloadApiInvestigationAttachmentPath',
    tokenData: {
      investigationToken: file.investigationToken,
      token: file.token,
    },
  })
}

export function downloadAttachment(file: InvestigationAttachment): AsyncThunk<void> {
  return downloadFile({
    downloadFileType: DownloadableFileType.Attachment,
    urlName: 'prepareDownloadApiInvestigationAttachmentPath',
    tokenData: {
      investigationToken: file.investigationToken,
      token: file.token,
    },
  })
}

// Navigate to an action (e.g. my account) of settings.
// Actions are currently my-account and filing-institutions
export function navigateToSettingsAction(action: string): Thunk<void> {
  return (dispatch, getState, { pilot }) => {
    dispatch(
      pilot.go('/settings/:action', {
        action,
      })
    )
  }
}

// Navigate to an action (e.g. my account) of settings.
// Actions are currently my-account and filing-institutions
export function navigateToDashboardAction(event: KeyboardOrMouseEvent | undefined, action: string): Thunk<void> {
  return (dispatch, _getState, _props) => {
    dispatch(
      navigateToInternalUrl(event, '/dashboard/:action', {
        action,
      })
    )
  }
}
