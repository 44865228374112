import { ChangeEvent } from 'react'

import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'

import HbTextArea, { HbTextAreaProps } from './HbTextArea'

/**
 * Drop-in component for react-hook-form
 * It expects `control` as well as the other `Controller` props
 */
function HbRHFTextArea<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, rules, onChange, ...rest }: UseControllerProps<TFieldValues, TName> & HbTextAreaProps) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <HbTextArea
            {...rest}
            {...field}
            onChange={
              onChange
                ? (value: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(value)
                    onChange(value)
                  }
                : field.onChange
            }
            ref={null}
            inputRef={field.ref}
            errors={fieldState.error?.message}
          />
        )
      }}
    />
  )
}

export default HbRHFTextArea
