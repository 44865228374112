import { useTheme } from '@emotion/react'
import { FilterList } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { HbDashedLine } from 'components/HbComponents/HbDashedLine/HbDashedLine'

import { HbText } from 'components/HbComponents/Text/HbText'

import { RecipePreviewAutomationRuleTemplateFragment as AutomationRuleTemplate } from 'components/pages/automations/__generated__/RecipePreview.generated'
import { jsonPrimitiveToDisplayString } from 'helpers/strings'
import { AutomationRuleType } from 'types/api'

import { RecipeStepIcon } from './AutomationIcon'
import { getLabelForDomainType } from './editor/TriggerFilterEditor/fieldConfig'

const Tile = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.styleguide.borderLight}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  background: theme.palette.styleguide.backgroundMedium,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const TileHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
  },
}))

const Line = () => <HbDashedLine orientation="vertical" length={32} block />

const WhenTile = ({ template }: { template: AutomationRuleTemplate }) => {
  let icon = null
  let text = null

  switch (template.__typename) {
    case 'ScheduledRuleTemplate':
      icon = <RecipeStepIcon type="schedule" variant="plain" />
      text = template.humanReadableSchedule
      break
    case 'TriggeredRuleTemplate':
    case 'ScheduledWithTriggerRuleTemplate': {
      const { domainType } = template
      if (domainType) {
        icon = <RecipeStepIcon type={template.domainType} variant="plain" />
        const domainTypeLabel = getLabelForDomainType(domainType)
        text =
          template.__typename === 'TriggeredRuleTemplate'
            ? `When ${domainTypeLabel} is updated`
            : `${template.humanReadableSchedule}, for any updated ${domainTypeLabel} `
      }
      break
    }
    default:
      return null
  }

  return (
    <>
      <Tile>
        <TileHeader>
          {icon}
          <HbText bold>{text}</HbText>
        </TileHeader>
      </Tile>
      <Line />
    </>
  )
}

const IfTile = ({ template }: { template: AutomationRuleTemplate }) => {
  const theme = useTheme()

  if (template.automationType === AutomationRuleType.Schedule) {
    return null
  }

  return (
    <>
      <Tile>
        <TileHeader>
          <FilterList css={{ color: theme.palette.styleguide.seaMedium }} />
          <HbText bold>Check if...</HbText>
        </TileHeader>
        <HbText block>{jsonPrimitiveToDisplayString(template.triggerDescription, 'N/A')}</HbText>
      </Tile>
      <Line />
    </>
  )
}

const ActionTile = ({ template }: { template: AutomationRuleTemplate }) => {
  return (
    <Tile>
      <TileHeader>
        <RecipeStepIcon type={template.actionType} variant="plain" />
        <HbText bold>{template.actionText}</HbText>
      </TileHeader>
    </Tile>
  )
}

export const RecipePreviewTiles = ({ template }: { template?: AutomationRuleTemplate }) => {
  if (!template) {
    return null
  }
  return (
    <>
      <WhenTile template={template} />
      <IfTile template={template} />
      <ActionTile template={template} />
    </>
  )
}
