import { gql, useQuery } from '@apollo/client'
import { Add } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { connect } from 'react-redux'

import * as narrativeTemplatesActions from 'actions/narrativeTemplatesActions'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbDialog, HbDialogContent } from 'components/HbComponents/HbDialog'
import Loader from 'components/library/Loader'

import { useHbMutation } from 'hooks/ApolloHelpers'
import { useDeleteNarrativeTemplateQuery } from 'hooks/SettingsHooks'
import { Template, TemplateError } from 'reducers/narrativeTemplatesReducer'

import NarrativeTemplateForm from './NarrativeTemplateForm'
import NarrativeTemplateList from './NarrativeTemplateList'
import {
  FetchNarrativeTemplatesQuery,
  FetchNarrativeTemplatesQueryVariables,
  UpsertNarrativeTemplateMutation,
  UpsertNarrativeTemplateMutationVariables,
} from './__generated__/NarrativeTemplateDialog.generated'

export const NARRATIVE_TEMPLATE_EDITOR_FIELDS = gql`
  fragment NarrativeTemplateEditorFields on NarrativeTemplate {
    token
    name
    content
    aiEnabled
    applicableReviewType {
      token
    }
  }
`

export const FETCH_NARRATIVE_TEMPLATES = gql`
  ${NARRATIVE_TEMPLATE_EDITOR_FIELDS}
  query FetchNarrativeTemplates {
    narrativeTemplates {
      ...NarrativeTemplateEditorFields
    }
  }
`

const UPSERT_NARRATIVE_TEMPLATE = gql`
  ${NARRATIVE_TEMPLATE_EDITOR_FIELDS}
  mutation UpsertNarrativeTemplate($input: UpsertNarrativeTemplateInput!) {
    upsertNarrativeTemplate(input: $input) {
      narrativeTemplate {
        ...NarrativeTemplateEditorFields
      }
    }
  }
`

export const DELETE_NARRATIVE_TEMPLATE = gql`
  ${NARRATIVE_TEMPLATE_EDITOR_FIELDS}
  mutation DeleteNarrativeTemplate($input: DeleteNarrativeTemplateInput!) {
    deleteNarrativeTemplate(input: $input) {
      narrativeTemplate {
        ...NarrativeTemplateEditorFields
      }
    }
  }
`

const Content = styled(HbDialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
  minHeight: 'min(60vh, 500px)',
  borderTop: `1px solid ${theme.palette.styleguide.borderLight}`,
}))

const TemplateListContainer = styled('div')(({ theme }) => ({
  flex: '0 0 250px',
  maxWidth: 250,
  display: 'flex',
  flexDirection: 'column',
  borderRight: `1px solid ${theme.palette.styleguide.borderLight}`,
}))

const FormContainer = styled('div')(() => ({
  flex: '1 1 600px',
  display: 'flex',
  flexDirection: 'column',
}))

const NewTemplateButton = styled(HbButton)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.styleguide.borderLight}`,
  borderRadius: 0,
  borderBottomLeftRadius: 16,
}))

interface OwnProps {
  open: boolean
  onClose: () => void
  onNewTemplate?: () => void
}

interface StateProps {
  activeTemplate: Template
  loading: boolean
  error: TemplateError
  setActiveTemplate: (template: Template | null) => void
}

function NarrativeTemplateDialog(props: StateProps & OwnProps) {
  const { activeTemplate, loading, open, onClose, setActiveTemplate, onNewTemplate = () => {}, error } = props

  const [upsertNarrativeTemplate] = useHbMutation<
    UpsertNarrativeTemplateMutation,
    UpsertNarrativeTemplateMutationVariables
  >(UPSERT_NARRATIVE_TEMPLATE, {
    logEvent: { name: 'settings:updateNarrativeTemplates:clicked' },
    refetchQueries: [FETCH_NARRATIVE_TEMPLATES],
  })

  const { deleteNarrativeTemplate } = useDeleteNarrativeTemplateQuery()

  const fetchResult = useQuery<FetchNarrativeTemplatesQuery, FetchNarrativeTemplatesQueryVariables>(
    FETCH_NARRATIVE_TEMPLATES
  )

  if (!fetchResult.data) {
    return null
  }

  const templates = fetchResult.data.narrativeTemplates

  const onDialogClose = async () => {
    setActiveTemplate(null)
    onClose()
  }

  const onTemplateSubmit = async (template: Template) => {
    const input = {
      applicableReviewTypeToken:
        template.applicableReviewTypeToken === 'any' ? null : template.applicableReviewTypeToken,
      content: template.content,
      name: template.name,
      token: template.token,
      aiEnabled: template.aiEnabled,
    }
    const result = await upsertNarrativeTemplate({
      variables: { input },
    })

    if (!result.errors) {
      onNewTemplate()
      onDialogClose()
    }
  }

  const onTemplateDelete = async (template: Template) => {
    await deleteNarrativeTemplate({
      variables: { input: { token: template.token ?? '' } },
    })
    setActiveTemplate(null)
  }

  if (!open) {
    return null
  }

  const templatesFormValues = templates.map((t) => ({
    token: t.token,
    name: t.name,
    content: t.content,
    applicableReviewTypeToken: t.applicableReviewType?.token,
    aiEnabled: t.aiEnabled,
  }))

  return (
    <HbDialog open={open} onClose={onDialogClose} title="Narrative Templates" disableEscapeKeyDown size="lg">
      {loading && <Loader variant="local" />}
      <Content>
        <TemplateListContainer>
          <NarrativeTemplateList
            selectedToken={activeTemplate?.token}
            templates={templatesFormValues}
            onSelect={setActiveTemplate}
          />
          <NewTemplateButton
            variant="textSecondary"
            Icon={Add}
            label="New Template"
            onClick={() => setActiveTemplate({ name: '', content: '' })}
          />
        </TemplateListContainer>
        <FormContainer>
          <NarrativeTemplateForm
            error={error}
            key={activeTemplate?.token} // needed due to the text editor
            onSubmit={onTemplateSubmit}
            onDelete={activeTemplate?.token ? onTemplateDelete : undefined}
            onCancel={onClose}
            template={activeTemplate || { name: '', content: '' }}
          />
        </FormContainer>
      </Content>
    </HbDialog>
  )
}

export default connect(
  (state: any, ownProps: OwnProps) => ({
    loading: state.view.loading.narrativeTemplates,
    templates: state.narrativeTemplates.generic,
    activeTemplate: state.narrativeTemplates.activeTemplate,
    error: state.narrativeTemplates.error,
    open: ownProps.open,
    onClose: ownProps.onClose,
  }),
  {
    setActiveTemplate: narrativeTemplatesActions.setActiveTemplate,
  }
)(NarrativeTemplateDialog)
