import { useCallback, useContext, useMemo, useRef } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { diff } from 'deep-object-diff'
import { Formik, FormikHelpers, useField } from 'formik'

import { groupBy } from 'lodash'

import {
  FormSection,
  Content as FormSectionContent,
  StyledFormSectionTitle,
} from 'components/HbComponents/Form/FormSection'
import { HbText } from 'components/HbComponents/Text/HbText'
import { usePendingChanges } from 'components/cases/Tabs/PendingChanges'
import { EditMode, editModeContainerClassName, EditModeRef } from 'components/library/EditMode'
import Loader from 'components/library/Loader'
import { FormConfigurationContext } from 'components/material/Form/FormConfiguration'
import { CustomFieldValue } from 'components/otherInfo/CustomFieldValue'
import { EmptyCustomFieldsMessage } from 'components/otherInfo/EmptyCustomFieldsMessage'
import { useCustomFieldLabelsQuery, useHasPermissionToManageCustomFields } from 'components/otherInfo/hooks'

import { CustomFieldLabelSearchData, OTHER_INFO_FIELD_NAME, sanitizeOtherInfo } from 'components/otherInfo/shared'
import { getDataTypeIcon } from 'components/settings/org-settings/OtherInfoLabels/helpers'
import { BasicInfoEntryWithIndex, DataWithOtherInfo, getAllCustomFields, isBasicInfo } from 'helpers/otherInfoHelpers'
import BoltOutlinedIcon from 'icons/BoltOutlinedIcon'
import { CustomFieldDatatypeEnum, OtherInfoEntry, OtherInfoLabelDisplayAsEnum, OtherInfoLabelTypeEnum } from 'types/api'

import { EntityInformationContext, EntityInformationContextValue } from '../Information/EntityInformationContext'
import {
  Editable,
  EditableArray,
  EditProps,
  FieldIcon,
  FieldWithIcon,
  getDiffStyles,
  InfoFieldArray,
  StyledRemoveButton,
} from '../InformationFields'

import { RevisionProps } from '../InformationFields.types'

import {
  OtherInfoField,
  OtherInfoFieldProps,
  OtherInfoLabel as StyledOtherInfoLabel,
  OtherInfoValue as StyledOtherInfoValue,
} from './FieldEdit'

import { PreserveFormState } from './PreserveFormState'

interface StyledOtherInfoFieldProps extends RevisionProps {
  isEmpty: boolean
}

const StyledField = styled(OtherInfoField)<StyledOtherInfoFieldProps>(
  ({ theme, isEmpty, hasDeletedDataInRevision, hasUpdatedDataInRevision }) => ({
    ...getDiffStyles({ hasDeletedDataInRevision, hasUpdatedDataInRevision, theme }),
    [`& ${StyledOtherInfoLabel}`]: {
      fontWeight: isEmpty ? theme.fontWeight.normal : theme.fontWeight.bolder,
      color: isEmpty ? theme.palette.styleguide.textGreyLight : theme.palette.styleguide.black,
      wordBreak: 'break-word',
    },
    [`& ${StyledOtherInfoValue}`]: {
      wordBreak: 'break-word',
    },
  })
)

const Read = ({ data, ...rest }: OtherInfoFieldProps) => {
  return <StyledField data={data} isEmpty={!data.value} {...rest} />
}

const NonEditableWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5),
  display: 'flex',
  flexFlow: 'row nowrap',
  columnGap: theme.spacing(4),
}))

const NonEditableRead = (props: OtherInfoFieldProps) => {
  const {
    data: { datatype, value },
  } = props
  const DataTypeIcon = getDataTypeIcon(datatype)
  return (
    <NonEditableWrapper>
      <FieldIcon as={DataTypeIcon} hasData={!!value} />
      <Read {...props} />
    </NonEditableWrapper>
  )
}

interface BasicInfoEditProps extends EditProps {
  hideLabel?: boolean
}

const Edit = ({ hideLabel, name }: BasicInfoEditProps) => {
  const baseName = name()
  const inputName = `${baseName}.value`
  const [{ value }] = useField(baseName)
  const { label, datatype, options, allowDecimal, allowNegative, allowTime } = value

  return (
    <CustomFieldValue
      label={label}
      inputName={inputName}
      datatype={datatype}
      options={options}
      hideLabel={hideLabel}
      allowDecimal={allowDecimal}
      allowNegative={allowNegative}
      allowTime={allowTime}
    />
  )
}

const getPopulatedFieldName = (entry: BasicInfoEntryWithIndex) => `basicInfo_${entry.token}`

export const getEntityOtherInfoDataWithCustomField = ({
  entityOtherInfoData,
  updatedField,
}: {
  entityOtherInfoData: OtherInfoEntry[]
  updatedField: BasicInfoEntryWithIndex
}) => {
  const { index, ...updatedEntry } = updatedField
  const updatedOtherInfoData = entityOtherInfoData.map(sanitizeOtherInfo)
  updatedOtherInfoData.splice(index, 1, sanitizeOtherInfo(updatedEntry))
  return {
    otherInfo: updatedOtherInfoData,
  }
}

const StyledEditable = styled(Editable)(({ theme }) => ({
  [`& ${FieldIcon}`]: {
    marginTop: theme.spacing(0.5),
  },
}))

interface PopulatedFieldEditableProps {
  data: BasicInfoEntryWithIndex
  previousData?: BasicInfoEntryWithIndex
  previousEntityDataExists: boolean
}

const PopulatedFieldEditable = <Entity extends DataWithOtherInfo>({
  data,
  previousData,
  previousEntityDataExists,
}: PopulatedFieldEditableProps) => {
  const { data: entityData } = useContext<EntityInformationContextValue<Entity>>(EntityInformationContext)
  // Since CRM objects can have duplicate other info,
  // we can identify the basic info field by name + index:
  const name = getPopulatedFieldName(data)

  const entityOtherInfoData = entityData.otherInfo

  /**
   * This helper is for *singular* custom field entries populated in entity other info.
   * Splices the updated value back into the array containing all other info
   * so the change preserves unrelated entity other info.
   * This won't affect the order of other info when saved.
   */
  const transformExistingDataWithCustomFieldBeforeSave = useCallback(
    (updatedFormValues: { [key: string]: BasicInfoEntryWithIndex }) => {
      const [updatedField] = Object.values(updatedFormValues)
      return getEntityOtherInfoDataWithCustomField({ entityOtherInfoData, updatedField })
    },
    [entityOtherInfoData]
  )

  // Additionally, by keying the form values by this "field name",
  // it integrates with how `Editable` and `PendingChanges` access values by `name`.
  const extractedData = useMemo(() => ({ [name]: data }), [data, name])
  const extractedValues = useMemo(() => ({ [name]: data }), [data, name])
  const extractedPreviousData = useMemo(() => previousData && { [name]: previousData }, [name, previousData])
  const extractedDiff = useMemo(() => {
    if (!previousEntityDataExists || data.value === previousData?.value) return undefined
    return { [name]: data }
  }, [data, name, previousData, previousEntityDataExists])

  const dataType = (previousData || data).datatype
  const DataTypeIcon = getDataTypeIcon(dataType)

  return (
    <StyledEditable
      Icon={DataTypeIcon}
      additionalTopLevelData={{ allowCustomFieldUpdates: true }}
      extractedData={extractedData}
      extractedDiff={extractedDiff}
      extractedPreviousData={extractedPreviousData}
      extractedValues={extractedValues}
      name={name}
      placeholder={data.label}
      transformValues={transformExistingDataWithCustomFieldBeforeSave}
      Read={Read}
      Edit={Edit}
    />
  )
}

interface MaybeManagedPopulatedFieldEditableProps extends PopulatedFieldEditableProps {
  managed?: boolean | null
}

const MaybeManagedPopulatedFieldEditable = <Entity extends DataWithOtherInfo>({
  managed,
  data,
  ...rest
}: MaybeManagedPopulatedFieldEditableProps) => {
  if (managed) return <NonEditableRead data={data} />
  return <PopulatedFieldEditable<Entity> data={data} {...rest} />
}

export const BaseStyledFormSection = styled(FormSection)(({ theme }) => ({
  [`& ${StyledFormSectionTitle}`]: {
    marginTop: theme.spacing(0.25),
    textTransform: 'capitalize',
    fontWeight: theme.fontWeight.bolder,
    ...theme.typography.md,
    letterSpacing: 0,
  },
}))

const getUniqueKey = (value: BasicInfoEntryWithIndex) => `${value.label}-${value.index}`

const getUnpopulatedFieldName = (token: string) => `basicInfo_${token}`

// TODO(bwe) use CustomFieldLabelRowFragment where possible https://thecharm.atlassian.net/browse/PROD-18927
interface UnpopulatedLabelEditableProps {
  label: string
  token: string
  datatype: CustomFieldDatatypeEnum
  options: string[]
  allowDecimal?: boolean | null
  allowNegative?: boolean | null
  allowTime?: boolean | null
}

// TODO(bwe) use CustomFieldLabelRowFragment where possible https://thecharm.atlassian.net/browse/PROD-18927
interface UnpopulatedLabelFormValues {
  [name: string]: {
    label: string
    value: string
    datatype: CustomFieldDatatypeEnum
    options: string[]
    allowDecimal?: boolean | null
    allowNegative?: boolean | null
    allowTime?: boolean | null
  }
}

const UnpopulatedLabelFieldWithIcon = styled(FieldWithIcon)(({ theme }) => ({
  padding: theme.spacing(1.5),
  columnGap: 0,
  [`& ${FieldIcon}`]: {
    marginTop: theme.spacing(2),
  },
}))

/**
 * Render an unpopulated "custom field" label,
 * so the user can edit the field and add a value.
 */
const UnpopulatedLabelEditable = <Entity extends DataWithOtherInfo>({
  label,
  token,
  datatype,
  options,
  allowDecimal,
  allowNegative,
  allowTime,
}: UnpopulatedLabelEditableProps) => {
  const name = getUnpopulatedFieldName(token)
  const initialValues: UnpopulatedLabelFormValues = {
    [name]: { label, datatype, allowDecimal, allowNegative, allowTime, options, value: '' },
  }
  const editMode = useRef<EditModeRef | null>(null)

  const { data, save } = useContext<EntityInformationContextValue<Entity>>(EntityInformationContext)

  const { pendingChanges, clearPendingChanges } = usePendingChanges<Record<string, string>>()

  const entityOtherInfoData = data.otherInfo

  const onSave = useCallback(
    async ({ [name]: updated }: UnpopulatedLabelFormValues, actions: FormikHelpers<UnpopulatedLabelFormValues>) => {
      const finalValues = {
        otherInfo: [...entityOtherInfoData.map(sanitizeOtherInfo), updated],
      }
      actions.setSubmitting(true)
      const relationships = undefined
      const success = await save?.(finalValues, relationships, { allowCustomFieldUpdates: true })
      if (success) {
        editMode.current?.setMode('read')
        clearPendingChanges()
      }
      actions.setSubmitting(false)
    },
    [clearPendingChanges, entityOtherInfoData, name, save]
  )

  const DataTypeIcon = getDataTypeIcon(datatype)

  return (
    <Formik initialValues={pendingChanges ?? initialValues} enableReinitialize onSubmit={onSave}>
      {({ submitForm, isSubmitting, values: currentValues, dirty }) => {
        const formEl = (
          <>
            <EditMode ref={editMode} saveDisabled={isSubmitting} save={submitForm} name={name}>
              {(params) => (params.mode === 'read' ? <Read data={{ datatype, label }} /> : <Edit name={() => name} />)}
            </EditMode>
            <PreserveFormState currentValues={currentValues} initialValues={initialValues} dirty={dirty} />
          </>
        )
        return (
          <UnpopulatedLabelFieldWithIcon Icon={DataTypeIcon} hasData={false}>
            {formEl}
          </UnpopulatedLabelFieldWithIcon>
        )
      }}
    </Formik>
  )
}

interface MaybeManagedUnpopulatedLabelProps extends UnpopulatedLabelEditableProps {
  managed?: boolean | null
  token: string
}

const MaybeManagedUnpopulatedLabelEditable = <Entity extends DataWithOtherInfo>({
  label,
  managed,
  token,
  datatype,
  options,
  allowDecimal,
  allowNegative,
  allowTime,
}: MaybeManagedUnpopulatedLabelProps) => {
  if (managed) return null
  return (
    <UnpopulatedLabelEditable<Entity>
      label={label}
      token={token}
      datatype={datatype}
      options={options}
      allowDecimal={allowDecimal}
      allowNegative={allowNegative}
      allowTime={allowTime}
    />
  )
}

const PopulatedEditableArrayEditLabel = styled(HbText)(({ theme }) => ({
  marginLeft: theme.spacing(),
}))

const PopulatedEditableArrayReadLabel = styled(HbText)(({ theme }) => ({
  fontWeight: theme.fontWeight.bolder,
}))

const ManagedPopulatedEditableArrayReadLabel = styled(PopulatedEditableArrayReadLabel)(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
}))

const MaybeManagedPopulatedFieldArrayContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const ManagedPopulatedFieldArrayContainer = styled(MaybeManagedPopulatedFieldArrayContainer)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
}))

interface ManagedPopulatedArrayProps {
  label: string
  populatedFieldsForLabel: BasicInfoEntryWithIndex[]
}

// A list of custom info entries for a particular label
// which is uneditable by the user because the label is "managed".
const ManagedPopulatedArray = ({ label, populatedFieldsForLabel }: ManagedPopulatedArrayProps) => {
  return (
    <div>
      <ManagedPopulatedEditableArrayReadLabel size="md" color="primary">
        {label}
      </ManagedPopulatedEditableArrayReadLabel>
      <ManagedPopulatedFieldArrayContainer>
        {populatedFieldsForLabel.map((populatedField) => (
          <Read hideLabel key={getUniqueKey(populatedField)} data={populatedField} />
        ))}
      </ManagedPopulatedFieldArrayContainer>
    </div>
  )
}

const PopulatedEditableArrayRead = (props: OtherInfoFieldProps) => <Read hideLabel {...props} />
const PopulatedEditableArrayEdit = (props: EditProps) => <Edit hideLabel {...props} />

const PopulatedFieldArrayContainer = styled(MaybeManagedPopulatedFieldArrayContainer)(({ theme }) => ({
  paddingTop: theme.spacing(),
  rowGap: theme.spacing(3),
  [`& ${StyledRemoveButton}`]: {
    top: theme.spacing(),
  },
}))

interface PopulatedEditableArrayLegendProps {
  label: string
  mode: 'edit' | 'read'
}

const PopulatedEditableArrayLegend = ({ label, mode }: PopulatedEditableArrayLegendProps) => {
  return (
    <legend>
      {mode === 'read' ? (
        // Mimics readable other info labels
        <PopulatedEditableArrayReadLabel size="md" color="primary">
          {label}
        </PopulatedEditableArrayReadLabel>
      ) : (
        // Mimics Hb form input labels
        <PopulatedEditableArrayEditLabel size="s" color="secondary">
          {label}
        </PopulatedEditableArrayEditLabel>
      )}
    </legend>
  )
}

export const getEntityOtherInfoDataWithCustomFields = ({
  entityOtherInfoData,
  populatedFieldsForLabel,
  updatedFields,
}: {
  entityOtherInfoData: OtherInfoEntry[]
  populatedFieldsForLabel: BasicInfoEntryWithIndex[]
  updatedFields: BasicInfoEntryWithIndex[]
}) => {
  // includes all ordinary other info and custom info values for unrelated labels
  const allEntityOtherInfo = entityOtherInfoData.map(sanitizeOtherInfo)

  // replace updated entry in-place in existing other info array
  const covered = new Set<number>([])
  updatedFields.forEach(({ index, ...updatedEntry }) => {
    if (!covered.has(index)) covered.add(index)
    allEntityOtherInfo.splice(index, 1, sanitizeOtherInfo(updatedEntry))
  })

  // then filter out deletions from array
  const deletedIndices = new Set(populatedFieldsForLabel.map((entry) => entry.index).filter((i) => !covered.has(i)))
  const updatedOtherInfoDataWithDeletions = allEntityOtherInfo.filter((_entry, i) => !deletedIndices.has(i))

  return updatedOtherInfoDataWithDeletions
}

const PopulatedFieldsForLabelStyledFieldset = styled('fieldset')(({ theme }) => ({
  padding: theme.spacing(1.5),
  display: 'flex',
  flexFlow: 'row nowrap',
  columnGap: theme.spacing(2),

  [`.${editModeContainerClassName}`]: {
    paddingTop: theme.spacing(0.5),
  },
}))

interface PopulatedFieldsForLabelEditableArrayProps {
  label: string
  populatedFieldsForLabel: BasicInfoEntryWithIndex[]
  previousBasicInfoFields?: BasicInfoEntryWithIndex[]
  previousEntityDataExists: boolean
}

// Slightly different UX when a label has a list of populated custom info entries
// e.g. [{ label: 'foo', value: 'a' }, { label: 'foo', value: 'b' }, ...]
const PopulatedFieldsForLabelEditableArray = <Entity extends DataWithOtherInfo>({
  label,
  populatedFieldsForLabel,
  previousBasicInfoFields,
  previousEntityDataExists,
}: PopulatedFieldsForLabelEditableArrayProps) => {
  const { data: entityData } = useContext<EntityInformationContextValue<Entity>>(EntityInformationContext)

  const entityOtherInfoData = entityData.otherInfo

  const extractedData = useMemo(() => ({ otherInfo: populatedFieldsForLabel }), [populatedFieldsForLabel])

  const extractedDiff = useMemo(() => {
    if (!previousEntityDataExists || !previousBasicInfoFields) return undefined
    return diff({ otherInfo: previousBasicInfoFields }, { otherInfo: populatedFieldsForLabel }) as Record<
      string,
      BasicInfoEntryWithIndex[]
    >
  }, [populatedFieldsForLabel, previousBasicInfoFields, previousEntityDataExists])

  const extractedPreviousData = useMemo(
    () => previousBasicInfoFields && { otherInfo: previousBasicInfoFields },
    [previousBasicInfoFields]
  )
  const extractedValues = useMemo(() => ({ otherInfo: populatedFieldsForLabel }), [populatedFieldsForLabel])

  /**
   * This helper is for *lists* of custom field entries **per label** in CRM other info.
   * Splices the updated values back into the array containing all other info
   * so the change preserves unrelated entity other info.
   * This won't affect the order of other info when saved either
   * (similar to the singular variant of this component).
   */
  const transformExistingDataWithEditedCustomFieldsBeforeSave = useCallback(
    (updatedFields: BasicInfoEntryWithIndex[]) =>
      getEntityOtherInfoDataWithCustomFields({
        entityOtherInfoData,
        populatedFieldsForLabel,
        updatedFields,
      }),
    [entityOtherInfoData, populatedFieldsForLabel]
  )

  const displayData = extractedData || extractedPreviousData

  const hasData = !!displayData.otherInfo.length

  const dataTypeOfLastItem = displayData.otherInfo[displayData.otherInfo.length - 1]?.datatype
  const DataTypeIcon = getDataTypeIcon(dataTypeOfLastItem)

  const editableArrayEl = (
    <EditableArray
      additionalTopLevelData={{ allowCustomFieldUpdates: true }}
      canAdd={false}
      extractedData={extractedData}
      extractedDiff={extractedDiff}
      extractedValues={extractedValues}
      extractedPreviousData={extractedPreviousData}
      name={OTHER_INFO_FIELD_NAME}
      transformValues={transformExistingDataWithEditedCustomFieldsBeforeSave}
    >
      {(params) => (
        <>
          <PopulatedEditableArrayLegend label={label} mode={params.mode} />
          <PopulatedFieldArrayContainer>
            <InfoFieldArray<BasicInfoEntryWithIndex>
              getDiffKey={getUniqueKey}
              params={params}
              Read={PopulatedEditableArrayRead}
              Edit={PopulatedEditableArrayEdit}
            />
          </PopulatedFieldArrayContainer>
        </>
      )}
    </EditableArray>
  )

  return (
    <PopulatedFieldsForLabelStyledFieldset>
      <FieldIcon as={DataTypeIcon} hasData={hasData} />
      {editableArrayEl}
    </PopulatedFieldsForLabelStyledFieldset>
  )
}

interface MaybeManagedPopulatedFieldsForLabelEditableArrayProps {
  label: string
  managed?: boolean | null
  populatedFieldsForLabel: BasicInfoEntryWithIndex[]
  previousBasicInfoFields?: BasicInfoEntryWithIndex[]
  previousEntityDataExists: boolean
}

const MaybeManagedPopulatedFieldsForLabelEditableArray = ({
  label,
  managed,
  populatedFieldsForLabel,
  previousBasicInfoFields,
  previousEntityDataExists,
}: MaybeManagedPopulatedFieldsForLabelEditableArrayProps) => {
  if (managed) return <ManagedPopulatedArray label={label} populatedFieldsForLabel={populatedFieldsForLabel} />

  return (
    <PopulatedFieldsForLabelEditableArray
      label={label}
      populatedFieldsForLabel={populatedFieldsForLabel}
      previousBasicInfoFields={previousBasicInfoFields}
      previousEntityDataExists={previousEntityDataExists}
    />
  )
}

const Section = styled('section')(({ theme }) => ({
  padding: theme.spacing(1.5),
}))

interface AllBasicInfoProps {
  fetchedCustomFieldLabels?: CustomFieldLabelSearchData
  populatedBasicInfoFields: BasicInfoEntryWithIndex[]
  readOnly?: boolean
}

const AllBasicInfo = <Entity extends DataWithOtherInfo>({
  fetchedCustomFieldLabels,
  populatedBasicInfoFields,
  readOnly,
}: AllBasicInfoProps) => {
  const { previousData: previousEntityData } =
    useContext<EntityInformationContextValue<Entity, Entity & DataWithOtherInfo>>(EntityInformationContext)

  const previousEntityOtherInfoData = previousEntityData?.otherInfo
  // It's possible for other info labels to be duplicated on an entity
  // prior to being elevated to custom fields.
  // In that case, we should still display all populated values.
  const populatedBasicInfoFieldsByLabel = useMemo(() => {
    return groupBy(populatedBasicInfoFields, 'label')
  }, [populatedBasicInfoFields])

  const previousBasicInfoFields = useMemo(() => {
    if (!previousEntityOtherInfoData) return undefined
    const fields: BasicInfoEntryWithIndex[] = []
    previousEntityOtherInfoData.forEach((entry, index) => {
      if (!isBasicInfo(entry)) return
      fields.push({ ...entry, index })
    })
    return fields
  }, [previousEntityOtherInfoData])

  const previousBasicInfoFieldsByLabel = useMemo(() => {
    if (!previousBasicInfoFields) return undefined
    return groupBy(previousBasicInfoFields, 'label')
  }, [previousBasicInfoFields])

  const basicInfoLabels = useMemo(
    () =>
      getAllCustomFields({
        customFieldLabels: fetchedCustomFieldLabels,
        populatedCustomFields: populatedBasicInfoFields,
      }),
    [fetchedCustomFieldLabels, populatedBasicInfoFields]
  )

  return (
    <>
      {basicInfoLabels.map(({ label, managed, token, datatype, options, allowDecimal, allowNegative, allowTime }) => {
        const populatedFieldsForLabel = populatedBasicInfoFieldsByLabel[label]

        if (!populatedFieldsForLabel?.length) {
          // hide an unpopulated field if the user can't add a value for it
          if (readOnly || managed) return null

          // labels should be unique keys, even if there can be duplicated custom field entries
          return (
            <MaybeManagedUnpopulatedLabelEditable
              key={label}
              label={label}
              managed={managed}
              datatype={datatype}
              options={options}
              allowDecimal={allowDecimal}
              allowNegative={allowNegative}
              allowTime={allowTime}
              token={token}
            />
          )
        }

        const previousBasicInfoFieldsForLabel = previousBasicInfoFieldsByLabel?.[label]

        // anticipating that most custom field entries will be unique by label.
        // however atm it's possible for multiple entries to exist per label
        // (either via CRM merges or because CRM other info can have duplicate labels).
        if (populatedFieldsForLabel.length === 1) {
          const [populatedField] = populatedFieldsForLabel
          const previousData = previousBasicInfoFieldsForLabel?.[0]

          // hide if managed
          if (populatedField.managed && !populatedField.value) return null

          return (
            <MaybeManagedPopulatedFieldEditable
              data={populatedField}
              key={label}
              managed={managed}
              previousData={previousData}
              previousEntityDataExists={!!previousEntityData}
            />
          )
        }

        // this handles the UX where there may be multiple entries for a label
        // where we may want to allow de-duping the entries
        return (
          <MaybeManagedPopulatedFieldsForLabelEditableArray
            key={label}
            label={label}
            managed={managed}
            populatedFieldsForLabel={populatedFieldsForLabel}
            previousBasicInfoFields={previousBasicInfoFieldsForLabel}
            previousEntityDataExists={!!previousEntityData}
          />
        )
      })}
    </>
  )
}

const EmptyFormSection = styled(BaseStyledFormSection)(({ theme }) => ({
  [`& ${FormSectionContent}`]: {
    background: theme.palette.styleguide.backgroundLight,
    padding: theme.spacing(2),
    rowGap: theme.spacing(2),
  },
}))

const EmptyBasicInfoSection = styled(Section)(() => ({
  maxWidth: 450,
}))

const EmptyBasicInfo = () => (
  <EmptyBasicInfoSection>
    <FieldWithIcon hasData Icon={BoltOutlinedIcon}>
      <EmptyFormSection title="Custom Fields">
        <EmptyCustomFieldsMessage />
      </EmptyFormSection>
    </FieldWithIcon>
  </EmptyBasicInfoSection>
)

interface BasicInfoFieldsInnerProps {
  labelType: OtherInfoLabelTypeEnum
}

const BasicInfoFieldsInner = <Entity extends DataWithOtherInfo>({ labelType }: BasicInfoFieldsInnerProps) => {
  const { data: entityData } =
    useContext<EntityInformationContextValue<Entity, Entity & DataWithOtherInfo>>(EntityInformationContext)

  const entityOtherInfoData = entityData.otherInfo

  const { readOnly } = useContext(FormConfigurationContext)

  const hasPermissionToManageCustomFields = useHasPermissionToManageCustomFields()

  const { data: customFieldLabelsQueryData, loading: customFieldLabelsLoading } = useCustomFieldLabelsQuery(labelType)

  const populatedBasicInfoFields = useMemo(() => {
    const populatedFields: BasicInfoEntryWithIndex[] = []
    entityOtherInfoData.forEach((entry, index) => {
      if (!isBasicInfo(entry)) return
      populatedFields.push({ ...entry, index, displayAs: OtherInfoLabelDisplayAsEnum.CustomInfo })
    })
    return populatedFields
  }, [entityOtherInfoData])

  if (customFieldLabelsLoading) {
    return (
      <Section>
        <Loader />
      </Section>
    )
  }

  if ((readOnly || !hasPermissionToManageCustomFields) && !populatedBasicInfoFields.length) {
    return null
  }

  if (
    !customFieldLabelsLoading &&
    !customFieldLabelsQueryData?.customFieldLabelSearch?.length &&
    !populatedBasicInfoFields.length
  ) {
    return <EmptyBasicInfo />
  }

  return (
    <AllBasicInfo
      fetchedCustomFieldLabels={customFieldLabelsQueryData?.customFieldLabelSearch}
      populatedBasicInfoFields={populatedBasicInfoFields}
      readOnly={readOnly}
    />
  )
}

interface BasicInfoFieldsProps {
  labelType: OtherInfoLabelTypeEnum
}

export const BasicInfoFields = ({ labelType }: BasicInfoFieldsProps) => {
  return <BasicInfoFieldsInner labelType={labelType} />
}
