// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { DecisionString } from './types'

const Container = styled('span')(() => ({
  fontWeight: 'lighter',
}))

const Name = styled('span')(() => ({
  color: 'white',
}))

const DecisionText = styled('i')(({ theme }) => ({
  color: theme.palette.styleguide.textGreyLight,
  fontStyle: 'italic',
}))

const decisionsLabelMap: Record<DecisionString, string> = {
  APPROVE: 'approved',
  REQUEST_CHANGES: 'requested changes',
}

const getDecisionText = (decision?: string | null) =>
  (decision && decisionsLabelMap[decision as DecisionString]) ?? 'pending review'

interface DecisionContextProps {
  decision?: string | null
  name: string
}

export const DecisionContext = ({ decision, name }: DecisionContextProps) => (
  <Container>
    <Name>{name} </Name>
    <DecisionText>({getDecisionText(decision)})</DecisionText>
  </Container>
)
