import { gql } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports
import { Box, DialogActions, dialogClasses, styled, useTheme } from '@mui/material'

import { useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbDialog, HbDialogContent } from 'components/HbComponents/HbDialog'
import { HbTag, HbTagList } from 'components/HbComponents/HbTag'
import { HbText } from 'components/HbComponents/Text/HbText'
import { RecipePreviewAutomationRuleTemplateFragment as AutomationRuleTemplate } from 'components/pages/automations/__generated__/RecipePreview.generated'

import { useCreateAutomationRule } from 'components/pages/automations/hooks/useCreateAutomationRule'
import { Tag } from 'components/pages/automations/recipeTags'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { hasPermission } from 'helpers/stateHelpers'

import { jsonPrimitiveToDisplayString } from 'helpers/strings'
import { BadgePermissionsEnum } from 'types/api'

import { getTemplateCategoryStyles } from './AutomationRuleTemplate/AutomationRuleTemplate.utils'
import * as Styles from './RecipePreview.styles'
import { RecipePreviewTiles } from './RecipePreviewTiles'
import { useAutomationAdminAccountCheck } from './hooks/AutomationAdminHelpers'

const Title = styled((props) => <HbText {...props} block bold size="lg" tag="h3" />)(({ theme }) => {
  return {
    marginBottom: theme.spacing(2),
  }
})

const TextSection = ({ title, value }: { title: string; value?: string | null }) => {
  return (
    <div>
      <Title>{title}</Title>
      <HbText size="md" block>
        {jsonPrimitiveToDisplayString(value, 'N/A')}
      </HbText>
    </div>
  )
}

export const RecipePreview = ({
  onClose,
  template,
  tags = [],
}: {
  onClose: () => void
  template?: AutomationRuleTemplate
  tags?: Array<Tag>
}) => {
  const theme = useTheme()
  const usage = useUsage()
  const hasManageAutomationsPermission = useSelector((state) =>
    hasPermission(state, BadgePermissionsEnum.ManageAutomations)
  )
  const name = template?.name || ''

  const category = template?.automationTemplateCategory
  const categoryStyles = category ? getTemplateCategoryStyles(category.style) : null

  const isAutomationAdmin = useAutomationAdminAccountCheck()

  const { createAutomationRule, loading } = useCreateAutomationRule(template?.slug || null)

  const handleCreateAutomation = () => {
    usage.logEvent({
      name: 'automations:addAutomationRecipe:clicked',
      data: {
        recipeName: name,
        recipeTags: tags.map((tag) => tag.display),
        recipeCategory: category?.name || '',
      },
    })
    if (hasManageAutomationsPermission) {
      createAutomationRule()
    }
  }

  return (
    <HbDialog
      id="recipe-preview"
      open={!!template}
      onClose={onClose}
      title={name}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: 880,
          width: '100%',
        },
      }}
      header={
        <Styles.Header>
          <Styles.DialogTitle>
            <HbText size="xl">{name}</HbText>
          </Styles.DialogTitle>
          <Box sx={{ mr: 2 }}>
            <HbTagList>
              {category && categoryStyles && <HbTag themeColor={categoryStyles.themeColor} label={category.name} />}
              {tags?.map((tag) => (
                <HbTag key={tag.display} label={tag.display} />
              ))}
            </HbTagList>
          </Box>
        </Styles.Header>
      }
    >
      <HbDialogContent
        sx={{
          display: 'grid',
          gap: 4,
          px: 3,
          pt: 3,
          pb: 4,
          gridTemplateColumns: '1.25fr 1fr',
        }}
      >
        <Box display="flex" flexDirection="column" gap={4}>
          <TextSection title="Description" value={template?.description} />
          <TextSection title="Setup" value={template?.setup} />
        </Box>
        <div>
          <Title>Recipe</Title>
          <RecipePreviewTiles template={template} />
        </div>
      </HbDialogContent>
      <DialogActions
        sx={{
          gap: 1,
          padding: theme.spacing(2, 3),
          margin: theme.spacing(0),
          boxShadow: '0px -4px 16px 0px #0000001A',
        }}
      >
        <HbButton onClick={onClose} variant="textSecondary" label="Cancel" disabled={loading} />
        {template?.token && isAutomationAdmin && (
          <HbButton
            to={`/automations/recipes/${template.token}`}
            label="Edit recipe"
            variant="secondary"
            loading={loading}
          />
        )}
        <HbButton
          onClick={handleCreateAutomation}
          disabled={!hasManageAutomationsPermission}
          label="Add automation"
          variant="primary"
          loading={loading}
        />
      </DialogActions>
    </HbDialog>
  )
}

RecipePreview.fragments = {
  template: gql`
    fragment RecipePreviewAutomationRuleTemplate on AutomationRuleTemplate {
      ... on AutomationRuleTemplateBase {
        actionText
        actionType
        automationType
        description
        humanReadableSchedule
        name
        setup
        slug
        token
        triggerDescription
        automationTemplateCategory {
          token
          name
          style
          slug
        }
      }
      ... on TriggeredRuleTemplate {
        domainType
      }
      ... on ScheduledWithTriggerRuleTemplate {
        domainType
      }
    }
  `,
}
