import { css } from '@emotion/css'
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/system'

import invariant from 'tiny-invariant'

import { GQLError } from 'components/GQLError'
import { HbPagination } from 'components/HbComponents/HbPagination/HbPagination'
import { HbText } from 'components/HbComponents/Text/HbText'
import Loader from 'components/library/Loader'
import { RoundedTable } from 'components/library/Table/RoundedTable'
import TableColumn from 'components/library/Table/TableColumn'

import { titleCase } from 'helpers/uiHelpers'
import { usePaginatedQuery } from 'hooks/ApolloHelpers'

import { useDateFormatter } from 'hooks/DateFormatHooks'

import { ViewAutomationInnerAutomationRuleFragment as AutomationRule } from '../__generated__/ViewAutomation.queries.generated'

import { PREVIEW_MODE_TABLE_PAGINATED } from './PreviewRunsTable.queries'
import {
  PreviewModeExecutionRowFragment,
  PreviewModeTablePaginatedQuery,
  PreviewModeTablePaginatedQueryVariables,
} from './__generated__/PreviewRunsTable.queries.generated'

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  overflowY: 'scroll',
}))

const StyledPagination = styled(HbPagination)(({ theme }) => ({
  boxShadow: `0px -4px 7px 0px #0000001A`,
  marginTop: theme.spacing(2),
}))

const overrideClasses = {
  container: css({ '&&': { overflowX: 'auto' } }),
}

type ExecutionRow = PreviewModeExecutionRowFragment

export const PreviewRunsTable = ({ automationRule }: { automationRule: AutomationRule }) => {
  const formatDate = useDateFormatter()

  const { error, loading, displayedData, paginationProps } = usePaginatedQuery<
    PreviewModeTablePaginatedQuery,
    ExecutionRow,
    PreviewModeTablePaginatedQueryVariables
  >({
    selector: (data) => {
      if (
        data?.automationRule?.__typename === 'TriggerAutomationRule' ||
        data?.automationRule?.__typename === 'ScheduleWithTriggerAutomationRule'
      ) {
        return data?.automationRule?.pendingRuleExecutions
      }
      return null
    },
    query: PREVIEW_MODE_TABLE_PAGINATED,
    args: {
      token: automationRule.token,
      organizationToken: null,
      after: null,
    },
    pageSizes: [50],
    functionOptions: {
      skip: !automationRule.token,
    },
  })

  if (loading && !displayedData.length) {
    return <Loader testId="preview_mode_table_loading" />
  }

  if (error) {
    return <GQLError error={error} />
  }

  if (
    automationRule.__typename !== 'TriggerAutomationRule' &&
    automationRule.__typename !== 'ScheduleWithTriggerAutomationRule'
  ) {
    return null
  }

  invariant(automationRule.primaryRuleTrigger)
  invariant(automationRule.name)

  return (
    <>
      <Wrapper>
        <RoundedTable
          uniqueKey="token"
          maskColor={(theme) => theme.palette.grey[50]}
          data={displayedData}
          loading={loading}
          emptyMessage="No matching runs found."
          styleOverrides={{
            Table: overrideClasses,
          }}
        >
          <TableColumn
            align="left"
            field="createdAt"
            header="Date"
            value={({ createdAt }) => {
              return <HbText>{formatDate(createdAt, null, 'lll')}</HbText>
            }}
          />
          <TableColumn
            align="left"
            field="ruleName"
            header="Run"
            value={() => {
              return automationRule.name
            }}
          />
          <TableColumn
            align="left"
            field="event.domainType"
            header={titleCase(automationRule.primaryRuleTrigger.domainType)}
            value={(execution) => {
              if (execution.event.__typename) {
                return (
                  <a href={execution.event.eventableHref} target="_blank" rel="noreferrer">
                    View
                  </a>
                )
              }

              return null
            }}
          />
        </RoundedTable>
      </Wrapper>
      <StyledPagination {...paginationProps} />
    </>
  )
}
