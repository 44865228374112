import { MouseEvent, useCallback } from 'react'

import { gql } from '@apollo/client'
import { DescriptionRounded } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import pluralize from 'pluralize'

import { useDispatch, useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbNonIdealState } from 'components/HbComponents/HbNonIdealState'
import { HbText } from 'components/HbComponents/Text/HbText'
import { AssignmentDetails } from 'components/cases/reviews/assignment/AssignmentDetails'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { getCasePagePinnedTab, openTab } from 'reducers/tabReducer'
import { shouldOpenNewTab } from 'utils/navigationHelpers'

import {
  OverviewCard,
  StyledCardTitleText,
  StyledSubtitleText,
  SubtitleContent,
  rootDimensions,
  rootFlexBasis,
} from '../OverviewCard'

import { StatusDetails } from './StatusDetails'
import { Timeline } from './Timeline'
import { ReviewsCardFragment } from './__generated__/ReviewsCard.generated'

export type CardReviews = ReviewsCardFragment['reviews']
export type CardReview = CardReviews[number]

const StyledOverviewCard = styled(OverviewCard)(({ theme }) => ({
  flex: '1 1 130px',
  minHeight: 'fit-content',
  height: 'initial',
  margin: 0,

  [`& ${StyledCardTitleText}`]: {
    ...theme.typography.sizes.s,
    fontWeight: theme.fontWeight.normal,
    color: theme.palette.styleguide.textGreyLight,
  },
}))

const SubtitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0.5),
  columnGap: theme.spacing(0.75),
}))

const StyledSubtitleContent = styled(SubtitleContent)(({ theme }) => ({
  marginBottom: 0,
  minWidth: 0,
  [`& ${StyledSubtitleText}`]: {
    ...theme.typography.noWrap,
  },
}))

const StyledTimeline = styled(Timeline)(({ theme }) => ({
  '&&': {
    flexShrink: 0,
    marginTop: 0,
    color: theme.palette.styleguide.textGreyDark,
  },
}))

const ReviewItemHeading = ({ review }: { review: CardReview }) => {
  const {
    reviewType: {
      canonicalReviewType: { name },
    },
  } = review
  return (
    <SubtitleContainer>
      <StyledSubtitleContent Icon={DescriptionRounded} subtitle={name} />
      <StyledTimeline review={review} />
    </SubtitleContainer>
  )
}

const ReviewItemContent = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'flex-end',
  width: '100%',
}))

interface ReviewItemInternalProps {
  handleReviewSelect: (e: MouseEvent) => void
  review: CardReview
}

export const ReviewItemInternal = ({ handleReviewSelect, review }: ReviewItemInternalProps) => {
  const { internalControlNumber, reviewType, token } = review
  const type = reviewType.canonicalReviewType.name
  return (
    <StyledOverviewCard
      testId={`review_${type}`}
      title={`#${internalControlNumber || token}`}
      subtitle={<ReviewItemHeading review={review} />}
      onClick={handleReviewSelect}
    >
      <ReviewItemContent>
        <StatusDetails review={review} />
      </ReviewItemContent>
    </StyledOverviewCard>
  )
}

interface ReviewItemProps {
  review: CardReview
}

const ReviewItem = ({ review }: ReviewItemProps) => {
  const dispatch = useDispatch()
  const usage = useUsage()

  const pinnedTab = useSelector(getCasePagePinnedTab)

  const { token } = review

  const handleReviewSelect = useCallback(
    (e: MouseEvent) => {
      const openInBackground = shouldOpenNewTab(e)
      usage.logEvent({ name: 'case:openReview:clicked', data: { reviewToken: token } })
      dispatch(
        openTab({
          tab: {
            type: 'review',
            highlight: pinnedTab?.type === 'review' && pinnedTab.reviewToken === token,
            reviewToken: token,
          },
          openInBackground,
        })
      )
    },
    [dispatch, pinnedTab, token, usage]
  )

  return <ReviewItemInternal handleReviewSelect={handleReviewSelect} review={review} />
}

const ReviewsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(2, 1),
  height: rootDimensions.height + theme.hbUnit(2.5),
  maxWidth: rootDimensions.maxWidth + theme.hbUnit(2.5),
  flex: rootDimensions.flex,
  rowGap: theme.spacing(),
  alignItems: 'stretch',
  flexFlow: 'column',
  minWidth: rootFlexBasis,
}))

const Footer = styled('footer')(({ theme }) => ({
  paddingTop: theme.spacing(),
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const ViewAllButton = styled(HbButton)(({ theme }) => ({
  '&&': {
    minWidth: 'initial',
    padding: 0,
    background: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
      borderColor: 'transparent',
    },
  },
}))

const MAX_REVIEW_PREVIEWS = 2

interface ReviewsCardInternalProps {
  handleAllReviewsClick: (e: MouseEvent) => void
  reviews?: CardReviews
}

export const ReviewsCardInternal = ({ handleAllReviewsClick, reviews }: ReviewsCardInternalProps) => {
  const reviewsCount = reviews?.length

  if (!reviews || !reviewsCount) {
    return (
      <OverviewCard Icon={DescriptionRounded} subtitle="Reviews" onClick={handleAllReviewsClick}>
        <HbNonIdealState Icon={DescriptionRounded} title="No reviews" message="Reviews will show here" />
      </OverviewCard>
    )
  }

  return (
    <ReviewsContainer>
      {reviews.slice(0, MAX_REVIEW_PREVIEWS).map((review) => {
        return <ReviewItem key={review.token} review={review} />
      })}
      <Footer>
        <HbText size="s" color="secondary">
          {reviewsCount} {pluralize('review', reviewsCount)}
        </HbText>
        <ViewAllButton label="View all" onClick={handleAllReviewsClick} variant="textPrimary" />
      </Footer>
    </ReviewsContainer>
  )
}

export interface Props {
  reviews?: CardReviews
}

export const ReviewsCard = ({ reviews }: Props) => {
  const dispatch = useDispatch()
  const usage = useUsage()

  const handleAllReviewsClick = (e: MouseEvent) => {
    const openInBackground = shouldOpenNewTab(e)
    usage.logEvent({ name: 'case:openReviews:clicked' })
    dispatch(openTab({ tab: { type: 'reviews' }, openInBackground }))
  }

  return <ReviewsCardInternal handleAllReviewsClick={handleAllReviewsClick} reviews={reviews} />
}

ReviewsCard.fragments = {
  entry: gql`
    fragment ReviewsCard on Investigation {
      reviews {
        ...ReviewsCardAssignmentDetails
        ... on Review {
          status
          actionDecisions {
            ... on ReviewActionDecision {
              choice {
                label
              }
            }
          }
          isCompleted
          dueAt
          internalControlNumber
          openAt
          reviewType {
            canonicalReviewType {
              name
            }
          }
          state
          token
        }
      }
    }
    ${AssignmentDetails.fragments.assignmentDetails}
  `,
}
