import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'
import { AutocompleteCloseReason, AutocompleteInputChangeReason } from '@mui/material/useAutocomplete'

import { upperCase } from 'lodash'

import { useDebounce } from 'use-debounce'

import Autocomplete from 'components/HbComponents/Form/Inputs/Autocomplete/Autocomplete'
import { theme } from 'components/themeRedesign'
import { useGetHeaderFilterValuesV2 } from 'dashboards/shared/hooks/useGetDashboardTableHeaderFilterValuesV2'
import { useSearchableField } from 'dashboards/shared/hooks/useIsSearchableField'
import { Option } from 'types/hb'

import { fieldHasUpperBound } from '../helpers'

interface SearchableFilterFieldProps {
  currentValue?: string
  styles?: string
  onChange(newValue: string): void
  fieldApiName: string
  aggsKey?: string
  multiple: boolean
}

// TODO: Generalize this. https://thecharm.atlassian.net/browse/PROD-10038
export const getIncludeDirectives = (searchableFields: Record<string, string>, fieldApiName: string) => {
  const includeDirectives = Object.entries(searchableFields).reduce<Record<string, boolean>>((acc, [key, value]) => {
    acc[value] = key === fieldApiName
    return acc
  }, {})

  return {
    includeTags: !!includeDirectives.tags,
    includeAlerts: !!includeDirectives.alerts,
  }
}

// '<' and '>' are reserved characters and cannot be escaped by search.
// https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html#_reserved_characters

// Prefer keeping the input visually in sync so it is obvious to the user it isn't included over stripping at the api level.
const FORBIDDEN_SEARCH_CHARACTERS = ['<', '>']

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  maxWidth: 500,
}))

export type MultiSelectSearchableFieldProps = Omit<SearchableFilterFieldProps, 'currentValue'> & {
  currentValue?: string | string[]
  onChange(newValue: string | string[]): void
  options: Option[]
  search: (query: string) => void
  loading: boolean
  mapCurrentValueToOption?: boolean // Probably shouldn't be possible to disable
}

export const MultiSelectSearchableField = ({
  styles,
  onChange,
  currentValue,
  fieldApiName,
  multiple,
  options,
  search,
  loading,
  mapCurrentValueToOption = true,
}: MultiSelectSearchableFieldProps) => {
  const searchableField = useSearchableField(fieldApiName)
  const shouldUseClientSideFiltering = fieldHasUpperBound(fieldApiName)

  const [_inputValue, setInputValue] = useState(
    multiple ? '' : (typeof currentValue === 'string' && currentValue) || ''
  )

  const queryInputValue = useMemo(
    () => (shouldUseClientSideFiltering ? '' : _inputValue.toLowerCase()),
    [_inputValue, shouldUseClientSideFiltering]
  )

  const [debouncedQueryInputValue] = useDebounce(queryInputValue, 250)

  // Do not do a new api call, let FE filter.
  const valueForSearch = shouldUseClientSideFiltering ? '' : debouncedQueryInputValue

  useEffect(() => {
    search(valueForSearch)
  }, [search, valueForSearch])

  const mapInputValueToOption = mapCurrentValueToOption || shouldUseClientSideFiltering
  const inputValue =
    _inputValue === currentValue && mapInputValueToOption
      ? (options.find((option) => option.value === currentValue)?.display ?? _inputValue)
      : _inputValue

  const newlySelectedOptionRef = useRef<Option | Option[] | null>(null)

  const handleChange = useCallback(
    (selected: Option) => {
      if (selected) {
        newlySelectedOptionRef.current = selected
        onChange(selected.value)
        setInputValue(selected.display ?? '')
      }
    },
    [onChange]
  )

  const handleInputChange = useCallback((updated: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'reset') {
      // do not clear the user-controlled input when the options data changes,
      // which may happen while switching between all/searched options:
      return
    }
    setInputValue(FORBIDDEN_SEARCH_CHARACTERS.reduce((val, forbiddenChar) => val.replace(forbiddenChar, ''), updated))
  }, [])

  const handleClose = useCallback(
    (_e: ChangeEvent, reason: AutocompleteCloseReason) => {
      if (reason !== 'selectOption') return
      if (newlySelectedOptionRef.current) {
        newlySelectedOptionRef.current = null
      } else {
        // if the existing selection is being re-selected,
        // we have to sync the input with the chosen option's display
        const existingOptionDisplay = options.find((o) => o.value === currentValue)?.display
        if (existingOptionDisplay) setInputValue(existingOptionDisplay)
      }
    },
    [currentValue, options]
  )

  const filterOptionsProps = shouldUseClientSideFiltering
    ? {}
    : { filterOptions: (filterOptions: Option[]) => filterOptions }

  const handleMultiChange = useCallback(
    (multipleSelected: Option[]) => {
      if (multipleSelected) {
        newlySelectedOptionRef.current = multipleSelected
        onChange(multipleSelected.map((selected) => selected.value))
        setInputValue('')
      }
    },
    [onChange]
  )

  const commonProps = {
    styles,
    options,
    noSpacing: true,
    hideLabel: true,
    loading,
    onClose: handleClose,
    label: upperCase(searchableField),
    setValueOnBlur: true,
    tagColor: theme.palette.background.medium,
    ...filterOptionsProps,
    value: currentValue,
    inputValue,
  }

  return multiple ? (
    <StyledAutocomplete
      {...commonProps}
      limitTags={2}
      multiple
      onChange={handleMultiChange}
      onInputChange={handleInputChange}
    />
  ) : (
    <StyledAutocomplete
      {...commonProps}
      multiple={false}
      value={currentValue}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  )
}

export const SearchableFilterField = ({
  aggsKey,
  ...props
}: Omit<SearchableFilterFieldProps, 'currentValue'> & {
  currentValue?: string | string[]
  onChange(newValue: string | string[]): void
}) => {
  const [valueForSearch, setValueForSearch] = useState('')
  const { getHeaderFilterValues, loading } = useGetHeaderFilterValuesV2(aggsKey, valueForSearch)
  const options: Option[] = useMemo(() => getHeaderFilterValues?.()?.() ?? [], [getHeaderFilterValues])

  return (
    <MultiSelectSearchableField
      {...props}
      mapCurrentValueToOption={false}
      options={options}
      search={setValueForSearch}
      loading={loading}
    />
  )
}
