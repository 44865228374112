// eslint-disable-next-line no-restricted-imports
import { FormHelperText, styled } from '@mui/material'

import { useSelector } from 'actions/store'

import HbRHFDropdown from 'components/HbComponents/Form/HbInputs/HbDropdown/HbRHFDropdown'
import { getOrganizationReviewTypes } from 'helpers/stateHelpers'

import { useIsAutomationRule } from '../AutomationRuleOrTemplateContext'
import { FormSchemaReturnType } from '../formSchema'

import { useReviewTypePrepopulatedFields } from './useReviewTypePrepopulatedFields'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

const PARAMS_PATH = 'actionParams.workflowActionReviewParams'

export default function CustomizeWorkflowActionReview({ form }: { form: FormSchemaReturnType }) {
  const { watch, control, setValue } = form
  const isRule = useIsAutomationRule()

  const reviewTypes = useSelector(getOrganizationReviewTypes)

  const canonicalId = watch(`${PARAMS_PATH}.canonicalReviewTypeId`)
  const selectedActionSlug = watch(`${PARAMS_PATH}.actionSlug`)

  const { actions } = useReviewTypePrepopulatedFields({ canonicalId: canonicalId || undefined })
  const selectedAction = actions?.find((action) => action.slug === selectedActionSlug)

  const reviewTypeOptions = reviewTypes.map((type) => ({
    display: type.name,
    value: type.canonicalId,
  }))

  const actionOptions = actions?.map((action) => ({
    display: action.title,
    value: action.slug,
  }))

  const choiceOptions =
    selectedAction?.choices
      ?.filter(({ accountType }) => !!accountType)
      ?.map((choice) => ({
        display: choice.label,
        value: choice.key,
      })) || []

  return (
    <div>
      <Container>
        <HbRHFDropdown
          name={`${PARAMS_PATH}.canonicalReviewTypeId`}
          control={control}
          options={reviewTypeOptions}
          onChange={() => setValue(`${PARAMS_PATH}.actionSlug`, '')}
          label="Review Type"
          disabled={!isRule}
        />
        <HbRHFDropdown
          name={`${PARAMS_PATH}.actionSlug`}
          control={control}
          options={actionOptions}
          disabled={!canonicalId}
          onChange={() => setValue(`${PARAMS_PATH}.choiceKey`, '')}
          label="Action"
        />
        <HbRHFDropdown
          name={`${PARAMS_PATH}.choiceKey`}
          control={control}
          options={choiceOptions}
          disabled={!selectedAction}
          label="Choice"
        />
      </Container>
      <FormHelperText>This automation will only run if the review matches the provided review type.</FormHelperText>
    </div>
  )
}
