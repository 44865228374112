import React, { useMemo } from 'react'

import { gql } from '@apollo/client'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { useDispatch } from 'actions/store'
import { HbNonIdealState } from 'components/HbComponents/HbNonIdealState'

import { HbText } from 'components/HbComponents/Text/HbText'
import { getInboundRequestTitle } from 'components/cases/Tabs/InformationRequestsApp/helpers/getInboundRequestTitle'
import { InformationRequestsCardFragment } from 'components/cases/Tabs/Overview/__generated__/InformationRequestsCard.generated'

import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { ForwardToInboxIcon } from 'icons/ForwardToInboxIcon'
import { openTab } from 'reducers/tabReducer'
import { shouldOpenNewTab } from 'utils/navigationHelpers'

import { OverviewCard, StyledOverviewCardContentContainer } from './OverviewCard'

const getTitle = (outbound: number, inbound: number) => {
  if (outbound && inbound) {
    return `${outbound} outbound • ${inbound} inbound`
  }
  if (outbound) {
    return `${outbound} outbound`
  }
  if (inbound) {
    return `${inbound} inbound`
  }
  return '0 requests'
}

interface InfoRequestDisplay {
  title: string
  token: string
  createdAt: string
}
export interface Props {
  informationRequests?: InformationRequestsCardFragment['informationRequests']
  inboundRequests?: InformationRequestsCardFragment['inboundRequests']
}

const StyledOverviewCard = styled(OverviewCard)(({ theme }) => ({
  [`& ${StyledOverviewCardContentContainer}`]: {
    marginTop: theme.spacing(4),
  },
}))

const StyledListItem = styled('li')(({ theme }) => ({
  background: theme.palette.background.contrastLight,
  padding: theme.spacing(1.75),
  borderRadius: theme.shape.largeContainer.borderRadius,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '& + &': {
    marginTop: theme.spacing(),
  },
}))

export function InformationRequestsCard({ informationRequests, inboundRequests }: Props) {
  const dispatch = useDispatch()
  const usage = useUsage()

  const onCardClick = (e: React.MouseEvent) => {
    const openInBackground = shouldOpenNewTab(e)
    usage.logEvent({ name: 'case:openInformationRequests:clicked' })
    dispatch(openTab({ tab: { type: 'informationRequests' }, openInBackground }))
  }

  const outboundCount = informationRequests?.totalCount || 0
  const inboundCount = inboundRequests?.length || 0
  const totalRequestsCount = outboundCount + inboundCount

  const preview = useMemo(() => {
    const outboundPreview =
      informationRequests?.edges?.reduce<InfoRequestDisplay[]>((acc, edge) => {
        if (edge?.node?.token) {
          acc.push({
            title: edge.node.title || `Request #${edge.node.token}`,
            token: edge.node.token,
            createdAt: edge.node.createdAt,
          })
        }
        return acc
      }, []) || []

    const inboundPreview =
      inboundRequests?.map((r) => ({
        title: getInboundRequestTitle(r),
        token: r.token,
        createdAt: r.informationRequest?.createdAt,
      })) || []

    return [...outboundPreview, ...inboundPreview]
      .sort((a, b) => {
        if (!a.createdAt) {
          return b.createdAt === undefined ? 0 : -1
        }
        if (!b.createdAt) {
          return a.createdAt === undefined ? 0 : 1
        }
        if (a.createdAt > b.createdAt) {
          return -1
        }
        if (a.createdAt < b.createdAt) {
          return 1
        }
        return 0
      })
      .slice(0, 4)
  }, [inboundRequests, informationRequests])

  if (!totalRequestsCount) {
    return (
      <OverviewCard Icon={ForwardToInboxIcon} subtitle="Requests" onClick={onCardClick}>
        <HbNonIdealState Icon={ForwardToInboxIcon} title="No requests" message="Created requests will show here" />
      </OverviewCard>
    )
  }

  const title = getTitle(outboundCount, inboundCount)

  return (
    <StyledOverviewCard Icon={ForwardToInboxIcon} title={title} subtitle="Requests" onClick={onCardClick}>
      <ul>
        {preview?.map((r) => {
          return (
            <StyledListItem key={r.token}>
              <HbText>{r.title}</HbText>
            </StyledListItem>
          )
        })}
      </ul>
    </StyledOverviewCard>
  )
}

InformationRequestsCard.fragments = {
  entry: gql`
    fragment InformationRequestsCard on Investigation {
      inboundRequests {
        token
        status
        informationRequest {
          createdAt
          title
        }
      }
      informationRequests(last: 4) {
        totalCount
        edges {
          node {
            token
            title
            createdAt
          }
        }
      }
    }
  `,
}
