import { gql } from '@apollo/client'

import { ATTACHMENT_FRAGMENT } from 'components/cases/Tabs/Attachment/CaseAttachmentTab.queries'
import { CASE_LOCK_FRAGMENT } from 'components/cases/hooks/useCaseLock'

export const GET_CASE_FILES_FRAGMENTS = gql`
  fragment CaseAttachments on Investigation {
    attachments(first: 10, after: $cursor) {
      edges {
        node {
          ...Attachment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  fragment CaseDocuments on Investigation {
    reviews {
      token
      createdAt
      updatedAt
      internalControlNumber
      reviewType {
        name
      }
      documents {
        ... on ReviewDocument {
          description
          type
          format
          path
          title
        }
      }
    }
  }

  fragment CaseReviewSurveyFileUploads on Investigation {
    reviews {
      token
      surveyResponseFileUploads {
        token
        title
        contentType
        clientId
        question {
          token
        }
        malwareScanStatus @include(if: $includeMalwareScanStatus)
      }
    }
  }

  fragment CaseInformationRequests on Investigation {
    informationRequests(first: 100, respondedOnly: true) {
      edges {
        node {
          token
          title
          informationRequestForm {
            title
          }
          informationRequestResponses {
            token
            surveyResponse {
              createdAt
            }
            informationRequestRecipient {
              email
            }
          }
        }
      }
    }
  }

  fragment GetCaseFilesFragment on Investigation {
    ...CaseAttachments
    ...CaseDocuments
    ...CaseReviewSurveyFileUploads
    ...CaseInformationRequests
  }

  ${ATTACHMENT_FRAGMENT}
`

export const GET_CASE_FILES = gql`
  query GetCaseFiles(
    $caseToken: String!
    $cursor: String
    $enableAttachmentSummarization: Boolean!
    $includeMalwareScanStatus: Boolean!
  ) {
    investigation(token: $caseToken) {
      token
      name
      createdAt
      updatedAt
      narrativeAutoCompleteDisabled
      downloadFilesDisabled
      ...GetCaseFilesFragment
      ...CaseLock
    }
  }

  ${CASE_LOCK_FRAGMENT}
  ${GET_CASE_FILES_FRAGMENTS}
`
