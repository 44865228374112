// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'

import { useFormattedDate } from 'hooks/DateFormatHooks'

import { ActivityLogItem } from './ActivityLogItem'

const VerticalLine = styled('div')(({ theme }) => ({
  width: '1px',
  height: '100%',
  backgroundColor: theme.palette.styleguide.borderLight,
  position: 'absolute',
  marginLeft: theme.spacing(2),
}))

const Root = styled('section')(() => ({
  position: 'relative',
  overflow: 'hidden',
  '&:last-of-type': {
    [`& ${VerticalLine}`]: {
      height: '40%',
    },
  },
}))

const Spacer = styled('div')(({ theme }) => ({
  height: theme.spacing(2),
}))

type Props = {
  date: string
  openTable: () => void
  runs: number
  type: 'ScheduleAutomationRule' | 'TriggerAutomationRule' | 'ScheduleWithTriggerAutomationRule' | 'BlankAutomationRule'
}

export const ActivityLogDay = ({ date, openTable, runs, type }: Props) => {
  const formattedDate = useFormattedDate(date, '', 'LL')
  return (
    <Root>
      <HbText tag="h3" bold>
        {formattedDate}
      </HbText>
      <VerticalLine />
      <Spacer />
      <ActivityLogItem runs={runs} date={date} openTable={openTable} type={type} />
    </Root>
  )
}
