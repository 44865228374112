import { ReactNode, forwardRef, useContext } from 'react'

// eslint-disable-next-line no-restricted-imports
import { Box, styled } from '@mui/material'

import { SectionTitle } from 'components/cases/report/summary/SectionTitle'
import { FormConfiguration, FormConfigurationContext } from 'components/material/Form/FormConfiguration'

import { EditableArray, EditableArrayProps } from '../InformationFields'

import { EntityInformationContext } from './EntityInformationContext'

const StyledSectionContainer = styled('section')<{ borderless?: boolean }>(({ borderless, theme }) => ({
  display: 'flex',
  breakInside: 'avoid',
  flexDirection: 'column',
  maxWidth: 'inherit',
  background: theme.palette.background.light,
  borderRadius: borderless ? '' : theme.shape.largeContainer.borderRadius,
  marginBottom: theme.spacing(2),
  paddingTop: borderless ? '' : theme.spacing(3),
  border: borderless ? '' : `1px solid ${theme.palette.dividers.light}`,
}))

export const TitleRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}))

export const StyledSectionTitle = styled(SectionTitle)(({ theme }) => ({
  flex: 1,
  paddingRight: theme.spacing(),
}))

export const SectionContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}))

export interface SectionProps {
  testId?: string
  title: string | null
  titleSize?: 'normal' | 'large'
  titleRow?: ReactNode
  children: ReactNode
  borderless?: boolean
  adornment?: ReactNode
  className?: string
}

export const Section = forwardRef<HTMLElement, SectionProps>((props, ref) => {
  const { testId, title, titleSize = 'normal', titleRow, children, borderless = false, adornment, className } = props

  return (
    <StyledSectionContainer borderless={borderless} className={className} data-testid={testId} ref={ref}>
      {title && (
        <TitleRow>
          <StyledSectionTitle title={title} size={titleSize} />
          {adornment}
          {titleRow}
        </TitleRow>
      )}
      <SectionContent>{children}</SectionContent>
    </StyledSectionContainer>
  )
})

export function EditableSection<V extends Record<string, unknown>>(props: EditableArrayProps<V> & SectionProps) {
  const { title, children, name, ...rest } = props
  const { readOnly } = useContext(FormConfigurationContext)
  const { values, previousData } = useContext(EntityInformationContext)

  const noCurrentData = !values[name]

  const noPreviousData = !previousData?.[name]

  const noData = noCurrentData && noPreviousData

  if (readOnly && noData) {
    return null
  }

  return (
    <Section title={title}>
      <Box px={2} pb={2}>
        <EditableArray name={name} {...rest}>
          {children}
        </EditableArray>
      </Box>
    </Section>
  )
}

interface InformationLayoutProps {
  readOnly?: boolean
  children: ReactNode
}

export function InformationLayout({ readOnly, children }: InformationLayoutProps) {
  const { layout } = useContext(EntityInformationContext)

  return (
    <FormConfiguration contextName="entityInformation" readOnly={readOnly} adornmentRedesign>
      <div style={{ columnCount: layout === 'twoColumn' ? 2 : 1 }}>{children}</div>
    </FormConfiguration>
  )
}
