import { camelCase, invert } from 'lodash'

import { SnakeDataTypeKey, SnakeLibraryDataTypeKey } from 'actions/importingFields.types'
import { IconName } from 'icons/types'
import {
  AutomationDomainType,
  BankAccountAttributes,
  BusinessAttributes,
  CryptoAddressAttributes,
  DeviceAttributes,
  FinancialInstitutionAttributes,
  InvestigationSearchResult,
  LibraryEntry,
  LibraryTypeEnum,
  PaymentCardAttributes,
  PersonAttributes,
  ProductAttributes,
} from 'types/api'

// Entities that stored as children of LibraryGqlQueryType entities
type NestedLibraryTypes =
  | LibraryTypeEnum.Attachments
  | LibraryTypeEnum.BankAccountBalances
  | LibraryTypeEnum.BusinessOwners
  | LibraryTypeEnum.InstitutionRelationships
  | LibraryTypeEnum.Relationships
  | LibraryTypeEnum.RelationshipDefinitions
  | LibraryTypeEnum.Tags
  | LibraryTypeEnum.Signatories
  | LibraryTypeEnum.TagDefinitions
  | LibraryTypeEnum.ScreeningSearchResults

// Entities that have dedicated library pages and show up at the case-level
export type LibraryGqlQueryType = Exclude<LibraryTypeEnum, NestedLibraryTypes>

type ValidLibraryEntryType<T extends LibraryEntry['__typename']> = T

type PrimaryLibrarySubjectTypeName = ValidLibraryEntryType<
  'LibraryPerson' | 'LibraryBusiness' | 'LibraryFinancialInstitution' | 'LibraryProduct'
>

type SecondaryLibrarySubjectTypeName = ValidLibraryEntryType<
  'LibraryBankAccount' | 'LibraryCryptoAddress' | 'LibraryDevice' | 'LibraryPaymentCard'
>

export type LibrarySubjectTypeName = PrimaryLibrarySubjectTypeName | SecondaryLibrarySubjectTypeName

export interface LibraryRelationshipTarget {
  type: LibraryGqlQueryType
  key: {
    token?: string
    externalId?: string
  }
}

export type PrimaryLibraryEntryAttributes =
  | BusinessAttributes
  | FinancialInstitutionAttributes
  | PersonAttributes
  | ProductAttributes

export type AssociationLibraryEntryAttributes =
  | BankAccountAttributes
  | CryptoAddressAttributes
  | DeviceAttributes
  | PaymentCardAttributes

export type LibraryEntryAttributes = PrimaryLibraryEntryAttributes | AssociationLibraryEntryAttributes

export type LibraryEntryAttributesByType<DataType extends SnakeLibraryDataTypeKey> = DataType extends 'business'
  ? BusinessAttributes
  : DataType extends 'crypto_address'
    ? CryptoAddressAttributes
    : DataType extends 'device'
      ? DeviceAttributes
      : DataType extends 'individual'
        ? PersonAttributes
        : DataType extends 'payment_card'
          ? PaymentCardAttributes
          : DataType extends 'product'
            ? ProductAttributes
            : never

const LIBRARY_TYPE_ENUM_TO_DATA_TYPE: Record<LibraryGqlQueryType, SnakeDataTypeKey> = {
  [LibraryTypeEnum.FinancialInstitutions]: 'institution',
  [LibraryTypeEnum.People]: 'individual',
  [LibraryTypeEnum.Businesses]: 'business',
  [LibraryTypeEnum.Devices]: 'device',
  [LibraryTypeEnum.BankAccounts]: 'bank_account',
  [LibraryTypeEnum.PaymentCards]: 'payment_card',
  [LibraryTypeEnum.CryptoAddresses]: 'crypto_address',
  [LibraryTypeEnum.Products]: 'product',
}

const DATA_TYPE_TO_LIBRARY_TYPE_ENUM = invert(LIBRARY_TYPE_ENUM_TO_DATA_TYPE)

const libraryGQLTypes: Set<string> = new Set(Object.keys(LIBRARY_TYPE_ENUM_TO_DATA_TYPE))
export const isLibraryGqlQueryType = (string?: string | null): string is LibraryGqlQueryType =>
  !!string && libraryGQLTypes.has(string)

export function dataTypeToGqlType(type: SnakeDataTypeKey): LibraryGqlQueryType {
  const gqlType = DATA_TYPE_TO_LIBRARY_TYPE_ENUM[type]
  if (!isLibraryGqlQueryType(gqlType)) {
    throw new Error(`Unsupported data type: ${type}`)
  }
  return gqlType
}

export function libraryObjectTypeToDataType(type: LibraryGqlQueryType): SnakeDataTypeKey {
  const dataType = LIBRARY_TYPE_ENUM_TO_DATA_TYPE[type]
  if (!dataType) {
    throw new Error(`Unsupported data type: ${type}`)
  }
  return dataType
}

export function gqlTypeToGqlKey(
  type: LibraryGqlQueryType
): 'people' | 'devices' | 'financialInstitutions' | 'bankAccounts' | 'businesses' {
  return camelCase(type) as any
}

export function dataTypeToGqlKey(type: SnakeDataTypeKey) {
  return gqlTypeToGqlKey(dataTypeToGqlType(type))
}

export function typeNameToGqlType(type: LibraryEntry['__typename']): LibraryGqlQueryType {
  switch (type) {
    case 'LibraryPerson':
      return LibraryTypeEnum.People
    case 'LibraryBusiness':
      return LibraryTypeEnum.Businesses
    case 'LibraryDevice':
      return LibraryTypeEnum.Devices
    case 'LibraryBankAccount':
      return LibraryTypeEnum.BankAccounts
    case 'LibraryPaymentCard':
      return LibraryTypeEnum.PaymentCards
    case 'LibraryFinancialInstitution':
      return LibraryTypeEnum.FinancialInstitutions
    case 'LibraryCryptoAddress':
      return LibraryTypeEnum.CryptoAddresses
    case 'LibraryProduct':
      return LibraryTypeEnum.Products
    default:
      throw new Error(`Unsupported data type: ${type}`)
  }
}

export function searchTypeNameToGqlType(type: InvestigationSearchResult['__typename']): LibraryGqlQueryType {
  switch (type) {
    case 'InvestigationLibraryBusinessSearchResult':
      return LibraryTypeEnum.Businesses
    case 'InvestigationLibraryFinancialInstitutionSearchResult':
      return LibraryTypeEnum.FinancialInstitutions
    case 'InvestigationLibraryPersonSearchResult':
      return LibraryTypeEnum.People
    case 'InvestigationLibraryProductSearchResult':
      return LibraryTypeEnum.Products
    case 'InvestigationLibraryDeviceSearchResult':
      return LibraryTypeEnum.Devices
    case 'InvestigationLibraryBankAccountSearchResult':
      return LibraryTypeEnum.BankAccounts
    case 'InvestigationLibraryCryptoAddressSearchResult':
      return LibraryTypeEnum.CryptoAddresses
    case 'InvestigationLibraryPaymentCardSearchResult':
      return LibraryTypeEnum.PaymentCards
    default:
      throw new Error(`Unsupported data type: ${type}`)
  }
}

export function dataTypeToLibraryEntryType(type: SnakeDataTypeKey): LibraryEntry['__typename'] {
  switch (type) {
    case 'individual':
      return 'LibraryPerson'
    case 'business':
      return 'LibraryBusiness'
    case 'device':
      return 'LibraryDevice'
    case 'bank_account':
      return 'LibraryBankAccount'
    case 'payment_card':
      return 'LibraryPaymentCard'
    case 'institution':
      return 'LibraryFinancialInstitution'
    case 'crypto_address':
      return 'LibraryCryptoAddress'
    case 'product':
      return 'LibraryProduct'
    default:
      throw new Error(`Unsupported data type: ${type}`)
  }
}

export function dataTypeOfEntry(entry: Pick<LibraryEntry, '__typename'>): SnakeDataTypeKey {
  switch (entry.__typename) {
    case 'LibraryPerson':
      return 'individual'
    case 'LibraryBusiness':
      return 'business'
    case 'LibraryFinancialInstitution':
      return 'institution'
    case 'LibraryDevice':
      return 'device'
    case 'LibraryBankAccount':
      return 'bank_account'
    case 'LibraryPaymentCard':
      return 'payment_card'
    case 'LibraryCryptoAddress':
      return 'crypto_address'
    case 'LibraryProduct':
      return 'product'
    default:
      throw new Error(`Unsupported data type: ${entry.__typename}`)
  }
}

export function displayTypeOfEntry(entry: Pick<LibraryEntry, '__typename'>): string {
  switch (entry.__typename) {
    case 'LibraryPerson':
      return 'Person'
    case 'LibraryBusiness':
      return 'Business'
    case 'LibraryFinancialInstitution':
      return 'Financial Institution'
    case 'LibraryDevice':
      return 'Device'
    case 'LibraryBankAccount':
      return 'Bank Account'
    case 'LibraryPaymentCard':
      return 'Payment Card'
    case 'LibraryCryptoAddress':
      return 'Crypto Address'
    case 'LibraryProduct':
      return 'Product'
    default:
      throw new Error(`Unsupported data type: ${entry.__typename}`)
  }
}

interface LibraryDataType {
  type: string
  icon: IconName
  name: string
  key: string
  category: string
}

export type LibraryDataTypeKey =
  | 'person'
  | 'business'
  | 'institution'
  | 'transaction'
  | 'bankAccount'
  | 'paymentCard'
  | 'device'
  | 'cryptoAddress'
  | 'product'

type LibraryDataTypes = { [key in LibraryDataTypeKey]: LibraryDataType }

const LIBRARY_DATA_TYPES: LibraryDataTypes = {
  person: {
    type: 'person',
    icon: 'person',
    name: 'person',
    key: 'entities',
    category: 'people',
  },
  business: {
    type: 'business',
    icon: 'business',
    name: 'business',
    key: 'entities',
    category: 'businesses',
  },
  institution: {
    type: 'financial_institution',
    icon: 'financial_institution',
    name: 'financial institution',
    key: 'institutions',
    category: 'financial_institutions',
  },
  transaction: {
    type: 'transactions',
    icon: 'transactions',
    name: 'transactions',
    key: 'transactionImports',
    category: 'transactions',
  },
  bankAccount: {
    type: 'bank_account',
    icon: 'bank_account',
    name: 'bank account',
    key: 'bankAccounts',
    category: 'bank_accounts',
  },
  paymentCard: {
    type: 'payment_card',
    icon: 'payment_card',
    name: 'payment card',
    key: 'paymentCards',
    category: 'payment_cards',
  },
  device: {
    type: 'device',
    icon: 'device',
    name: 'device',
    key: 'devices',
    category: 'devices',
  },
  cryptoAddress: {
    type: 'crypto_address',
    icon: 'crypto_address',
    name: 'crypto address',
    key: 'cryptoAddresses',
    category: 'crypto_addresses',
  },
  product: {
    type: 'product',
    icon: 'product',
    name: 'product',
    key: 'products',
    category: 'products',
  },
}

export const findLibraryInfoByKey = function (key: LibraryDataTypeKey) {
  return LIBRARY_DATA_TYPES[key]
}

export function libraryDataTypeKeyFromSnakeDataTypeKey(key: SnakeDataTypeKey): LibraryDataTypeKey {
  return (key === 'individual' ? 'person' : camelCase(key)) as LibraryDataTypeKey
}

export const getEntityLabel = (dataType: SnakeDataTypeKey) =>
  findLibraryInfoByKey(libraryDataTypeKeyFromSnakeDataTypeKey(dataType))?.name ?? 'Entity'

export const getEntityCreateTabTitle = (dataType: SnakeLibraryDataTypeKey) => `New ${getEntityLabel(dataType)}`

export function automationDomainTypeForLibraryType(type: LibrarySubjectTypeName): AutomationDomainType | null {
  switch (type) {
    case 'LibraryPerson':
      return AutomationDomainType.Person
    case 'LibraryBusiness':
      return AutomationDomainType.Business
    default:
      return null
  }
}
