import { AnyAction } from 'redux'

import { DISPLAY_TYPES, SnakeDataTypeKey } from 'actions/importingFields.types'
import {
  SET_ACTIVE_IMPORT_FORM,
  SET_ACTIVE_ENTITY_TYPE,
  SET_LOADING,
  SET_ACTIVE,
  SET_SHOW_DISPLAY,
  INCR_PENDING_OP,
  DECR_PENDING_OP,
  SET_UPPY_MODAL_OPEN,
} from 'actions/viewActions'

export interface ViewState {
  activeForm: SnakeDataTypeKey | null
  activeEntityType: SnakeDataTypeKey | null
  loading: { [key: string]: boolean }
  active: Record<string, boolean>
  pendingOps: { [key: string]: number }
  showDisplayByType: { [key: string]: string }
  uppyModalOpen: boolean
}

const getInitialState = (): ViewState => ({
  activeForm: null,
  activeEntityType: null,
  loading: {},
  active: {},
  pendingOps: {},
  showDisplayByType: {},
  uppyModalOpen: false,
})

const viewReducer = (state = getInitialState(), action: AnyAction): ViewState => {
  switch (action.type) {
    case SET_ACTIVE_IMPORT_FORM: {
      return { ...state, activeForm: action.activeForm }
    }
    case SET_ACTIVE_ENTITY_TYPE: {
      const updates = {
        activeEntityType: action.entityType,
      }
      return { ...state, ...updates }
    }
    case SET_LOADING: {
      const newLoading = { ...state.loading }
      newLoading[action.token || 'global'] = action.loading
      return { ...state, loading: newLoading }
    }
    case SET_ACTIVE: {
      const newActive = { ...state.active }
      newActive[action.token] = action.active
      return { ...state, active: newActive }
    }
    case SET_SHOW_DISPLAY: {
      if (!Object.keys(DISPLAY_TYPES).includes(action.showDisplay)) {
        return state
      }

      const newShowDisplayByType = {
        ...state.showDisplayByType,
        [action.dataType]: action.showDisplay,
        category: action.category,
      }

      return { ...state, showDisplayByType: newShowDisplayByType }
    }
    case SET_UPPY_MODAL_OPEN: {
      return { ...state, uppyModalOpen: action.uppyModalOpen }
    }
    case INCR_PENDING_OP: {
      const { key } = action
      return {
        ...state,
        pendingOps: {
          ...state.pendingOps,
          [key]: (state.pendingOps[key] ?? 0) + 1,
        },
      }
    }
    case DECR_PENDING_OP: {
      const { key } = action
      return {
        ...state,
        pendingOps: {
          ...state.pendingOps,
          [key]: Math.max((state.pendingOps[key] ?? 0) - 1, 0),
        },
      }
    }
    default: {
      return state
    }
  }
}

export default viewReducer
