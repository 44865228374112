import { useMemo, useRef, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { HbPopper } from 'components/HbComponents/HbPopper'

import { HbText } from 'components/HbComponents/Text/HbText'

import { Theme } from 'types/hb'

import { ApprovalAssigneeAvatar } from './ApprovalAssigneeAvatar'
import { getAssignmentsOverflow } from './helpers'
import { ASSIGNMENT_AVATAR_SIZE } from './shared'
import { ApprovalAssigneeDetails, ApprovalAssignmentDetails, ApprovalAssignmentWithAssignee } from './types'

export const OverflowCount = styled('div')(({ theme }) => ({
  // prevents the overflow count from getting squished
  // if a flex container is shrinking:
  flexShrink: 0,
  width: ASSIGNMENT_AVATAR_SIZE,
  height: ASSIGNMENT_AVATAR_SIZE,
  ...theme.typography.sizes.s,
  color: theme.palette.styleguide.textGreyDark,
  backgroundColor: theme.palette.styleguide.backgroundDark,
  borderRadius: theme.shape.roundedRadius,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'default',
  '&:hover': {
    backgroundColor: theme.palette.styleguide.backgroundMedium,
  },
}))

const StyledList = styled('ul')(({ theme }) => ({
  padding: theme.spacing(1.25),
}))

const StyledListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  columnGap: theme.spacing(),
  marginBottom: theme.spacing(),
  '&:last-of-type': {
    marginBottom: 0,
  },
}))

interface OverflowAssigneesProps {
  assignments: ApprovalAssignmentWithAssignee[]
}

export const OverflowAssignees = ({ assignments }: OverflowAssigneesProps) => {
  const overflowCount = assignments.length
  const overflowCountRef = useRef<HTMLDivElement>(null)

  const [overflowPopperOpen, setOverflowPopperOpen] = useState(false)

  const closePopper = () => setOverflowPopperOpen(false)
  const openPopper = () => setOverflowPopperOpen(true)

  return (
    <>
      <OverflowCount ref={overflowCountRef} onMouseOver={openPopper} onMouseLeave={closePopper}>
        +{overflowCount}
      </OverflowCount>
      <HbPopper
        anchorEl={overflowCountRef.current}
        isOpen={overflowPopperOpen}
        modifiers={[{ name: 'offset', options: { offset: [0, 0] } }]}
        onClose={closePopper}
        placement="bottom-start"
      >
        <StyledList>
          {assignments.map((assignment) => (
            <StyledListItem key={assignment.token}>
              <ApprovalAssigneeAvatar assignee={assignment.assignee} decision={assignment.decision} />
              <HbText size="s">{assignment.assignee.fullName}</HbText>
            </StyledListItem>
          ))}
        </StyledList>
      </HbPopper>
    </>
  )
}

interface ListWithOverflowProps {
  assignments: ApprovalAssignmentWithAssignee[]
  limit: number
  showPendingReviewDecisions?: boolean
}

const AssigneesListWithOverflow = ({
  assignments,
  limit,
  showPendingReviewDecisions = false,
}: ListWithOverflowProps) => {
  const { hasOverflow, overflowAssignments, visibleAssignments } = getAssignmentsOverflow({ assignments, limit })

  return (
    <>
      {visibleAssignments.map((assignment) => (
        <ApprovalAssigneeAvatar
          key={assignment.token}
          assignee={assignment.assignee}
          decision={assignment.decision}
          showDecision={showPendingReviewDecisions}
        />
      ))}
      {hasOverflow && <OverflowAssignees assignments={overflowAssignments} />}
    </>
  )
}

export const RESPONSIVE_APPROVAL_ASSIGNEES_CONTAINER_NAME = 'responsive-approval-assignees-container'

const ResponsiveContainer = styled('div')(() => ({
  maxWidth: '100%',
  containerType: 'inline-size',
  containerName: RESPONSIVE_APPROVAL_ASSIGNEES_CONTAINER_NAME,
}))

const AssigneesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  columnGap: theme.spacing(0.5),
  alignItems: 'center',
}))

const NonTruncatedContainer = styled(AssigneesContainer)(() => ({}))
const TruncatedContainer = styled(AssigneesContainer)(() => ({}))

const NON_TRUNCATED_LIMIT = 3
const TRUNCATED_LIMIT = 1

export const getTruncatedBreakpointWidth = (theme: Theme) => (ASSIGNMENT_AVATAR_SIZE + theme.hbUnit(0.5)) * 4

const ApprovalAssigneesContainer = styled('div')(({ theme }) => {
  const truncatedBreakpointWidth = getTruncatedBreakpointWidth(theme)
  return {
    [`@container ${RESPONSIVE_APPROVAL_ASSIGNEES_CONTAINER_NAME} (width <= ${truncatedBreakpointWidth}px)`]: {
      [`& ${NonTruncatedContainer}`]: {
        display: 'none',
      },
    },
    [`@container ${RESPONSIVE_APPROVAL_ASSIGNEES_CONTAINER_NAME} (width > ${truncatedBreakpointWidth}px)`]: {
      [`& ${TruncatedContainer}`]: {
        display: 'none',
      },
    },
  }
})

interface ApprovalAssigneesProps {
  approvalAssignments: (ApprovalAssignmentDetails & { assignee: ApprovalAssigneeDetails })[]
  showPendingReviewDecisions?: boolean
}

export const ApprovalAssignees = ({
  approvalAssignments: unorderedApprovalAssignments,
  showPendingReviewDecisions = false,
}: ApprovalAssigneesProps) => {
  const decisionSortedApprovalAssignments = useMemo(
    () => [...unorderedApprovalAssignments].sort((a) => (a.decision ? -1 : 1)),
    [unorderedApprovalAssignments]
  )

  const nonTruncated = (
    <NonTruncatedContainer>
      <AssigneesListWithOverflow
        assignments={decisionSortedApprovalAssignments}
        showPendingReviewDecisions={showPendingReviewDecisions}
        limit={NON_TRUNCATED_LIMIT}
      />
    </NonTruncatedContainer>
  )

  const truncated = (
    <TruncatedContainer>
      <AssigneesListWithOverflow
        assignments={decisionSortedApprovalAssignments}
        showPendingReviewDecisions={showPendingReviewDecisions}
        limit={TRUNCATED_LIMIT}
      />
    </TruncatedContainer>
  )

  // if too many to show, use the space for one avatar to render the overflow indicator
  return (
    <ResponsiveContainer>
      <ApprovalAssigneesContainer>
        {nonTruncated}
        {truncated}
      </ApprovalAssigneesContainer>
    </ResponsiveContainer>
  )
}
