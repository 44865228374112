import { camelCase, snakeCase } from 'lodash'

import pluralize from 'pluralize'
import { SnakeCase } from 'type-fest'

import { IconName } from 'icons/types'

import { AsyncThunk } from './store'

export interface DataType {
  type: string
  icon: IconName
  name: string
  key: string | null
  category: string
  allowTextImport: boolean
  deleteFn?: (caseToken: string, itemToken: string) => AsyncThunk<void>
}

export type DataTypes = { [key in DataTypeKey]: DataType }

export type AssociationDataTypeKey = 'paymentCard' | 'bankAccount' | 'device' | 'cryptoAddress'

export type SnakeAssociationDataTypeKey = SnakeCase<AssociationDataTypeKey>
type PluralizeSnakeAssociationDataTypeKey<K extends SnakeAssociationDataTypeKey> = K extends 'crypto_address'
  ? `${K}es`
  : `${K}s`
export type LibraryAssociationDataTypeKey = PluralizeSnakeAssociationDataTypeKey<SnakeAssociationDataTypeKey>

export type PrimaryDataTypeKey = 'individual' | 'business' | 'institution' | 'product'

export type LibraryDataTypeKey = AssociationDataTypeKey | PrimaryDataTypeKey

export type SnakeLibraryDataTypeKey = SnakeCase<LibraryDataTypeKey>

export type DataTypeKey = LibraryDataTypeKey | 'transactionImports' | 'attachment' | 'importTemplate'

export type SnakeDataTypeKey =
  | SnakeCase<DataTypeKey>
  | 'import_template'
  | 'case_import'
  | 'transactions'
  | 'institution_relationship'

export function toDataTypeKey(key: SnakeDataTypeKey | DataTypeKey): DataTypeKey {
  return camelCase(key) as DataTypeKey
}

export const DISPLAY_TYPES = {
  EMPTY: 'EMPTY',
  RAW: 'RAW',
  IMPORT: 'IMPORT',
  CREATE_LIBRARY: 'CREATE_LIBRARY',
}

export function toLibraryAssociationDataTypeKey(key: string): LibraryAssociationDataTypeKey {
  return snakeCase(pluralize(key)) as LibraryAssociationDataTypeKey
}

const snakeLibraryDataTypes: SnakeLibraryDataTypeKey[] = [
  'bank_account',
  'business',
  'crypto_address',
  'device',
  'individual',
  'institution',
  'payment_card',
  'product',
]
const snakeLibraryDataTypesMap = snakeLibraryDataTypes.reduce(
  (acc, next) => {
    acc[next] = true
    return acc
  },
  {} as Record<SnakeLibraryDataTypeKey, true>
)
export const isSnakeLibraryDataTypeKey = (type: SnakeDataTypeKey | null | undefined): type is SnakeLibraryDataTypeKey =>
  !!type && type in snakeLibraryDataTypesMap

const entityTypesWithoutCaseData: (SnakeAssociationDataTypeKey | Extract<SnakeLibraryDataTypeKey, 'product'>)[] = [
  'product',
  'bank_account',
  'payment_card',
  'crypto_address',
  'device',
]
export type EntityTypeWithoutCaseData = (typeof entityTypesWithoutCaseData)[number]
export const entityTypesWithoutCaseDataMap = entityTypesWithoutCaseData.reduce(
  (acc, next) => {
    acc[next] = true
    return acc
  },
  {} as Record<EntityTypeWithoutCaseData, true>
)
export const isEntityTypeWithoutCaseData = (type: SnakeDataTypeKey | null): type is EntityTypeWithoutCaseData =>
  !!type && type in entityTypesWithoutCaseDataMap
