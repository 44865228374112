import { ComponentType } from 'react'

import {
  ArrowDropDownCircleOutlined,
  CalendarTodayOutlined,
  LensOutlined,
  MonetizationOnOutlined,
  NumbersOutlined,
  TextFieldsOutlined,
} from '@mui/icons-material'

import { Option } from 'components/material/Form'
import { CustomFieldDatatypeEnum, OtherInfoLabelDisplayAsEnum, OtherInfoLabelTypeEnum } from 'types/api'

export const labelTypeOptions: Option[] = [
  { display: 'Bank Account', value: OtherInfoLabelTypeEnum.BankAccount },
  { display: 'Business', value: OtherInfoLabelTypeEnum.Business },
  { display: 'Crypto Address', value: OtherInfoLabelTypeEnum.CryptoAddress },
  { display: 'Device', value: OtherInfoLabelTypeEnum.Device },
  { display: 'Institution', value: OtherInfoLabelTypeEnum.FinancialInstitution },
  { display: 'Payment Card', value: OtherInfoLabelTypeEnum.PaymentCard },
  { display: 'Person', value: OtherInfoLabelTypeEnum.Person },
  { display: 'Product', value: OtherInfoLabelTypeEnum.Product },
  { display: 'Review', value: OtherInfoLabelTypeEnum.Review },
  { display: 'Transaction', value: OtherInfoLabelTypeEnum.Transaction },
]

export const deserializeLabelType = (type: string | undefined): OtherInfoLabelTypeEnum | null => {
  switch (type) {
    case 'bank_account':
      return OtherInfoLabelTypeEnum.BankAccount
    case 'business':
      return OtherInfoLabelTypeEnum.Business
    case 'crypto_address':
      return OtherInfoLabelTypeEnum.CryptoAddress
    case 'device':
      return OtherInfoLabelTypeEnum.Device
    case 'financial_institution':
      return OtherInfoLabelTypeEnum.FinancialInstitution
    case 'payment_card':
      return OtherInfoLabelTypeEnum.PaymentCard
    case 'person':
      return OtherInfoLabelTypeEnum.Person
    case 'product':
      return OtherInfoLabelTypeEnum.Product
    case 'review':
      return OtherInfoLabelTypeEnum.Review
    case 'transaction':
      return OtherInfoLabelTypeEnum.Transaction
    case '':
      return null
    default:
      throw new Error(`Unknown field type '${type}'`)
  }
}

export const supportedOnDashboards = (type: OtherInfoLabelTypeEnum): boolean => {
  switch (type) {
    case OtherInfoLabelTypeEnum.Transaction:
    case OtherInfoLabelTypeEnum.Review:
      return true
    default:
      return false
  }
}

export const additionalDetailsDisplayAs = (): Option => ({
  display: 'Additional Details',
  value: OtherInfoLabelDisplayAsEnum.OtherInfo,
})
export const defaultFieldsDisplayAs = (): Option => ({
  display: 'Default Fields',
  value: OtherInfoLabelDisplayAsEnum.CustomInfo,
})

export const displayAsOptionsByLabelType = (type: OtherInfoLabelTypeEnum): Option[] => {
  const additionalDetailsDisplayAsValue = additionalDetailsDisplayAs()
  const defaultFieldsDisplayAsValue = defaultFieldsDisplayAs()
  const allDisplayAsValues = [defaultFieldsDisplayAsValue, additionalDetailsDisplayAsValue]

  switch (type) {
    case OtherInfoLabelTypeEnum.Review:
      return [additionalDetailsDisplayAsValue]
    case OtherInfoLabelTypeEnum.Transaction:
      return allDisplayAsValues
    default:
      return allDisplayAsValues
  }
}

export const currentDisplayOptionIsSupported = (
  type: OtherInfoLabelTypeEnum,
  displayAs: OtherInfoLabelDisplayAsEnum
): boolean => {
  const currentOptions = displayAsOptionsByLabelType(type).map((option) => option.value)
  return currentOptions.includes(displayAs)
}

export const datatypeOptions: Option[] = [
  { display: 'Text', value: CustomFieldDatatypeEnum.Text },
  { display: 'Dropdown', value: CustomFieldDatatypeEnum.Dropdown },
  { display: 'Yes/No', value: CustomFieldDatatypeEnum.Yesno },
  { display: 'Date', value: CustomFieldDatatypeEnum.Date },
  { display: 'Number', value: CustomFieldDatatypeEnum.Number },
  { display: 'Currency', value: CustomFieldDatatypeEnum.Currency },
]

const dataTypeIconsConfig: Record<CustomFieldDatatypeEnum, ComponentType> = {
  [CustomFieldDatatypeEnum.Currency]: MonetizationOnOutlined,
  [CustomFieldDatatypeEnum.Date]: CalendarTodayOutlined,
  [CustomFieldDatatypeEnum.Dropdown]: ArrowDropDownCircleOutlined,
  [CustomFieldDatatypeEnum.Number]: NumbersOutlined,
  [CustomFieldDatatypeEnum.Text]: TextFieldsOutlined,
  [CustomFieldDatatypeEnum.Yesno]: LensOutlined,
}

export const getDataTypeIcon = (dataType: CustomFieldDatatypeEnum | null | undefined) =>
  dataTypeIconsConfig[dataType || CustomFieldDatatypeEnum.Text]
