import { MutableRefObject, useCallback } from 'react'

// eslint-disable-next-line no-restricted-imports
import { ClassNameMap } from '@mui/styles'

import classNames from 'classnames'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { HeaderCellRenderProp, TableColumnElement } from './TableColumn'
import { MINIMUM_BATCH_COLUMN_WIDTH } from './TableRow.styles'
import {
  getField,
  getIsColumnDragDisabled,
  getIsColumnResizeDisabled,
  getIsSecondColTooLongToBeSticky,
  OnColumnResizeStop,
  RenderMeasurementColumn,
  TableColWidthsState,
  TableWithReorderableColumnsClassKey,
} from './helpers'

interface Props {
  allColsWidth: number
  availableWidth?: number
  batchColumnEnabled: boolean
  classes: ClassNameMap<TableWithReorderableColumnsClassKey>
  columns: TableColumnElement[]
  columnResizingEnabled: boolean
  draggableHeaderRef: MutableRefObject<HTMLDivElement | null>
  getHeaderRenderProp: (column: TableColumnElement) => HeaderCellRenderProp
  onColumnChange?: (arg: any) => void
  onColumnResizeStop: OnColumnResizeStop
  renderMeasurementColumn: RenderMeasurementColumn
  reorderableColumns?: boolean
  rightStickyColumn?: TableColumnElement
  tableColWidths: TableColWidthsState
  tableHeight: number
  uniqueKey: string
}

export const DraggableTableHeader = ({
  allColsWidth,
  availableWidth,
  batchColumnEnabled,
  classes,
  columns,
  columnResizingEnabled: columnResizingEnabledForTable = false,
  draggableHeaderRef,
  getHeaderRenderProp,
  onColumnChange,
  onColumnResizeStop,
  renderMeasurementColumn,
  reorderableColumns,
  rightStickyColumn,
  tableColWidths,
  tableHeight,
  uniqueKey,
}: Props) => {
  const updateColumns = useCallback(
    (sourceIndex: number, destinationIndex: number, draggableId: string) => {
      const destinationColumn = columns[destinationIndex]
      if (!destinationColumn.props.isReorderable) return
      const fields = columns.map(getField)
      fields.splice(sourceIndex, 1)
      fields.splice(destinationIndex, 0, draggableId)

      if (!onColumnChange) return
      onColumnChange(fields.map((value) => ({ display: value, value })))
    },
    [columns, onColumnChange]
  )

  const onDragEnd = useCallback(
    ({ destination, source, draggableId }: any) => {
      if (!destination || destination.index === source.index) return
      updateColumns(source.index, destination.index, draggableId)
    },
    [updateColumns]
  )

  const batchColWidth = columnResizingEnabledForTable ? tableColWidths.batch : MINIMUM_BATCH_COLUMN_WIDTH

  const secondColFieldName = columns[1] && getField(columns[1])

  const isSecondTooLongToBeSticky = getIsSecondColTooLongToBeSticky(
    batchColumnEnabled,
    columns.length,
    tableColWidths[secondColFieldName],
    1,
    availableWidth,
    batchColWidth
  )

  const secondColumnOffsetStyles = !batchColumnEnabled ? undefined : { left: batchColWidth }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`table-header-droppable-${uniqueKey}`} direction="horizontal">
        {(provided) => (
          <div
            className={classNames('hb-table__header-row', classes.visibleTableHeader, {
              [classes.batchColumnEnabled]: batchColumnEnabled && !isSecondTooLongToBeSticky,
            })}
            ref={(ref) => {
              provided.innerRef(ref)
              // eslint-disable-next-line no-param-reassign
              draggableHeaderRef.current = ref
            }}
            style={columnResizingEnabledForTable ? { width: allColsWidth } : undefined}
            {...provided.droppableProps}
            data-testid="hb-table-header-row"
          >
            {columns.length === 0 && (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <th className="hb-table__header hb-table__header--empty" />
            )}
            {columns.map((column, columnIndex) => {
              const fieldName = getField(column)
              const isBatch = fieldName === 'batch'

              const colWidth = tableColWidths[fieldName]

              const isColumnDragDisabled = getIsColumnDragDisabled(column)
              const isColumnResizeDisabled = getIsColumnResizeDisabled(column) || !columnResizingEnabledForTable

              const isSecond = columnIndex === 1
              const isLast = columnIndex === columns.length - 1
              const isLastNonSticky = rightStickyColumn ? isLast : false

              // note has to be flex or the column resizing does not expand correctly
              const widthStyles = columnResizingEnabledForTable
                ? { flex: `${isBatch || isColumnResizeDisabled ? 0 : 1} 0 ${colWidth}px` }
                : undefined
              const style =
                batchColumnEnabled && columnIndex === 1 && isSecond && !isSecondTooLongToBeSticky
                  ? {
                      ...widthStyles,
                      ...secondColumnOffsetStyles,
                    }
                  : widthStyles

              const baseProps = {
                column,
                classes: {
                  root: classNames(column.props.headerClassName, {
                    [classes.draggable]: reorderableColumns,
                  }),
                  lastNonStickyHeader: classes.lastNonStickyHeader,
                },
                lastNonSticky: isLastNonSticky,
                style,
              }

              return (
                <Draggable
                  key={fieldName}
                  draggableId={fieldName}
                  index={columnIndex}
                  isDragDisabled={!reorderableColumns || isColumnDragDisabled}
                >
                  {({ innerRef, dragHandleProps, draggableProps }, { isDragging }) =>
                    getHeaderRenderProp(column)({
                      ...baseProps,
                      classes: {
                        root: classNames(baseProps.classes.root, {
                          [classes.dragging]: isDragging,
                        }),
                      },
                      columnResizingEnabled: !isColumnResizeDisabled,
                      headerCellRef: innerRef,
                      isLast,
                      ...dragHandleProps,
                      ...draggableProps,
                      renderMeasurementColumn,
                      style: {
                        ...draggableProps.style,
                        ...baseProps.style,
                      },
                      tableHeight,
                      availableWidth,
                      colWidth,
                      onColumnResizeStop,
                    })
                  }
                </Draggable>
              )
            })}
            {rightStickyColumn &&
              getHeaderRenderProp(rightStickyColumn)({
                column: rightStickyColumn,
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
