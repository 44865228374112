import { useState } from 'react'

import { gql, useQuery } from '@apollo/client'

import { css } from '@emotion/css'
import { Close } from '@mui/icons-material'
import CircleIcon from '@mui/icons-material/Circle'
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
// eslint-disable-next-line no-restricted-imports
import { Box, dialogClasses, DialogTitle, styled, ClickAwayListener } from '@mui/material'

import invariant from 'tiny-invariant'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbDialog } from 'components/HbComponents/HbDialog'
import { HbTooltip } from 'components/HbComponents/HbTooltip'

import { HbText } from 'components/HbComponents/Text/HbText'
import Loader from 'components/library/Loader'
import { useTriggerPreviewModeRun } from 'components/pages/automations/hooks/useTriggerPreviewModeRun'
import { useFormattedDate } from 'hooks/DateFormatHooks'

import { AutomationRuleUpcomingRunSummary } from 'types/api'

import {
  ViewAutomationInnerAutomationRuleFragment as AutomationRule,
  ViewAutomationInnerAutomationRule_ScheduleWithTriggerAutomationRule_Fragment,
  ViewAutomationInnerAutomationRule_TriggerAutomationRule_Fragment,
} from '../__generated__/ViewAutomation.queries.generated'

import { PreviewModeCard } from './PreviewModeCard'
import { PreviewRunsTable } from './PreviewRunsTable'
import { ScheduledUpcomingRunsTable } from './ScheduledUpcomingRunsTable'
import { UpcomingRunCard } from './UpcomingRunCard'

const ActiveIcon = styled(CircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  height: '7px',
  width: '7px',
}))

const ActiveText = styled(HbText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}))

const StopPreviewButton = styled(HbButton)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const Divider = styled('hr')(({ theme }) => ({
  border: 0,
  borderTop: `1px solid ${theme.palette.styleguide.borderLight}`,
  margin: theme.spacing(3, 0),
}))

const Section = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

const StyledDialogHeader = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 2, 2, 3),
  boxShadow: `0px 4px 7px 0px #0000001A`,
  marginBottom: theme.spacing(3),
}))

const StyledTooltipText = styled(HbText)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

const overrideClasses = {
  tooltip: css({ borderRadius: '6px', width: '220px', padding: '16px' }),
}

const ConfirmRunAction = ({ disabled, onConfirm }: { disabled: boolean; onConfirm: () => void }) => {
  return (
    <>
      <StyledTooltipText size="s" color="white" block>
        Manually running this automation will consume credits
      </StyledTooltipText>
      <HbButton size="small" label="Confirm" variant="confirmation" disabled={disabled} onClick={onConfirm} />
    </>
  )
}

const TriggeredRuleContent = ({ rule }: { rule: AutomationRule }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmRunPopperOpen, setIsConfirmRunPopperOpen] = useState(false)

  const openPreviewModal = () => {
    setIsModalOpen(true)
  }

  const openConfirmRunPopper = () => {
    setIsConfirmRunPopperOpen((prev) => !prev)
  }
  const { triggerAutomationPreviewModeRun, loading: triggerPreviewRunLoading } = useTriggerPreviewModeRun(rule)

  const handleConfirmRun = async () => {
    triggerAutomationPreviewModeRun()
    setIsConfirmRunPopperOpen(false)
  }

  let currentPendingRun:
    | ViewAutomationInnerAutomationRule_TriggerAutomationRule_Fragment['previewModePendingRun']
    | null = null
  let previousRuns: ViewAutomationInnerAutomationRule_TriggerAutomationRule_Fragment['previewModePendingRun'][] = []
  let primaryRuleTrigger:
    | ViewAutomationInnerAutomationRule_TriggerAutomationRule_Fragment['primaryRuleTrigger']
    | null = null

  if (rule?.__typename === 'TriggerAutomationRule') {
    currentPendingRun = rule?.previewModePendingRun
    previousRuns = rule?.previewModeManualRuns || []
    primaryRuleTrigger = rule?.primaryRuleTrigger
  }

  invariant(currentPendingRun)
  invariant(primaryRuleTrigger)

  return (
    <>
      <PreviewModeCard
        run={currentPendingRun}
        domainType={primaryRuleTrigger.domainType}
        showEndDateAsToday
        onClick={openPreviewModal}
      />
      <Divider />

      <Section>
        <HbText tag="h3" bold block>
          Manual Runs
        </HbText>

        {previousRuns.length > 0 ? (
          previousRuns.map((run) =>
            run ? <PreviewModeCard key={run.ranAt} domainType={primaryRuleTrigger.domainType} run={run} /> : null
          )
        ) : (
          <HbText>This automation has not had any manual runs yet</HbText>
        )}
      </Section>

      <HbDialog
        id="activity-log"
        title="Activity"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={{
          [`& .${dialogClasses.paper}`]: {
            width: 1200,
          },
        }}
        header={
          <StyledDialogHeader id="activity-log">
            <HbText size="xl">Run Automation</HbText>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <ClickAwayListener onClickAway={() => setIsConfirmRunPopperOpen(false)}>
                <div>
                  <HbTooltip
                    showArrow
                    classes={overrideClasses}
                    open={isConfirmRunPopperOpen}
                    title={
                      <ConfirmRunAction
                        disabled={currentPendingRun.totalEventsMatchedCount <= 0 || triggerPreviewRunLoading}
                        onConfirm={handleConfirmRun}
                      />
                    }
                  >
                    <HbButton
                      label="Run automation manually"
                      disabled={currentPendingRun.totalEventsMatchedCount <= 0 || triggerPreviewRunLoading}
                      onClick={openConfirmRunPopper}
                    />
                  </HbTooltip>
                </div>
              </ClickAwayListener>
              <HbButton label="Close modal" iconOnly Icon={Close} onClick={() => setIsModalOpen(false)} />
            </Box>
          </StyledDialogHeader>
        }
      >
        <PreviewRunsTable automationRule={rule} />
      </HbDialog>
    </>
  )
}

const ScheduledRuleContent = ({ rule }: { rule: AutomationRule }) => {
  const [isPreviewMatchesModalOpen, setIsPreviewMatchesModalOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmRunPopperOpen, setIsConfirmRunPopperOpen] = useState(false)

  const openPreviewModal = () => {
    setIsModalOpen(true)
  }

  const openConfirmRunPopper = () => {
    setIsConfirmRunPopperOpen((prev) => !prev)
  }
  const { triggerAutomationPreviewModeRun, loading: triggerPreviewRunLoading } = useTriggerPreviewModeRun(rule)

  const handleConfirmRun = async () => {
    triggerAutomationPreviewModeRun()
    setIsConfirmRunPopperOpen(false)
  }

  const openPreviewMatchesModal = () => {
    setIsPreviewMatchesModalOpen(true)
  }

  let upcomingScheduledRunSummary: AutomationRuleUpcomingRunSummary | null = null
  let currentPendingRun:
    | ViewAutomationInnerAutomationRule_ScheduleWithTriggerAutomationRule_Fragment['previewModePendingRun']
    | null = null
  let previousRuns: ViewAutomationInnerAutomationRule_ScheduleWithTriggerAutomationRule_Fragment['previewModeManualRuns'] =
    []
  let primaryRuleTrigger:
    | ViewAutomationInnerAutomationRule_ScheduleWithTriggerAutomationRule_Fragment['primaryRuleTrigger']
    | null = null

  if (rule?.__typename === 'ScheduleWithTriggerAutomationRule') {
    upcomingScheduledRunSummary = rule?.upcomingScheduledRunSummary
    currentPendingRun = rule?.previewModePendingRun
    previousRuns = rule?.previewModeManualRuns || []
    primaryRuleTrigger = rule?.primaryRuleTrigger
  }

  invariant(upcomingScheduledRunSummary)
  invariant(primaryRuleTrigger)

  return (
    <>
      <Section>
        <HbText tag="h3" bold block>
          Upcoming Run
        </HbText>
        <UpcomingRunCard
          run={upcomingScheduledRunSummary}
          domainType={primaryRuleTrigger.domainType}
          onClick={openPreviewMatchesModal}
        />
      </Section>

      <Divider />

      <Section>
        <HbText tag="h3" bold block>
          Recent Runs
        </HbText>

        {currentPendingRun || previousRuns.length > 0 ? (
          <>
            {currentPendingRun && (
              <PreviewModeCard
                run={currentPendingRun}
                domainType={primaryRuleTrigger.domainType}
                onClick={openPreviewModal}
              />
            )}
            {previousRuns.map((run) =>
              run ? <PreviewModeCard key={run.ranAt} run={run} domainType={primaryRuleTrigger.domainType} /> : null
            )}
          </>
        ) : (
          <HbText>This automation has not had any runs yet</HbText>
        )}
      </Section>

      <HbDialog
        id="activity-log"
        title="Activity"
        open={isPreviewMatchesModalOpen}
        onClose={() => setIsPreviewMatchesModalOpen(false)}
        sx={{
          [`& .${dialogClasses.paper}`]: {
            width: 1200,
          },
        }}
        header={
          <StyledDialogHeader id="activity-log">
            <HbText size="xl">Preview Matches</HbText>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <HbButton label="Close modal" iconOnly Icon={Close} onClick={() => setIsPreviewMatchesModalOpen(false)} />
            </Box>
          </StyledDialogHeader>
        }
      >
        <ScheduledUpcomingRunsTable automationRule={rule} />
      </HbDialog>

      <HbDialog
        id="activity-log"
        title="Activity"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={{
          [`& .${dialogClasses.paper}`]: {
            width: 1200,
          },
        }}
        header={
          <StyledDialogHeader id="activity-log">
            <HbText size="xl">Run Automation</HbText>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <ClickAwayListener onClickAway={() => setIsConfirmRunPopperOpen(false)}>
                <div>
                  <HbTooltip
                    showArrow
                    classes={overrideClasses}
                    open={isConfirmRunPopperOpen}
                    title={
                      <ConfirmRunAction
                        disabled={
                          (currentPendingRun && currentPendingRun.totalEventsMatchedCount <= 0) ||
                          triggerPreviewRunLoading
                        }
                        onConfirm={handleConfirmRun}
                      />
                    }
                  >
                    <HbButton
                      label="Run automation manually"
                      disabled={
                        (currentPendingRun && currentPendingRun.totalEventsMatchedCount <= 0) ||
                        triggerPreviewRunLoading
                      }
                      onClick={openConfirmRunPopper}
                    />
                  </HbTooltip>
                </div>
              </ClickAwayListener>
              <HbButton label="Close modal" iconOnly Icon={Close} onClick={() => setIsModalOpen(false)} />
            </Box>
          </StyledDialogHeader>
        }
      >
        <PreviewRunsTable automationRule={rule} />
      </HbDialog>
    </>
  )
}

export const PreviewModeActivePanel = ({
  deactivatePreviewMode,
  automationRule,
}: {
  deactivatePreviewMode: () => void
  automationRule: AutomationRule
}) => {
  const formattedEnabledAtDate = useFormattedDate(automationRule.enabledAt, '', 'LL')

  // Poll for data every 5s to update cache
  useQuery(
    gql`
      query PollAutomationRulePreviewMode($token: ID!, $organizationToken: ID) {
        automationRule(token: $token, organizationToken: $organizationToken) {
          ... on CanonicalAutomationRuleBase {
            token
          }
          ...PreviewModePanelAutomationRule
        }
      }
      ${PreviewModeActivePanel.fragments.automationRule}
    `,
    {
      variables: {
        token: automationRule.token,
        organizationToken: null,
      },
      pollInterval: 5000,
      fetchPolicy: 'cache-and-network',
    }
  )

  return (
    <Box sx={{ m: 2 }}>
      <Loader
        variant="global"
        css={{
          '[role="progressbar"]': { background: 'none' },
          '*': { borderRadius: '0px' },
        }}
      />

      <ActiveText>
        <ActiveIcon />
        Active since {formattedEnabledAtDate}
      </ActiveText>
      <StopPreviewButton
        variant="secondary"
        label="Stop preview mode"
        fullWidth
        onClick={deactivatePreviewMode}
        Icon={StopCircleOutlinedIcon}
      />

      {automationRule.__typename === 'TriggerAutomationRule' ? (
        <TriggeredRuleContent rule={automationRule} />
      ) : (
        <ScheduledRuleContent rule={automationRule} />
      )}
    </Box>
  )
}

PreviewModeActivePanel.fragments = {
  automationRule: gql`
    fragment PreviewModePanelAutomationRule on CanonicalAutomationRule {
      ... on TriggerAutomationRule {
        previewModePendingRun {
          startingAtDate
          totalEventsMatchedCount
          totalEventsActionedCount
          domainType
          ranAt
        }
        previewModeManualRuns {
          startingAtDate
          totalEventsMatchedCount
          totalEventsActionedCount
          domainType
          ranAt
        }
      }
      __typename
      ... on ScheduleWithTriggerAutomationRule {
        upcomingScheduledRunSummary {
          nextRunDate
          totalEventsMatchedCount
        }
        previewModePendingRun {
          startingAtDate
          totalEventsMatchedCount
          totalEventsActionedCount
          domainType
        }
        previewModeManualRuns {
          startingAtDate
          totalEventsMatchedCount
          totalEventsActionedCount
          domainType
          ranAt
        }
      }
    }
  `,
}
