import { FieldPath, FieldValues } from 'react-hook-form'

import { useSelector } from 'actions/store'
import HbRHFDropdown, { HbRHFDropdownProps } from 'components/HbComponents/Form/HbInputs/HbDropdown/HbRHFDropdown'
import {
  HbRHFDropdownWithOther,
  HbRHFDropdownWithOtherProps,
} from 'components/HbComponents/Form/HbInputs/HbDropdownWithOther/HbRHFDropdownWithOther'
import { SelectField, LabeledField } from 'components/material/Form'
import { LabeledFieldProps, SelectWithOtherField, SelectWithOtherProps } from 'components/material/Form/FieldElements'
import { getEnum } from 'helpers/stateHelpers'

interface SharedProps {
  enum: string
  skipValues?: string[]
}

export type EnumFieldProps = SharedProps &
  Omit<LabeledFieldProps<typeof SelectField>, 'options' | 'adornment' | 'FieldComponent'>

export function EnumField(props: EnumFieldProps) {
  const { enum: enumName, skipValues = [], ...otherProps } = props
  const options = useSelector((state) => getEnum(state, enumName))

  const filteredOptions = options.filter(({ value }) => !skipValues.includes(value))

  const finalProps = {
    FieldComponent: SelectField,
    options: filteredOptions,
    adornment: 'NONE',
    ...otherProps,
  }

  return <LabeledField {...(finalProps as LabeledFieldProps<typeof SelectField>)} />
}

type EnumRHFDropdownProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = SharedProps & Omit<HbRHFDropdownProps<TFieldValues, TName>, 'options'>

export const EnumRHFDropdown = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: EnumRHFDropdownProps<TFieldValues, TName>
) => {
  const { enum: enumName, skipValues = [], ...otherProps } = props
  const options = useSelector((state) => getEnum(state, enumName))

  const filteredOptions = options.filter(({ value }) => !skipValues.includes(value))

  const finalProps = {
    options: filteredOptions,
    ...otherProps,
  }

  return <HbRHFDropdown {...finalProps} />
}

export function EnumFieldWithOther(props: SharedProps & Omit<SelectWithOtherProps, 'options'>) {
  const { enum: enumName, name, label, skipValues = [], ...otherProps } = props

  const options = useSelector((state) => getEnum(state, enumName))
  const filteredOptions = options.filter(({ value }) => !skipValues.includes(value))

  return <SelectWithOtherField name={name} label={label} options={filteredOptions} {...otherProps} />
}

type EnumRHFDropdownWithOtherProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = SharedProps & Omit<HbRHFDropdownWithOtherProps<TFieldValues, TName>, 'options'>

export const EnumRHFDropdownWithOther = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: EnumRHFDropdownWithOtherProps<TFieldValues, TName>
) => {
  const { enum: enumName, name, label, skipValues = [], ...otherProps } = props

  const options = useSelector((state) => getEnum(state, enumName))
  const filteredOptions = options.filter(({ value }) => !skipValues.includes(value))

  return <HbRHFDropdownWithOther label={label} name={name} options={filteredOptions} {...otherProps} />
}
