import { FocusEvent, FocusEventHandler, ReactNode } from 'react'

import VisuallyHidden from '@reach/visually-hidden'

import { FieldProps, getIn } from 'formik'

import { convertNewlinesToJSX } from 'components/HbComponents/Form/Inputs'

export const displayStringValue = ({
  value,
  noValueIndicator: noValueIndicatorProp,
}: {
  value?: unknown
  noValueIndicator?: ReactNode
}) => {
  if (typeof value === 'string' && value !== '') {
    return convertNewlinesToJSX(value)
  }

  const noValueIndicator = noValueIndicatorProp || (
    <>
      <span aria-hidden="true">-</span>
      <VisuallyHidden>No value</VisuallyHidden>
    </>
  )
  return value ?? noValueIndicator
}

type BaseFormikInputProps<InputElement extends HTMLInputElement | HTMLTextAreaElement> = FieldProps & {
  disabled?: boolean
  onBlur?: FocusEventHandler<InputElement>
  helperText?: ReactNode
  label: ReactNode
}

// Our variant of `fieldToTextField` based on formik-mui
export const formikFieldToHbInput = <
  InputElement extends HTMLInputElement | HTMLTextAreaElement,
  FormikInputProps extends BaseFormikInputProps<InputElement> = BaseFormikInputProps<InputElement>,
>({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  label,
  ...props
}: FormikInputProps) => {
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function _onBlur(e: FocusEvent<InputElement>) {
        fieldOnBlur(e ?? field.name)
      },
    label,
    ...field,
    ...props,
  }
}
