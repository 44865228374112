// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'
import { SearchIcon } from 'icons'

export const Root = styled('div')(() => {
  return { display: 'flex', flexDirection: 'column', overflowY: 'scroll', maxHeight: 700, height: 700 }
})

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => {
  return { margin: theme.spacing(0, 1) }
})

export const TemplateSection = styled('div')(({ theme }) => {
  return { display: 'flex', flexDirection: 'column', marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }
})

export const TemplatesList = styled('div')(() => {
  return { display: 'grid', gridTemplateColumns: '1fr 1fr' }
})

export const SectionHeader = styled(HbText)(({ theme }) => {
  return { textTransform: 'capitalize', marginBottom: theme.spacing(1) }
})

export const TemplateSearch = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flex: 1,
    marginBottom: theme.spacing(4),
    justifyContent: 'space-between',
    maxHeight: theme.spacing(10),
    top: 0,
    background: 'white',
    position: 'sticky',
  }
})

export const SearchInput = styled('div')(({ theme }) => {
  return { width: '60%', margin: theme.spacing(2) }
})
