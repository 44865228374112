import CheckIcon from '@mui/icons-material/Check'
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import pluralize from 'pluralize'

import invariant from 'tiny-invariant'

import { HbButton } from 'components/HbComponents/HbButton'

import { HbText } from 'components/HbComponents/Text/HbText'
import { useFormattedDate } from 'hooks/DateFormatHooks'
import { AutomationRulePreviewRun, AutomationDomainType } from 'types/api'

const Card = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.styleguide.borderLight}`,
  borderRadius: theme.shape.largeContainer.borderRadius,
  padding: theme.spacing(2),
  background: theme.palette.styleguide.backgroundMedium,
}))

const Content = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  gap: theme.spacing(),
}))

const StatusBar = styled('div')<{ manuallyRun?: boolean }>(({ theme, manuallyRun }) => ({
  height: '100%',
  width: '6px',
  background: manuallyRun ? theme.palette.styleguide.successMedium : theme.palette.styleguide.seaMedium,
  borderRadius: '24px',
}))

const EmphasizedNumber = styled(HbText)(() => ({
  '&&': {
    fontSize: '24px',
    lineHeight: '28px',
  },
}))

const RunsText = styled(HbText)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(1),
}))

const CreditsText = styled(HbText)(({ theme }) => ({
  display: 'block',
  marginTop: theme.spacing(1.2),
}))

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  marginTop: '2px',
  height: '16px',
  width: '16px',
}))

const ManualRunText = styled(HbText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  gap: theme.spacing(1),
}))

const numberFormat = new Intl.NumberFormat('en-US')

const ContentBody = ({
  manuallyRun,
  domainType,
  eventsActioned,
  eventsMatched,
}: {
  manuallyRun: boolean
  domainType: AutomationDomainType
  eventsActioned: number
  eventsMatched: number
}) => {
  const formattedMatchedEventsCount = numberFormat.format(eventsMatched ?? 0)
  const formattedActionedEventsCount = manuallyRun
    ? numberFormat.format(eventsActioned ?? 0)
    : formattedMatchedEventsCount

  if (!manuallyRun) {
    return (
      <>
        <EmphasizedNumber bold>{formattedMatchedEventsCount}</EmphasizedNumber> {pluralize(domainType, eventsMatched)}{' '}
        met the conditions
      </>
    )
  }

  return (
    <>
      <RunsText>
        <EmphasizedNumber bold>{formattedActionedEventsCount}</EmphasizedNumber> {pluralize('run', eventsActioned)}
      </RunsText>
      <HbText block>
        {formattedMatchedEventsCount} {pluralize(domainType, eventsMatched)} met the conditions
      </HbText>
    </>
  )
}

export const PreviewModeCard = ({
  run,
  domainType,
  showEndDateAsToday = false,
  onClick,
}: {
  run: Pick<
    AutomationRulePreviewRun,
    'ranAt' | 'startingAtDate' | 'totalEventsMatchedCount' | 'totalEventsActionedCount'
  >
  domainType: AutomationDomainType
  showEndDateAsToday?: boolean
  onClick?: () => void
}) => {
  const manuallyRun = !!run.ranAt

  const formattedStartingDate = useFormattedDate(run.startingAtDate, '', 'LL')
  const formattedRanAtDate = useFormattedDate(run.ranAt, '', 'LL · LT')

  invariant(domainType)

  return (
    <Card>
      <HbText bold block>
        {formattedStartingDate} {showEndDateAsToday && '- Today'}
      </HbText>
      <Content>
        <div>
          <StatusBar manuallyRun={manuallyRun} />
        </div>
        <div>
          <ContentBody
            manuallyRun={manuallyRun}
            domainType={domainType}
            eventsMatched={run.totalEventsMatchedCount}
            eventsActioned={run.totalEventsActionedCount}
          />
        </div>
      </Content>
      {!manuallyRun && (
        <div>
          <HbButton
            variant="secondary"
            label="Run automation manually"
            disabled={run.totalEventsMatchedCount <= 0}
            fullWidth
            onClick={onClick}
          />
          <CreditsText block color="secondary" center size="s">
            Manually running this automation will consume credits
          </CreditsText>
        </div>
      )}
      {manuallyRun && (
        <ManualRunText>
          <StyledCheckIcon />
          Manually run on {formattedRanAtDate}
        </ManualRunText>
      )}
    </Card>
  )
}
