import { gql } from '@apollo/client'

import { ArrowRightAlt } from '@mui/icons-material'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { useSelector } from 'actions/store'

import { HbTag } from 'components/HbComponents/HbTag'
import * as Styles from 'components/pages/automations/RecipeCard.styles'
import { StyledHbTag } from 'components/pages/automations/RecipeCard.styles'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { hasPermission } from 'helpers/stateHelpers'
import { BadgePermissionsEnum } from 'types/api'

import { AutomationIconStyleProps, RecipeStep, RecipeStepIcon } from './AutomationIcon'

import { getTemplateCategoryStyles } from './AutomationRuleTemplate/AutomationRuleTemplate.utils'
import { RecipeCardAutomationRuleTemplateFragment as AutomationRuleTemplate } from './__generated__/RecipeCard.generated'
import { useCreateAutomationRule } from './hooks/useCreateAutomationRule'
import { Tag } from './recipeTags'

interface Props {
  template: AutomationRuleTemplate
  tags: Array<Tag>
  openPreview?: () => void
  className?: string
}

interface RecipeArrowIconsProps {
  type: RecipeStep | null
  actionType: RecipeStep | null
}

const Arrow = styled(ArrowRightAlt)(({ theme }) => ({
  color: theme.palette.styleguide.borderDark,
}))

export function RecipeArrowIcons(props: RecipeArrowIconsProps) {
  const { type, actionType } = props
  const iconProps: Partial<AutomationIconStyleProps> = {
    variant: 'plain',
    grey: true,
  }

  return (
    <>
      <RecipeStepIcon type={type} {...iconProps} /> <Arrow /> <RecipeStepIcon type={actionType} {...iconProps} />
    </>
  )
}

export default function RecipeCard({ className, template, tags, openPreview }: Props) {
  const { name } = template
  const usage = useUsage()
  const hasManageAutomationsPermission = useSelector((state) =>
    hasPermission(state, BadgePermissionsEnum.ManageAutomations)
  )
  const { createAutomationRule } = useCreateAutomationRule(template.slug)

  const category = template.automationTemplateCategory
  const categoryStyles = category ? getTemplateCategoryStyles(category.style) : null

  const handleCreateAutomation = () => {
    usage.logEvent({
      name: 'automations:addAutomationRecipe:clicked',
      data: {
        recipeName: name || '',
        recipeTags: tags.map((tag) => tag.display),
        recipeCategory: category?.name || '',
      },
    })
    if (hasManageAutomationsPermission) {
      createAutomationRule()
    }
  }

  return (
    <Styles.Card
      aria-label="select-recipe-template"
      type="button"
      onClick={openPreview || handleCreateAutomation}
      className={className}
    >
      <Styles.Header>
        <Styles.Recipe>
          <RecipeArrowIcons
            type={
              template.__typename === 'TriggeredRuleTemplate' ||
              template.__typename === 'ScheduledWithTriggerRuleTemplate'
                ? template.domainType
                : 'schedule'
            }
            actionType={template.actionType}
          />
        </Styles.Recipe>
      </Styles.Header>
      <Styles.Description>{name}</Styles.Description>
      <Styles.Tags>
        {category && categoryStyles && <HbTag themeColor={categoryStyles.themeColor} label={category.name} />}
        {tags?.map((tag) => (
          <StyledHbTag key={tag.value} label={tag.display} />
        ))}
      </Styles.Tags>
    </Styles.Card>
  )
}

RecipeCard.fragments = {
  template: gql`
    fragment RecipeCardAutomationRuleTemplate on AutomationRuleTemplate {
      ... on AutomationRuleTemplateBase {
        token
        name
        actionType
        slug
        automationType
        automationTemplateCategory {
          token
          name
          style
          slug
        }
      }
      ... on TriggeredRuleTemplate {
        domainType
      }
      ... on ScheduledWithTriggerRuleTemplate {
        domainType
      }
    }
  `,
}
