import { useState } from 'react'

import { gql, useQuery } from '@apollo/client'

import { css } from '@emotion/css'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material/styles'

import { useHistory } from 'react-router-dom'

import { useSelector } from 'actions/store'
import { GQLError } from 'components/GQLError'
import { HbTag } from 'components/HbComponents/HbTag'
import { HbText } from 'components/HbComponents/Text/HbText'
import Loader from 'components/library/Loader'

import { TableColumn } from 'components/library/Table'
import { RoundedTable } from 'components/library/Table/RoundedTable'
import { MyAutomationsEmptyState } from 'components/pages/automations/MyAutomationsEmptyState'
import { NewAutomationModal } from 'components/pages/automations/NewAutomationModal'
import { RecommendedAutomationsCard } from 'components/pages/automations/RecommendedAutomations'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { getCurrentOrganizationToken } from 'helpers/stateHelpers'
import { fromNow } from 'helpers/uiHelpers'
import { useFeatureFlag, useToggle } from 'hooks'
import { useDateFormatter } from 'hooks/DateFormatHooks'

import { AutomationRuleExecutionMode, FeatureFlag } from 'types/api'
import { ArrayElement } from 'utils/typeDerivations'

import DisableAutomationSwitch from './DisableAutomationSwitch'

import { MyAutomationsQuery, MyAutomationsQueryVariables } from './__generated__/MyAutomations.generated'

import { OrganizationSelectDialog } from './editor/AutomationEditorSteps/OrganizationSelectComponents'

import { useAutomationAdminAccountCheck } from './hooks/AutomationAdminHelpers'
import { useCreateAutomationRule } from './hooks/useCreateAutomationRule'

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}))

const CenterCell = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const styleOverrides = {
  Table: { innerContainer: css({ margin: 0, padding: 0 }), container: css({ maxWidth: '100%' }) },
  TableWithReorderableColumns: { table: css({ margin: 0, padding: 0 }) },
  TableRow: { bodyCell: css({ textWrap: 'wrap', maxWidth: 400 }) },
}

const PAGE_QUERY = gql`
  query MyAutomations($first: Int, $datasourcesEnabled: Boolean!) {
    currentOrganization {
      automationRules(first: $first) {
        nodes {
          ... on CanonicalAutomationRuleBase {
            token
            organizationToken
            disabled
            displayName
            createdAt
            createdBy {
              fullName
            }
            latestExecution {
              createdAt
              status
            }
            usageCount
            ...DisableAutomationSwitchAutomationRule
          }
        }
        totalCount
      }
    }
  }
  ${DisableAutomationSwitch.fragments.automationRule}
`

type CanonicalAutomationRule = NonNullable<
  ArrayElement<NonNullable<NonNullable<MyAutomationsQuery['currentOrganization']>['automationRules']>['nodes']>
>

export default function MyAutomations() {
  const datasourcesEnabled = useFeatureFlag(FeatureFlag.EnableDatasources)
  const history = useHistory()
  const usage = useUsage()
  const [createMenuOpen, setCreateMenuOpen] = useState(false)
  const formatTimestamp = useDateFormatter('LLL')

  const { loading, data, error } = useQuery<MyAutomationsQuery, MyAutomationsQueryVariables>(PAGE_QUERY, {
    fetchPolicy: 'cache-and-network',
    // TODO(ali): use proper pagination
    variables: { first: 250, datasourcesEnabled },
  })

  const orgSelectToggle = useToggle(false)
  const currentOrgToken = useSelector(getCurrentOrganizationToken)
  const [selectedOrgToken, setSelectedOrgToken] = useState<string | null>(null)
  const hasAdminPermissions = useAutomationAdminAccountCheck()

  const getAutomationRulePath = (automationRuleOrgToken: string, ruleToken: string) => {
    return `/automations/${
      hasAdminPermissions && currentOrgToken !== automationRuleOrgToken ? `${automationRuleOrgToken}/` : ''
    }${ruleToken}`
  }

  const { createAutomationRule, createAutomationRuleAsAdmin } = useCreateAutomationRule(null, {
    selectedOrgToken,
    getAutomationRulePath,
  })

  if (loading || !data) {
    return <Loader variant="global" />
  }

  if (error) {
    return <GQLError error={error} />
  }

  const rules = data.currentOrganization?.automationRules?.nodes?.filter((r): r is CanonicalAutomationRule => !!r) ?? []

  if (!rules.length && !loading) {
    return <MyAutomationsEmptyState />
  }

  // TODO(jh): Filter out feature flagged rules server-side, this is temporary
  const tableData = rules.map((rule) => ({
    token: rule.token,
    organizationToken: rule.organizationToken,
    name: rule.displayName,
    usage: rule.usageCount,
    lastUsage: rule.latestExecution ? fromNow(rule.latestExecution.createdAt) : 'Never',
    status: rule.latestExecution?.status ? rule.latestExecution.status.toLowerCase() : 'unknown',
    executionMode: rule.executionMode,
    disabled: rule.disabled,
    createdByName: rule.createdBy.fullName === 'System' ? 'Hummingbird' : rule.createdBy.fullName,
    createdAt: formatTimestamp(rule.createdAt),
    rawAutomationRule: rule,
  }))

  return (
    <>
      {hasAdminPermissions && createAutomationRuleAsAdmin && (
        <OrganizationSelectDialog
          open={orgSelectToggle.value}
          onSelect={(orgToken) => setSelectedOrgToken(orgToken)}
          onConfirm={() => {
            orgSelectToggle.toggle()
            setCreateMenuOpen(false)
            createAutomationRuleAsAdmin()
          }}
          onClose={() => orgSelectToggle.toggle()}
        />
      )}
      <NewAutomationModal
        open={createMenuOpen}
        onClose={() => setCreateMenuOpen(false)}
        onCreateFromBlank={() => {
          if (hasAdminPermissions) {
            orgSelectToggle.toggle()
          } else {
            createAutomationRule()
          }
        }}
      />
      <Root>
        <RecommendedAutomationsCard />
        <HbText size="lg" bold css={{ marginLeft: 3 }}>
          My Automations
        </HbText>
        <RoundedTable
          data={tableData}
          uniqueKey="token"
          styleOverrides={styleOverrides}
          onRowClick={(r: CanonicalAutomationRule) => {
            history.push(`${getAutomationRulePath(r.organizationToken, r.token)}`)
          }}
        >
          <TableColumn align="left" field="name" header="Recipe" />
          <TableColumn align="left" field="usage" header="Usage" />
          <TableColumn align="left" field="lastUsage" header="Last Usage" />
          <TableColumn
            align="left"
            field="status"
            header="Status"
            value={(t) => {
              if (t.disabled) {
                return <HbTag key="Disabled" label="Disabled" disabled />
              }

              if (t.executionMode === AutomationRuleExecutionMode.Preview) {
                return <HbTag key="Preview" label="Preview" themeColor="sea" />
              }

              switch (t.status.toLowerCase()) {
                case 'error':
                  return <HbTag key="Failing" label="Failing" themeColor="error" />
                default:
                  return <HbTag key="Operational" label="Operational" themeColor="success" />
              }
            }}
          />
          <TableColumn
            field="type"
            align="left"
            header="Type"
            value={(t) => {
              switch (t.rawAutomationRule.__typename) {
                case 'TriggerAutomationRule':
                  return 'Trigger'
                case 'ScheduleAutomationRule':
                  return 'Scheduled'
                case 'ScheduleWithTriggerAutomationRule':
                  return 'Scheduled with Trigger'
                default:
                  return 'Unknown'
              }
            }}
          />
          <TableColumn align="left" field="createdByName" header="Created By" />
          <TableColumn align="left" field="createdAt" header="Created At" />
          <TableColumn
            align="center"
            field="enabled"
            header="Enabled"
            value={(val: (typeof tableData)[number]) => (
              <CenterCell>
                <DisableAutomationSwitch
                  automationRule={val.rawAutomationRule}
                  onClick={(e: React.MouseEvent) => {
                    usage.logEvent({
                      name: 'automations:enableAutomationToggle:clicked',
                      data: {
                        recipeName: val.rawAutomationRule.displayName,
                        startingState: val.rawAutomationRule.enabled ? 'enabled' : 'disabled',
                      },
                    })
                    e.stopPropagation()
                  }}
                />
              </CenterCell>
            )}
          />
        </RoundedTable>
      </Root>
    </>
  )
}
