import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { kebabCase } from 'lodash'
import { nanoid } from 'nanoid'
import { DraggableLocation } from 'react-beautiful-dnd'

import { SurveySelectOption } from 'components/cases/report/survey/SurveyTypes'
import {
  QuestionFieldsFragment,
  QuestionWithDependentFieldsFragment,
} from 'components/cases/report/survey/__generated__/Question.generated'
import {
  GetReviewTypeQuery,
  StageEditEffectFragment,
  StageEditTaskFragment,
} from 'components/settings/ReviewTypes/ReviewType/__generated__/queries.generated'
import { isTaskSurvey } from 'components/settings/ReviewTypes/ReviewType/utils'
import { moveItemInList } from 'helpers/DraggableHelpers'
import {
  SurveyQuestion,
  SurveyQuestionNumberProps,
  SurveyQuestionOption,
  SurveyQuestionStyleEnum,
  SurveyQuestionTypeEnum,
} from 'types/api'

type EditableReviewType = GetReviewTypeQuery['reviewType']
type EditableTask = Pick<StageEditTaskFragment, 'title' | 'headline' | 'headlineSubtext' | 'slug'>
// eslint-disable-next-line camelcase
type EditableResearchTaskParams = {
  sources?: string[]
  instructions?: string
}

type BaseQuestionConfig = {
  text: string
  required: boolean
  parentQuestion?: SurveyQuestion | null
  parentSelectOption?: SurveySelectOption | null
}

type QuestionError =
  | { token: string; actionSlug: string; taskSlug: string; choiceToken?: undefined } // task question error
  | { token: string; actionSlug: string; choiceToken: string; taskSlug?: undefined } // effect error
  | { token: string; actionSlug?: undefined; taskSlug?: undefined; choiceToken?: undefined } // review type attribute error (ie name)
  | { token: string; actionSlug: string; taskSlug?: undefined; choiceToken?: undefined } // action error

type QuestionMap = Record<string, QuestionError[]>
export interface EditReviewTypeState {
  reviewType: GetReviewTypeQuery['reviewType'] | null
  isModified: boolean
  errorsByTask: QuestionMap
  surveyQuestionTags: QuestionMap
}

const getInitialState = (): EditReviewTypeState => ({
  reviewType: null,
  isModified: false,
  errorsByTask: {},
  surveyQuestionTags: {},
})

export const getTask = (
  state: EditReviewTypeState,
  { actionSlug, taskSlug }: { actionSlug: string; taskSlug: string }
) => {
  const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
  if (action) {
    return action.tasks.find((x) => x.slug === taskSlug)
  }
  return null
}

export const getSurveyTask = (
  state: EditReviewTypeState,
  { actionSlug, taskSlug }: { actionSlug: string; taskSlug: string }
) => {
  const task = getTask(state, { actionSlug, taskSlug })
  if (isTaskSurvey(task)) {
    return task
  }
  return null
}

export const getSurveySectionByToken = (
  state: EditReviewTypeState,
  { actionSlug, taskSlug, sectionToken }: { actionSlug: string; taskSlug: string; sectionToken: string }
) => {
  const task = getTask(state, { actionSlug, taskSlug })
  if (isTaskSurvey(task)) {
    for (const section of task.survey.sections) {
      if (section.token === sectionToken) {
        return section
      }
    }
  }
  return null
}

export const getSurveySectionForQuestion = (
  state: EditReviewTypeState,
  { actionSlug, taskSlug, questionToken }: { actionSlug: string; taskSlug: string; questionToken: string }
) => {
  const task = getTask(state, { actionSlug, taskSlug })
  if (isTaskSurvey(task)) {
    for (const section of task.survey.sections) {
      for (const question of section.questions) {
        if (question.token === questionToken) {
          return section
        }
      }
    }
  }
  return null
}

export const getSurveyQuestion = (
  state: EditReviewTypeState,
  { actionSlug, taskSlug, questionToken }: { actionSlug: string; taskSlug: string; questionToken: string }
) => {
  const task = getTask(state, { actionSlug, taskSlug })
  if (isTaskSurvey(task)) {
    for (const section of task.survey.sections) {
      for (const question of section.questions) {
        if (question.token === questionToken) {
          return question
        }
      }
    }
  }
  return null
}

const hasDuplicates = (state: QuestionMap, taskSlug: string) => {
  const duplicates = Object.values(state).filter((items) => items.length > 1)
  for (let i = 0; i < duplicates.length; i += 1) {
    const items = duplicates[i]
    for (let j = 0; j < items.length; j += 1) {
      const item = items[j]
      if (item.taskSlug === taskSlug) {
        return true
      }
    }
  }
  return false
}
export const getTaskHasError = (state: EditReviewTypeState, taskSlug: string) => {
  const errorsForTask = state.errorsByTask[taskSlug]
  if (errorsForTask && errorsForTask.length > 0) {
    return true
  }
  return hasDuplicates(state.surveyQuestionTags, taskSlug)
}

const keyByParentQuestionToken = ({ questions }: { questions: QuestionWithDependentFieldsFragment[] }) => {
  return questions.reduce<Record<string, QuestionWithDependentFieldsFragment[]>>((acc, q) => {
    if (q.parentQuestion?.token) {
      if (!acc[q.parentQuestion.token]) {
        acc[q.parentQuestion.token] = [q]
      } else {
        acc[q.parentQuestion.token].push(q)
      }
    }
    return acc
  }, {})
}

export const keyByParentQuestionTokenAndOptionToken = ({
  questions,
}: {
  questions: QuestionWithDependentFieldsFragment[]
}) => {
  return questions.reduce<Record<string, Record<string, QuestionWithDependentFieldsFragment[]>>>((acc, q) => {
    if (!q.parentQuestion?.token || !q.parentSelectOption?.token) {
      return acc
    }

    if (!acc[q.parentQuestion.token]) {
      acc[q.parentQuestion.token] = { [q.parentSelectOption.token]: [q] }
    } else if (acc[q.parentQuestion?.token][q.parentSelectOption.token]) {
      acc[q.parentQuestion.token][q.parentSelectOption.token].push(q)
    } else {
      acc[q.parentQuestion.token][q.parentSelectOption.token] = [q]
    }

    return acc
  }, {})
}

const getDependentQuestions = (
  arr: QuestionWithDependentFieldsFragment[],
  questions: QuestionWithDependentFieldsFragment[],
  dependentQuestionsByParentToken: Record<string, QuestionWithDependentFieldsFragment[]>
): QuestionWithDependentFieldsFragment[] => {
  if (!questions.length) {
    return arr
  }
  questions.forEach((q) => {
    arr.push(q)
    const dependentQuestions = dependentQuestionsByParentToken[q.token] || []
    if (dependentQuestions.length) {
      getDependentQuestions(arr, dependentQuestions, dependentQuestionsByParentToken)
    }
  })
  return arr
}

const getTopLevelQuestions = (questions: QuestionWithDependentFieldsFragment[]) => {
  return questions.filter((q) => !q.parentQuestion && !q.parentSelectOption)
}

interface BaseQuestionMapProps {
  statePath: QuestionMap
  key: string
  token: string
}

interface QuestionMapPropsWithChoiceToken extends BaseQuestionMapProps {
  taskSlug?: undefined
  choiceToken: string
  actionSlug: string
}

interface QuestionMapPropsWithTaskSlug extends BaseQuestionMapProps {
  taskSlug: string
  choiceToken?: undefined
  actionSlug: string
}

interface QuestionMapPropsForReviewTypeError extends BaseQuestionMapProps {
  taskSlug?: undefined
  choiceToken?: undefined
  actionSlug?: undefined
}

interface QuestionMapPropsForActionError extends BaseQuestionMapProps {
  taskSlug?: undefined
  choiceToken?: undefined
  actionSlug: string
}

const addToQuestionMap = ({
  statePath,
  key,
  token,
  ...rest
}:
  | QuestionMapPropsWithChoiceToken
  | QuestionMapPropsWithTaskSlug
  | QuestionMapPropsForReviewTypeError
  | QuestionMapPropsForActionError) => {
  if (!statePath[key]) {
    statePath[key] = [{ token, ...rest }]
  } else {
    statePath[key].push({ token, ...rest })
  }
}

const removeFromQuestionMap = ({ statePath, key, token }: { statePath: QuestionMap; key: string; token: string }) => {
  if (!statePath[key]) {
    return
  }
  const filteredTags = statePath[key].filter((item) => item.token !== token)
  if (!filteredTags.length) {
    delete statePath[key]
  } else {
    statePath[key] = filteredTags
  }
}

const editSurveyQuestion = (
  state: EditReviewTypeState,
  reduxAction: PayloadAction<{
    actionSlug: string
    taskSlug: string
    questionToken: string
    questionConfig: {
      text: string
      required: boolean
      multiline?: boolean
      multivalued?: boolean
      options?: SurveyQuestionOption[]
    }
  }>
) => {
  const { actionSlug, taskSlug, questionToken, questionConfig } = reduxAction.payload
  const task = getTask(state, { actionSlug, taskSlug })
  if (isTaskSurvey(task)) {
    const sectionIndex = task.survey.sections.findIndex((section) =>
      section.questions.find((q) => q.token === questionToken)
    )
    const nextQuestions = task.survey.sections[sectionIndex].questions.map((q) => {
      if (q.token === questionToken) {
        return {
          ...q,
          ...questionConfig,
        }
      }
      return q
    })
    task.survey.sections[sectionIndex].questions = nextQuestions

    state.isModified = true
  }
}

export const editReviewTypeFlowSlice = createSlice({
  name: 'editReviewTypeReducer',
  initialState: getInitialState(),
  reducers: {
    enterEditFlow(state, action: PayloadAction<{ reviewType: EditableReviewType; isModified?: boolean }>) {
      const surveyQuestionTags: QuestionMap = {}
      const errorsByTask: QuestionMap = {}
      action.payload.reviewType?.actions.forEach((a) => {
        // make map of survey tags
        a.tasks.forEach((task) => {
          if (isTaskSurvey(task)) {
            task.survey.sections.forEach((section) => {
              section.questions.forEach((question) => {
                if (question.tag) {
                  addToQuestionMap({
                    statePath: surveyQuestionTags,
                    key: question.tag,
                    token: question.token,
                    actionSlug: a.slug,
                    taskSlug: task.slug,
                  })
                }
              })
            })
          }
        })

        // check initial state of create_review effect
        a.choices.forEach((choice) => {
          choice.effects.forEach((effect) => {
            if (effect.type === 'create_review' && !effect.defaults?.newReviewTypeCanonicalId) {
              addToQuestionMap({
                statePath: errorsByTask,
                key: a.slug,
                token: effect.token,
                choiceToken: choice.token,
                actionSlug: a.slug,
              })
            }
          })
        })
      })
      return {
        ...state,
        reviewType: action.payload.reviewType,
        isModified: action.payload.isModified || false,
        surveyQuestionTags,
        errorsByTask,
      }
    },
    exitEditFlow() {
      return getInitialState()
    },
    editName(state, reduxAction: PayloadAction<{ name: string; updateCanonicalId: boolean }>) {
      const { name, updateCanonicalId } = reduxAction.payload
      if (!state.reviewType) return
      state.reviewType.name = name

      if (updateCanonicalId) {
        state.reviewType.canonicalReviewType = {
          ...state.reviewType.canonicalReviewType,
          canonicalId: kebabCase(name),
        }
      }
      state.isModified = true
    },
    addAction(state, reduxAction: PayloadAction<{ action: NonNullable<EditableReviewType>['actions'][number] }>) {
      const { action } = reduxAction.payload
      state.reviewType?.actions.push(action)
      state.isModified = true
    },
    editAction(state, reduxAction: PayloadAction<{ action: NonNullable<EditableReviewType>['actions'][number] }>) {
      const { action } = reduxAction.payload
      if (!state.reviewType) return
      const actionIndex = state.reviewType.actions.findIndex((x) => x.slug === action.slug)
      if (actionIndex !== -1) {
        state.reviewType.actions[actionIndex] = { ...state.reviewType?.actions[actionIndex], ...action }
        state.isModified = true
      }
    },
    deleteAction(state, reduxAction: PayloadAction<{ actionSlug: string }>) {
      const { actionSlug } = reduxAction.payload
      if (!state.reviewType) return
      const relatedTasks = state.reviewType.actions.find((x) => x.slug === actionSlug)?.tasks || []
      state.reviewType.actions = state.reviewType.actions.filter((x) => x.slug !== actionSlug)
      // also delete errors associated with action
      delete state.errorsByTask[actionSlug]
      // also delete tags associated with action
      relatedTasks.forEach((task) => {
        delete state.errorsByTask[task.slug]
      })
      // also delete from required actions
      state.reviewType.actionRequirements = state.reviewType.actionRequirements.filter(
        (x) => x?.action?.slug !== actionSlug && x?.requiredAction?.slug !== actionSlug
      )
      state.isModified = true
    },
    editActionRequirements: {
      reducer: (
        state,
        reduxAction: PayloadAction<{
          actionSlug: string
          actionRequirements: { slug: string; token: string }[]
        }>
      ) => {
        const { actionSlug, actionRequirements } = reduxAction.payload
        if (!state.reviewType) return
        const requirementsForOtherActions = state.reviewType.actionRequirements.filter(
          (x) => x?.action?.slug !== actionSlug
        )
        const preexistingRequirementsForThisAction = state.reviewType.actionRequirements.filter(
          (x) => x?.action?.slug === actionSlug
        )

        const nextRequirementsForThisAction = actionRequirements.map(({ slug, token }) => {
          const preexistingRequirement = preexistingRequirementsForThisAction.find(
            (x) => x?.requiredAction?.slug === slug
          )
          if (preexistingRequirement) {
            return preexistingRequirement
          }
          return {
            __typename: 'ReviewTypeActionRequirement' as const,
            token,
            action: {
              __typename: 'ReviewTypeAction' as const,
              slug: actionSlug,
            },
            requiredAction: {
              __typename: 'ReviewTypeAction' as const,
              slug,
            },
          }
        })

        state.reviewType.actionRequirements = [...requirementsForOtherActions, ...nextRequirementsForThisAction]
        state.isModified = true
      },
      prepare: (actionSlug: string, actionRequirements: string[]) => {
        return {
          payload: {
            actionSlug,
            actionRequirements: actionRequirements.map((slug) => ({ slug, token: nanoid() })),
          },
        }
      },
    },
    editDecisionChoice: {
      reducer: (
        state,
        reduxAction: PayloadAction<{
          actionSlug: string
          choiceToken: string
          choiceLabel: string
          taskRequirements: { slug: string; token: string }[]
        }>
      ) => {
        const { actionSlug, choiceLabel, choiceToken, taskRequirements } = reduxAction.payload
        const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
        if (action) {
          let currentChoice: NonNullable<EditableReviewType>['actions'][number]['choices'][number]
          action.choices = action.choices.map((choice) => {
            if (choice.token === choiceToken) {
              currentChoice = {
                ...choice,
                label: choiceLabel,
              }
              return currentChoice
            }
            return choice
          })

          // these are the task requirements associated with other choices
          const otherTaskRequirements = action.taskRequirements.filter((x) => x.choice.token !== choiceToken)

          // these are the pre-existing task requirements associated with the current choice
          const preExistingTaskRequirements = action.taskRequirements.filter((x) => x.choice.token === choiceToken)
          // We need to build the list of task requirements associated with the current choice
          // pulling the pre-existing task requirements if they exist, and creating new ones if they don't

          const relevantTaskRequirements = taskRequirements.reduce(
            (acc, requirement) => {
              const preExistingTaskRequirement = preExistingTaskRequirements.find(
                (x) => x.task.slug === requirement.slug
              )
              if (preExistingTaskRequirement) {
                acc.push(preExistingTaskRequirement)
              } else {
                const task = action.tasks.find((x) => x.slug === requirement.slug)
                if (task) {
                  acc.push({
                    __typename: 'ReviewTypeTaskRequirement' as const,
                    token: requirement.token,
                    choice: {
                      ...currentChoice,
                    },
                    task,
                  })
                }
              }
              return acc
            },
            [] as NonNullable<EditableReviewType>['actions'][number]['taskRequirements']
          )

          action.taskRequirements = [...otherTaskRequirements, ...relevantTaskRequirements]
          state.isModified = true
        }
      },
      prepare: (payload: {
        actionSlug: string
        choiceToken: string
        choiceLabel: string
        taskRequirements: string[]
      }) => {
        return {
          payload: {
            ...payload,
            taskRequirements: payload.taskRequirements.map((slug) => ({ slug, token: nanoid() })),
          },
        }
      },
    },
    reorderDecisionChoice(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        source: DraggableLocation
        destination: DraggableLocation
        fromState: string
      }>
    ) {
      const { actionSlug, source, destination, fromState } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (action) {
        const choicesByFromState = action.choices.reduce<
          Record<string, NonNullable<EditableReviewType>['actions'][number]['choices'][number][]>
        >((acc, choice) => {
          if (acc[choice.fromState]) {
            acc[choice.fromState].push(choice)
          } else {
            acc[choice.fromState] = [choice]
          }
          return acc
        }, {})

        const reorderedRelevantChoices = moveItemInList(choicesByFromState[fromState], source.index, destination.index)
        choicesByFromState[fromState] = reorderedRelevantChoices
        action.choices = Object.values(choicesByFromState).flat()
        state.isModified = true
      }
    },
    addEffect(
      state,
      reduxAction: PayloadAction<{ actionSlug: string; choiceToken: string; effect: StageEditEffectFragment }>
    ) {
      const { actionSlug, choiceToken, effect } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (action) {
        const choice = action.choices.find((x) => x.token === choiceToken)
        if (choice) {
          choice.effects.push(effect)
          state.isModified = true
        }
      }
    },
    editEffect(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        choiceToken: string
        effectToken: string
        effectDefaults: Record<string, string | number | string[] | null>
      }>
    ) {
      const { actionSlug, choiceToken, effectToken, effectDefaults } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (!action) return

      const choice = action.choices.find((x) => x.token === choiceToken)
      if (choice) {
        choice.effects = choice.effects.map((x) => {
          if (x.token === effectToken) {
            return {
              ...x,
              defaults: {
                ...(x.defaults || {}),
                ...effectDefaults,
              } as StageEditEffectFragment['defaults'],
            }
          }
          return x
        })
        state.isModified = true
      }
    },
    reorderEffect(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        choiceToken: string
        source: DraggableLocation
        destination: DraggableLocation
      }>
    ) {
      const { actionSlug, source, choiceToken, destination } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (action) {
        const choice = action.choices.find((x) => x.token === choiceToken)
        if (choice) {
          choice.effects = moveItemInList(choice.effects, source.index, destination.index)
          state.isModified = true
        }
      }
    },
    deleteEffect(state, reduxAction: PayloadAction<{ actionSlug: string; choiceToken: string; effectToken: string }>) {
      const { actionSlug, choiceToken, effectToken } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (action) {
        const choice = action.choices.find((x) => x.token === choiceToken)
        if (choice) {
          choice.effects = choice.effects.filter((x) => x.token !== effectToken)
          state.isModified = true
          // make sure to remove the effect from the error map
          removeFromQuestionMap({ statePath: state.errorsByTask, key: actionSlug, token: effectToken })
        }
      }
    },
    editTaskRequirements(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        choiceKey: string
        taskRequirements: NonNullable<EditableReviewType>['actions'][number]['taskRequirements']
      }>
    ) {
      const { actionSlug, choiceKey, taskRequirements } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (action) {
        const otherTaskRequirements = action.taskRequirements.filter((x) => x.choice.key !== choiceKey)
        action.taskRequirements = [...otherTaskRequirements, ...taskRequirements]
        state.isModified = true
      }
    },
    addTask(state, reduxAction: PayloadAction<{ actionSlug: string; task: StageEditTaskFragment }>) {
      const { actionSlug, task } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (action) {
        action.tasks.push(task)
        state.isModified = true
      }
    },
    editTask(state, reduxAction: PayloadAction<{ actionSlug: string; task: EditableTask }>) {
      const { actionSlug, task } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (action) {
        const taskIndex = action.tasks.findIndex((x) => x.slug === task.slug)
        if (taskIndex !== -1) {
          action.tasks[taskIndex] = { ...action.tasks[taskIndex], ...task }
          state.isModified = true
        }
      }
    },
    editTaggingTaskParams(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        params: { groups: string[]; categories: string[] }
      }>
    ) {
      const { actionSlug, taskSlug, params } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (!action) return
      const task = action.tasks.find((x) => x.slug === taskSlug)
      if (task?.__typename === 'TaskTypeTagging') {
        task.params = { ...task.params, ...params }
        state.isModified = true
      }
    },
    editTextEntryTaskParams(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        params: { helpText: string[]; sarNarrative: boolean }
      }>
    ) {
      const { actionSlug, taskSlug, params } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (!action) return
      const task = action.tasks.find((x) => x.slug === taskSlug)
      if (task?.__typename === 'TaskTypeTextEntry') {
        task.params = { ...task.params, ...params }
        state.isModified = true
      }
    },
    editFilingDetailsTaskParams(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        params: { batchFilingEnabled: boolean }
      }>
    ) {
      const { actionSlug, taskSlug, params } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (!action) return
      const task = action.tasks.find((x) => x.slug === taskSlug)
      if (task?.__typename === 'TaskTypeFilingDetails') {
        task.params = { ...task.params, ...params }
        state.isModified = true
      }
    },
    deleteTask(state, reduxAction: PayloadAction<{ actionSlug: string; taskSlug: string }>) {
      const { actionSlug, taskSlug } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (action) {
        action.tasks = action.tasks.filter((x) => x.slug !== taskSlug)
        // also remove task from requirements
        action.taskRequirements = action.taskRequirements.filter((r) => r.task.slug !== taskSlug)
        // also remove errors associated with the task
        delete state.errorsByTask[taskSlug]
        state.isModified = true
      }
    },
    editResearchTaskParams(
      state,
      reduxAction: PayloadAction<{ actionSlug: string; taskSlug: string; params: EditableResearchTaskParams }>
    ) {
      const { actionSlug, taskSlug } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (action) {
        const task = action.tasks.find((x) => x.slug === taskSlug)
        if (task?.__typename === 'TaskTypeResearch') {
          task.params = { ...task.params, ...reduxAction.payload.params }
          state.isModified = true
        }
      }
    },
    addSurveySection(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        sectionIndex?: number
        sectionConfig: {
          token: string
          title: string
          questions: QuestionWithDependentFieldsFragment[]
        }
      }>
    ) {
      const { sectionConfig, sectionIndex, actionSlug, taskSlug } = reduxAction.payload
      const task = getTask(state, { actionSlug, taskSlug })
      if (!isTaskSurvey(task)) {
        return
      }
      if (!sectionIndex && typeof sectionIndex !== 'number') {
        task.survey.sections.push({
          ...sectionConfig,
        })
      } else {
        task.survey.sections.splice(sectionIndex, 0, sectionConfig)
      }
      state.isModified = true
    },
    deleteSurveySection(
      state,
      reduxAction: PayloadAction<{ actionSlug: string; taskSlug: string; sectionToken: string }>
    ) {
      const { sectionToken, taskSlug, actionSlug } = reduxAction.payload
      const task = getTask(state, { actionSlug, taskSlug })
      if (!isTaskSurvey(task)) {
        return
      }
      let deletedQuestions: QuestionWithDependentFieldsFragment[] = []
      task.survey.sections = task.survey.sections.filter((section) => {
        if (section.token !== sectionToken) {
          return true
        }
        deletedQuestions = section.questions
        return false
      })
      deletedQuestions.forEach((question) => {
        // remove deleted question from errors map
        removeFromQuestionMap({ statePath: state.errorsByTask, key: taskSlug, token: question.token })
        if (question.tag) {
          // remove deleted question from tags map
          removeFromQuestionMap({ statePath: state.surveyQuestionTags, key: question.tag, token: question.token })
        }
      })
      state.isModified = true
    },
    reorderSurveySection(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        source: DraggableLocation
        destination: DraggableLocation
      }>
    ) {
      const { source, destination, actionSlug, taskSlug } = reduxAction.payload
      const task = getTask(state, { actionSlug, taskSlug })
      if (!isTaskSurvey(task)) {
        return
      }
      task.survey.sections = moveItemInList(task.survey.sections, source.index, destination.index)
      state.isModified = true
    },
    editSurveySectionTitle(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        sectionIndex: number
        sectionTitle: string
      }>
    ) {
      const { sectionIndex, sectionTitle, actionSlug, taskSlug } = reduxAction.payload
      const task = getSurveyTask(state, { actionSlug, taskSlug })
      if (!task) {
        return
      }
      const nextSections = task.survey.sections.map((section, index) => {
        if (index === sectionIndex) {
          return {
            ...section,
            title: sectionTitle,
          }
        }
        return section
      })
      task.survey.sections = nextSections
      state.isModified = true
    },
    editSurveyTextQuestion(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionToken: string
        questionConfig: {
          multiline: boolean
          multivalued?: boolean
        } & BaseQuestionConfig
      }>
    ) {
      editSurveyQuestion(state, reduxAction)
    },
    editSurveyQuestionReportableLabel(
      state: EditReviewTypeState,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionToken: string
        reportableLabel: string | null
      }>
    ) {
      const { actionSlug, taskSlug, questionToken, reportableLabel } = reduxAction.payload
      const task = getTask(state, { actionSlug, taskSlug })
      if (isTaskSurvey(task)) {
        const sectionIndex = task.survey.sections.findIndex((section) =>
          section.questions.find((q) => q.token === questionToken)
        )
        const nextQuestions = task.survey.sections[sectionIndex].questions.map((q) => {
          if (q.token === questionToken) {
            return {
              ...q,
              reportAs: reportableLabel ? { label: reportableLabel } : null,
            }
          }
          return q
        })
        task.survey.sections[sectionIndex].questions = nextQuestions

        state.isModified = true
      }
    },
    editSurveyQuestionTag(
      state: EditReviewTypeState,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionToken: string
        tag: string | null
      }>
    ) {
      const { actionSlug, taskSlug, questionToken, tag } = reduxAction.payload
      const task = getTask(state, { actionSlug, taskSlug })
      if (isTaskSurvey(task)) {
        const sectionIndex = task.survey.sections.findIndex((section) =>
          section.questions.find((q) => q.token === questionToken)
        )
        const nextQuestions = task.survey.sections[sectionIndex].questions.map((q) => {
          if (q.token === questionToken) {
            // remove old tags
            if (q.tag && q.tag !== tag) {
              removeFromQuestionMap({ statePath: state.surveyQuestionTags, key: q.tag, token: questionToken })
            }
            // add new tags
            if (tag && q.tag !== tag) {
              addToQuestionMap({
                statePath: state.surveyQuestionTags,
                key: tag,
                token: questionToken,
                actionSlug,
                taskSlug,
              })
            }

            return {
              ...q,
              tag,
            }
          }
          return q
        })
        task.survey.sections[sectionIndex].questions = nextQuestions

        state.isModified = true
      }
    },
    editSurveySelectQuestion(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionToken: string
        questionConfig: {
          multivalued?: boolean
          options: SurveyQuestionOption[]
        } & BaseQuestionConfig
      }>
    ) {
      editSurveyQuestion(state, reduxAction)
    },
    editSurveyNumberQuestion(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionToken: string
        questionConfig: {
          numberProps?: Omit<
            SurveyQuestionNumberProps,
            'thousandSeparator' | 'thousandsGroupStyle' | 'decimalSeparator'
          > | null
        } & BaseQuestionConfig
      }>
    ) {
      editSurveyQuestion(state, reduxAction)
    },
    editSurveyFileUploadQuestion(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionToken: string
        questionConfig: { multivalued: boolean } & BaseQuestionConfig
      }>
    ) {
      editSurveyQuestion(state, reduxAction)
    },
    editSurveyDateQuestion(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionToken: string
        questionConfig: { type: 'date' | 'datetime' } & BaseQuestionConfig
      }>
    ) {
      editSurveyQuestion(state, reduxAction)
    },
    editSurveySwitchQuestion(
      // checkbox & radio
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionToken: string
        questionConfig: BaseQuestionConfig
      }>
    ) {
      editSurveyQuestion(state, reduxAction)
    },
    addSurveyQuestion(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionConfig: {
          required: boolean
          token: string
          type: SurveyQuestionTypeEnum
          parentSelectOption?: SurveySelectOption | null
          parentQuestion?: QuestionFieldsFragment | null
        }
        index?: number
        activeSectionToken: string | null
      }>
    ) {
      const { questionConfig, index, actionSlug, taskSlug } = reduxAction.payload
      const task = getSurveyTask(state, { actionSlug, taskSlug })
      if (task) {
        let sectionIndex = task.survey.sections.findIndex(
          (section) => section.token === reduxAction.payload.activeSectionToken
        )
        if (sectionIndex === -1) {
          sectionIndex = task.survey.sections.length - 1
        }
        const newQuestion: QuestionWithDependentFieldsFragment = {
          __typename: 'SurveyQuestion' as const,
          parentQuestion: null,
          multivalued: false,
          multiline: null,
          reportAs: null,
          tag: null,
          style: SurveyQuestionStyleEnum.Default,
          options: [],
          isDependentQuestion: false,
          parentSelectOption: null,
          numberProps: null,
          text: 'Label',
          fileUploadConfiguration: null,
          ...questionConfig,
        }
        const nextQuestions = [...task.survey.sections[sectionIndex].questions]
        if (index === undefined) {
          nextQuestions.push(newQuestion)
        } else {
          nextQuestions.splice(index, 0, newQuestion)
        }
        task.survey.sections[sectionIndex].questions = nextQuestions
        state.isModified = true
      }
    },
    deleteSurveyQuestion(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionToken: string
      }>
    ) {
      const { questionToken, actionSlug, taskSlug } = reduxAction.payload
      const task = getSurveyTask(state, { actionSlug, taskSlug })
      if (task) {
        const sectionIndex = task.survey.sections.findIndex((section) =>
          section.questions.find((q) => q.token === questionToken)
        )
        let deletedQuestion: QuestionWithDependentFieldsFragment | undefined
        const nextQuestions = task.survey.sections[sectionIndex].questions.filter((q) => {
          if (q.token !== questionToken) {
            return true
          }
          deletedQuestion = q
          return false
        })
        task.survey.sections[sectionIndex].questions = nextQuestions
        // make sure deleted question is removed from error map
        removeFromQuestionMap({ statePath: state.errorsByTask, key: taskSlug, token: questionToken })
        // make sure deleted question is removed from tag map
        if (deletedQuestion?.tag) {
          removeFromQuestionMap({
            statePath: state.surveyQuestionTags,
            key: deletedQuestion.tag,
            token: questionToken,
          })
        }

        state.isModified = true
      }
    },
    swapSurveyQuestion(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionToken: string
        questionConfig: {
          required: boolean
          token: string
          type: SurveyQuestionTypeEnum
          parentSelectOption?: SurveySelectOption | null
          parentQuestion?: QuestionFieldsFragment | null
        }
        activeSectionToken: string | null
      }>
    ) {
      const { questionToken, actionSlug, taskSlug, questionConfig, activeSectionToken } = reduxAction.payload
      const task = getSurveyTask(state, { actionSlug, taskSlug })
      if (task) {
        const sectionIndex = task.survey.sections.findIndex((section) => section.token === activeSectionToken)
        const newQuestion: QuestionWithDependentFieldsFragment = {
          __typename: 'SurveyQuestion' as const,
          parentQuestion: null,
          multivalued: false,
          multiline: null,
          reportAs: null,
          tag: null,
          style: SurveyQuestionStyleEnum.Default,
          options: [],
          isDependentQuestion: false,
          parentSelectOption: null,
          numberProps: null,
          text: 'Label',
          fileUploadConfiguration: null,
          ...questionConfig,
        }
        let deletedQuestion: QuestionWithDependentFieldsFragment | undefined
        const nextQuestions = task.survey.sections[sectionIndex].questions.map((q) => {
          if (q.token === questionToken) {
            deletedQuestion = q
            return newQuestion
          }
          return q
        })
        task.survey.sections[sectionIndex].questions = nextQuestions
        // make sure previous question is removed from error map
        removeFromQuestionMap({ statePath: state.errorsByTask, key: taskSlug, token: questionToken })
        // make sure previous question is removed from tag map
        if (deletedQuestion?.tag) {
          removeFromQuestionMap({
            statePath: state.surveyQuestionTags,
            key: deletedQuestion.tag,
            token: questionToken,
          })
        }
        state.isModified = true
      }
    },
    addDependentSurveyQuestion(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        questionToken: string
        questionConfig: { required: boolean; token: string; type: SurveyQuestionTypeEnum }
      }>
    ) {
      const { questionConfig, actionSlug, taskSlug, questionToken } = reduxAction.payload
      const task = getSurveyTask(state, { actionSlug, taskSlug })
      if (task) {
        let parentQuestionIndex = -1
        const sectionIndex = task.survey.sections.findIndex((section) => {
          parentQuestionIndex = section.questions.findIndex((q) => q.token === questionToken)
          return parentQuestionIndex !== -1
        })
        const parentQuestion = task.survey.sections[sectionIndex].questions[parentQuestionIndex]
        const parentSelectOption = parentQuestion?.options?.[0] || null
        // only create a new dependent question if the parent question is a select question
        if (parentSelectOption) {
          const newQuestion: QuestionWithDependentFieldsFragment = {
            __typename: 'SurveyQuestion' as const,
            parentQuestion,
            multivalued: false,
            multiline: null,
            style: SurveyQuestionStyleEnum.Default,
            options: [],
            isDependentQuestion: true,
            fileUploadConfiguration: null,
            parentSelectOption: {
              __typename: 'SurveyQuestionSelectOption' as const,
              label: parentSelectOption?.label || '',
              token: parentSelectOption?.token || '',
            },
            numberProps: null,
            reportAs: null,
            tag: null,
            text: 'Label',
            ...questionConfig,
          }
          const nextQuestions = [...task.survey.sections[sectionIndex].questions]
          nextQuestions.splice(parentQuestionIndex + 1, 0, newQuestion)

          task.survey.sections[sectionIndex].questions = nextQuestions
          state.isModified = true
        }
      }
    },
    reorderSurveyQuestion(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        source: DraggableLocation
        destination: DraggableLocation
      }>
    ) {
      const { source, destination, actionSlug, taskSlug } = reduxAction.payload
      const task = getSurveyTask(state, { actionSlug, taskSlug })
      if (!task) {
        return
      }
      const startSectionIndex = source.droppableId.match(/\d+/g)?.[0]
      const finishSectionIndex = destination.droppableId.match(/\d+/g)?.[0]

      // if the question is moved within the same section
      if (startSectionIndex === finishSectionIndex) {
        const sectionIndex = Number(startSectionIndex)
        // the destination and source index actually refer to the index of the top level question
        const topLevelQuestions = getTopLevelQuestions(task.survey.sections[sectionIndex].questions)
        const nextTopLevelQuestions = moveItemInList(topLevelQuestions, source.index, destination.index)
        // now that the have the top level questions in the correct order, we need to place the dependent questions
        // in the right order
        const dependentQuestionsByParentToken = keyByParentQuestionToken({
          questions: task.survey.sections[sectionIndex].questions,
        })
        const nextQuestionsWithDependentQuestions = getDependentQuestions(
          [],
          nextTopLevelQuestions,
          dependentQuestionsByParentToken
        )

        task.survey.sections[sectionIndex].questions = nextQuestionsWithDependentQuestions
        state.isModified = true
      }

      // if the question is moved to another section
      if (startSectionIndex && finishSectionIndex && startSectionIndex !== finishSectionIndex) {
        const startSectionIndexNumber = Number(startSectionIndex)
        const finishSectionIndexNumber = Number(finishSectionIndex)
        const dependentQuestionsByParentToken = keyByParentQuestionToken({
          questions: [
            ...task.survey.sections[startSectionIndexNumber].questions,
            ...task.survey.sections[finishSectionIndexNumber].questions,
          ],
        })
        // remove the question from the start section
        const startTopLevelQuestions = getTopLevelQuestions(task.survey.sections[startSectionIndexNumber].questions)
        const [question] = startTopLevelQuestions.splice(source.index, 1)
        task.survey.sections[startSectionIndexNumber].questions = getDependentQuestions(
          [],
          startTopLevelQuestions,
          dependentQuestionsByParentToken
        )
        // add the question to the finish section
        const finishTopLevelQuestions = getTopLevelQuestions(task.survey.sections[finishSectionIndexNumber].questions)
        finishTopLevelQuestions.splice(destination.index, 0, question)
        task.survey.sections[finishSectionIndexNumber].questions = getDependentQuestions(
          [],
          finishTopLevelQuestions,
          dependentQuestionsByParentToken
        )
        state.isModified = true
      }
    },
    reorderDependentSurveyQuestion(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        taskSlug: string
        source: DraggableLocation
        destination: DraggableLocation
        questionToken: string
      }>
    ) {
      const { source, destination, actionSlug, taskSlug } = reduxAction.payload
      const task = getSurveyTask(state, { actionSlug, taskSlug })
      if (!task) {
        return
      }

      const [startSectionIndex, startParentQuestionToken, startParentSelectOptionToken] = source.droppableId.split('--')
      const [finishSectionIndex, finishParentQuestionToken, finishParentSelectOptionToken] =
        destination.droppableId.split('--')

      const startSectionIndexNumber = Number(startSectionIndex)
      const finishSectionIndexNumber = Number(finishSectionIndex)
      const relevantQuestions =
        startSectionIndexNumber === finishSectionIndexNumber
          ? [...task.survey.sections[startSectionIndexNumber].questions]
          : [
              ...task.survey.sections[startSectionIndexNumber].questions,
              ...task.survey.sections[finishSectionIndexNumber].questions,
            ]

      const dependentQuestionsByParentTokenAndParentSelectToken = keyByParentQuestionTokenAndOptionToken({
        questions: relevantQuestions,
      })

      const startSubset =
        dependentQuestionsByParentTokenAndParentSelectToken[startParentQuestionToken]?.[startParentSelectOptionToken]

      if (!startSubset) return

      // if the start and finish parentSelectOption are the same,
      // meaning we are just reordering questions within the same subset
      if (startParentSelectOptionToken === finishParentSelectOptionToken) {
        dependentQuestionsByParentTokenAndParentSelectToken[startParentQuestionToken][startParentSelectOptionToken] =
          moveItemInList(startSubset, source.index, destination.index)

        // otherwise, we need to remove the question from the start subset
        // and add it to the finish subset
      } else if (startParentSelectOptionToken !== finishParentSelectOptionToken) {
        // removing from start subset
        const [question] = startSubset.splice(source.index, 1)
        const parentQuestion = relevantQuestions.find((q) => q.token === finishParentQuestionToken) || null
        const parentSelectOption =
          parentQuestion?.options?.find((o) => o.token === finishParentSelectOptionToken) || null
        // edit question so that it has the correct parentQuestion and parentSelectOption
        question.parentQuestion = parentQuestion
        question.parentSelectOption = parentSelectOption
          ? {
              ...parentSelectOption,
              __typename: 'SurveyQuestionSelectOption' as const,
            }
          : null

        // add the question to the finish parentSelectOption subset
        const finishSubset =
          dependentQuestionsByParentTokenAndParentSelectToken[finishParentQuestionToken]?.[
            finishParentSelectOptionToken
          ]
        if (finishSubset) {
          finishSubset.splice(destination.index, 0, question)
        } else if (dependentQuestionsByParentTokenAndParentSelectToken[finishParentQuestionToken]) {
          dependentQuestionsByParentTokenAndParentSelectToken[finishParentQuestionToken][
            finishParentSelectOptionToken
          ] = [question]
        } else {
          dependentQuestionsByParentTokenAndParentSelectToken[finishParentQuestionToken] = {
            [finishParentSelectOptionToken]: [question],
          }
        }
      }
      const dependentQuestionsByParentToken = Object.entries(
        dependentQuestionsByParentTokenAndParentSelectToken
      ).reduce<Record<string, QuestionWithDependentFieldsFragment[]>>(
        (acc, [parentToken, parentSelectOptionTokenMap]) => {
          const questions = Object.values(parentSelectOptionTokenMap).flat()
          acc[parentToken] = questions
          return acc
        },
        {}
      )

      // update the redux state preserving the correct order
      // of dependent questions
      task.survey.sections[startSectionIndexNumber].questions = getDependentQuestions(
        [],
        getTopLevelQuestions(task.survey.sections[startSectionIndexNumber].questions),
        dependentQuestionsByParentToken
      )

      if (startSectionIndexNumber !== finishSectionIndexNumber) {
        task.survey.sections[finishSectionIndexNumber].questions = getDependentQuestions(
          [],
          getTopLevelQuestions(task.survey.sections[finishSectionIndexNumber].questions),
          dependentQuestionsByParentToken
        )
      }
      state.isModified = true
    },
    reorderTask(
      state,
      reduxAction: PayloadAction<{
        actionSlug: string
        source: number
        destination: number
      }>
    ) {
      const { actionSlug, source, destination } = reduxAction.payload
      const action = state.reviewType?.actions?.find((x) => x.slug === actionSlug)
      if (action) {
        const tasks = moveItemInList(action.tasks, source, destination)
        action.tasks = tasks
        state.isModified = true
      }
    },
    reorderStage(
      state,
      reduxAction: PayloadAction<{
        source: number
        destination: number
      }>
    ) {
      const { source, destination } = reduxAction.payload
      const { reviewType } = state
      if (!reviewType) return
      reviewType.actions = moveItemInList(reviewType.actions, source, destination)
      state.isModified = true
    },
    updateErrorState(
      state,
      reduxAction: PayloadAction<
        | {
            actionSlug: string
            taskSlug: string
            token: string
            hasError: boolean
            choiceToken?: undefined
          }
        | {
            actionSlug: string
            taskSlug?: undefined
            token: string
            hasError: boolean
            choiceToken: string
          }
        | {
            actionSlug: string
            taskSlug?: undefined
            token: string
            hasError: boolean
            choiceToken?: undefined
          }
        | {
            actionSlug?: undefined
            taskSlug?: undefined
            token: string
            hasError: boolean
            choiceToken?: undefined
          }
      >
    ) {
      const { actionSlug, token, hasError, taskSlug, choiceToken } = reduxAction.payload
      if (hasError) {
        if (taskSlug) {
          addToQuestionMap({ statePath: state.errorsByTask, key: taskSlug || actionSlug, token, taskSlug, actionSlug })
        } else if (choiceToken) {
          addToQuestionMap({ statePath: state.errorsByTask, key: actionSlug, token, choiceToken, actionSlug })
        } else if (actionSlug) {
          addToQuestionMap({ statePath: state.errorsByTask, key: actionSlug, token, actionSlug })
        } else {
          addToQuestionMap({ statePath: state.errorsByTask, key: token, token })
        }
      } else {
        removeFromQuestionMap({ statePath: state.errorsByTask, key: taskSlug || actionSlug || token, token })
      }
    },
  },
})

export const { actions } = editReviewTypeFlowSlice

export default editReviewTypeFlowSlice.reducer
