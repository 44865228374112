import { Dispatch, SetStateAction } from 'react'

import { Resizable } from 're-resizable'

import { TableColumnElement } from './TableColumn'
import { MINIMUM_BATCH_COLUMN_WIDTH } from './TableRow.styles'

export const getField = (column: TableColumnElement) => column.props.field
export const getIsColumnDragDisabled = (column: TableColumnElement) => !!column.props.isDragDisabled
export const getIsColumnResizeDisabled = (column: TableColumnElement) => !!column.props.isResizeDisabled

export type TableWithReorderableColumnsClassKey =
  | 'dragging'
  | 'draggable'
  | 'table'
  | 'lastNonStickyHeader'
  | 'hiddenTableHeader'
  | 'visibleTableHeader'
  | 'batchColumnEnabled'
  | 'autoTableLayout'
  | 'fixedTableLayout'

export type OnColumnResizeStop = (
  fieldName: string,
  resizeAmount: number,
  minWidth: number,
  resizableRef: Resizable | null
) => void
export type TableColWidthsState = { [field: string]: number }
export type SetTableColWidthsState = Dispatch<SetStateAction<TableColWidthsState>>

/**
 * Renders a temporary `MeasurementColumn` to get the "natural" width of a column,
 * when a column is double-clicked for resizing it to fit the contents.
 * Uses ReactDOM.render to work outside the main render tree.
 */
export type RenderMeasurementColumn = (column: TableColumnElement, container: HTMLDivElement) => Promise<unknown>

export const haveColumnsChanged = (prev: TableColumnElement[], curr: TableColumnElement[]) => {
  if (prev.length !== curr.length) return true
  const currMap = curr.reduce(
    (acc, val) => {
      const fieldName = getField(val)
      acc[fieldName] = true
      return acc
    },
    {} as Record<string, true>
  )
  const everyPrevValuePersisted = prev?.every((prevVal) => {
    const fieldName = getField(prevVal)
    const isPrevValInCurr = currMap[fieldName]
    delete currMap[fieldName]
    return isPrevValInCurr
  })
  const newValues = !!Object.keys(currMap).length
  return newValues || !everyPrevValuePersisted
}

export const sumAllColWidths = (colWidths: Record<string, number>) =>
  Object.values(colWidths).reduce((sum, val) => sum + val, 0)

export const getIsSecondColTooLongToBeSticky = (
  batchColumnEnabled: boolean,
  numColumns: number,
  colWidth: number,
  index: number,
  availableWidth?: number,
  batchColWidth = MINIMUM_BATCH_COLUMN_WIDTH
) => {
  const isSecond = index === 1
  const isSecondSticky = isSecond && batchColumnEnabled && numColumns > 2
  const someMarginOfError = 50
  return isSecondSticky && colWidth >= (availableWidth ?? 0) - (batchColWidth + someMarginOfError)
}
