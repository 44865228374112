// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { AutomationsHeaderNav } from 'components/pages/automations/AutomationsHeaderNav'

const Root = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  width: '100%',
  maxWidth: '100%',
}))

const Container = styled('div')(({ theme }) => ({
  height: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.light,
  borderRadius: 10,
  margin: theme.spacing(2),
  padding: 0,
  position: 'relative',
  maxWidth: '100%',
  boxSizing: 'border-box',
  // Contain shadow of footer
  // Not `overflow: hidden;` so that position sticky can work for the footer
  contain: 'paint',
}))

const Main = styled('main')(({ theme }) => ({
  height: '100%',
  marginTop: theme.spacing(3),
  padding: theme.spacing(0, 3, 3),
  maxWidth: '100%',
}))

export const AutomationsTabLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Root>
      <Container>
        <AutomationsHeaderNav />
        <Main>{children}</Main>
      </Container>
    </Root>
  )
}
