import { css } from '@emotion/css'
// eslint-disable-next-line no-restricted-imports
import { autocompleteClasses, inputAdornmentClasses, inputBaseClasses, styled, useTheme } from '@mui/material'

import classnames from 'classnames'

import { getIn, useFormikContext } from 'formik'

import { useSelector } from 'actions/store'
import { BaseAutocomplete } from 'components/HbComponents/Form/Inputs/Autocomplete/Autocomplete'
import { NumberInputV2 } from 'components/HbComponents/Form/Inputs/NumberInputV2'
import { SelectDropdownInput } from 'components/HbComponents/Form/Inputs/SelectDropdownInput'
import { useInlineFieldStyles } from 'components/entities/Edit/FieldEdit'
import {
  LabeledField,
  SelectField,
  NumberField,
  TextField,
  DateField,
  CurrencyLabeledFieldAutocompleteInput,
  datePickerTextFieldRootClassName,
} from 'components/material/Form'
import { DateOrTimeField, HbFormikDateOrTimePicker } from 'components/material/Form/DateOrTimeField'
import { getEnum } from 'helpers/stateHelpers'
import { useFeatureFlag } from 'hooks'
import { CustomFieldDatatypeEnum, FeatureFlag } from 'types/api'

import { FormikOtherInfoDatePicker } from './FormikOtherInfoDatePicker'
import { FormikOtherInfoTextArea } from './FormikOtherInfoTextArea'

const yesnoOptions = [
  { display: 'Yes', value: 'yes' },
  { display: 'No', value: 'no' },
]

// NOTE: the following have styles applied in SuperInfoTransactionFormDialog
export const StyledSelectDropdownInput = styled(SelectDropdownInput)(() => ({}))
export const StyledAutocomplete = styled(BaseAutocomplete)(() => ({}))
export const StyledNumberInputV2 = styled(NumberInputV2)(() => ({}))
export const StyledHbFormikDateOrTimePicker = styled(HbFormikDateOrTimePicker)(() => ({}))

// used only for currency field; due to the extra padding added on top & bottom, we need to override the top padding
// from 4px to 1px
const customTopPadding = css({
  [`& .${autocompleteClasses.inputRoot} .${inputBaseClasses.input}.${inputBaseClasses.inputSizeSmall}`]: {
    paddingTop: '1px',
  },
})

// TODO(bwe) use CustomFieldLabelRowFragment where possible https://thecharm.atlassian.net/browse/PROD-18927
interface CustomFieldValueProps {
  label: string
  inputName: string
  datatype?: CustomFieldDatatypeEnum | null
  options?: string[] | null
  allowDecimal?: boolean | null
  allowNegative?: boolean | null
  allowTime?: boolean | null
  disabled?: boolean
  hideLabel?: boolean
  labeledFieldVariant?: boolean
  className?: string
}

export const CustomFieldValue = ({
  inputName,
  label,
  datatype,
  options,
  allowDecimal = null,
  allowNegative = null,
  allowTime = null,
  disabled,
  hideLabel,
  labeledFieldVariant,
  className,
}: CustomFieldValueProps) => {
  const dropdownOptions = (options || []).map((l) => {
    return { display: l, value: l }
  })
  const customFieldDatatypesEnabled = useFeatureFlag(FeatureFlag.SuperInfoDataTypes)
  const { fill, second } = useInlineFieldStyles()
  const theme = useTheme()

  const currencyOptions = useSelector((state) => getEnum(state, 'currencyInputCode'))
  const currencyOptionLabels = currencyOptions?.map((o) => o.display)
  const { values, handleBlur, setFieldValue } = useFormikContext()

  if (customFieldDatatypesEnabled && datatype === 'date') {
    if (labeledFieldVariant) {
      const inputAdornmentClassName = css({
        [`& .${inputAdornmentClasses.root}`]: {
          maxHeight: '26px',
        },
      })

      if (allowTime) {
        return (
          <LabeledField
            FieldComponent={DateOrTimeField}
            classes={{ root: classnames(fill, second, inputAdornmentClassName, className) }}
            disabled={disabled}
            label={label}
            name={inputName}
            hideLabel
            requireTime
          />
        )
      }

      const dateFieldClassName = css({
        [`& .${datePickerTextFieldRootClassName}`]: {
          paddingBottom: theme.spacing(2),
        },
      })

      return (
        <LabeledField
          FieldComponent={DateField}
          classes={{ root: classnames(fill, second, dateFieldClassName, inputAdornmentClassName, className) }}
          disabled={disabled}
          name={inputName}
          dateOnly
          fullWidth
        />
      )
    }

    if (allowTime) {
      return <StyledHbFormikDateOrTimePicker name={inputName} label={label} requireTime disabled={disabled} />
    }

    return <FormikOtherInfoDatePicker name={inputName} label={label} disabled={disabled} />
  }

  if (customFieldDatatypesEnabled && datatype === 'currency') {
    if (labeledFieldVariant) {
      return (
        <CurrencyLabeledFieldAutocompleteInput
          classes={{ root: classnames(fill, second, customTopPadding, className) }}
          name={inputName}
          disabled={disabled}
          hideLabel
        />
      )
    }

    return (
      <StyledAutocomplete
        key={label}
        value={getIn(values, inputName)}
        label={label}
        options={currencyOptionLabels}
        disabled={disabled}
        onInputChange={(newCurrencyValue: string) => setFieldValue(inputName, newCurrencyValue)}
        onBlur={handleBlur}
        setValueOnBlur
        freeSolo
        onChange={() => {}} // We want to rely on onInputChange to update the value
      />
    )
  }

  const labeledFieldVariantClasses = { root: classnames(fill, second, className) }

  if (customFieldDatatypesEnabled && datatype === 'dropdown') {
    if (labeledFieldVariant) {
      return (
        <LabeledField
          name={inputName}
          FieldComponent={SelectField}
          classes={labeledFieldVariantClasses}
          options={dropdownOptions}
          placeholder="Select one..."
          disabled={disabled}
        />
      )
    }
    return (
      <StyledSelectDropdownInput
        key={label}
        label={label}
        name={inputName}
        options={dropdownOptions}
        disabled={disabled}
      />
    )
  }

  if (customFieldDatatypesEnabled && datatype === 'yesno') {
    if (labeledFieldVariant) {
      return (
        <LabeledField
          name={inputName}
          FieldComponent={SelectField}
          classes={labeledFieldVariantClasses}
          options={yesnoOptions}
          placeholder="Select one..."
          disabled={disabled}
        />
      )
    }
    return (
      <StyledSelectDropdownInput
        key={label}
        label={label}
        name={inputName}
        options={yesnoOptions}
        disabled={disabled}
      />
    )
  }

  if (customFieldDatatypesEnabled && datatype === 'number') {
    const decimalProps = allowDecimal ? {} : { decimalScale: 0 }
    const negativeProps = allowNegative === null ? { allowNegative: false } : { allowNegative }

    if (labeledFieldVariant) {
      return (
        <LabeledField
          name={inputName}
          FieldComponent={NumberField}
          classes={labeledFieldVariantClasses}
          disabled={disabled}
          numberProps={{ ...decimalProps, ...negativeProps }}
        />
      )
    }
    return (
      <StyledNumberInputV2
        key={label}
        label={label}
        name={inputName}
        disabled={disabled}
        numberProps={{ ...decimalProps, ...negativeProps }}
      />
    )
  }

  if (labeledFieldVariant) {
    return (
      <LabeledField
        name={inputName}
        FieldComponent={TextField}
        classes={labeledFieldVariantClasses}
        placeholder="Value"
        disabled={disabled}
      />
    )
  }

  return <FormikOtherInfoTextArea label={label} name={inputName} disabled={disabled} hideLabel={hideLabel} />
}
