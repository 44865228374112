import { useEffect } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { useDispatch, useSelector } from 'actions/store'
import { HbText } from 'components/HbComponents/Text/HbText'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { CombinatorEnum, OperatorEnum } from 'types/api'

import { Theme } from 'types/hb'

import { isSearchFilterWithId, SearchGroupWithId } from 'utils/query/api.types'

import { AddButton, DeleteButton } from '../sharedComponents'

import CombinatorSelect from './CombinatorSelect'
import FilterEntry from './FilterEntry'
import { StyledAutocomplete } from './fields/SearchableFilterField'

const useStyles = makeStyles((theme: Theme) => ({
  noFiltersText: { padding: `${theme.spacing(1)} ${theme.spacing(4)}`, gridColumnStart: 1, gridColumnEnd: -1 },
  groupCombinator: { alignSelf: 'start' },
  deleteGroup: { paddingRight: theme.spacing() },
  buttonRow: { gridColumnStart: 1, gridColumnEnd: -1 },
}))

interface FilterGroupProps {
  // required
  index: number
  // callbacks
  onDelete: (id?: string) => void
  onParentCombinatorChange: (combinator: CombinatorEnum) => void
  // optional
  addButtonStyles?: string
  groupId?: string
  parentCombinator?: CombinatorEnum
}

const FilterGroupContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  grid: 'auto-flow minmax(40px, max-content) / 100px auto auto 1fr 48px',
  gap: theme.spacing(1, 0.5),
  minWidth: theme.spacing(75),

  gridColumnStart: 2,
  gridColumnEnd: -2,

  backgroundColor: theme.palette.background.contrastMedium,
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(),
  borderRadius: 10,

  [`& ${StyledAutocomplete}`]: {
    maxWidth: 300,
  },
}))

const FilterGroup = ({
  index,
  groupId = '',
  onDelete,
  onParentCombinatorChange,
  parentCombinator,
  addButtonStyles,
}: FilterGroupProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isInitialParent = index === -1
  const dashboardsSelectors = useDashboardSelectors()
  const dashboardsActions = useDashboardActions()
  const group = useSelector((state) => dashboardsSelectors.filters.byGroupId(state, groupId))
  const groupCombinator = group?.combinator || CombinatorEnum.And

  // TODO issue here is that onCombinatorChange is causing a rerender, wiping out the new entry
  const onCombinatorChange = (newGroupCombinator: CombinatorEnum) => {
    if (groupCombinator !== newGroupCombinator) {
      dispatch(
        dashboardsActions.filters.updateGroupById({
          _type: 'SearchGroupWithId',
          children: group?.children ?? [],
          id: groupId,
          combinator: newGroupCombinator,
        })
      )
    }
  }

  useEffect(() => {
    // initial parent group shouldn't run effects
    if (isInitialParent) return
    if (!group) {
      dispatch(
        dashboardsActions.filters.createComponentById.action({
          _type: 'SearchGroupWithId',
          parentID: isInitialParent ? groupId : '',
          id: '', // ID will be created by the reducer
          combinator: groupCombinator,
          children: [],
        })
      )
      onDelete()
    }
  }, [dispatch, group, groupCombinator, groupId, isInitialParent, onDelete, dashboardsActions])

  const internalProps: InternalProps = {
    group,
    onParentCombinatorChange,
    onCombinatorChange,
    groupCombinator,
    isInitialParent,
    onDelete,
    addButtonStyles,
  }
  // Index of -1 indicates this is the global parent group for the search query
  if (isInitialParent) {
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <Internals {...internalProps} />
    )
  }

  return (
    <>
      <CombinatorSelect
        onChange={onParentCombinatorChange}
        index={index}
        combinator={parentCombinator}
        styles={classes.groupCombinator}
      />
      <FilterGroupContainer>
        {!group || group?.children.length === 0 ? (
          <div className={classes.noFiltersText}>
            <HbText color="disabled" size="md" noSelection>
              No filter conditions added to this filter group yet
            </HbText>
          </div>
        ) : (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <Internals {...internalProps} />
        )}
        <div className={classnames(addButtonStyles, classes.buttonRow)}>
          <AddButton
            label="Add filter"
            onClick={() => {
              // create initial 'invalid' filter entry
              dispatch(
                dashboardsActions.filters.createComponentById.action({
                  _type: 'SearchFilterWithId',
                  parentID: group?.id,
                  id: '',
                  field: '',
                  predicate: { values: [], operator: OperatorEnum.Is },
                })
              )
            }}
          />
        </div>
      </FilterGroupContainer>
      <div className={classes.groupCombinator}>
        <DeleteButton onClick={() => onDelete(group?.id)} />
      </div>
    </>
  )
}

interface InternalProps {
  group?: SearchGroupWithId
  onParentCombinatorChange: (combinator: CombinatorEnum) => void
  onCombinatorChange: (combinator: CombinatorEnum) => void
  groupCombinator: CombinatorEnum
  isInitialParent: boolean
  onDelete: (id?: string) => void
  addButtonStyles?: string
}

function Internals({
  group,
  onParentCombinatorChange,
  onCombinatorChange,
  groupCombinator,
  isInitialParent,
  onDelete,
  addButtonStyles,
}: InternalProps) {
  return (
    <>
      {group?.children.map((child, childIndex) =>
        isSearchFilterWithId(child) ? (
          <FilterEntry
            key={child.id}
            index={childIndex}
            onCombinatorChange={(newCombo) =>
              isInitialParent ? onParentCombinatorChange(newCombo) : onCombinatorChange(newCombo)
            }
            parentCombinator={groupCombinator}
            filter={child}
            parentID={group.id}
            deleteHandler={onDelete}
          />
        ) : (
          <FilterGroup
            key={child.id}
            index={childIndex || 0}
            parentCombinator={groupCombinator}
            onParentCombinatorChange={onCombinatorChange}
            groupId={child.id}
            addButtonStyles={addButtonStyles}
            onDelete={onDelete}
          />
        )
      )}
    </>
  )
}

export default FilterGroup
