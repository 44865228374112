import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/styles'

import { Box } from '@mui/system'

import { HbText } from 'components/HbComponents/Text/HbText'
import AutomationEnabledPill from 'components/pages/automations/AutomationEnabledPill'

import { fromNow, properCase } from 'helpers/uiHelpers'
import { useFeatureFlag } from 'hooks'
import { useDateFormatter } from 'hooks/DateFormatHooks'

import { FeatureFlag } from 'types/api'

import { ViewAutomationInnerAutomationRuleFragment as AutomationRule } from '../__generated__/ViewAutomation.queries.generated'

import { NotesSection } from './NotesSection'

const DescriptionListItem = ({ label, value }: { label: string; value: ReactNode }) => {
  const theme = useTheme()
  return (
    <>
      <HbText tag="dt" size="s" bold color="disabled">
        {label}
      </HbText>
      <HbText tag="dd" size="s" color="emphasized" css={{ margin: theme.spacing(0.25, 0, 2) }}>
        {value}
      </HbText>
    </>
  )
}

export const AutomationOverviewPanel = ({ automationRule }: { automationRule: AutomationRule }) => {
  const theme = useTheme()
  const {
    latestExecution,
    usageCount,
    usagePercentage,
    enabled,
    unsampledCount,
    sampleRate,
    createdAt,
    createdBy,
    updatedAt,
  } = automationRule

  const usageText = usagePercentage === 0 ? usageCount : `${usageCount} (${usagePercentage}% of Total Usage)`
  const effectiveSamplingRateText =
    usageCount > 0 ? `${((usageCount / (usageCount + unsampledCount)) * 100).toFixed()}%` : '-'
  const formatTimestamp = useDateFormatter('LLL')
  const areNotesEnabled = useFeatureFlag(FeatureFlag.EnableAutomationRecipeNotes)
  return (
    <Box sx={{ px: 2 }}>
      <section>
        <HbText size="lg" tag="h3" bold css={{ margin: theme.spacing(3, 0, 2) }}>
          Usage
        </HbText>

        <dl>
          <DescriptionListItem
            label="Status"
            value={<AutomationEnabledPill enabled={enabled} executionMode={automationRule.executionMode} />}
          />
          <DescriptionListItem label="Usage" value={usageText} />

          {sampleRate != null && (
            <>
              <DescriptionListItem label="Unsampled Count" value={unsampledCount} />
              <DescriptionListItem label="Effective Sampling Rate" value={effectiveSamplingRateText} />
            </>
          )}
          <DescriptionListItem
            label="Last Usage"
            value={latestExecution ? properCase(fromNow(latestExecution.createdAt)) : 'Never'}
          />
        </dl>
      </section>
      <section>
        <HbText
          size="lg"
          tag="h3"
          bold
          css={{
            margin: theme.spacing(3, 0, 2),
            paddingTop: theme.spacing(3),
            borderTop: `1px solid ${theme.palette.styleguide.borderLight}`,
          }}
        >
          Details
        </HbText>
        <dl>
          <DescriptionListItem label="Added" value={formatTimestamp(createdAt)} />
          <DescriptionListItem label="Added By" value={createdBy.fullName} />
          <DescriptionListItem label="Last Edit" value={formatTimestamp(updatedAt)} />
        </dl>
      </section>
      {areNotesEnabled && <NotesSection automationRule={automationRule} />}
    </Box>
  )
}
