import { ComponentType, ReactNode, useCallback, useMemo } from 'react'

import { VerifiedUser } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { HbRadio } from 'components/HbComponents/Form/HbRadio'
import { HbTagListWithOverflow } from 'components/HbComponents/HbTag'
import { HbText } from 'components/HbComponents/Text/HbText'

import { PersonEntity, getEntityCaseAndAlertCount } from 'components/cases/Tabs/Library/CaseLibraryHelpers'
import { displayTypeOfEntry } from 'components/entities/LibraryQueries'
import { LibraryObjectIcon } from 'components/library/LibraryObjectIcon'
import { useResizeObserver } from 'components/library/Resize/hooks'
import { formatTin } from 'helpers/uiHelpers'
import { TinTypeStrings } from 'strings/strings'
import { Tin } from 'types/api'
import { Theme } from 'types/hb'

import { MergeEntity } from '../types'

const useCompareTableLayoutStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    borderCollapse: 'collapse',
    border: 'none',
    minWidth: 600,
    tableLayout: 'fixed',
    width: '100%',
  },
  row: {},
  cell: {
    verticalAlign: 'top',
    height: 1,
    '$row > &': {
      padding: theme.spacing(0, 1),

      '&:first-child': {
        paddingLeft: theme.spacing(1.5),
      },
      '&:last-child': {
        paddingRight: theme.spacing(1.5),
      },
    },
  },
  cellContent: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(),
    boxSizing: 'border-box',
    borderLeft: '2px solid transparent',
    borderRight: '2px solid transparent',

    '$row:first-of-type &': {
      paddingTop: 0,
    },
  },
  firstRowCellContent: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderTop: '2px solid transparent',
  },
  lastRowCellContent: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottom: '2px solid transparent',
  },
  selectedCellContent: {
    borderLeft: `2px solid ${theme.palette.action.active}`,
    borderRight: `2px solid ${theme.palette.action.active}`,
  },
  firstRowSelectedCellContent: {
    borderTop: `2px solid ${theme.palette.action.active}`,
  },
  lastRowSelectedCellContent: {
    borderBottom: `2px solid ${theme.palette.action.active}`,
  },
}))

export const CompareTableLayout = <T extends { token: string }, Row extends string>({
  contentComponents,
  data,
  rows,
  selectedIndex,
}: {
  contentComponents: Record<Row, ComponentType<{ datum: T }>>
  data: T[]
  rows: readonly Row[]
  selectedIndex?: number | undefined
}) => {
  const classes = useCompareTableLayoutStyles()
  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <tbody>
          {rows.map((row, rowIndex) => {
            const isFirstRow = rowIndex === 0
            const isLastRow = rowIndex === rows.length - 1
            const Component = contentComponents[row] as ComponentType<{ datum: T }>
            return (
              <tr key={row} className={classes.row}>
                {data.map((datum, i) => {
                  const isSelected = selectedIndex === i
                  return (
                    <td key={`${row}_${datum.token}`} className={classes.cell}>
                      <div
                        className={classNames(
                          classes.cellContent,
                          isFirstRow ? classes.firstRowCellContent : isLastRow ? classes.lastRowCellContent : undefined,
                          isSelected && classes.selectedCellContent,
                          isSelected &&
                            (isFirstRow
                              ? classes.firstRowSelectedCellContent
                              : isLastRow
                                ? classes.lastRowSelectedCellContent
                                : undefined)
                        )}
                      >
                        <Component datum={datum} />
                      </div>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const useSharedEntityHeaderStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    padding: theme.spacing(2, 0, 0),
    boxSizing: 'border-box',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: theme.spacing(0.5),
    flex: '1 1 180px',
    height: '100%',
  },
  entityIcon: {
    width: 30,
    height: 30,
    padding: theme.spacing(1.5),
    marginLeft: theme.spacing(-1),
    borderRadius: 30,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.medium,
  },
  entityType: {
    textTransform: 'uppercase',
  },
  nameAndAdornment: {
    display: 'flex',
    columnGap: theme.spacing(),
  },
  tagsList: {
    flex: '1 1 200px',
    justifyContent: 'flex-end',
  },
  tagsListItem: {
    flex: '0 1 fit-content',
    width: 0,
  },
}))

type BaseSharedEntityHeaderProps = {
  adornment?: ReactNode
  entity: MergeEntity
  readOnly?: boolean
}

type ReadOnlySharedEntityHeaderProps = BaseSharedEntityHeaderProps & {
  readOnly: true
}

type SelectableSharedEntityHeaderProps = BaseSharedEntityHeaderProps & {
  disabled?: boolean
  readOnly?: false
  isSelected: boolean
  setSelected: () => void
}

type SharedEntityHeaderProps = ReadOnlySharedEntityHeaderProps | SelectableSharedEntityHeaderProps

export const SharedEntityHeader = (props: SharedEntityHeaderProps) => {
  const classes = useSharedEntityHeaderStyles()

  const { adornment, entity, readOnly } = props

  let radioEl = null
  if (!readOnly) {
    const { disabled, isSelected, setSelected } = props
    radioEl = (
      <HbRadio
        autoFocus
        aria-label={entity.displayName}
        checked={isSelected}
        disabled={disabled}
        onChange={setSelected}
      />
    )
  }

  const tags = useMemo(() => entity.tags.map((tag) => tag.tagDefinition), [entity.tags])
  return (
    <section className={classes.container} aria-label="Identification">
      {radioEl}
      <LibraryObjectIcon className={classes.entityIcon} libraryObject={entity} noHover />
      <div className={classes.details}>
        <HbText className={classes.entityType} color="secondary" size="s">
          {displayTypeOfEntry(entity)}
        </HbText>
        <div className={classes.nameAndAdornment}>
          <HbText bold>{entity.displayName}</HbText>
          {adornment}
        </div>
        <HbText color="secondary" size="s">
          {getEntityCaseAndAlertCount(entity)}
        </HbText>
      </div>
      <HbTagListWithOverflow classes={{ list: classes.tagsList, listItem: classes.tagsListItem }} tags={tags} max={3} />
    </section>
  )
}

const useDetailListItemStyles = makeStyles((theme: Theme) => ({
  detail: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'flex-start',
    columnGap: theme.spacing(2),
    paddingLeft: 0,
    '&:last-of-type': {
      paddingBottom: 0,
    },
  },
  detailEmpty: {
    color: theme.palette.styleguide.greyE,
  },
}))

interface DetailListItemProps {
  children: ReactNode
  Icon: ComponentType
  isEmpty: boolean
  minHeight?: number
}

export const DetailListItem = ({ children, Icon, isEmpty }: DetailListItemProps) => {
  const classes = useDetailListItemStyles()
  return (
    <li className={classNames(classes.detail, { [classes.detailEmpty]: isEmpty })}>
      <Icon />
      {children}
    </li>
  )
}

interface SimpleDetailProps {
  Icon: ComponentType
  fallbackLabel?: string
  value: string | null | undefined
}

export const SimpleDetail = ({ Icon, fallbackLabel, value }: SimpleDetailProps) => {
  return (
    <DetailListItem Icon={Icon} isEmpty={!value}>
      <HbText color="inherit">{value ?? fallbackLabel}</HbText>
    </DetailListItem>
  )
}

const useDetailsSectionStyles = makeStyles((theme: Theme) => ({
  detailsSection: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.styleguide.gray300}`,
    borderRadius: theme.shape.largeContainer.borderRadius,
    height: '100%',
    boxSizing: 'border-box',
  },
  detailsList: {
    padding: theme.spacing(1, 0, 0),
  },
}))

export const DetailsSection = ({
  className,
  children,
  heading,
}: {
  className?: string
  children: ReactNode
  heading: string
}) => {
  const classes = useDetailsSectionStyles()
  return (
    <section className={classNames(classes.detailsSection, className)} aria-label={heading}>
      <HbText bold tag="h5">
        {heading}
      </HbText>
      <ul className={classes.detailsList}>{children}</ul>
    </section>
  )
}

const useListDetailStyles = makeStyles((theme: Theme) => ({
  listItemText: {
    margin: theme.spacing(1, 0),
    '&:first-of-type': {
      marginTop: theme.spacing(0),
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(0),
    },
  },
}))

interface ListDetailProps<T> {
  fallbackLabel: string
  getDisplayValue?: (value: T) => ReactNode
  Icon: ComponentType
  minHeight?: number
  setMinHeight?: (minHeight: number) => void
  values: T[] | null
}

export const ListDetail = <T,>({
  fallbackLabel,
  getDisplayValue = (value: unknown) => value,
  Icon,
  minHeight = 0,
  setMinHeight,
  values,
}: ListDetailProps<T>) => {
  const classes = useListDetailStyles()
  const handleResize = useCallback(
    ([{ target }]) => {
      if (!setMinHeight) return
      if (target.clientHeight > minHeight) {
        setMinHeight(target.clientHeight)
      }
    },
    [minHeight, setMinHeight]
  )
  const { getRef } = useResizeObserver(handleResize)
  const adaptiveHeightProps = setMinHeight ? { ref: getRef, style: { minHeight } } : {}
  return (
    <DetailListItem Icon={Icon} isEmpty={!values?.length}>
      {values?.length ? (
        <ul {...adaptiveHeightProps}>
          {values.map((value, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <HbText className={classes.listItemText}>{getDisplayValue(value)}</HbText>
            </li>
          ))}
        </ul>
      ) : (
        <HbText {...adaptiveHeightProps} color="inherit">
          {fallbackLabel}
        </HbText>
      )}
    </DetailListItem>
  )
}

const useTinsDetailStyles = makeStyles((theme: Theme) => ({
  separator: {
    margin: theme.spacing(0, 1),
  },
}))

interface TinsDetailProps {
  tins: NonNullable<PersonEntity>['tins']
}

export const TinsDetail = ({ tins }: TinsDetailProps) => {
  const classes = useTinsDetailStyles()

  return (
    <ListDetail
      fallbackLabel="Tins"
      getDisplayValue={(tin: Tin) => {
        const { type } = tin
        return (
          <>
            <HbText tag="span">{formatTin(tin)}</HbText>
            {type && (
              <>
                <HbText className={classes.separator} color="secondary" size="s" tag="span">
                  ·
                </HbText>
                <HbText color="secondary" size="s" tag="span">
                  {TinTypeStrings[type]}
                </HbText>
              </>
            )}
          </>
        )
      }}
      Icon={VerifiedUser}
      values={tins}
    />
  )
}
