import { ChangeEvent } from 'react'

import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'

import HbTextInput, { HbTextInputProps } from './HbTextInput'

export type HbRHFTextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & HbTextInputProps

/** *
 * Drop-in component for react-hook-form
 * It expects `control` as well as the other `Controller` props
 */
function HbRHFTextInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, rules, onChange, ...rest }: HbRHFTextInputProps<TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <HbTextInput
            {...rest}
            {...field}
            onChange={(value: ChangeEvent<HTMLInputElement>) => {
              field.onChange(value)
              if (onChange) {
                onChange(value)
              }
            }}
            ref={null}
            inputRef={field.ref}
            errors={fieldState.error?.message}
          />
        )
      }}
    />
  )
}

export default HbRHFTextInput
