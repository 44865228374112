// eslint-disable-next-line no-restricted-imports
import { Box, styled } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'
import { InternalLink } from 'components/library/InternalLink'
import { RecommendedAutomations } from 'components/pages/automations/RecommendedAutomations'

const StyledInternalLink = styled(InternalLink)(() => {
  return {
    fontSize: '18px',
    fontWeight: 500,
  }
})

export const MyAutomationsEmptyState = () => {
  return (
    <>
      <Box sx={{ mt: 2, mb: 5 }}>
        <HbText size="xl" bold>
          Get started with our most popular recipes or{' '}
          <StyledInternalLink to="/automations/explore" title="explore other recipes" />
        </HbText>
      </Box>
      <RecommendedAutomations />
    </>
  )
}
