import invariant from 'tiny-invariant'

import { HbButton } from 'components/HbComponents/HbButton'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { useOrgTimeZone } from 'hooks/DateFormatHooks'

import { ChangeCircleOutlinedIcon } from 'icons'

import { AutomationRuleType } from 'types/api'

import { useAutomationAdminOperations } from '../hooks/AutomationAdminHelpers'

import SelectedOrganizationStep from './AutomationEditorSteps/OrganizationSelectComponents'

import { ViewActionAutomationStep } from './AutomationEditorSteps/ViewActionAutomationStep'
import { ViewSamplingAutomationStep } from './AutomationEditorSteps/ViewSamplingAutomationStep'
import ViewScheduledAutomationStep from './AutomationEditorSteps/ViewScheduledAutomationStep'
import { ViewTriggerAutomationStep } from './AutomationEditorSteps/ViewTriggerAutomationStep'
import Step, { StepList } from './Step'
import { FormSchemaReturnType, ScheduleSchemaReturnType } from './formSchema'

import { getDefaultScheduleValues, resetKeepDirty } from './util'

interface Props {
  setActionModalOpen: (open: boolean) => void
  form: FormSchemaReturnType
}

export function ViewAutomationSteps({ setActionModalOpen, form }: Props) {
  const { watch, getValues } = form

  const automationType = watch('automationType')
  const organizationToken = getValues('organizationToken')
  const isAutomationAdminRule = useAutomationAdminOperations(organizationToken)
  const usage = useUsage()
  const orgTimeZone = useOrgTimeZone()
  const handleChangeToScheduled = () => {
    resetKeepDirty(
      form,
      (existingData) => {
        invariant(existingData.automationType === AutomationRuleType.Trigger)

        usage.logEvent({
          name: 'automations:changeToScheduled:clicked',
          data: {
            domainType: existingData.domain.type || undefined,
            actionType: existingData.actionType || undefined,
          },
        })

        return {
          ...existingData,
          ...getDefaultScheduleValues(orgTimeZone),
          automationType: AutomationRuleType.ScheduleWithTrigger,
        }
      },
      true
    )
  }
  const handleChangeFromScheduled = () => {
    resetKeepDirty(
      form,
      (existingData) => {
        invariant(existingData.automationType === AutomationRuleType.ScheduleWithTrigger)

        usage.logEvent({
          name: 'automations:removeScheduling:clicked',
          data: {
            domainType: existingData.domain.type || undefined,
            actionType: existingData.actionType || undefined,
          },
        })

        return {
          ...existingData,
          automationType: AutomationRuleType.Trigger,
          scheduleConfig: null,
          scheduleStartOn: null,
        }
      },
      true
    )
  }

  return (
    <StepList>
      {isAutomationAdminRule ? <SelectedOrganizationStep key="select_org_section" /> : null}
      {automationType === AutomationRuleType.ScheduleWithTrigger ? (
        <ViewScheduledAutomationStep
          key="schedule_section_for_trigger"
          form={form as ScheduleSchemaReturnType}
          onChangeFromScheduled={handleChangeFromScheduled}
        />
      ) : automationType === AutomationRuleType.Schedule ? (
        <ViewScheduledAutomationStep key="schedule_section_without_trigger" form={form as ScheduleSchemaReturnType} />
      ) : (
        <Step
          key="continuous_schedule_section"
          size="lg"
          title="On Every Update"
          type="schedule"
          ActionButton={
            <HbButton
              variant="secondary"
              Icon={ChangeCircleOutlinedIcon}
              label="Change to Scheduled"
              size="small"
              onClick={handleChangeToScheduled}
            />
          }
        />
      )}
      {automationType !== AutomationRuleType.Schedule ? (
        <ViewTriggerAutomationStep key="trigger_section" form={form} />
      ) : null}
      {automationType !== AutomationRuleType.Schedule ? (
        <ViewSamplingAutomationStep key="sampling_section" form={form} />
      ) : null}
      <ViewActionAutomationStep
        key="action_section"
        form={form}
        setActionModalOpen={setActionModalOpen}
        isTriggerType={automationType !== AutomationRuleType.Schedule}
      />
    </StepList>
  )
}
