// This file is automatically generated by crm_headers_md_generator.rb

interface Header {
  header: string
  values?: string | string[]
}

interface Section {
  name: string
  headers: Header[]
}

export const crmHydraHeaders: Section[] = [
  {
    name: 'Bank Account Balance',
    headers: [
      {
        header: 'bank account balance amount',
      },
      {
        header: 'bank account balance recorded at',
        values: 'parse_date_and_time',
      },
      {
        header: 'bank account balance bank account id',
      },
    ],
  },
  {
    name: 'Bank Account',
    headers: [
      {
        header: 'bank account routing number',
      },
      {
        header: 'bank account number',
      },
      {
        header: 'bank account classification type',
        values: ['DEPOSITORY', 'LOAN', 'MORTGAGE', 'BROKERAGE', 'OTHER', 'UNKNOWN'],
      },
      {
        header: 'bank account classification type other',
      },
      {
        header: 'bank account classification subtype',
        values: [
          'CHECKING',
          'SAVINGS',
          'MARKET',
          'AUTO_LOAN',
          'HOME_LOAN',
          'HOME_EQUITY_LOAN',
          'INSTALLMENT_LOAN',
          'MORTGAGE_LOAN',
          'HOME_MORTGAGE',
          'BROKERAGE',
          'CASH_MANAGEMENT',
          'IRA',
          'CD',
          'CERTIFICATE_OF_DEPOSIT',
          'MUTUAL_FUND',
          'OTHER',
          'UNKNOWN',
        ],
      },
      {
        header: 'bank account classification subtype other',
      },
      {
        header: 'bank account opened at',
        values: 'parse_date_and_time',
      },
      {
        header: 'bank account closed at',
        values: 'parse_date_and_time',
      },
      {
        header: 'bank account holder business id',
      },
      {
        header: 'bank account holder person id',
      },
      {
        header: 'bank account financial institution id',
      },
      {
        header: 'bank account notes',
      },
      {
        header: 'bank account quick links href',
      },
      {
        header: 'bank account quick links text',
      },
      {
        header: 'bank account foreign',
      },
      {
        header: 'bank account country and state',
      },
      {
        header: 'bank account other info label',
      },
      {
        header: 'bank account other info value',
      },
      {
        header: 'bank account closed',
      },
    ],
  },
  {
    name: 'Business',
    headers: [
      {
        header: 'business legal names',
      },
      {
        header: 'business dba names',
      },
      {
        header: 'business type label',
      },
      {
        header: 'business type naics code',
      },
      {
        header: 'business email addresses',
      },
      {
        header: 'business websites',
      },
      {
        header: 'business usernames',
      },
      {
        header: 'business tins type',
      },
      {
        header: 'business tins number',
      },
      {
        header: 'business identity documents type',
      },
      {
        header: 'business identity documents type other',
      },
      {
        header: 'business identity documents number',
      },
      {
        header: 'business identity documents issuing state code',
      },
      {
        header: 'business identity documents issuing country code',
      },
      {
        header: 'business identity documents expires on',
        values: 'parse_date_parts',
      },
      {
        header: 'business identity documents nationality country code',
      },
      {
        header: 'business addresses line 1',
      },
      {
        header: 'business addresses line 2',
      },
      {
        header: 'business addresses locality',
      },
      {
        header: 'business addresses sublocality',
      },
      {
        header: 'business addresses administrative district level 1',
      },
      {
        header: 'business addresses postal code',
      },
      {
        header: 'business addresses country',
      },
      {
        header: 'business addresses address type',
      },
      {
        header: 'business addresses address type other',
      },
      {
        header: 'business phone numbers type',
      },
      {
        header: 'business phone numbers number',
      },
      {
        header: 'business phone numbers extension',
      },
      {
        header: 'business notes',
      },
      {
        header: 'business quick links href',
      },
      {
        header: 'business quick links text',
      },
      {
        header: 'business other info label',
      },
      {
        header: 'business other info value',
      },
    ],
  },
  {
    name: 'Crypto Address',
    headers: [
      {
        header: 'crypto address blockchain type',
        values: [
          'BITCOIN',
          'BITCOIN_CASH',
          'CARDANO',
          'EOS',
          'ETHEREUM',
          'LITECOIN',
          'MONERO',
          'RIPPLE',
          'OTHER',
          'UNKNOWN',
        ],
      },
      {
        header: 'crypto address blockchain type other',
      },
      {
        header: 'crypto address address',
      },
      {
        header: 'crypto address account holder business id',
      },
      {
        header: 'crypto address account holder person id',
      },
      {
        header: 'crypto address financial institution id',
      },
      {
        header: 'crypto address notes',
      },
      {
        header: 'crypto address quick links href',
      },
      {
        header: 'crypto address quick links text',
      },
      {
        header: 'crypto address other info label',
      },
      {
        header: 'crypto address other info value',
      },
    ],
  },
  {
    name: 'Device',
    headers: [
      {
        header: 'device name',
      },
      {
        header: 'device ip address',
      },
      {
        header: 'device fingerprint',
      },
      {
        header: 'device operating system type',
        values: ['WINDOWS', 'ANDROID', 'IOS', 'MAC_OS', 'LINUX', 'OTHER'],
      },
      {
        header: 'device operating system type other',
      },
      {
        header: 'device browser type',
        values: ['FIREFOX', 'INTERNET_EXPLORER', 'SAFARI', 'CHROME', 'TOR', 'OTHER'],
      },
      {
        header: 'device browser type other',
      },
      {
        header: 'device user agent',
      },
      {
        header: 'device first seen at',
        values: 'parse_date_and_time',
      },
      {
        header: 'device last seen at',
        values: 'parse_date_and_time',
      },
      {
        header: 'device owner business id',
      },
      {
        header: 'device owner person id',
      },
      {
        header: 'device notes',
      },
      {
        header: 'device quick links href',
      },
      {
        header: 'device quick links text',
      },
      {
        header: 'device other info label',
      },
      {
        header: 'device other info value',
      },
    ],
  },
  {
    name: 'Financial Institution',
    headers: [
      {
        header: 'financial institution name',
      },
      {
        header: 'financial institution alternate names',
      },
      {
        header: 'financial institution classification type',
      },
      {
        header: 'financial institution classification type other',
      },
      {
        header: 'financial institution classification subtype',
      },
      {
        header: 'financial institution classification subtype other',
      },
      {
        header: 'financial institution primary federal regulator',
      },
      {
        header: 'financial institution tin type',
      },
      {
        header: 'financial institution tin number',
      },
      {
        header: 'financial institution identification type',
      },
      {
        header: 'financial institution identification number',
      },
      {
        header: 'financial institution contact name',
      },
      {
        header: 'financial institution contact phone number',
      },
      {
        header: 'financial institution address address line 1',
      },
      {
        header: 'financial institution address address line 2',
      },
      {
        header: 'financial institution address locality',
      },
      {
        header: 'financial institution address sublocality',
      },
      {
        header: 'financial institution address administrative district level 1',
      },
      {
        header: 'financial institution address postal code',
      },
      {
        header: 'financial institution address country',
      },
      {
        header: 'financial institution address address type',
      },
      {
        header: 'financial institution address address type other',
      },
      {
        header: 'financial institution notes',
      },
      {
        header: 'financial institution quick links href',
      },
      {
        header: 'financial institution quick links text',
      },
      {
        header: 'financial institution other info label',
      },
      {
        header: 'financial institution other info value',
      },
      {
        header: 'financial institution swift code',
      },
    ],
  },
  {
    name: 'Institution Relationship',
    headers: [
      {
        header: 'institution relationship business id',
      },
      {
        header: 'institution relationship person id',
      },
      {
        header: 'institution relationship financial institution id',
      },
      {
        header: 'institution relationship type',
        values: [
          'ACCOUNTANT',
          'AGENT',
          'APPRAISER',
          'ATTORNEY',
          'BORROWER',
          'CUSTOMER',
          'DIRECTOR',
          'EMPLOYEE',
          'NONE',
          'OFFICER',
          'OWNER',
          'OTHER',
        ],
      },
      {
        header: 'institution relationship type other',
      },
      {
        header: 'institution relationship status',
        values: ['RELATIONSHIP_CONTINUES', 'SUSPENDED', 'RESIGNED', 'TERMINATED'],
      },
      {
        header: 'institution relationship actioned at',
        values: 'parse_date_and_time',
      },
    ],
  },
  {
    name: 'Payment Card',
    headers: [
      {
        header: 'payment card masked pan',
      },
      {
        header: 'payment card opened at',
        values: 'parse_date_and_time',
      },
      {
        header: 'payment card closed at',
        values: 'parse_date_and_time',
      },
      {
        header: 'payment card activated at',
        values: 'parse_date_and_time',
      },
      {
        header: 'payment card status',
      },
      {
        header: 'payment card expiration',
        values: 'parse_year_month',
      },
      {
        header: 'payment card type',
        values: ['CREDIT', 'DEBIT', 'PREPAID', 'GIFT', 'OTHER'],
      },
      {
        header: 'payment card type other',
      },
      {
        header: 'payment card network type',
        values: [
          'AMERICAN_EXPRESS',
          'CARDGUARD',
          'CHINA_UNIONPAY',
          'DANKORT',
          'DINERS_CLUB_INTL',
          'DINERS_CLUB_US_CAN',
          'DISCOVER',
          'INSTAPAYMENT',
          'INTERPAYMENT',
          'JCB',
          'MAESTRO',
          'MASTERCARD',
          'RUPAY',
          'TROY',
          'UATP',
          'VERVE',
          'VISA',
          'OTHER',
        ],
      },
      {
        header: 'payment card network type other',
      },
      {
        header: 'payment card account holder business id',
      },
      {
        header: 'payment card account holder person id',
      },
      {
        header: 'payment card financial institution id',
      },
      {
        header: 'payment card credit limit',
      },
      {
        header: 'payment card notes',
      },
      {
        header: 'payment card quick links href',
      },
      {
        header: 'payment card quick links text',
      },
      {
        header: 'payment card foreign',
      },
      {
        header: 'payment card other info label',
      },
      {
        header: 'payment card other info value',
      },
      {
        header: 'payment card closed',
      },
    ],
  },
  {
    name: 'Person',
    headers: [
      {
        header: 'person first name',
      },
      {
        header: 'person middle name',
      },
      {
        header: 'person last name',
      },
      {
        header: 'person name suffix',
      },
      {
        header: 'person gender',
      },
      {
        header: 'person tins type',
      },
      {
        header: 'person tins number',
      },
      {
        header: 'person addresses line 1',
      },
      {
        header: 'person addresses line 2',
      },
      {
        header: 'person addresses locality',
      },
      {
        header: 'person addresses sublocality',
      },
      {
        header: 'person addresses administrative district level 1',
      },
      {
        header: 'person addresses postal code',
      },
      {
        header: 'person addresses country',
      },
      {
        header: 'person addresses address type',
      },
      {
        header: 'person addresses address type other',
      },
      {
        header: 'person email addresses',
      },
      {
        header: 'person websites',
      },
      {
        header: 'person usernames',
      },
      {
        header: 'person occupations company',
      },
      {
        header: 'person occupations industry',
      },
      {
        header: 'person occupations occupation',
      },
      {
        header: 'person occupations company website',
      },
      {
        header: 'person occupations naics code',
      },
      {
        header: 'person phone numbers type',
      },
      {
        header: 'person phone numbers number',
      },
      {
        header: 'person phone numbers extension',
      },
      {
        header: 'person birthdate',
        values: 'parse_date_parts',
      },
      {
        header: 'person identity documents type',
      },
      {
        header: 'person identity documents type other',
      },
      {
        header: 'person identity documents number',
      },
      {
        header: 'person identity documents issuing state code',
      },
      {
        header: 'person identity documents issuing country code',
      },
      {
        header: 'person identity documents expires on',
        values: 'parse_date_parts',
      },
      {
        header: 'person identity documents nationality country code',
      },
      {
        header: 'person alternate names',
      },
      {
        header: 'person notes',
      },
      {
        header: 'person quick links href',
      },
      {
        header: 'person quick links text',
      },
      {
        header: 'person other info label',
      },
      {
        header: 'person other info value',
      },
    ],
  },
  {
    name: 'Product',
    headers: [
      {
        header: 'product name',
      },
      {
        header: 'product sku',
      },
      {
        header: 'product cusip',
      },
      {
        header: 'product market traded',
      },
      {
        header: 'product commodity type',
      },
      {
        header: 'product notes',
      },
      {
        header: 'product quick links href',
      },
      {
        header: 'product quick links text',
      },
      {
        header: 'product other info label',
      },
      {
        header: 'product other info value',
      },
    ],
  },
  {
    name: 'Screening Search Result',
    headers: [
      {
        header: 'screening search result result',
      },
      {
        header: 'screening search result entity business id',
      },
      {
        header: 'screening search result entity person id',
      },
      {
        header: 'screening search result provider',
      },
      {
        header: 'screening search result last synced at',
      },
      {
        header: 'screening search result match status',
      },
      {
        header: 'screening search result notes',
      },
      {
        header: 'screening search result added by id',
      },
    ],
  },
  {
    name: 'Signatory',
    headers: [
      {
        header: 'signatory person id',
      },
      {
        header: 'signatory bank account id',
      },
      {
        header: 'signatory role',
      },
    ],
  },
  {
    name: 'Tag Definition',
    headers: [
      {
        header: 'tag definition label',
      },
      {
        header: 'tag definition color',
      },
      {
        header: 'tag definition allowed types',
      },
    ],
  },
  {
    name: 'Tag',
    headers: [
      {
        header: 'tag tagged object attachment id',
      },
      {
        header: 'tag bank account balance id',
      },
      {
        header: 'tag bank account id',
      },
      {
        header: 'tag tagged object business owner id',
      },
      {
        header: 'tag business id',
      },
      {
        header: 'tag crypto address id',
      },
      {
        header: 'tag device id',
      },
      {
        header: 'tag financial institution id',
      },
      {
        header: 'tag institution relationship id',
      },
      {
        header: 'tag payment card id',
      },
      {
        header: 'tag person id',
      },
      {
        header: 'tag product id',
      },
      {
        header: 'tag tagged object relationship definition id',
      },
      {
        header: 'tag tagged object relationship id',
      },
      {
        header: 'tag tagged object screening search result id',
      },
      {
        header: 'tag signatory id',
      },
      {
        header: 'tag tagged object tag definition id',
      },
      {
        header: 'tag tagged object tag id',
      },
      {
        header: 'tag definition id',
      },
    ],
  },
]
