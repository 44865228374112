import { Ref, MouseEvent, useMemo, useEffect, useState, CSSProperties, useRef } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles, useTheme } from '@mui/styles'

import classnames from 'classnames'

import { Resizable, ResizeCallback } from 're-resizable'

import { RESIZE_OVERLAY_Z_INDEX } from 'components/themeRedesign'
import { mergeOverrideStyles } from 'components/utils/styles'
import { getMeasurableContainer, stopEventPropagation } from 'helpers/uiHelpers'
import { getElementHasOverflow } from 'hooks'

import { Theme } from 'types/hb'

import { HbTooltip } from '../../HbComponents/HbTooltip'
import { ResizeHandle } from '../Resize/ResizeHandle'
import { useResizable } from '../Resize/hooks'

import { TableColumnElement } from './TableColumn'
import { TABLE_HEADER_HEIGHT } from './Tables.constants'
import { getField, OnColumnResizeStop, RenderMeasurementColumn } from './helpers'

export type DefaultHeaderCellOverrides = Partial<ReturnType<typeof useStyles>>

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.styleguide.darkGray,
    background: theme.palette.styleguide.nearWhite,
    boxSizing: 'border-box',
    verticalAlign: 'bottom',
    '&$clickable:hover': {
      color: theme.palette.styleguide.nearBlack,
      backgroundColor: theme.palette.styleguide.lightGray1,
    },
    '&$clickable:focus': {
      outline: 'none',
      color: theme.palette.styleguide.nearBlack,
      backgroundColor: theme.palette.styleguide.lightGray1,
    },
  },

  headerCellContent: {
    height: TABLE_HEADER_HEIGHT,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    ...theme.typography.sizes.md,
  },
  headerTitle: {},

  lastNonStickyHeader: {},
  clickable: {},
}))

const useResizeHandleStyles = makeStyles((theme: Theme) => ({
  resizeHandleContainer: {
    // Resizable adds an invisible overlay over the page while resizing
    // to avoid unintended text selection. In most cases, this doesn't make a difference,
    // but when trying to register a "dblclick" listener on our resize handle component,
    // it gets in the way. So this workaround puts the handle component above the overlay.
    zIndex: RESIZE_OVERLAY_Z_INDEX + 1,
    transform: 'translate(0)',
    '&:hover': {
      opacity: 1,
    },
  },
  resizeHandleInner: {
    backgroundColor: theme.palette.action.active,
    borderRadius: 0,
  },
}))

const HeaderTitle = styled('div')<{ resizable: boolean }>(({ resizable }) => ({
  fontWeight: 500,
  marginRight: 'auto',
  flex: '0 0 auto',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '100%',

  ...(resizable && {
    flex: '0 1 auto',
  }),
}))

interface Props {
  availableWidth?: number
  label?: string
  lastNonSticky?: boolean
  onClick?: (event: MouseEvent<HTMLElement>) => void
  classes?: DefaultHeaderCellOverrides
  colWidth?: number
  column?: TableColumnElement
  children?: any
  headerCellRef?: Ref<HTMLTableCellElement>
  isLast?: boolean
  rootClassName?: string
  renderMeasurementColumn?: RenderMeasurementColumn
  styleOverrides?: DefaultHeaderCellOverrides
  columnResizingEnabled?: boolean
  maxWidth?: number
  onColumnResizeStop?: OnColumnResizeStop
  style?: CSSProperties
  tableHeight?: number
}

const DEFAULT_MIN_WIDTH = 75
const DEFAULT_MAX_WIDTH = 400
// text truncation ellipses may take the same width as the text
const POSSIBLE_ELLIPSIS_BUFFER = 15

/**
 * Clamp the minimum width of a column to an arbitrary default value,
 * unless the natural width is narrower.
 */
const getClampedMinWidth = (naturalWidth?: number) =>
  naturalWidth ? Math.min(naturalWidth, DEFAULT_MIN_WIDTH) : DEFAULT_MIN_WIDTH

/**
 * Clamp the maximum width of a column to available table width if natural width is missing,
 * or fall back to an arbitrary default expansion width
 */
const getComputedMaxWidth = (naturalWidth?: number, availableWidth?: number) =>
  naturalWidth || availableWidth || DEFAULT_MAX_WIDTH

const measureTableColumn = (el: HTMLDivElement) => el.clientWidth + POSSIBLE_ELLIPSIS_BUFFER

// Resizable requires explicitly setting the other resize directions to `false` to disable
const resizeDirections = {
  top: false,
  // only the right direction is enabled by default
  right: true,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
}

function DefaultHeaderCell(props: Props) {
  const {
    availableWidth,
    classes: propClasses,
    label,
    colWidth,
    children,
    column,
    onClick,
    lastNonSticky = false,
    headerCellRef: forwardedHeaderCellRef,
    isLast,
    rootClassName,
    styleOverrides = {},
    columnResizingEnabled = false,
    maxWidth,
    renderMeasurementColumn,
    onColumnResizeStop,
    tableHeight,
    ...rest
  } = props

  const { getResizableRef, resizableRef } = useResizable()

  const classes = { ...mergeOverrideStyles(useStyles(), propClasses), ...styleOverrides }
  const resizeHandleClasses = useResizeHandleStyles()

  const clickProps =
    onClick === undefined
      ? {}
      : {
          role: 'button',
          tabIndex: 0,
          onClick,
        }

  const className = classnames(classes.root, rootClassName, {
    [classes.lastNonStickyHeader]: lastNonSticky,
    [classes.clickable]: onClick !== undefined,
  })

  const theme = useTheme<Theme>()
  const resizableHandleStyles = useMemo(
    () => ({
      right: {
        right: `-${theme.spacing(0.75)}`,
        width: theme.spacing(2),
        height: tableHeight,
        // This flex display promotes the stacking order of the custom Resizable handle
        // above the context of the invisible page overlay, to allow registering a "dblclick" handler.
        display: 'flex',
      },
    }),
    [tableHeight, theme]
  )

  const titleRef = useRef<HTMLDivElement>(null)
  const [showTitleTooltip, setShowTitleTooltip] = useState(false)

  const titleEl = label && (
    <HeaderTitle ref={titleRef} className={classes.headerTitle} resizable={columnResizingEnabled}>
      {label}
    </HeaderTitle>
  )

  const contents = (
    <div className={classes.headerCellContent}>
      {titleEl && (showTitleTooltip ? <HbTooltip title={label}>{titleEl}</HbTooltip> : titleEl)}
      {children}
    </div>
  )

  useEffect(() => {
    if (!columnResizingEnabled || !colWidth || !resizableRef) return
    resizableRef.updateSize({ width: colWidth, height: 'initial' })
  }, [columnResizingEnabled, colWidth, resizableRef])

  const fieldName = column && getField(column)

  const [naturalWidth, setNaturalWidth] = useState<number>()

  const actualNaturalWidth = naturalWidth ?? colWidth

  const clampedMinWidth = getClampedMinWidth(actualNaturalWidth)

  const handleResizeStop: ResizeCallback = (_mouseupEvent, _resizeDir, _node, delta) => {
    if (!fieldName || !onColumnResizeStop) return
    const { width } = delta
    if (titleRef.current) {
      const titleHasOverflowed = getElementHasOverflow(titleRef.current)
      setShowTitleTooltip(titleHasOverflowed)
    }
    onColumnResizeStop(fieldName, width, clampedMinWidth, resizableRef)
  }

  const handleResizeHandleClick = (e: MouseEvent<HTMLButtonElement>) => {
    // prevent the default click action on the header cell itself
    stopEventPropagation(e)
  }

  const handleResizeHandleDoubleClick = async () => {
    if (
      !columnResizingEnabled ||
      !colWidth ||
      !renderMeasurementColumn ||
      !resizableRef ||
      !fieldName ||
      !onColumnResizeStop
    ) {
      return
    }

    const { container, teardownMeasurableContainer } = getMeasurableContainer()
    document.body.appendChild(container)

    await renderMeasurementColumn(column, container)

    // measurable ref must exist, so we're using a callback ref
    const measuredWidth = measureTableColumn(container)

    // update measured width of the table column in case contents have changed since last render
    setNaturalWidth(measuredWidth)

    // compute the projected change in width:
    const computedMaxWidth = getComputedMaxWidth(measuredWidth, availableWidth)
    const updatedClampedMinWidth = getClampedMinWidth(measuredWidth)
    // toggle between min/max column width based on which is nearer
    const isCurrentlyCloserToMaxWidth =
      Math.abs(colWidth - computedMaxWidth) < Math.abs(colWidth - updatedClampedMinWidth)
    const nextWidth = isCurrentlyCloserToMaxWidth ? updatedClampedMinWidth : computedMaxWidth
    const widthDelta = nextWidth - colWidth
    // tear down disposable portal container and temporary table elements
    teardownMeasurableContainer(container)
    onColumnResizeStop(fieldName, widthDelta, updatedClampedMinWidth, resizableRef)
  }

  const isBatch = fieldName === 'batch'

  const headerProps = {
    className,
    ref: forwardedHeaderCellRef,
    ...clickProps,
    ...rest,
  }

  return columnResizingEnabled && !isBatch ? (
    <th {...headerProps}>
      <Resizable
        ref={getResizableRef}
        enable={resizeDirections}
        handleStyles={resizableHandleStyles}
        handleComponent={{
          right: (
            <ResizeHandle
              classes={{
                container: resizeHandleClasses.resizeHandleContainer,
                resizeHandleInner: resizeHandleClasses.resizeHandleInner,
              }}
              onClick={handleResizeHandleClick}
              onDoubleClick={handleResizeHandleDoubleClick}
              resizeOrientation="horizontal"
            />
          ),
        }}
        minWidth={clampedMinWidth}
        maxWidth={maxWidth}
        onResizeStop={handleResizeStop}
      >
        {contents}
      </Resizable>
    </th>
  ) : (
    <th {...headerProps}>{contents}</th>
  )
}

export default DefaultHeaderCell
