import { useMemo, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { List, ListItemButton, OutlinedInput, styled } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'

import AutoAwesomeAiGradientIcon from 'icons/AutoAwesomeAiGradientIcon'
import { Template } from 'reducers/narrativeTemplatesReducer'

interface Props {
  selectedToken?: string
  templates: Template[]
  onSelect: (template: Template) => void
}

const StyledList = styled(List)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  paddingTop: theme.spacing(),
  borderTop: `1px solid ${theme.palette.styleguide.borderLight}`,
}))

const ListButton = styled(ListItemButton)(({ theme }) => ({
  border: 'none',
  borderRadius: 8,
  margin: theme.spacing(2),
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(),
  '& svg': {
    marginRight: '8px',
  },

  [`&.Mui-selected`]: {
    fontWeight: 500,
    backgroundColor: theme.palette.styleguide.backgroundMedium,
  },
}))

const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  margin: theme.spacing(),
}))

function NarrativeTemplateList({ selectedToken, templates, onSelect }: Props) {
  const [query, setQuery] = useState('')
  const sortedTemplates = useMemo(() => {
    const lowerQuery = query.toLocaleLowerCase()
    return templates
      .filter((t) => !query || t.name.toLocaleLowerCase().includes(lowerQuery))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [query, templates])

  return (
    <>
      <StyledInput name="Search" placeholder="Search" value={query} onChange={(e) => setQuery(e.target.value)} />
      <StyledList disablePadding>
        {sortedTemplates.map((template) => (
          <ListButton
            key={template.token}
            divider
            selected={template.token === selectedToken}
            onClick={() => onSelect(template)}
          >
            {template.aiEnabled && <AutoAwesomeAiGradientIcon />}
            <HbText noWrap>{template.name}</HbText>
          </ListButton>
        ))}
      </StyledList>
    </>
  )
}

export default NarrativeTemplateList
