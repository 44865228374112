import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { getHbGradient } from 'components/colors'

import { HbText } from './Text/HbText'

export type HbBannerVariant = 'custom' | 'dark' | 'light' | 'hb'

const Container = styled('div')<{ variant?: HbBannerVariant }>(({ theme, variant }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  columnGap: theme.spacing(),

  ...(variant === 'light'
    ? {
        backgroundColor: theme.palette.styleguide.lemonLight,
        color: theme.palette.text.primary,
      }
    : variant === 'dark'
      ? {
          backgroundColor: theme.palette.background.dark,
          color: theme.palette.styleguide.white,
        }
      : variant === 'hb'
        ? {
            background: getHbGradient(7),
            color: theme.palette.text.primary,
          }
        : {}),
}))

export const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  columnGap: theme.spacing(),
  flex: 1,
}))

export const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(),
  rowGap: theme.spacing(),
}))

type Props = {
  actions?: ReactNode
  className?: string
  content: ReactNode
  icon?: ReactNode
  variant?: HbBannerVariant
}

export const HbBanner = (props: Props) => {
  const { actions, className, content, icon, variant = 'custom' } = props

  return (
    <Container className={className} variant={variant}>
      <ContentContainer>
        {icon}
        {typeof content === 'string' ? <HbText color="inherit">{content}</HbText> : content}
      </ContentContainer>
      {actions && <ActionsContainer>{actions}</ActionsContainer>}
    </Container>
  )
}
