import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled, CSSObject, ClassNameMap } from '@mui/material'

import classNames from 'classnames'

import { HbText } from 'components/HbComponents/Text/HbText'

type TitleTag = 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

const TitleText = styled(HbText)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}))

interface TitleProps {
  className?: string
  tag?: TitleTag
  children: ReactNode
}

export function FormSectionTitle({ className, tag = 'h2', children }: TitleProps) {
  return (
    <TitleText className={className} size="s" variant="upcase" bold tag={tag}>
      {children}
    </TitleText>
  )
}

export const StyledFormSectionTitle = styled(FormSectionTitle)(() => ({}))

export type LayoutTypes = 'default' | 'large'

interface StylesProps {
  layout?: LayoutTypes
}

const Section = styled('section')<StylesProps>(({ layout, theme }) => {
  let styles: CSSObject = { marginBottom: 0 }
  if (layout === 'large') {
    styles = {
      ...styles,
      marginTop: theme.spacing(6),
    }
  }
  return styles
})

const SubtitleText = styled(HbText)(({ theme }) => ({
  color: theme.palette.styleguide.mediumGray2,
  marginTop: theme.spacing(-1),
  marginBottom: theme.spacing(),
}))

export const Content = styled('div')<StylesProps>(({ layout, theme }) => {
  let styles: CSSObject = {
    backgroundColor: theme.palette.background.contrastMedium,
    borderRadius: theme.shape.largeContainer.borderRadius,
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(2, 2, 0, 2),
  }

  if (layout === 'large') {
    styles = {
      ...styles,
      padding: theme.spacing(4, 4, 0, 4),
    }
  }

  return styles
})

export interface Props {
  children: ReactNode
  classes?: Partial<ClassNameMap<'root' | 'subtitle' | 'sectionV2'>>
  className?: string
  layout?: LayoutTypes
  subtitle?: string
  testid?: string
  title?: string
  titleTag?: TitleTag
}

export const FormSection = ({
  className,
  classes: overrideClasses,
  children,
  layout = 'default',
  subtitle,
  testid,
  title,
  titleTag,
}: Props) => (
  <Section className={classNames(overrideClasses?.root, className)} data-testid={testid} layout={layout}>
    {title && <StyledFormSectionTitle tag={titleTag}>{title}</StyledFormSectionTitle>}
    {subtitle && (
      <SubtitleText block size="s" className={overrideClasses?.subtitle}>
        {subtitle}
      </SubtitleText>
    )}
    <Content className={overrideClasses?.sectionV2}>{children}</Content>
  </Section>
)
