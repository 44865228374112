// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

export const Root = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
}))

export const Container = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.light,
  borderRadius: 10,
  margin: theme.spacing(2),
  padding: 0,
  position: 'relative',
  overflow: 'hidden',
}))

export const Main = styled('main')(() => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  overflow: 'hidden',
}))

export const EditorWrapper = styled('div')(({ theme }) => ({
  margin: `0 auto`,
  padding: theme.spacing(3),
  flex: '1 1 auto',
  overflowY: 'auto',
}))

export const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}))
