import {
  CSSProperties,
  memo,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { DragIndicatorRounded, LockOutlined, VisibilityOff } from '@mui/icons-material'
import { Divider, Paper, Switch, useTheme } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  DraggableProvided,
  DraggableProvidedDraggableProps,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd'
import { ListChildComponentProps } from 'react-window'

import { useDebouncedCallback } from 'use-debounce'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbPopper } from 'components/HbComponents/HbPopper'
import { HbTooltip, Placement } from 'components/HbComponents/HbTooltip'
import { HbText } from 'components/HbComponents/Text/HbText'
import { DynamicVirtualList, DynamicVirtualListItemInnerWrapper } from 'components/library/Virtualized/List'
import { EnhancedItemData } from 'components/library/Virtualized/types'
import {
  HandleSingleColumnSelectionChange,
  SelectedColumnsMap,
  HandleColumnOrderChange,
} from 'dashboards/shared/components/types'
import { checkIsColumnNonHideable, useDashboardColumns } from 'dashboards/shared/hooks/useDashboardColumns'
import { createDragHandler } from 'helpers/DraggableHelpers'
import { useContentHasOverflow, usePrevious, useToggle } from 'hooks'
import { VisibilityOffIcon, VisibilityOnIcon } from 'icons'
import { DashboardColumn } from 'reducers/reviewsReducer'
import { Theme } from 'types/hb'

import { FilterControlProps } from './FilterControls'
import useLogDashboardFilterInteraction from './hooks/useLogDashboardFilterInteraction'

const ROW_CONTENT_HEIGHT = 39
const ROW_GAP = 8
const ROW_HEIGHT = ROW_CONTENT_HEIGHT + ROW_GAP
const PARTIAL_ITEM_HEIGHT = ROW_HEIGHT / 4
const MIN_LIST_ITEMS_VISIBLE = 5

const VISIBLE_COLUMNS_LIST = 'visibleColumnsList'
const HIDDEN_COLUMNS_LIST = 'hiddenColumnsList'

type DROPPABLE_LIST_ID = typeof VISIBLE_COLUMNS_LIST | typeof HIDDEN_COLUMNS_LIST

const useToggleItemStyles = makeStyles((theme: Theme) => ({
  columnEntry: {
    height: ROW_CONTENT_HEIGHT,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  dragButton: {
    cursor: 'grab',
    borderRadius: theme.shape.roundedRadius,
    color: theme.palette.styleguide.borderDark,
    flexShrink: 0,
  },
  toggleContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(0, 1),
    cursor: 'pointer',
    boxSizing: 'border-box',
    width: 'calc(100% - 32px)',

    '&:not($toggleContainerDisabled):hover': {
      backgroundColor: theme.palette.background.medium,
      borderRadius: theme.shape.smallContainer.borderRadius,
    },
  },
  toggleContainerDisabled: {
    cursor: 'initial',
    '&:hover': {
      backgroundColor: theme.palette.background.light,
    },
  },
  toggleText: {
    flex: 1,
    alignItems: 'center',
    ...theme.typography.noWrap,
  },
  toggleDisabledIcon: {
    color: theme.palette.text.disabled,
  },
}))

/**
 * Because the visible/hidden lists are now separated,
 * toggling a column in either list means it instantly gets relocated.
 * This hook delays the effect after the animation of the Switch.
 */
const useDelayedToggleForVisualFeedback = ({
  checked,
  handleSingleColumnSelectionChange,
  item,
}: {
  checked: boolean
  handleSingleColumnSelectionChange: HandleSingleColumnSelectionChange
  item: DashboardColumn
}) => {
  const theme = useTheme()
  const [appearsChecked, setAppearsChecked] = useState(checked)
  const handleToggleVisible = () => {
    setAppearsChecked(!appearsChecked)
  }

  const debouncedHandleSingleColumnSelectionChange = useDebouncedCallback(
    handleSingleColumnSelectionChange,
    // in sync with the duration of the switch component
    theme.transitions.duration.short
  )

  const appearedChecked = usePrevious(appearsChecked)
  useEffect(() => {
    if (appearedChecked === undefined || appearsChecked === appearedChecked) return
    // cancel existing invocation if needed
    debouncedHandleSingleColumnSelectionChange.cancel()
    debouncedHandleSingleColumnSelectionChange(item)
  }, [appearsChecked, appearedChecked, debouncedHandleSingleColumnSelectionChange, item])

  return {
    appearsChecked,
    handleToggleVisible,
  }
}

interface ToggleItemProps {
  checked: boolean
  handleSingleColumnSelectionChange: HandleSingleColumnSelectionChange
  item: DashboardColumn
  dragHandleProps: DraggableProvided['dragHandleProps']
}

const ToggleItem = memo(({ checked, dragHandleProps, handleSingleColumnSelectionChange, item }: ToggleItemProps) => {
  const classes = useToggleItemStyles()
  const toggleSwitchId = `toggle_${item.apiName}_switch`
  const itemNotHideable = checkIsColumnNonHideable(item)

  const { appearsChecked, handleToggleVisible } = useDelayedToggleForVisualFeedback({
    checked,
    item,
    handleSingleColumnSelectionChange,
  })

  const { getRef: getTextRef, hasOverflowed } = useContentHasOverflow()

  return (
    <div className={classes.columnEntry}>
      <HbButton
        className={classes.dragButton}
        label="Reorder column"
        variant="textSecondary"
        iconOnly
        Icon={DragIndicatorRounded}
        size="small"
        {...dragHandleProps}
      />
      <label
        className={classNames(classes.toggleContainer, itemNotHideable && classes.toggleContainerDisabled)}
        htmlFor={toggleSwitchId}
      >
        <HbTooltip title={hasOverflowed ? item.title : ''}>
          <HbText className={classes.toggleText} color="primary" size="md" noWrap ref={getTextRef}>
            {item.title}
          </HbText>
        </HbTooltip>
        {itemNotHideable ? (
          <HbTooltip title="This column is always visible">
            <LockOutlined className={classes.toggleDisabledIcon} />
          </HbTooltip>
        ) : (
          <Switch
            id={toggleSwitchId}
            checked={appearsChecked}
            disableRipple
            focusVisibleClassName=".Mui-focusVisible"
            onChange={handleToggleVisible}
          />
        )}
      </label>
    </div>
  )
})

const useVirtualStyles = makeStyles(() => ({
  list: {
    boxSizing: 'border-box',
    '& > ul': {
      position: 'relative',
    },
  },
}))

const useColumnToggleStyles = makeStyles((theme: Theme) => ({
  root: {
    boxSizing: 'border-box',
    padding: theme.spacing(0, 1),
  },
  inner: {
    padding: theme.spacing(0.5, 0),
    overflowWrap: 'break-word',
    position: 'relative',
  },
  dragging: {
    borderRadius: theme.shape.largeContainer.borderRadius,
  },
}))

interface ColumnListData {
  listId: DROPPABLE_LIST_ID
  listItems: DashboardColumn[]
  handleSingleColumnSelectionChange: HandleSingleColumnSelectionChange
  selectedColumnsMap: SelectedColumnsMap
}
type ListItemData = EnhancedItemData<ColumnListData>

const getDraggableContentStyles = ({
  draggableStyle,
  listId,
  snapshot,
  virtualStyle,
}: {
  virtualStyle: CSSProperties
  listId: DROPPABLE_LIST_ID
  snapshot: DraggableStateSnapshot
  draggableStyle: DraggableProvidedDraggableProps['style']
}) => {
  let combined = { ...virtualStyle, ...draggableStyle }

  // the hidden columns list is alphabetical + non-reorderable, so we
  // prevent default displacement animation from react-beautiful-dnd
  // of the other list items that are not being currently dragged,
  // since that displacement implies reordering
  if (listId === HIDDEN_COLUMNS_LIST && !snapshot.isDragging) {
    combined = {
      ...combined,
      transform: 'none !important',
    }
  }

  return combined
}

const ColumnToggleListItem = memo(({ data, index, style: virtualStyle }: ListChildComponentProps<ListItemData>) => {
  const classes = useColumnToggleStyles()
  const { getItemSize, handleSingleColumnSelectionChange, layout, listId, listItems, selectedColumnsMap, setItemSize } =
    data
  const item = listItems[index]

  if (!item) {
    return null
  }

  const draggableId = `toggle_${item.apiName}_draggable`
  return (
    <Draggable index={index} draggableId={draggableId} key={draggableId} isDragDisabled={item.isDragDisabled}>
      {(provided, snapshot) => {
        const { style: draggableStyle, ...restDraggableProps } = provided.draggableProps
        const combinedStyles = getDraggableContentStyles({ draggableStyle, listId, snapshot, virtualStyle })
        return (
          <DynamicVirtualListItemInnerWrapper
            classes={classes}
            getItemSize={getItemSize}
            index={index}
            layout={layout}
            setItemSize={setItemSize}
            OuterContainerProps={restDraggableProps}
            outerElementType="li"
            ref={provided.innerRef}
            style={combinedStyles}
          >
            <ToggleItem
              checked={!!selectedColumnsMap[item.apiName]}
              dragHandleProps={provided.dragHandleProps}
              handleSingleColumnSelectionChange={handleSingleColumnSelectionChange}
              item={item}
            />
          </DynamicVirtualListItemInnerWrapper>
        )
      }}
    </Draggable>
  )
})

interface DraggedColumnToggleListItemProps {
  checked: boolean
  handleSingleColumnSelectionChange: HandleSingleColumnSelectionChange
  item: DashboardColumn
  provided: DraggableProvided
}

/**
 * Rendered while dragging an item in the virtualized list
 */
const DraggedColumnToggleListItem = memo(
  ({ checked, handleSingleColumnSelectionChange, item, provided }: DraggedColumnToggleListItemProps) => {
    const classes = useColumnToggleStyles()
    return (
      <Paper
        elevation={2}
        className={classNames(classes.root, classes.dragging)}
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <div className={classes.inner}>
          <ToggleItem
            checked={checked}
            dragHandleProps={provided.dragHandleProps}
            handleSingleColumnSelectionChange={handleSingleColumnSelectionChange}
            item={item}
          />
        </div>
      </Paper>
    )
  }
)

const useColumnToggleListHeadingStyles = makeStyles((theme: Theme) => ({
  heading: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    columnGap: theme.spacing(),
  },
}))

interface ColumnToggleListHeaderProps {
  heading: string
  icon: ReactNode
}

const ColumnToggleListHeading = ({ heading, icon }: ColumnToggleListHeaderProps) => {
  const classes = useColumnToggleListHeadingStyles()
  return (
    <div className={classes.heading}>
      {icon}
      <HbText size="lg">{heading}</HbText>
    </div>
  )
}

const useColumnToggleListStyles = makeStyles((theme: Theme) => ({
  dropZoneActive: {
    background: theme.palette.styleguide.backgroundMedium,
    transition: '0.125s background ease-in-out',
  },
  header: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(2, 0.5, 1, 1),
  },
  hiddenPlaceholder: {
    display: 'none',
  },
}))

interface ColumnToggleListProps {
  columns: DashboardColumn[]
  draggingFromListId: DROPPABLE_LIST_ID | undefined
  draggingItem: DashboardColumn | null
  handleSingleColumnSelectionChange: HandleSingleColumnSelectionChange
  header: ReactNode
  height: number
  listId: DROPPABLE_LIST_ID
  selectedColumnsMap: SelectedColumnsMap
}

const ColumnToggleList = ({
  columns,
  draggingFromListId,
  draggingItem,
  handleSingleColumnSelectionChange,
  header,
  height,
  listId,
  selectedColumnsMap,
}: ColumnToggleListProps) => {
  const itemData = useMemo(
    () => ({
      listId,
      listItems: columns,
      handleSingleColumnSelectionChange,
      selectedColumnsMap,
    }),
    [columns, handleSingleColumnSelectionChange, listId, selectedColumnsMap]
  )

  const draggingFromHiddenColumnsList = draggingFromListId === HIDDEN_COLUMNS_LIST
  const draggingNonHideableItem = !!draggingItem && checkIsColumnNonHideable(draggingItem)

  const isHiddenList = listId === HIDDEN_COLUMNS_LIST
  const isDropDisabled = isHiddenList && (draggingFromHiddenColumnsList || draggingNonHideableItem)

  const virtualClasses = useVirtualStyles()
  const classes = useColumnToggleListStyles()

  return (
    <Droppable
      droppableId={listId}
      mode="virtual"
      renderClone={(provided, _, rubric) => (
        <DraggedColumnToggleListItem
          checked={!!selectedColumnsMap[columns[rubric.source.index].apiName]}
          handleSingleColumnSelectionChange={handleSingleColumnSelectionChange}
          item={columns[rubric.source.index]}
          provided={provided}
        />
      )}
      isDropDisabled={isDropDisabled}
    >
      {(provided, snapshot) => {
        return (
          <section
            data-testid={listId}
            {...provided.droppableProps}
            className={classNames({
              [classes.dropZoneActive]: snapshot.isDraggingOver,
            })}
          >
            <header className={classes.header}>{header}</header>
            <div style={{ height }}>
              <DynamicVirtualList<ColumnListData>
                classes={virtualClasses}
                estimatedItemSize={ROW_HEIGHT}
                fallbackItemSize={ROW_HEIGHT}
                itemCount={snapshot.isUsingPlaceholder && !isHiddenList ? itemData.listItems.length + 1 : undefined}
                innerElementType="ul"
                itemData={itemData}
                outerRef={provided.innerRef}
              >
                {ColumnToggleListItem}
              </DynamicVirtualList>
              <span className={classes.hiddenPlaceholder}>{provided.placeholder}</span>
            </div>
          </section>
        )
      }}
    </Droppable>
  )
}

const getMinColumnsListHeight = (len: number) => {
  const itemsHeight = Math.min(len, MIN_LIST_ITEMS_VISIBLE) * ROW_HEIGHT
  let listHeight = itemsHeight
  if (len > MIN_LIST_ITEMS_VISIBLE) {
    // adds an extra partial item's height at the bottom
    // to suggest there is overflow in the list
    listHeight += PARTIAL_ITEM_HEIGHT
  }
  return listHeight
}

const useDynamicColumnsListHeights = ({
  hiddenColumns,
  visibleColumns,
}: {
  anchorRef: MutableRefObject<HTMLButtonElement | null>
  hiddenColumns: DashboardColumn[]
  visibleColumns: DashboardColumn[]
}) => {
  const containerHeight = window.innerHeight

  const visibleColumnsLen = visibleColumns.length
  const visibleColumnsHaveOverflow = visibleColumnsLen > MIN_LIST_ITEMS_VISIBLE
  const hiddenColumnsLen = hiddenColumns.length
  const hiddenColumnsHaveOverflow = hiddenColumnsLen > MIN_LIST_ITEMS_VISIBLE
  const totalColumnsLen = hiddenColumnsLen + visibleColumnsLen

  const minVisibleColumnsListHeight = getMinColumnsListHeight(visibleColumnsLen)
  const minHiddenColumnsListHeight = getMinColumnsListHeight(hiddenColumnsLen)

  let visibleColumnsListHeight = minVisibleColumnsListHeight
  let hiddenColumnsListHeight = minHiddenColumnsListHeight

  const availableHeight = containerHeight * 0.6

  const remainingHeight = availableHeight - (minVisibleColumnsListHeight + minHiddenColumnsListHeight)

  if (remainingHeight > 0 && (visibleColumnsHaveOverflow || hiddenColumnsHaveOverflow)) {
    const getHeightToAdd = (itemsLen: number) => {
      const itemsRatio = itemsLen / totalColumnsLen
      const heightLeft = remainingHeight * itemsRatio
      const itemsThatCanFit = Math.floor(heightLeft / ROW_HEIGHT)
      const heightOfItemsThatCanFit = itemsThatCanFit * ROW_HEIGHT
      const heightOfOverflowedItems = (itemsLen - MIN_LIST_ITEMS_VISIBLE) * ROW_HEIGHT
      let heightToAdd = Math.min(heightOfItemsThatCanFit, heightOfOverflowedItems)
      const isThereStillOverflow = itemsLen - itemsThatCanFit > MIN_LIST_ITEMS_VISIBLE
      if (isThereStillOverflow) heightToAdd += PARTIAL_ITEM_HEIGHT
      return heightToAdd
    }

    if (visibleColumnsHaveOverflow) {
      visibleColumnsListHeight += getHeightToAdd(visibleColumnsLen)
    }
    if (hiddenColumnsHaveOverflow) {
      hiddenColumnsListHeight += getHeightToAdd(hiddenColumnsLen)
    }
  }

  return {
    hidden: hiddenColumnsListHeight,
    visible: visibleColumnsListHeight,
  }
}

const useColumnToggleListPopoverStyles = makeStyles((theme: Theme) => ({
  divider: {
    margin: theme.spacing(0, 2),
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  popoverContent: { width: 355 },
  searchButton: {
    borderRadius: theme.shape.roundedRadius,
    color: theme.palette.text.disabled,
  },
}))

const popperModifiers = [
  { name: 'preventOverflow', options: { altAxis: true, altBoundary: true, boundary: document.body } },
]

interface ColumnToggleListPopoverProps {
  allColumns: DashboardColumn[]
  anchorRef: MutableRefObject<HTMLButtonElement | null>
  containerRef?: HTMLElement | Window
  handleClick: () => void
  handleColumnOrderChange: HandleColumnOrderChange
  handleSingleColumnSelectionChange: HandleSingleColumnSelectionChange
  open: boolean
  popoverShared: string
  selectedColumnsMap: Record<string, DashboardColumn>
  zIndex?: number
}

const ColumnToggleListPopover = ({
  allColumns,
  anchorRef,
  handleClick,
  handleColumnOrderChange,
  handleSingleColumnSelectionChange,
  open,
  popoverShared,
  selectedColumnsMap,
  zIndex,
}: ColumnToggleListPopoverProps) => {
  const classes = useColumnToggleListPopoverStyles()

  const visibleColumns = useMemo(
    () => allColumns.filter((c) => checkIsColumnNonHideable(c) || selectedColumnsMap[c.apiName]),
    [allColumns, selectedColumnsMap]
  )

  const hiddenColumns = useMemo(() => {
    const collation = new Intl.Collator('en', { numeric: true })
    return allColumns
      .filter((c) => !checkIsColumnNonHideable(c) && !selectedColumnsMap[c.apiName])
      .sort((a, b) => collation.compare(a.title, b.title))
  }, [allColumns, selectedColumnsMap])

  const visibleColumnsPopulated = !!visibleColumns.length
  const hiddenColumnsPopulated = !!hiddenColumns.length

  // state for disabling the drop zone depending on the source list
  const [dragSource, setDragSource] = useState<{
    draggingFromListId: DROPPABLE_LIST_ID
    draggingItem: DashboardColumn | null
  } | null>(null)

  const handleDragEnd = useCallback<DragDropContextProps['onDragEnd']>(
    (result) => {
      const toOptions = (cols: DashboardColumn[]) => cols.map((c) => ({ display: c.title, value: c.apiName }))
      const handleReorderVisibleColumns = createDragHandler(visibleColumns, (changedColumns) => {
        handleColumnOrderChange(toOptions(changedColumns))
      })
      setDragSource(null)
      if (!result.destination) return

      // if moving across hidden <-> visible lists:
      if (result.source.droppableId !== result.destination.droppableId) {
        const sourceColumn = (result.source.droppableId === VISIBLE_COLUMNS_LIST ? visibleColumns : hiddenColumns)[
          result.source.index
        ]
        // 1. toggle visibility
        handleSingleColumnSelectionChange(sourceColumn)
        // 2. if moving into the visible list, reorder to match drop index:
        if (result.destination.droppableId === VISIBLE_COLUMNS_LIST) {
          const updatedColumnsWithToggledInsert = [...visibleColumns]
          updatedColumnsWithToggledInsert.splice(result.destination.index, 0, hiddenColumns[result.source.index])
          handleColumnOrderChange(toOptions(updatedColumnsWithToggledInsert))
        }
        return
      }

      if (result.source.droppableId === VISIBLE_COLUMNS_LIST) {
        handleReorderVisibleColumns(result)
      }
    },
    [handleColumnOrderChange, handleSingleColumnSelectionChange, hiddenColumns, visibleColumns]
  )

  const handleDragStart = useCallback<NonNullable<DragDropContextProps['onDragStart']>>(
    (initial) => {
      const draggingFromListId = initial.source?.droppableId as DROPPABLE_LIST_ID
      return setDragSource({
        draggingFromListId,
        draggingItem:
          draggingFromListId === VISIBLE_COLUMNS_LIST
            ? visibleColumns[initial.source.index]
            : draggingFromListId === HIDDEN_COLUMNS_LIST
              ? hiddenColumns[initial.source.index]
              : null,
      })
    },
    [hiddenColumns, visibleColumns]
  )

  const { visible: visibleColumnsListHeight, hidden: hiddenColumnsListHeight } = useDynamicColumnsListHeights({
    anchorRef,
    hiddenColumns,
    visibleColumns,
  })

  return (
    <HbPopper
      id="column-hide-popper"
      classes={{ content: classes.popoverContent }}
      isOpen={open}
      anchorEl={anchorRef.current}
      onClose={handleClick}
      zIndex={zIndex}
      modifiers={popperModifiers}
    >
      <div className={popoverShared}>
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <div>
            {visibleColumnsPopulated && (
              <ColumnToggleList
                columns={visibleColumns}
                draggingFromListId={dragSource?.draggingFromListId}
                draggingItem={dragSource?.draggingItem ?? null}
                handleSingleColumnSelectionChange={handleSingleColumnSelectionChange}
                header={<ColumnToggleListHeading heading="Visible Columns" icon={<VisibilityOnIcon />} />}
                height={visibleColumnsListHeight}
                listId={VISIBLE_COLUMNS_LIST}
                selectedColumnsMap={selectedColumnsMap}
              />
            )}
            {visibleColumnsPopulated && hiddenColumnsPopulated && <Divider className={classes.divider} />}
            {hiddenColumnsPopulated && (
              <ColumnToggleList
                columns={hiddenColumns}
                draggingFromListId={dragSource?.draggingFromListId}
                draggingItem={dragSource?.draggingItem ?? null}
                handleSingleColumnSelectionChange={handleSingleColumnSelectionChange}
                header={<ColumnToggleListHeading heading="Hidden Columns" icon={<VisibilityOffIcon />} />}
                height={hiddenColumnsListHeight}
                listId={HIDDEN_COLUMNS_LIST}
                selectedColumnsMap={selectedColumnsMap}
              />
            )}
          </div>
        </DragDropContext>
      </div>
    </HbPopper>
  )
}

export interface HideButtonProps extends FilterControlProps {
  allColumns: DashboardColumn[]
  handleColumnOrderChange: HandleColumnOrderChange
  handleSingleColumnSelectionChange: HandleSingleColumnSelectionChange
  selectedColumnsMap: SelectedColumnsMap
}

export function HideButton({
  allColumns,
  buttonActive,
  popoverShared,
  handleColumnOrderChange,
  handleSingleColumnSelectionChange,
  selectedColumnsMap,
  iconOnly,
  size = 'small',
  zIndex,
}: HideButtonProps) {
  const logAction = useLogDashboardFilterInteraction()

  const open = useToggle(false)
  const handleClick = () => {
    if (!open.value) {
      logAction('dashboard:hideColumns:clicked')
    }
    open.toggle()
  }
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <HbButton
        className={open.value ? buttonActive : ''}
        ref={buttonRef}
        Icon={VisibilityOff}
        label="Columns"
        size={size}
        variant="secondary"
        onClick={handleClick}
        iconOnly={iconOnly}
        tooltip={iconOnly}
        tooltipPlacement={Placement.Top}
      />
      <ColumnToggleListPopover
        allColumns={allColumns}
        anchorRef={buttonRef}
        handleClick={handleClick}
        handleColumnOrderChange={handleColumnOrderChange}
        handleSingleColumnSelectionChange={handleSingleColumnSelectionChange}
        selectedColumnsMap={selectedColumnsMap}
        open={open.value}
        popoverShared={popoverShared}
        zIndex={zIndex}
      />
    </>
  )
}

const DashboardHideButton = (props: FilterControlProps) => {
  const {
    columnsWithUserSelectedColumnsPreserved: allColumns,
    handleColumnOrderChange,
    handleSingleColumnSelectionChange,
    selectedColumnsMap,
  } = useDashboardColumns()
  return (
    <HideButton
      {...props}
      allColumns={allColumns}
      handleColumnOrderChange={handleColumnOrderChange}
      handleSingleColumnSelectionChange={handleSingleColumnSelectionChange}
      selectedColumnsMap={selectedColumnsMap}
    />
  )
}

export default DashboardHideButton
