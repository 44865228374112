import { useTheme } from '@emotion/react'
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import pluralize from 'pluralize'

import { HbText } from 'components/HbComponents/Text/HbText'
import { BUTTON_OUTLINE_STYLE } from 'components/themeRedesign'

import { getTemplateCategoryStyles } from './AutomationRuleTemplate/AutomationRuleTemplate.utils'
import { AutomationRuleTemplateIcon } from './AutomationRuleTemplate/AutomationRuleTemplateIcon'
import { CardCarousel } from './CardCarousel'
import { CategoryWithCount } from './ExploreAutomations/ExploreAutomations.hooks'

const Card = styled('button')(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxSizing: 'border-box',
  background: theme.palette.background.lightGray,
  width: '100%',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.12)',
  '&:hover': {
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.12)',
  },
  '&:focus-visible': {
    outline: BUTTON_OUTLINE_STYLE,
  },
}))

export type RecipeCategoryCardProps = { onClick: (value: string) => void; count?: number } & CategoryWithCount

export const RecipeCategoryCard = ({ onClick, category, count }: RecipeCategoryCardProps) => {
  const theme = useTheme()
  if (!category?.slug) return null
  const templateIconProps = getTemplateCategoryStyles(category.style)
  return (
    <Card onClick={() => onClick(category.slug || '')}>
      <AutomationRuleTemplateIcon {...templateIconProps} />
      <HbText size="lg" bold block css={{ margin: theme.spacing(2, 0, 1, 0) }}>
        {category.name}
      </HbText>
      <HbText size="s" block color="secondary">
        {count} {pluralize('recipe', count)}
      </HbText>
    </Card>
  )
}

export type RecipeCategoryCardsProps = {
  categories: Array<Omit<RecipeCategoryCardProps, 'onClick'>>
} & Pick<RecipeCategoryCardProps, 'onClick'>

export const RecipeCategoryCards = ({ onClick, categories }: RecipeCategoryCardsProps) => {
  return (
    <CardCarousel
      cardData={categories}
      getKey={(c, i) => c.category?.token || i}
      renderCard={({ category, count }) => <RecipeCategoryCard category={category} count={count} onClick={onClick} />}
      cardWidthPx={330}
      stepMultiplier={2}
      gap={3}
      showScrollButtons={categories.length > 5}
    />
  )
}
