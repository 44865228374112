// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { HbTooltip } from 'components/HbComponents/HbTooltip'

import { accountAvatarDetailsFragment } from 'components/accounts/AccountAvatar'
import { userInitials } from 'helpers/uiHelpers'

import { InvestigationReviewApprovalDecisionEnum } from 'types/api'

import { DecisionContext } from './DecisionContext'
import { DEFAULT_ASSIGNEE_TOOLTIP_DELAY, StyledAccountAvatar } from './shared'
import { ApprovalAssigneeDetails } from './types'

interface StyledApprovalAssigneeAccountAvatarProps {
  decision?: string | null
}

const AvatarContainer = styled('div')(() => ({
  width: 'fit-content',
}))

const BaseStyledApprovalAssigneeAccountAvatar = styled(StyledAccountAvatar)(() => ({
  borderWidth: 1,
}))

export const StyledApprovalAssigneeAccountAvatar = styled(
  BaseStyledApprovalAssigneeAccountAvatar
)<StyledApprovalAssigneeAccountAvatarProps>(({ decision, theme }) => ({
  '&&': {
    ...(decision === InvestigationReviewApprovalDecisionEnum.Approve
      ? {
          borderStyle: 'solid',
          borderColor: theme.palette.styleguide.mintMedium,
          backgroundColor: theme.palette.styleguide.mintLight,
        }
      : decision === InvestigationReviewApprovalDecisionEnum.RequestChanges
        ? {
            borderStyle: 'solid',
            borderColor: theme.palette.styleguide.coralMedium,
            backgroundColor: theme.palette.styleguide.coralLight,
          }
        : {
            borderStyle: 'dashed',
            borderColor: theme.palette.styleguide.borderDark,
            backgroundColor: theme.palette.styleguide.backgroundLight,
            '&:hover': {
              backgroundColor: theme.palette.styleguide.backgroundMedium,
            },
          }),
  },
}))

export const ApprovalAssigneeAvatar = ({
  assignee,
  className,
  decision,
  showDecision,
  tooltipDelay = DEFAULT_ASSIGNEE_TOOLTIP_DELAY,
}: {
  assignee: ApprovalAssigneeDetails
  className?: string
  decision?: string | null
  showDecision?: boolean
  tooltipDelay?: number
}) => (
  <HbTooltip
    title={
      !!decision || showDecision ? <DecisionContext decision={decision} name={assignee.fullName} /> : assignee.fullName
    }
    enterDelay={tooltipDelay}
    enterNextDelay={tooltipDelay}
  >
    {/* This wrapper is needed for the tooltip to render */}
    <AvatarContainer className={className}>
      <StyledApprovalAssigneeAccountAvatar decision={decision}>
        {userInitials(assignee)}
      </StyledApprovalAssigneeAccountAvatar>
    </AvatarContainer>
  </HbTooltip>
)

ApprovalAssigneeAvatar.fragments = {
  assignee: accountAvatarDetailsFragment,
}
