import React, { PropsWithChildren, ReactNode, forwardRef } from 'react'

// eslint-disable-next-line no-restricted-imports
import { FormControl, FormHelperText, FormLabel, styled } from '@mui/material'

import VisuallyHidden from '@reach/visually-hidden'

import classnames from 'classnames'

import { HbText } from 'components/HbComponents/Text/HbText'
import Markdown from 'components/utils/Markdown'

import { makeRequiredStyles } from 'components/utils/styles'
import { Theme } from 'types/hb'

import { InputError } from '../InputError'

export const getHelperTextId = (fieldName: string) => `form_helper_text_${fieldName}`

type UseStylesProps = Pick<InputContainerProps, 'noSpacing'>
const useStyles = makeRequiredStyles((theme: Theme) => ({
  root: (props: UseStylesProps) => {
    return {
      width: '100%',
      boxSizing: 'border-box',
      borderRadius: theme.spacing(),
      marginBottom: props.noSpacing ? undefined : theme.spacing(3),
    }
  },
  frame: (props: UseStylesProps) => {
    return { display: 'flex', flexDirection: 'column', marginTop: props.noSpacing ? undefined : theme.spacing(1) }
  },
  labelText: {
    ...theme.typography.sizes.s,
    '& *': {
      ...theme.typography.sizes.s,
      fontWeight: 500,
    },
  },
}))

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  cursor: 'default',
  color: theme.palette.styleguide.mediumGray2,
})) as typeof FormLabel

export const SublabelText = styled(Markdown)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '& *': theme.typography.sizes.xs,
  marginBottom: 0,
}))

export interface InputContainerProps {
  testId?: string
  label?: string | ReactNode
  sublabel?: string
  isErroneous?: boolean
  className?: string
  clientError?: string | string[]
  apiError?: string | string[]
  errors?: string | string[]
  noSpacing?: boolean
  htmlFor: string | undefined
  labelId?: string
  helperText?: ReactNode
  required?: boolean
  hideLabel?: boolean
}

const getTestId = ({ testId, label, suffix }: { testId?: string; label?: ReactNode; suffix: string }) => {
  return testId ? `${testId}_${suffix}` : typeof label === 'string' ? `${label?.slice(0, 20)}_${suffix}` : suffix
}

export const InputContainer = forwardRef<HTMLDivElement, PropsWithChildren<InputContainerProps>>(
  (
    {
      children,
      testId,
      label,
      sublabel,
      isErroneous,
      clientError,
      apiError,
      errors,
      noSpacing,
      className,
      htmlFor,
      labelId,
      helperText,
      required,
      hideLabel,
    },
    ref
  ) => {
    const styles = useStyles({ noSpacing })

    const labelContent = typeof label === 'string' ? <Markdown className={styles.labelText}>{label}</Markdown> : label

    return (
      <FormControl
        data-testid={getTestId({ testId, label, suffix: 'input-container' })}
        error={isErroneous}
        className={classnames(styles.root, className)}
        required={required}
        ref={ref}
      >
        {label && !hideLabel && (
          <StyledFormLabel component="label" focused={false} error={false} htmlFor={htmlFor} id={labelId}>
            {labelContent}
            {sublabel ? <SublabelText>{sublabel}</SublabelText> : null}
          </StyledFormLabel>
        )}
        {label && hideLabel && (
          <FormLabel component="label" focused={false} error={false} htmlFor={htmlFor} id={labelId}>
            <VisuallyHidden>{label}</VisuallyHidden>
          </FormLabel>
        )}
        <div className={styles.frame}>
          {children
            ? React.Children.map(children, (child) => {
                if (React.isValidElement(child) && child?.props?.form) {
                  return React.cloneElement(child, {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore: augmenting a prop we know exists by conditional
                    form: {
                      ...child.props.form,
                      errors: {},
                      initialValues: {
                        ...child.props.form.initialValues,
                        validation: {},
                      },
                      values: {
                        ...child.props.form.values,
                        validation: {},
                      },
                    },
                  })
                }
                return child
              })
            : null}
        </div>

        {(isErroneous || helperText) && (
          <FormHelperText
            data-testid={getTestId({ testId, label, suffix: 'helper_text' })}
            id={getHelperTextId(htmlFor ?? 'missing')}
          >
            {isErroneous && (
              <InputError
                testId={getTestId({ testId, label, suffix: 'form-error' })}
                isErroneous={isErroneous}
                apiError={apiError}
                clientError={clientError}
                errors={errors}
                fieldName={htmlFor}
                tag="span"
              />
            )}
            {helperText && (
              <HbText size="s" block color="secondary">
                {helperText}
              </HbText>
            )}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
)
