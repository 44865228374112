// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'
import SARFieldAdornment from 'components/library/SARFieldAdornment'
import { IDNumberAndTypeField } from 'components/material/Form'
import { formatTin } from 'helpers/uiHelpers'
import { useFeatureFlags } from 'hooks'
import { IdNumberFieldIcon } from 'icons/IdNumberFieldIcon'

import { TinTypeEnums, TinTypeStrings } from 'strings/strings'
import { FeatureFlag, Tin, TinMetadata, TinTypeEnum } from 'types/api'

import {
  EditProps,
  EditableArray,
  InfoFieldArray,
  InfoFieldSeparator,
  MetadataInfoFieldArray,
  createGetMetadataDiffKey,
  createInfoField,
  getDiffStyles,
  revisionUpdateHighlightColors,
} from '../InformationFields'
import { RevisionProps } from '../InformationFields.types'

// A bit abitrary but this is the maximum length that works while still
// satisfying the DB constraints
const TIN_MAX_LENGTH = 267

export const numberValidate = (value: unknown) => {
  if (typeof value === 'string' && value.length > TIN_MAX_LENGTH) {
    return 'is too long'
  }
  return undefined
}

export const TinField = createInfoField<Tin>(formatTin, (tin) => tin.type && TinTypeStrings[tin.type])

export function TinEdit({ name }: EditProps) {
  return (
    <IDNumberAndTypeField
      typeOptions={TinTypeEnums}
      typeLabel="TIN Type"
      typeName={name('type')}
      numberLabel="Number"
      numberName={name('number')}
      numberValidate={numberValidate}
      typeAdornment={<SARFieldAdornment field="17: TIN type" />}
      numberAdornment={<SARFieldAdornment field="16: TIN" />}
      defaultTypeOptionValue={null}
    />
  )
}

const getTinDiffKey = (value: Tin) => formatTin(value) ?? ''

const LegacyTinEditBlock = () => (
  <EditableArray name="tins">
    {(params) => (
      <InfoFieldArray<Tin>
        getDiffKey={getTinDiffKey}
        placeholder="Tax identification number"
        params={params}
        Icon={IdNumberFieldIcon}
        Read={TinField}
        Edit={TinEdit}
      />
    )}
  </EditableArray>
)

const TinValueText = styled(HbText)(() => ({}))
const PrimaryText = styled(HbText)(() => ({}))
const PrimaryTinTypeContainer = styled('div')<RevisionProps>(
  ({ hasDeletedDataInRevision, hasUpdatedDataInRevision, theme }) => ({
    ...getDiffStyles({ theme, hasDeletedDataInRevision, hasUpdatedDataInRevision }),
    padding: 0,
    [`&&, &&& ${TinValueText}, &&& ${PrimaryText}`]: hasDeletedDataInRevision
      ? {
          color: theme.palette.styleguide.textGreyDark,
        }
      : hasUpdatedDataInRevision
        ? {
            fontWeight: theme.fontWeight.bold,
            color: revisionUpdateHighlightColors.color,
          }
        : {},
  })
)

interface PrimaryTinTypeDisplayProps extends RevisionProps {
  data: Tin['type']
}

const PrimaryTinTypeDisplay = ({ data, ...revisionProps }: PrimaryTinTypeDisplayProps) => {
  if (!data) return <PrimaryText tag="span">Primary</PrimaryText>
  const formattedValue = TinTypeStrings[data]
  return (
    <PrimaryTinTypeContainer {...revisionProps}>
      <TinValueText color="secondary" size="s" tag="span">
        {formattedValue}
      </TinValueText>
      <InfoFieldSeparator />
      <PrimaryText color="secondary" size="s" tag="span">
        Primary
      </PrimaryText>
    </PrimaryTinTypeContainer>
  )
}

const TinMetadataEdit = ({ name: getFieldName }: { name: (key?: string) => string }) => {
  const metadataFieldNameRoot = getFieldName('value')

  return (
    <IDNumberAndTypeField
      typeOptions={TinTypeEnums}
      typeLabel="TIN Type"
      typeName={`${metadataFieldNameRoot}.type`}
      numberLabel="Number"
      numberName={`${metadataFieldNameRoot}.number`}
      numberValidate={numberValidate}
      typeAdornment={<SARFieldAdornment field="17: TIN type" />}
      numberAdornment={<SARFieldAdornment field="16: TIN" />}
      defaultTypeOptionValue={null}
    />
  )
}

export const PrimaryTinField = createInfoField<Tin>(formatTin, (tin, _, revisionProps) => {
  return <PrimaryTinTypeDisplay data={tin.type} {...revisionProps} />
})

interface TinMetadataReadProps extends RevisionProps {
  data: TinMetadata
}

export const TinMetadataRead = ({ data, ...revisionProps }: TinMetadataReadProps) => {
  if (data.active) return <PrimaryTinField data={data.value} {...revisionProps} />
  return <TinField data={data.value} {...revisionProps} />
}

/**
 * Crude logic to make the first value for each tin type the primary value.
 * Any extra active fields are turned into secondary values.
 */
export const transformSingleActiveValuesBeforeSave = (data: TinMetadata[]) => {
  const primaryValues = data.reduce(
    (acc: Record<TinTypeEnum, TinMetadata>, datum: TinMetadata) => {
      if (datum.value.type && !acc[datum.value.type] && datum.active) {
        acc[datum.value.type] = datum
      }
      return acc
    },
    {} as Record<TinTypeEnum, TinMetadata>
  )

  const transformed = data.map((datum) => {
    if (!datum.value.type) return datum
    // if there's no active value for this tin type,
    // we make this the active value
    if (!primaryValues[datum.value.type]) {
      const newPrimaryValue = { ...datum, active: true }
      primaryValues[datum.value.type] = newPrimaryValue
      return newPrimaryValue
    }
    // if this isn't the first active value in the metadata array,
    // we make this value inactive
    if (primaryValues[datum.value.type] !== datum) {
      return { ...datum, active: false }
    }
    return datum
  })

  const sorted = transformed.sort((a, b) => {
    // if both are in/active
    // sort alphabetically to keep sub-order deterministic
    if (a.active === b.active) {
      // first by type
      if (!a.value.type || !b.value.type) return 0
      if (a.value.type !== b.value.type) {
        return a.value.type < b.value.type ? -1 : 1
      }
      // then by number
      if (!a.value.number || !b.value.number) return 0
      return a.value.number < b.value.number ? -1 : 1
    }
    // otherwise sort active-first
    return a.active ? -1 : 1
  })

  return sorted
}

const getTinMetadataDiffKey = createGetMetadataDiffKey<TinMetadata>((value) => (value && formatTin(value)) ?? '')

export const RedesignTinEditBlock = () => (
  <EditableArray name="tinsMetadata" transformValues={transformSingleActiveValuesBeforeSave}>
    {(params) => (
      <MetadataInfoFieldArray<TinMetadata>
        getDiffKey={getTinMetadataDiffKey}
        allowRemovingActive
        placeholder="Tax identification number"
        params={params}
        Icon={IdNumberFieldIcon}
        Read={TinMetadataRead}
        Edit={TinMetadataEdit}
      />
    )}
  </EditableArray>
)

export const TinEditBlock = ({
  featureFlags = [FeatureFlag.EnableLibraryMerging],
}: {
  featureFlags?: FeatureFlag[]
}) => {
  const isFeatureEnabled = useFeatureFlags(...featureFlags)

  if (isFeatureEnabled) return <RedesignTinEditBlock />

  return <LegacyTinEditBlock />
}
