// This file is automatically generated by case_headers_md_generator.rb

export interface Header {
  header: string
  description?: string
}

export interface Section {
  name: string
  headers: Header[]
}

export const caseHydraHeaders: Section[] = [
  {
    name: 'Cases',
    headers: [
      {
        header: 'Case Id',
      },
      {
        header: 'Case Name',
        description: 'The name of the case; used for grouping.',
      },
      {
        header: 'Case Other Info',
      },
    ],
  },
  {
    name: 'Reviews',
    headers: [
      {
        header: 'Case Review Activity Started On',
      },
      {
        header: 'Case Review Activity Ended On',
      },
      {
        header: 'Case Review Amount Involved',
      },
      {
        header: 'Case Review Filing Type',
      },
      {
        header: 'Case Review Other Info',
      },
      {
        header: 'Case Review Type Canonical Id',
      },
      {
        header: 'Case Review Assignee Email',
      },
      {
        header: 'Case Review Queue',
      },
      {
        header: 'Case Review Activity Tag Category',
      },
      {
        header: 'Case Review Activity Tag Report Name',
      },
      {
        header: 'Case Review Activity Tag Report Classification',
      },
      {
        header: 'Case Review Activity Tag Structured Name',
      },
      {
        header: 'Case Review Activity Tag Other',
      },
      {
        header: 'Case Review Alert Id',
      },
      {
        header: 'Case Review Alert Token',
      },
      {
        header: 'Case Review Alert Rule',
      },
      {
        header: 'Case Review Alert Comments',
      },
      {
        header: 'Case Review Alert Other Info',
      },
      {
        header: 'Case Review Alert Reference Type',
      },
      {
        header: 'Case Review Alert Reference Id',
      },
      {
        header: 'Case Review Alert Link Label',
      },
      {
        header: 'Case Review Alert Link Url',
      },
      {
        header: 'Case Review Alert Description',
      },
      {
        header: 'Case Review Alert Triggered At',
      },
    ],
  },
  {
    name: 'Bank Accounts',
    headers: [
      {
        header: 'Case Bank Account Id',
      },
      {
        header: 'Case Bank Account Holder Id',
      },
      {
        header: 'Case Bank Account Institution Id',
      },
      {
        header: 'Case Bank Account Routing Number',
      },
      {
        header: 'Case Bank Account Number',
      },
      {
        header: 'Case Bank Account Foreign',
      },
      {
        header: 'Case Bank Account Type',
      },
      {
        header: 'Case Bank Account Type Other',
      },
      {
        header: 'Case Bank Account Subtype',
      },
      {
        header: 'Case Bank Account Subtype Other',
      },
      {
        header: 'Case Bank Account Opened At',
      },
      {
        header: 'Case Bank Account Closed At',
      },
      {
        header: 'Case Bank Account Closed',
      },
      {
        header: 'Case Bank Account Country',
      },
      {
        header: 'Case Bank Account Institution Tin',
      },
      {
        header: 'Case Bank Account Signatory Entity Id',
      },
      {
        header: 'Case Bank Account Signatory Role',
      },
      {
        header: 'Case Bank Account Balance Recorded At',
      },
      {
        header: 'Case Bank Account Balance Amount',
      },
      {
        header: 'Case Bank Account Other Info',
      },
      {
        header: '',
      },
    ],
  },
  {
    name: 'Crypto Addresses',
    headers: [
      {
        header: 'Case Crypto Address Id',
      },
      {
        header: 'Case Crypto Address Account Holder Id',
      },
      {
        header: 'Case Crypto Address',
      },
      {
        header: '',
      },
      {
        header: 'Case Crypto Address Blockchain',
      },
    ],
  },
  {
    name: 'Devices',
    headers: [
      {
        header: 'Case Device Id',
      },
      {
        header: 'Case Device IP Address',
      },
      {
        header: 'Case Device Fingerprint',
      },
      {
        header: 'Case Device Operating System',
      },
      {
        header: 'Case Device Operating System Other',
      },
      {
        header: 'Case Device Browser',
      },
      {
        header: 'Case Device Browser Other',
      },
      {
        header: 'Case Device User Agent',
      },
      {
        header: 'Case Device First Seen At',
      },
      {
        header: 'Case Device Last Seen At',
      },
      {
        header: 'Case Device Owner Id',
      },
      {
        header: 'Case Device Name',
      },
      {
        header: 'Case Device Other Info',
      },
      {
        header: '',
      },
    ],
  },
  {
    name: 'Individuals',
    headers: [
      {
        header: 'Case Individual Id',
      },
      {
        header: 'Case Individual Aka Name',
      },
      {
        header: 'Case Individual Aka Name First Name',
      },
      {
        header: 'Case Individual Aka Name Middle Name',
      },
      {
        header: 'Case Individual Aka Name Last Name',
      },
      {
        header: 'Case Individual Aka Name Suffix',
      },
      {
        header: 'Case Individual Username',
      },
      {
        header: 'Case Individual Date Of Birth',
      },
      {
        header: 'Case Individual Website Url',
      },
      {
        header: 'Case Individual Email Address',
      },
      {
        header: 'Case Individual Address Type',
      },
      {
        header: 'Case Individual Address Type Other',
      },
      {
        header: 'Case Individual Address Address Line 1',
      },
      {
        header: 'Case Individual Address Address Line 2',
      },
      {
        header: 'Case Individual Address Locality',
      },
      {
        header: 'Case Individual Address Sublocality',
      },
      {
        header: 'Case Individual Address Administrative District Level 1',
      },
      {
        header: 'Case Individual Address Postal Code',
      },
      {
        header: 'Case Individual Address Country',
      },
      {
        header: 'Case Individual Phone Number',
      },
      {
        header: 'Case Individual Phone Number Extension',
      },
      {
        header: 'Case Individual Phone Number Type',
      },
      {
        header: 'Case Individual Tin',
      },
      {
        header: 'Case Individual Tin Type',
      },
      {
        header: 'Case Individual Identity Document Id Type',
      },
      {
        header: 'Case Individual Identity Document Id Type Other',
      },
      {
        header: 'Case Individual Identity Document Id Number',
      },
      {
        header: 'Case Individual Identity Document Issuing State',
      },
      {
        header: 'Case Individual Identity Document Issuing Country',
      },
      {
        header: 'Case Individual Identity Document Expires On',
      },
      {
        header: 'Case Individual Institution Relationships',
      },
      {
        header: 'Case Individual Institution Relationship Other',
      },
      {
        header: 'Case Individual Institution Relationship Status',
      },
      {
        header: 'Case Individual Institution Relationship Actioned On',
      },
      {
        header: 'Case Individual Institution Relationship Institution Tin',
      },
      {
        header: 'Case Individual Occupation Company',
      },
      {
        header: 'Case Individual Occupation Industry',
      },
      {
        header: 'Case Individual Occupation',
      },
      {
        header: 'Case Individual Occupation Company Website',
      },
      {
        header: 'Case Individual Occupation NAICS Code',
      },
      {
        header: 'Case Individual Full Name',
      },
      {
        header: 'Case Individual Gender',
      },
      {
        header: 'Case Individual Role In Activity',
      },
      {
        header: '',
      },
      {
        header: 'Case Individual Other Info',
      },
    ],
  },
  {
    name: 'Businesses',
    headers: [
      {
        header: 'Case Business Token',
      },
      {
        header: 'Case Business Id',
      },
      {
        header: 'Case Business Library Business Token',
      },
      {
        header: 'Case Business Legal Name',
      },
      {
        header: '',
      },
      {
        header: '',
      },
      {
        header: '',
      },
      {
        header: '',
      },
      {
        header: 'Case Business Dba Name',
      },
      {
        header: '',
      },
      {
        header: '',
      },
      {
        header: '',
      },
      {
        header: '',
      },
      {
        header: 'Case Business Website Url',
      },
      {
        header: 'Case Business Email Address',
      },
      {
        header: 'Case Business Address Type',
      },
      {
        header: 'Case Business Address Type Other',
      },
      {
        header: 'Case Business Address Address Line 1',
      },
      {
        header: 'Case Business Address Address Line 2',
      },
      {
        header: 'Case Business Address Locality',
      },
      {
        header: 'Case Business Address Sublocality',
      },
      {
        header: 'Case Business Address Administrative District Level 1',
      },
      {
        header: 'Case Business Address Postal Code',
      },
      {
        header: 'Case Business Address Country',
      },
      {
        header: 'Case Business Phone Number',
      },
      {
        header: 'Case Business Phone Number Extension',
      },
      {
        header: 'Case Business Phone Number Type',
      },
      {
        header: 'Case Business Tin',
      },
      {
        header: 'Case Business Tin Type',
      },
      {
        header: 'Case Business Institution Relationships',
      },
      {
        header: 'Case Business Institution Relationship Other',
      },
      {
        header: 'Case Business Institution Relationship Status',
      },
      {
        header: 'Case Business Institution Relationship Actioned On',
      },
      {
        header: 'Case Business Institution Relationship Institution Tin',
      },
      {
        header: 'Case Business Type Of Business',
      },
      {
        header: 'Case Business NAICS Code',
      },
      {
        header: 'Case Business Role In Activity',
      },
      {
        header: '',
      },
      {
        header: 'Case Business Other Info',
      },
    ],
  },
  {
    name: 'Institutions',
    headers: [
      {
        header: 'Case Institution Id',
      },
      {
        header: 'Case Institution Name',
      },
      {
        header: 'Case Institution Type',
      },
      {
        header: 'Case Institution Type Other',
      },
      {
        header: 'Case Institution Subtype',
      },
      {
        header: 'Case Institution Subtype Other',
      },
      {
        header: 'Case Institution Swift Code',
      },
      {
        header: 'Case Institution Primary Federal Regulator',
      },
      {
        header: 'Case Institution Tin',
      },
      {
        header: 'Case Institution Tin Type',
      },
      {
        header: 'Case Institution Identification Number',
      },
      {
        header: 'Case Institution Identification Type',
      },
      {
        header: 'Case Institution Contact Office',
      },
      {
        header: 'Case Institution Alternate Names',
      },
      {
        header: 'Case Institution Role In Transaction',
      },
      {
        header: 'Case Institution Loss To Institution',
      },
      {
        header: 'Case Institution Internal Control Number',
      },
      {
        header: 'Case Institution Address Line 1',
      },
      {
        header: 'Case Institution Address Line 2',
      },
      {
        header: 'Case Institution Locality',
      },
      {
        header: 'Case Institution Administrative District Level 1',
      },
      {
        header: 'Case Institution Postal Code',
      },
      {
        header: 'Case Institution Country',
      },
      {
        header: 'Case Institution Other Info',
      },
      {
        header: '',
      },
    ],
  },
  {
    name: 'Payment Cards',
    headers: [
      {
        header: 'Case Payment Card Id',
      },
      {
        header: 'Case Payment Card Account Holder Id',
      },
      {
        header: 'Case Payment Card Institution Id',
      },
      {
        header: 'Case Payment Card Network',
      },
      {
        header: 'Case Payment Card Account Number',
      },
      {
        header: 'Case Payment Card Bin',
      },
      {
        header: 'Case Payment Card Last 4',
      },
      {
        header: 'Case Payment Card Type',
      },
      {
        header: 'Case Payment Card Expiration Month',
      },
      {
        header: 'Case Payment Card Expiration Year',
      },
      {
        header: 'Case Payment Card Activated At',
      },
      {
        header: 'Case Payment Card Opened At',
      },
      {
        header: 'Case Payment Card Closed At',
      },
      {
        header: 'Case Payment Card Closed',
      },
      {
        header: 'Case Payment Card Credit Limit',
      },
      {
        header: 'Case Payment Card Status',
      },
      {
        header: 'Case Payment Card Other Info',
      },
      {
        header: '',
      },
    ],
  },
  {
    name: 'Transactions',
    headers: [
      {
        header: 'Case Transaction Id',
      },
      {
        header: 'Case Transaction Instrument Type',
      },
      {
        header: 'Case Transaction Currency Issuing Country Code',
      },
      {
        header: 'Case Transaction Description',
      },
      {
        header: 'Case Transaction Amount Currency',
      },
      {
        header: 'Case Transaction Fincen CTR Transaction Type',
      },
      {
        header: 'Case Transaction Fincen CTR Transaction Type Other',
      },
      {
        header: 'Case Transaction Amount',
      },
      {
        header: 'Case Transaction Amount Local Currency',
      },
      {
        header: 'Case Transaction Amount Local',
      },
      {
        header: 'Case Transaction Response Code',
      },
      {
        header: 'Case Transaction Initiated At',
      },
      {
        header: 'Case Transaction Completed At',
      },
      {
        header: 'Case Transaction Status',
      },
      {
        header: 'Case Transaction Other Info',
      },
      {
        header: 'Case Transaction Source Description',
      },
      {
        header: 'Case Transaction Source Location Address Line 1',
      },
      {
        header: 'Case Transaction Source Location Address Line 2',
      },
      {
        header: 'Case Transaction Source Location Locality',
      },
      {
        header: 'Case Transaction Source Location Sublocality',
      },
      {
        header: 'Case Transaction Source Location Administrative District Level 1',
      },
      {
        header: 'Case Transaction Source Location Postal Code',
      },
      {
        header: 'Case Transaction Source Location Country',
      },
      {
        header: 'Case Transaction Source Phone Number',
      },
      {
        header: 'Case Transaction Source Phone Extension',
      },
      {
        header: 'Case Transaction Source Phone Type',
      },
      {
        header: 'Case Transaction Source Entity Type',
        description: 'Only supports: `individual` & `business`',
      },
      {
        header: 'Case Transaction Source Entity Id',
      },
      {
        header: 'Case Transaction Source Account Type',
        description: 'Only supports: `bank_account`, `payment_card`, & `crypto_address`',
      },
      {
        header: 'Case Transaction Source Account Id',
      },
      {
        header: 'Case Transaction Source Amount',
      },
      {
        header: 'Case Transaction Destination Description',
      },
      {
        header: 'Case Transaction Destination Location Address Line 1',
      },
      {
        header: 'Case Transaction Destination Location Address Line 2',
      },
      {
        header: 'Case Transaction Destination Location Locality',
      },
      {
        header: 'Case Transaction Destination Location Sublocality',
      },
      {
        header: 'Case Transaction Destination Location Administrative District Level 1',
      },
      {
        header: 'Case Transaction Destination Location Postal Code',
      },
      {
        header: 'Case Transaction Destination Location Country',
      },
      {
        header: 'Case Transaction Destination Phone Number',
      },
      {
        header: 'Case Transaction Destination Phone Extension',
      },
      {
        header: 'Case Transaction Destination Phone Type',
      },
      {
        header: 'Case Transaction Destination Entity Type',
        description: 'Only supports: `individual` & `business`',
      },
      {
        header: 'Case Transaction Destination Entity Id',
      },
      {
        header: 'Case Transaction Destination Account Type',
        description: 'Only supports: `bank_account`, `payment_card`, & `crypto_address`',
      },
      {
        header: 'Case Transaction Destination Account Id',
      },
      {
        header: 'Case Transaction Destination Amount',
      },
      {
        header: 'Case Transaction Location Address Line 1',
      },
      {
        header: 'Case Transaction Location Address Line 2',
      },
      {
        header: 'Case Transaction Location Locality',
      },
      {
        header: 'Case Transaction Location Sublocality',
      },
      {
        header: 'Case Transaction Location Administrative District Level 1',
      },
      {
        header: 'Case Transaction Location Postal Code',
      },
      {
        header: 'Case Transaction Location Country',
      },
      {
        header: 'Case Transaction Direction',
      },
    ],
  },
]
