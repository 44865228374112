import { useMemo, useRef, useState } from 'react'

import { ClassNames, useTheme } from '@emotion/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { MenuBookOutlined } from '@mui/icons-material'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
// eslint-disable-next-line no-restricted-imports
import { Box, Stack, styled, switchClasses } from '@mui/material'

import { FormProvider, useForm } from 'react-hook-form'

import { z } from 'zod'

import { useSelector } from 'actions/store'
import HbRHFDropdown from 'components/HbComponents/Form/HbInputs/HbDropdown/HbRHFDropdown'
import HbRHFSwitch from 'components/HbComponents/Form/HbInputs/HbSwitch/HbRHFSwitch'
import HbRHFTextInput from 'components/HbComponents/Form/HbInputs/HbTextInput/HbRHFTextInput'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbTooltip } from 'components/HbComponents/HbTooltip'
import { HbText } from 'components/HbComponents/Text/HbText'
import TypeAheadEditor, { EditorApi } from 'components/library/TypeAheadEditor'
import { getOrganizationReviewTypes } from 'helpers/stateHelpers'
import { useFeatureFlag } from 'hooks'
import { TrashOutlineIcon } from 'icons'
import { Template, TemplateError } from 'reducers/narrativeTemplatesReducer'
import { FeatureFlag } from 'types/api'

interface Props {
  template: Template
  error: TemplateError
  onSubmit: (template: Template) => void
  onDelete?: (template: Template) => void
  onCancel: () => void
}

const ScrollContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
  overflowY: 'auto',
}))

const AiStyledSwitch = styled(HbRHFSwitch)(({ theme }) => ({
  [`& .${switchClasses.switchBase}.${switchClasses.checked} + .${switchClasses.track}`]: {
    backgroundImage: theme.gradients.ai,
  },
}))

const StyledTypeAheadEditor = styled(TypeAheadEditor)(() => ({
  flex: 1,
}))

const StyledHelpIcon = styled(HelpOutlineOutlinedIcon)(({ theme }) => ({
  verticalAlign: 'middle',
  height: 16,
  width: 16,
  color: theme.palette.styleguide.textGreyLight,
}))

const StyledTooltipText = styled(HbText)(({ theme }) => ({
  fontWeight: theme.fontWeight.normal,
}))

const StyledDocsLinkIcon = styled(MenuBookOutlined)(({ theme }) => ({
  verticalAlign: 'middle',
  color: theme.palette.styleguide.seaMedium,
  margin: theme.spacing(0, 0.5),
}))

const StyledDocsLink = styled('a')(({ theme }) => ({
  '&, &:hover, &:active, &:focus, &:visited': {
    color: theme.palette.styleguide.seaMedium,
  },
}))

export const formSchema = z.object({
  token: z.string().optional(),
  name: z.string(),
  applicableReviewTypeToken: z.string().optional(),
  content: z.string(),
  aiEnabled: z.boolean().optional(),
})

type FormSchema = z.infer<typeof formSchema>

function NarrativeTemplateForm(props: Props) {
  const { template, onSubmit, onDelete, onCancel, error } = props
  const narrativeGenerationEnabled = useFeatureFlag(FeatureFlag.EnableOpenAiNarrativeGeneratorDemo)
  const reviewTypes = useSelector(getOrganizationReviewTypes)
  const reviewTypeOptions = useMemo(
    () => [{ value: 'any', display: 'Any' }].concat(reviewTypes.map((t) => ({ value: t.token, display: t.name }))),
    [reviewTypes]
  )
  const editorApi = useRef<EditorApi>(null)
  const liquidEnabled = useFeatureFlag(FeatureFlag.EnableSmartValuesInNarrativeTemplates)
  const [showInstructions, setShowInstructions] = useState(template.aiEnabled)

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: template,
    mode: 'onChange',
  })
  const { control, handleSubmit, setValue, formState } = form

  const theme = useTheme()

  return (
    <FormProvider {...form}>
      {error && <div>{error}</div>}
      <ScrollContainer>
        <Box display="flex" flexDirection="row" gap={2} pb={2}>
          <HbRHFTextInput label="Template Name" name="name" value={template.name} />
          <HbRHFDropdown
            fullWidth
            control={control}
            variant="outlined"
            label="Review Type"
            name="applicableReviewTypeToken"
            value={template.applicableReviewTypeToken}
            options={reviewTypeOptions}
            defaultOptionValue="any"
          />
        </Box>
        {(template.aiEnabled || narrativeGenerationEnabled) && (
          <ClassNames>
            {({ css }) => {
              return (
                <Stack direction="row" alignItems="baseline" spacing={theme.spacing(0.5)}>
                  <AiStyledSwitch
                    name="aiEnabled"
                    label="Enable for AI Narrative Generation"
                    value={template.aiEnabled}
                    size="small"
                    onChange={(event) => setShowInstructions(event.target.checked)}
                    formControlLabelProps={{
                      labelPlacement: 'end',
                      className: css({
                        width: 'fit-content',
                        paddingBottom: theme.spacing(1.5),
                        gap: theme.spacing(1),
                      }),
                    }}
                  />
                  <HbTooltip
                    classes={{
                      popper: css({
                        [`& > div`]: {
                          padding: theme.spacing(1.25, 2.25),
                        },
                      }),
                    }}
                    title={
                      <StyledTooltipText color="white">
                        Learn more about AI Narrative Templates in our docs <StyledDocsLinkIcon />
                        <StyledDocsLink
                          href="https://beta.docs.hummingbird.co/using-hummingbird/features-in-beta/narrative-ai/ai-narrative-generation/ai-narrative-templates"
                          target="_blank"
                          rel="noreferrer"
                        >
                          AI Narrative Templates
                        </StyledDocsLink>
                      </StyledTooltipText>
                    }
                  >
                    <StyledHelpIcon />
                  </HbTooltip>
                </Stack>
              )
            }}
          </ClassNames>
        )}
        <StyledTypeAheadEditor
          initialValue={template.content}
          placeholder="Write out your narrative template here..."
          onChange={(value: string) => setValue('content', value)}
          ref={editorApi}
          richText={{ liquid: liquidEnabled, instructions: showInstructions }}
        />
      </ScrollContainer>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        gap={2}
        px={3}
        py={2}
        position="sticky"
        bottom={0}
        zIndex={3}
        boxShadow="0px -4px 16px 0px #0000001A"
      >
        {onDelete && (
          <HbButton label="Delete" onClick={() => onDelete(template)} Icon={TrashOutlineIcon} variant="secondary" />
        )}
        <Box flexGrow={1} />
        <HbButton label="Cancel" onClick={onCancel} variant="secondary" />
        <HbButton
          label="Save"
          disabled={formState.isSubmitting || !formState.isValid}
          onClick={handleSubmit(onSubmit)}
          color="primary"
        />
      </Box>
    </FormProvider>
  )
}

export default NarrativeTemplateForm
