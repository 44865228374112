import { ReactElement, ReactNode } from 'react'

// Get a base text field from Material UI
import { Check, ExpandMore } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { Typography, ListItemText, MenuItem, TextField, TextFieldProps, styled } from '@mui/material'

import { FastField, FieldAttributes, FieldProps } from 'formik'

import { CheckboxProps as CheckboxPropsType, HbCheckbox } from 'components/HbComponents/Form/HbCheckbox'
import { opacify } from 'helpers/colors'
import { Maybe } from 'types/api'

import { fieldToTextField, TextInputProps } from './TextInput'

export interface Option {
  display: string
  value: string | number | boolean
  custom?: { [key: string]: string | number | boolean | null | undefined }
}

export type SelectedValue = string | number | boolean | Array<string | number | boolean | null>

export type RenderValue = (
  multiple: boolean,
  options: Option[],
  placeholder?: Option | null
) => (selected: SelectedValue) => ReactNode

const renderValue: RenderValue = (multiple: boolean, options: Option[], _placeholder?: Option | null) => {
  return (selected: SelectedValue) => {
    // if we allow multiple selections, `selected` may be an array
    if (Array.isArray(selected)) {
      return options
        .filter(({ value }) => selected.includes(value))
        .map(({ display }) => display)
        .join(', ')
    }

    // otherwise we are only expecting a single selection
    return options.filter(({ value }) => selected === value).map(({ display }) => display)
  }
}

// Export the icon to make it easier in case it's needed elsewhere
export const SelectIcon = ExpandMore

export type HbSelectProps = TextFieldProps & {
  options: Option[]
  customComponent?: (option: Option) => ReactElement
  value?: string | number | boolean | Array<Maybe<string> | Maybe<number> | Maybe<boolean>> | null
  readOnly?: boolean
  multiple?: boolean
  placeholder?: string
  disablePlaceholderSelect?: boolean
  showPlaceholderInMenu?: boolean
  testId?: string
  defaultOptionValue?: string | null
  CheckboxProps?: CheckboxPropsType
  renderValue?: RenderValue
  hideCheckbox?: boolean
  disableOptions?: boolean
}

const StyledPlaceholderMenuItem = styled(MenuItem)(({ theme }) => ({
  '&&': {
    minHeight: theme.spacing(5),
  },
  '&.MuiListItem-root.Mui-selected:hover': {
    color: `${theme.palette.text.primary}`,
    backgroundColor: `${theme.palette.background.light}`,
  },
  '&.Mui-selected': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.light,
  },
}))

const StyledOptionMenuItem = styled(MenuItem)(({ theme }) => ({
  '&&': {
    maxHeight: theme.spacing(5.37),
    minHeight: theme.spacing(5),
  },
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.styleguide.greyF,
    '& input[type="checkbox"] + span': {
      backgroundColor: theme.palette.styleguide.greyF,
      borderColor: theme.palette.styleguide.dark,
    },
    '& input[type="checkbox"]:checked + span': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: opacify(theme.palette.primary.main, 0.1),
  },
}))

const StyledCheckIcon = styled(Check)(() => ({
  visibility: 'hidden',
  '.Mui-selected &': {
    visibility: 'visible',
  },
}))

const StyledCheckbox = styled(HbCheckbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(),
  },
}))

export const StyledOptionText = styled(ListItemText)(() => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}))

// Exposes a basic select that matches our styling and allows for easier use with an option array.
export function SelectBase({
  options,
  customComponent,
  value,
  multiple = false,
  children,
  SelectProps,
  CheckboxProps,
  placeholder,
  testId,
  readOnly,
  variant,
  defaultOptionValue = '',
  renderValue: renderValueProp,
  disablePlaceholderSelect = false,
  showPlaceholderInMenu = true,
  hideCheckbox = false,
  disableOptions = false,
  ...props
}: HbSelectProps) {
  const getRenderValue = renderValueProp || renderValue

  return (
    <TextField
      SelectProps={{
        IconComponent: SelectIcon,
        multiple: multiple || false,
        renderValue: getRenderValue(
          multiple,
          options,
          placeholder ? { display: placeholder, value: defaultOptionValue || '' } : null
        ),
        displayEmpty: Boolean(placeholder),
        readOnly,
        MenuProps: multiple
          ? {
              // this is to prevent the menu from jumping around upon selecting an item
              variant: 'menu',
            }
          : undefined,
        ...SelectProps,
      }}
      data-testid={testId}
      value={value || ''}
      variant={variant}
      select
      {...props}
    >
      {placeholder && showPlaceholderInMenu && (
        <StyledPlaceholderMenuItem
          key="placeholder"
          value={defaultOptionValue as any}
          disabled={disablePlaceholderSelect}
        >
          <Typography color="textSecondary" variant="body1">
            {placeholder}
          </Typography>
        </StyledPlaceholderMenuItem>
      )}
      {options &&
        options.map((option) => (
          <StyledOptionMenuItem
            key={String(option.value)}
            /**
             * Typing the MenuItem `value` as `any` explicitly:
             * https://github.com/mui/material-ui/issues/14286#issuecomment-514971203:
             * The MenuItem doesn't actually get the `value` prop,
             * because Select intercepts the value: https://github.com/mui/material-ui/blob/ff7f4e0f8be4fd950e00ba892b90b350054be553/packages/mui-material/src/Select/SelectInput.js#L412.
             * In Select, the `value` is typed as `any`:
             * https://mui.com/material-ui/api/select/#props
             */
            value={option.value as any}
            disabled={readOnly || disableOptions}
            data-testid={`option_${option.display}`}
          >
            {multiple && !hideCheckbox && (
              <StyledCheckbox
                {...CheckboxProps}
                readOnly={readOnly}
                checked={Array.isArray(value) ? value?.includes(option.value) : value === option.value}
              />
            )}
            {customComponent ? (
              customComponent(option)
            ) : (
              <StyledOptionText disableTypography primary={option.display} />
            )}
            {!multiple && <StyledCheckIcon />}
          </StyledOptionMenuItem>
        ))}
      {children}
    </TextField>
  )
}

// Exposes an implementation of TextField with select, configured to work with Formik and display
// according to Hummingbird styling.
export const SelectInput = (props: HbSelectProps & TextInputProps & FieldProps<unknown>) => (
  <SelectBase {...(fieldToTextField(props) as unknown as HbSelectProps)} />
)

export type HbSelectFieldProps = HbSelectProps & FieldAttributes<unknown>

// Always wrap <SelectInput> with <FastField> since it's slow to re-render
export const SelectField = (props: HbSelectFieldProps) => <FastField {...props} component={SelectInput} />
