import { useMemo } from 'react'

import { DocumentNode, gql, QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client'

import { pluckEdgeNodes } from 'helpers/apolloHelpers'

import {
  GetOrganizationBadgesQuery,
  GetOrganizationBadgesQueryVariables,
} from './__generated__/useOrganizationBadges.generated'

/**
 * Just the bare-bones details needed to render a badge
 */
export const BASE_BADGE_FRAGMENT = gql`
  fragment BaseBadge on Badge {
    token
    color
    displayName
  }
`

export const CURRENT_ORGANIZATION_BADGES_FRAGMENT = gql`
  fragment CurrentOrganizationBadges on Organization {
    token
    # There is a max of 100 badges per organization
    # TODO: Add pagination
    badges(first: 100) {
      totalCount
      edges {
        node {
          ...BaseBadge
        }
      }
    }
  }
  ${BASE_BADGE_FRAGMENT}
`

export const GET_ORGANIZATION_BADGES = gql`
  query GetOrganizationBadges {
    currentOrganization {
      ...CurrentOrganizationBadges
    }
  }
  ${CURRENT_ORGANIZATION_BADGES_FRAGMENT}
`

export const useOrganizationBadges = <
  Query extends GetOrganizationBadgesQuery = GetOrganizationBadgesQuery,
  QueryVariables = GetOrganizationBadgesQueryVariables,
>(
  query: DocumentNode | TypedDocumentNode<Query, QueryVariables> = GET_ORGANIZATION_BADGES,
  options?: QueryHookOptions<Query, QueryVariables>
) => {
  const res = useQuery<Query, QueryVariables>(query, options)

  const badges = useMemo(() => pluckEdgeNodes(res.data?.currentOrganization.badges.edges), [res.data])

  return { ...res, badges }
}
