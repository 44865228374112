export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AlertSubject: ['LibraryBusiness', 'LibraryPerson'],
    AttachmentMalwareScanFields: ['Attachment', 'LibraryAttachment', 'SurveyResponseFileUpload'],
    AutomationEvent: [
      'AutomationCaseEvent',
      'AutomationDatasourceEvent',
      'AutomationFilingEvent',
      'AutomationLibraryEvent',
      'AutomationMiddeskBusinessEvent',
      'AutomationReviewEvent',
    ],
    AutomationRuleTemplate: [
      'EmptyRuleTemplate',
      'ScheduledRuleTemplate',
      'ScheduledWithTriggerRuleTemplate',
      'TriggeredRuleTemplate',
    ],
    AutomationRuleTemplateBase: [
      'EmptyRuleTemplate',
      'ScheduledRuleTemplate',
      'ScheduledWithTriggerRuleTemplate',
      'TriggeredRuleTemplate',
    ],
    BaseAddress: ['Address', 'BusinessAddress', 'PersonAddress'],
    BaseAutomationEvent: [
      'AutomationCaseEvent',
      'AutomationDatasourceEvent',
      'AutomationFilingEvent',
      'AutomationLibraryEvent',
      'AutomationMiddeskBusinessEvent',
      'AutomationReviewEvent',
    ],
    CanonicalAutomationRule: [
      'BlankAutomationRule',
      'ScheduleAutomationRule',
      'ScheduleWithTriggerAutomationRule',
      'TriggerAutomationRule',
    ],
    CanonicalAutomationRuleBase: [
      'BlankAutomationRule',
      'ScheduleAutomationRule',
      'ScheduleWithTriggerAutomationRule',
      'TriggerAutomationRule',
    ],
    CaseFile: ['Attachment', 'InformationRequestResponse', 'ReviewDocument', 'SurveyResponseFileUpload'],
    Download: ['EmailDownload', 'FileDownloadAuthorization'],
    EmbeddedAnalyticsDashboard: ['OmniEmbeddedAnalyticsDashboard'],
    FileInterface: ['Attachment', 'InformationRequestResponse', 'ReviewDocument', 'SurveyResponseFileUpload'],
    FilingEventDetail: ['FilingEventInfoTable', 'FilingEventParagraph'],
    FilingJurisdictionRules: ['GenericFilingJurisdictionRules', 'GoAMLFilingJurisdictionRules'],
    FormConfigurationByPathUnion: ['InfoRequestPath', 'TipIntakeConfiguration'],
    InvestigationSearchResult: [
      'InvestigationLibraryBankAccountSearchResult',
      'InvestigationLibraryBusinessSearchResult',
      'InvestigationLibraryCryptoAddressSearchResult',
      'InvestigationLibraryDeviceSearchResult',
      'InvestigationLibraryFinancialInstitutionSearchResult',
      'InvestigationLibraryPaymentCardSearchResult',
      'InvestigationLibraryPersonSearchResult',
      'InvestigationLibraryProductSearchResult',
    ],
    InvestigationSecondarySubject: [
      'InvestigationSubjectBankAccount',
      'InvestigationSubjectCryptoAddress',
      'InvestigationSubjectDevice',
      'InvestigationSubjectPaymentCard',
    ],
    InvestigationSubject: [
      'InvestigationSubjectBankAccount',
      'InvestigationSubjectBusiness',
      'InvestigationSubjectCryptoAddress',
      'InvestigationSubjectDevice',
      'InvestigationSubjectFinancialInstitution',
      'InvestigationSubjectPaymentCard',
      'InvestigationSubjectPerson',
      'InvestigationSubjectProduct',
    ],
    InvestigationSubjectEntityShared: ['InvestigationSubjectBusiness', 'InvestigationSubjectPerson'],
    Job: [
      'AlertsExportJob',
      'AsyncSummarizationJob',
      'DashboardExportJob',
      'HistoryExportJob',
      'MiddeskBusinessJob',
      'ReviewNarrativeGenerationJob',
      'ScreeningSearchJob',
      'ThomsonReutersBusinessReportJob',
      'ThomsonReutersBusinessSearchJob',
      'ThomsonReutersPersonReportJob',
      'ThomsonReutersPersonSearchJob',
      'WebhookEndpointTestJob',
    ],
    LibraryAccount: ['LibraryBankAccount', 'LibraryCryptoAddress', 'LibraryPaymentCard'],
    LibraryAccountsAndDevicesFields: ['LibraryBusiness', 'LibraryPerson'],
    LibraryBusinessOwnerRelationshipMemberFields: ['LibraryBusiness', 'LibraryPerson'],
    LibraryEntity: ['LibraryBusiness', 'LibraryPerson'],
    LibraryEntry: [
      'LibraryBankAccount',
      'LibraryBusiness',
      'LibraryCryptoAddress',
      'LibraryDevice',
      'LibraryFinancialInstitution',
      'LibraryPaymentCard',
      'LibraryPerson',
      'LibraryProduct',
    ],
    LibraryInstitutionRelationshipMemberFields: ['LibraryBusiness', 'LibraryPerson'],
    LibraryNestedObject: [
      'LibraryBankAccountBalance',
      'LibraryBusinessOwner',
      'LibraryInstitutionRelationship',
      'LibraryRelationship',
      'LibraryRelationshipDefinition',
      'LibrarySignatory',
      'LibraryTag',
      'LibraryTagDefinition',
    ],
    LibraryObject: [
      'LibraryBankAccount',
      'LibraryBusiness',
      'LibraryCryptoAddress',
      'LibraryDevice',
      'LibraryFinancialInstitution',
      'LibraryPaymentCard',
      'LibraryPerson',
      'LibraryProduct',
    ],
    LibraryRelationshipMember: ['LibraryBusiness', 'LibraryFinancialInstitution', 'LibraryPerson', 'LibraryProduct'],
    LibraryRelationshipMemberFields: [
      'LibraryBusiness',
      'LibraryFinancialInstitution',
      'LibraryPerson',
      'LibraryProduct',
    ],
    LibraryScreeningSearchMetadata: ['LibraryPerson'],
    LibraryScreeningSearchResultsFields: ['LibraryPerson'],
    RequestForInformation: ['CompletedRequestForInformation', 'IncompleteRequestForInformation'],
    ReviewTypeTask: [
      'TaskTypeAlertReview',
      'TaskTypeCTRFilingDetails',
      'TaskTypeCaseInformation',
      'TaskTypeCreateReviewSettings',
      'TaskTypeCyberEventIndicators',
      'TaskTypeDisputeSurvey',
      'TaskTypeFINTRACFilingDetails',
      'TaskTypeFilingDetails',
      'TaskTypeFiuFilingReportIdentifier',
      'TaskTypeGoAMLFilingDetails',
      'TaskTypeGoAMLFilingReportId',
      'TaskTypeInformationSharing',
      'TaskTypeNetActivity',
      'TaskTypeRequestForInformationDetails',
      'TaskTypeRequestForInformationPrepopulation',
      'TaskTypeRequestForInformationResponseView',
      'TaskTypeResearch',
      'TaskTypeSurvey',
      'TaskTypeTagging',
      'TaskTypeTextEntry',
      'TaskTypeTipView',
    ],
    SurveyResponseEntry: ['SurveyResponseEntryArray', 'SurveyResponseEntryBoolean', 'SurveyResponseEntryString'],
    TaggedObject: ['Investigation', 'Review'],
    TriggerableAutomationRule: ['ScheduleWithTriggerAutomationRule', 'TriggerAutomationRule'],
  },
}
export default result
