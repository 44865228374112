// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'

import { reviewTimeSensitivity, timeLeft } from 'helpers/uiHelpers'
import { useOrgTimeZone } from 'hooks/DateFormatHooks'
import { ClockIcon } from 'icons'

import { CardReview } from './ReviewsCard'
import { getTimeLeftAbbreviatedText } from './helpers'

const CompletedContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
  columnGap: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
}))

interface TimeLeftTextStyleProps {
  timeSensitivity: 'alert' | 'overdue' | null
}

const TimeLeftText = styled(HbText)<TimeLeftTextStyleProps>(({ theme, timeSensitivity }) => ({
  marginTop: theme.spacing(0.5),
  '&&&': {
    color:
      timeSensitivity === 'alert'
        ? theme.palette.warning.main
        : timeSensitivity === 'overdue'
          ? theme.palette.error.main
          : theme.palette.action.active,
  },
}))

interface TimelineProps {
  className?: string
  review: CardReview
}

export const LegacyTimeline = ({ review }: TimelineProps) => {
  const { actionDecisions, dueAt, isCompleted, openAt } = review

  const orgTimeZone = useOrgTimeZone()
  const timeSensitivity = reviewTimeSensitivity({ isCompleted, reviewDueAt: dueAt }, orgTimeZone)

  const actionLabel = actionDecisions?.[0]?.choice?.label

  return isCompleted ? (
    <CompletedContainer>
      <HbText color="disabled" size="s">
        Completed
      </HbText>
      {actionLabel && (
        <>
          <HbText color="disabled" size="s">
            ▪
          </HbText>
          <HbText color="disabled" size="s">
            {actionLabel}
          </HbText>
        </>
      )}
    </CompletedContainer>
  ) : (
    <TimeLeftText timeSensitivity={timeSensitivity} size="s">
      {timeLeft({ reviewDueAt: dueAt, openAt, state: review.state }, orgTimeZone, false) || 'Open'}
    </TimeLeftText>
  )
}

const StyledClockIcon = styled(ClockIcon)(() => ({
  width: 12,
  height: 12,
  verticalAlign: 'middle',
  marginBottom: 2,
}))

const TimelineText = styled(HbText)<TimeLeftTextStyleProps>(({ theme, timeSensitivity }) => ({
  '&&&': {
    color:
      timeSensitivity === 'alert'
        ? theme.palette.warning.main
        : timeSensitivity === 'overdue'
          ? theme.palette.error.main
          : theme.palette.action.active,
  },
}))

export const Timeline = ({ className, review }: TimelineProps) => {
  const { dueAt, isCompleted } = review

  const orgTimeZone = useOrgTimeZone()
  const timeSensitivity = reviewTimeSensitivity({ isCompleted, reviewDueAt: dueAt }, orgTimeZone)

  const timeLeftText = getTimeLeftAbbreviatedText(review, orgTimeZone)

  return (
    <TimelineText className={className} timeSensitivity={timeSensitivity} size="s">
      {timeLeftText && (
        <>
          <StyledClockIcon /> {timeLeftText}
        </>
      )}
    </TimelineText>
  )
}
