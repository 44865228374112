import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { SnackbarKey } from 'notistack'

import {
  ScreeningCurrentSearch,
  ScreeningUIState,
  ScreeningUIStateBase,
  SelectedDetail,
} from 'components/cases/Tabs/SanctionsScreening/ScreeningTab.types'
import { ScreeningSearchRowFragment } from 'components/cases/Tabs/SanctionsScreening/__generated__/Screening.queries.generated'
import { ScreeningSearchResultMatchStatus } from 'types/api'

export type ScreeningState = {
  currentSearches: Array<ScreeningCurrentSearch>
  UIState: {
    [libraryToken: string]: ScreeningUIState[]
  }
}

export const initialState: ScreeningState = {
  currentSearches: [],
  UIState: {},
}

export const getUIStateIndex = (
  state: ScreeningState,
  {
    libraryToken,
    investigationToken,
    provider,
  }: Pick<ScreeningUIState, 'investigationToken' | 'libraryToken' | 'provider'>
) => {
  const UIStates = state.UIState[libraryToken]
  if (!UIStates?.length) {
    return -1
  }
  // we need to find the index of the UIState that matches the investigationToken AND provider
  return UIStates.findIndex(
    (UIState) => UIState.investigationToken === investigationToken && UIState.provider === provider
  )
}

const ScreeningSlice = createSlice({
  name: 'Screening',
  initialState,
  reducers: {
    storeFormState(
      state,
      action: PayloadAction<Pick<ScreeningUIState, 'libraryToken' | 'investigationToken' | 'provider' | 'searchFields'>>
    ) {
      const { libraryToken } = action.payload

      const stateIndex = getUIStateIndex(state, action.payload)
      const oldState = state.UIState[libraryToken]?.[stateIndex] || {}
      const { status } = oldState

      if (status === 'unsubmitted' || !status) {
        const nextState = {
          ...action.payload,
          status: 'unsubmitted' as const,
        }
        if (stateIndex > -1) {
          state.UIState[libraryToken][stateIndex] = nextState
        } else if (state.UIState[libraryToken]) {
          state.UIState[libraryToken].push(nextState)
        } else {
          state.UIState[libraryToken] = [nextState]
        }
      }
    },
    setPolling(
      state,
      action: PayloadAction<ScreeningUIStateBase & { snackbarKey: SnackbarKey | null; jobToken: string }>
    ) {
      const { libraryToken } = action.payload
      const stateIndex = getUIStateIndex(state, action.payload)
      const nextState = {
        ...action.payload,
        status: 'polling' as const,
      }
      if (stateIndex > -1) {
        state.UIState[libraryToken][stateIndex] = nextState
      } else if (state.UIState[libraryToken]) {
        state.UIState[libraryToken].push(nextState)
      } else {
        state.UIState[libraryToken] = [nextState]
      }
    },
    addCurrentSearch(state, action: PayloadAction<ScreeningCurrentSearch>) {
      state.currentSearches.push(action.payload)
    },
    removeCurrentSearch(state, action: PayloadAction<{ jobToken: string }>) {
      state.currentSearches = state.currentSearches.filter((search) => search.jobToken !== action.payload.jobToken)
    },
    initiateAsyncResults(state, action: PayloadAction<ScreeningUIStateBase>) {
      const { libraryToken } = action.payload
      const stateIndex = getUIStateIndex(state, action.payload)
      if (stateIndex > -1) {
        state.UIState[libraryToken][stateIndex] = {
          status: 'processed',
          ...action.payload,
        }
      }
    },
    initiateSyncSearch(state, action: PayloadAction<ScreeningUIStateBase>) {
      const { libraryToken } = action.payload
      const stateIndex = getUIStateIndex(state, action.payload)
      const nextState = {
        status: 'submitted' as const,
        ...action.payload,
      }
      if (stateIndex > -1) {
        state.UIState[libraryToken][stateIndex] = nextState
      } else if (state.UIState[libraryToken]) {
        state.UIState[libraryToken].push(nextState)
      } else {
        state.UIState[libraryToken] = [nextState]
      }
    },
    setSelectedSearchHit(
      state,
      action: PayloadAction<
        {
          searchHit: ScreeningSearchRowFragment
          screeningSearchToken: string
        } & Pick<ScreeningUIState, 'libraryToken' | 'investigationToken' | 'provider'>
      >
    ) {
      const { libraryToken, searchHit, screeningSearchToken } = action.payload
      const stateIndex = getUIStateIndex(state, action.payload)
      const oldState = state.UIState[libraryToken]?.[stateIndex] || {}
      const { status } = oldState
      if (stateIndex > -1) {
        if (status === 'submitted' || status === 'processed') {
          state.UIState[libraryToken][stateIndex].selectedSearchHit = searchHit
          state.UIState[libraryToken][stateIndex].screeningSearchToken = screeningSearchToken
        }
      }
    },
    clearSelectedSearchHit(
      state,
      action: PayloadAction<Pick<ScreeningUIState, 'libraryToken' | 'investigationToken' | 'provider'>>
    ) {
      const { libraryToken } = action.payload
      const stateIndex = getUIStateIndex(state, action.payload)
      if (stateIndex > -1) {
        state.UIState[libraryToken][stateIndex].selectedSearchHit = null
        state.UIState[libraryToken][stateIndex].screeningSearchToken = ''
      }
    },
    updateSearchHitMatchStatus(
      state,
      action: PayloadAction<
        {
          matchStatus: ScreeningSearchResultMatchStatus
        } & Pick<ScreeningUIState, 'libraryToken' | 'investigationToken' | 'provider'>
      >
    ) {
      const { libraryToken, matchStatus } = action.payload
      const stateIndex = getUIStateIndex(state, action.payload)
      const oldState = state.UIState[libraryToken]?.[stateIndex] || {}
      const { status } = oldState
      if (stateIndex > -1) {
        if (status === 'submitted' || status === 'processed') {
          const { selectedSearchHit } = state.UIState[libraryToken][stateIndex]
          if (typeof selectedSearchHit !== 'undefined' && selectedSearchHit !== null) {
            selectedSearchHit.searchHitFields = {
              ...selectedSearchHit.searchHitFields,
              matchStatus,
            }
          }
        }
      }
    },
    setSelectedDetail(
      state,
      action: PayloadAction<
        {
          detail?: SelectedDetail | null
        } & Pick<ScreeningUIState, 'libraryToken' | 'investigationToken' | 'provider'>
      >
    ) {
      const { libraryToken, detail } = action.payload
      const stateIndex = getUIStateIndex(state, action.payload)
      const oldState = state.UIState[libraryToken]?.[stateIndex] || {}
      const { status } = oldState
      if (stateIndex > -1) {
        if (status === 'submitted' || status === 'processed') {
          state.UIState[libraryToken][stateIndex].selectedDetail = detail || undefined
        }
      }
    },

    resetSearch(
      state,
      action: PayloadAction<
        Pick<ScreeningUIState, 'libraryToken' | 'investigationToken' | 'provider' | 'screeningSearchToken'>
      >
    ) {
      const { libraryToken, investigationToken, provider } = action.payload
      const stateIndex = getUIStateIndex(state, action.payload)
      const oldState = state.UIState[libraryToken]?.[stateIndex] || {}

      if (stateIndex > -1) {
        state.UIState[libraryToken][stateIndex] = {
          status: 'unsubmitted',
          searchFields: oldState.searchFields,
          libraryToken,
          investigationToken,
          provider,
        }
      }
    },
  },
})

export const {
  initiateSyncSearch,
  initiateAsyncResults,
  addCurrentSearch,
  removeCurrentSearch,
  storeFormState,
  resetSearch,
  setSelectedSearchHit,
  clearSelectedSearchHit,
  updateSearchHitMatchStatus,
  setSelectedDetail,
  setPolling,
} = ScreeningSlice.actions
export default ScreeningSlice.reducer
