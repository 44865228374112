import {
  BackHandRounded,
  CancelRounded,
  CheckCircle,
  CreateNewFolderRounded,
  DraftsRounded,
  HistoryToggleOffRounded,
  PendingOutlined,
  TaskRounded,
} from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { HbTag } from 'components/HbComponents/HbTag'
import { HbTooltip } from 'components/HbComponents/HbTooltip'
import { Hue } from 'components/colors'
import IncompleteCircleIcon from 'icons/IncompleteCircleIcon'
import { InvestigationReviewStatusEnum } from 'types/api'

export interface StatusReviewDetails {
  status: InvestigationReviewStatusEnum
}

const StyledHbTag = styled(HbTag)(({ theme }) => ({
  '&&': {
    borderRadius: theme.shape.largePillContainer.borderRadius,
  },
}))

const workStartedEnabledFormattedReviewStatuses: Record<InvestigationReviewStatusEnum, string> = {
  [InvestigationReviewStatusEnum.Approved]: 'Approved',
  [InvestigationReviewStatusEnum.Cancelled]: 'Cancelled',
  [InvestigationReviewStatusEnum.Completed]: 'Completed',
  [InvestigationReviewStatusEnum.Created]: 'Created',
  [InvestigationReviewStatusEnum.ChangesRequested]: 'Changes Requested',
  [InvestigationReviewStatusEnum.ReadyForReview]: 'Ready For Review',
  [InvestigationReviewStatusEnum.Opened]: 'Opened',
  [InvestigationReviewStatusEnum.Pending]: 'Pending',
  [InvestigationReviewStatusEnum.WorkStarted]: 'Work Started',
}

const config: Record<string, { icon: JSX.Element; themeColor: Hue }> = {
  [InvestigationReviewStatusEnum.Approved]: { icon: <CheckCircle />, themeColor: 'mint' },
  [InvestigationReviewStatusEnum.Cancelled]: { icon: <CancelRounded />, themeColor: 'rose' },
  [InvestigationReviewStatusEnum.ChangesRequested]: { icon: <BackHandRounded />, themeColor: 'coral' },
  [InvestigationReviewStatusEnum.Completed]: { icon: <TaskRounded />, themeColor: 'cyan' },
  [InvestigationReviewStatusEnum.Created]: { icon: <CreateNewFolderRounded />, themeColor: 'plum' },
  [InvestigationReviewStatusEnum.Opened]: { icon: <DraftsRounded />, themeColor: 'lemon' },
  [InvestigationReviewStatusEnum.Pending]: { icon: <HistoryToggleOffRounded />, themeColor: 'ivory' },
  [InvestigationReviewStatusEnum.ReadyForReview]: { icon: <PendingOutlined />, themeColor: 'salmon' },
  [InvestigationReviewStatusEnum.WorkStarted]: { icon: <IncompleteCircleIcon />, themeColor: 'sea' },
}

const getStatus = ({ status }: StatusReviewDetails) => {
  if (!status) return null

  return workStartedEnabledFormattedReviewStatuses[status]
}

const getValues = (review: StatusReviewDetails) => {
  const label = getStatus(review)
  if (!label) return null
  const { icon, themeColor } = config[review.status]

  return { icon, label, themeColor }
}

interface StatusProps {
  review: StatusReviewDetails
}

export const Status = ({ review }: StatusProps) => {
  const configValues = getValues(review)
  if (!configValues) return null
  const { icon, label, themeColor } = configValues

  return <StyledHbTag label={label} themeColor={themeColor} icon={icon} />
}

const StyledHbTooltip = styled(HbTooltip)(() => ({
  maxWidth: '100%',
  width: 'fit-content',
}))

interface StatusWithTooltipProps {
  review: StatusReviewDetails
}

const TOOLTIP_ENTER_DELAY = 250

export const StatusWithTooltip = ({ review }: StatusWithTooltipProps) => {
  const configValues = getValues(review)
  if (!configValues) return null
  const { label } = configValues

  if (label === 'Ready For Review') {
    return (
      <StyledHbTooltip
        title="Only 1 Review Required"
        enterDelay={TOOLTIP_ENTER_DELAY}
        enterNextDelay={TOOLTIP_ENTER_DELAY}
      >
        <div>
          <Status review={review} />
        </div>
      </StyledHbTooltip>
    )
  }

  return <Status review={review} />
}
