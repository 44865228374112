import { useCallback } from 'react'

import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Controller, ControllerRenderProps } from 'react-hook-form'

import invariant from 'tiny-invariant'

import { HbText } from 'components/HbComponents/Text/HbText'
import { useThinControlStyles } from 'components/pages/automations/editor/TriggerFilterEditor/styles'
import { Theme } from 'types/hb'

import { FormSchemaReturnType } from '../formSchema'

import { InputRelativeIncrementValue, SelectRelativeIncrementUnit, useSharedStyles } from './shared'

const useStyles = makeStyles((theme: Theme) => ({
  dateInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
}))

type ReviewUpdateRelativeToParam = 'currentDate' | 'dueDate' | 'createdDate'

const UPDATE_REVIEW_DUE_DATE_PARAMS_PATH = 'actionParams.updateReviewDueDateParams'
export default function CustomizeUpdateReviewDueDateAction({ form }: { form: FormSchemaReturnType }) {
  const classes = useStyles()
  const controlClasses = useThinControlStyles()
  const selectClasses = {
    className: controlClasses.control,
    classes: { root: controlClasses.selectRoot },
  }
  const { container: valueInputContainer } = useSharedStyles()

  const { watch, control, setValue } = form
  const {
    updateToDateRelativeIncrementValue: dateVal,
    relativeToCreatedDate,
    relativeToDueDate,
  } = watch(UPDATE_REVIEW_DUE_DATE_PARAMS_PATH) || {}

  // Re-derive "relative" display value from underlying form values
  const relative: ReviewUpdateRelativeToParam | undefined =
    !relativeToDueDate && !relativeToCreatedDate
      ? 'currentDate'
      : relativeToDueDate
        ? 'dueDate'
        : relativeToCreatedDate
          ? 'createdDate'
          : undefined
  invariant(relative !== undefined)

  const setValues = useCallback(
    (dueDate: boolean, createdDate: boolean) => {
      setValue(`${UPDATE_REVIEW_DUE_DATE_PARAMS_PATH}.relativeToDueDate`, dueDate, { shouldDirty: true })
      setValue(`${UPDATE_REVIEW_DUE_DATE_PARAMS_PATH}.relativeToCreatedDate`, createdDate, { shouldDirty: true })
    },
    [setValue]
  )

  const handleChange = (event: SelectChangeEvent) => {
    switch (event.target.value) {
      case 'dueDate': {
        setValues(true, false)
        break
      }
      case 'createdDate': {
        setValues(false, true)
        break
      }
      case 'currentDate': {
        setValues(false, false)
        break
      }
      default: {
        break
      }
    }
  }

  return (
    <div className={classes.dateInfo}>
      <div className={classes.title}>
        <HbText size="s" color="secondary" bold>
          Due Date
        </HbText>
      </div>
      <div className={valueInputContainer}>
        <Controller
          name={`${UPDATE_REVIEW_DUE_DATE_PARAMS_PATH}.updateToDateRelativeIncrementValue`}
          render={({ field }: { field: ControllerRenderProps }) => <InputRelativeIncrementValue valueField={field} />}
        />
        <Controller
          name={`${UPDATE_REVIEW_DUE_DATE_PARAMS_PATH}.updateToDateRelativeIncrementUnit`}
          control={control}
          render={({ field }) => <SelectRelativeIncrementUnit inputProps={field} currentDateValue={dateVal} />}
        />
        <Select<ReviewUpdateRelativeToParam>
          value={relative}
          {...selectClasses}
          variant="outlined"
          onChange={handleChange}
        >
          <MenuItem value="createdDate">after review was created</MenuItem>
          <MenuItem value="dueDate">after current review due date</MenuItem>
          <MenuItem value="currentDate">after trigger condition is met</MenuItem>
        </Select>
      </div>
    </div>
  )
}
