import React, { ChangeEvent, useMemo } from 'react'

import { gql, useQuery } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports

import { BoltOutlined } from '@mui/icons-material'
import { Autocomplete, MenuItem, Select, TextField } from '@mui/material'

import { startCase } from 'lodash'
import pluralize, { singular } from 'pluralize'
import { useFieldArray } from 'react-hook-form'

import { GQLError } from 'components/GQLError'
import { InputContainer } from 'components/HbComponents/Form/Inputs'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'

import Loader from 'components/library/Loader'

import { caseHydraHeaders, Header } from 'helpers/caseHydraHeaders.generated'
import { crmHydraHeaders } from 'helpers/crmHydraHeaders.generated'
import { AddIcon, TrashOutlineIcon } from 'icons'
import { UniversalInterpreterTypeEnum } from 'types/api'

import styles from './CustomizeDataImportAction.module.css'
import {
  DataImportActionDataSourceQuery,
  DataImportActionDataSourceQueryVariables,
} from './__generated__/CustomizeDataImportAction.generated'

import type { FormSchemaReturnType } from '../formSchema'

const DATASOURCE_QUERY = gql`
  query DataImportActionDataSource($token: ID!) {
    datasource(token: $token) {
      token
      name
      columns {
        token
        name
        type
      }
      datatable {
        token
        location
        filterClauseColumn {
          token
          name
        }
      }
    }
    personCustomFields: otherInfoLabelSearch(labelType: person, query: null) {
      token
      label
    }
    bankAccountCustomFields: otherInfoLabelSearch(labelType: bank_account, query: null) {
      token
      label
    }
    businessCustomFields: otherInfoLabelSearch(labelType: business, query: null) {
      token
      label
    }
    reviewCustomFields: otherInfoLabelSearch(labelType: review, query: null) {
      token
      label
    }
    transactionCustomFields: otherInfoLabelSearch(labelType: transaction, query: null) {
      token
      label
    }
  }
`

const CREATE_DATA_IMPORT_PARAMS_PATH = 'actionParams.dataImportParams'

function getCustomFieldHydraHeader(interpreterType: UniversalInterpreterTypeEnum, prefix: string) {
  if (interpreterType === UniversalInterpreterTypeEnum.Case) {
    return `case ${prefix} other info`
  }

  return `${prefix} other info`
}

// Hacky stuff, should have a better implementation for fetching these depending on how we move forward with this
function getCustomFieldHeaders(interpreterType: UniversalInterpreterTypeEnum, data: DataImportActionDataSourceQuery) {
  const personHeaders = (data?.personCustomFields || []).map((cf) => ({
    label: cf.label,
    value: getCustomFieldHydraHeader(interpreterType, 'person'),
    otherInfoLabelToken: cf.token,
    category: 'Individuals',
  }))
  const bankAccountHeaders = (data?.bankAccountCustomFields || []).map((cf) => ({
    label: cf.label,
    value: getCustomFieldHydraHeader(interpreterType, 'bank account'),
    otherInfoLabelToken: cf.token,
    category: 'Bank Accounts',
  }))
  const businessHeaders = (data?.businessCustomFields || []).map((cf) => ({
    label: cf.label,
    value: getCustomFieldHydraHeader(interpreterType, 'business'),
    otherInfoLabelToken: cf.token,
    category: 'Businesses',
  }))

  if (interpreterType === UniversalInterpreterTypeEnum.Case) {
    const reviewHeaders = (data?.reviewCustomFields || []).map((cf) => ({
      label: cf.label,
      value: getCustomFieldHydraHeader(interpreterType, 'review'),
      otherInfoLabelToken: cf.token,
      category: 'Reviews',
    }))
    const transactionHeaders = (data?.transactionCustomFields || []).map((cf) => ({
      label: cf.label,
      value: getCustomFieldHydraHeader(interpreterType, 'transaction'),
      otherInfoLabelToken: cf.token,
      category: 'Transactions',
    }))

    return personHeaders.concat(bankAccountHeaders, businessHeaders, reviewHeaders, transactionHeaders)
  }

  return personHeaders.concat(bankAccountHeaders, businessHeaders)
}

function hydraHeaderToHeaderOption(sectionName: string, header: Header) {
  // Remove the repetitive prefix from the header label
  const prefix = singular(sectionName).toLowerCase()
  const index = header.header.toLowerCase().indexOf(prefix)

  let label = header.header
  if (index !== -1) {
    label = label.slice(index + prefix.length).trim()
  }

  const category = pluralize(sectionName)

  return {
    label: startCase(label),
    value: header.header,
    category: category === 'Individuals' ? 'People' : category, // Don't use Individuals term
  }
}

interface HeaderOption {
  label: string
  value: string
  category: string
  otherInfoLabelToken?: string
}

// Exclude since we're adding explicit other info labels separately
const EXCLUDED_HEADERS = ['Other Info Label', 'Other Info Value']

/**
 * TODOs
 * - Hydra seems to want a minimum of two mappings so it's comma separated
 * - There are a lot of rules about required fields for different objects in Hydra. We don't currently encode those here.
 *   (e.g. case requires name, transaction requires time, etc)
 * - Should we force some type of review to be created? Mandatory review type selector for the Case option?
 */
function MappingForm({ form, data }: { form: FormSchemaReturnType; data: DataImportActionDataSourceQuery }) {
  const { setValue, control, watch } = form
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `${CREATE_DATA_IMPORT_PARAMS_PATH}.fieldMappings`,
  })

  const interpreterType = watch(`${CREATE_DATA_IMPORT_PARAMS_PATH}.interpreterType`)

  const { columns } = data.datasource

  const headerOptions: HeaderOption[] = useMemo(() => {
    const headers = interpreterType === UniversalInterpreterTypeEnum.Case ? caseHydraHeaders : crmHydraHeaders

    return headers
      .map((h) =>
        h.headers
          .filter((header) => !!header.header)
          .map((header) => hydraHeaderToHeaderOption(h.name, header))
          .filter((header) => !EXCLUDED_HEADERS.includes(header.label))
      )
      .flat()
      .concat(getCustomFieldHeaders(interpreterType, data))
      .sort((a, b) => {
        const compare = a.category.localeCompare(b.category)
        if (compare !== 0) {
          return compare
        }

        return a.label.localeCompare(b.label)
      })
  }, [data, interpreterType])

  const handleInterpreterTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.value as UniversalInterpreterTypeEnum
    setValue(`${CREATE_DATA_IMPORT_PARAMS_PATH}.interpreterType`, newType)
    remove() // Clear existing mappings
    append({ datasourceColumnToken: '', hydraColumnName: '' }) // Add initial empty mapping
  }

  return (
    <div className={styles.root}>
      <InputContainer
        htmlFor="interpreterType"
        label={
          <HbText color="secondary">
            Import <HbText bold>{data.datasource.name}</HbText> data to
          </HbText>
        }
      >
        <Select size="small" required variant="outlined" onChange={handleInterpreterTypeChange} value={interpreterType}>
          <MenuItem value={UniversalInterpreterTypeEnum.Case}>Case</MenuItem>
          <MenuItem value={UniversalInterpreterTypeEnum.Crm}>CRM</MenuItem>
        </Select>
      </InputContainer>

      {interpreterType && (
        <>
          <HbText color="secondary">Map columns to data</HbText>
          <div className={styles.mappingsContainer}>
            {fields.map((field, index) => (
              <React.Fragment key={field.id}>
                <InputContainer htmlFor="column" label="Column">
                  <Select
                    size="small"
                    required
                    variant="outlined"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      update(index, {
                        ...field,
                        datasourceColumnToken: e.target.value,
                      })
                    }}
                    value={field.datasourceColumnToken}
                  >
                    {columns.map((column) => (
                      <MenuItem key={column.token} value={column.token}>
                        {column.name}
                      </MenuItem>
                    ))}
                  </Select>
                </InputContainer>

                <InputContainer htmlFor="mapTo" label="Map to">
                  <Autocomplete
                    renderInput={(props) => <TextField variant="outlined" {...props} />}
                    options={headerOptions}
                    groupBy={(option) => option.category}
                    disableClearable
                    value={headerOptions.find(
                      (o) =>
                        o.value === fields[index].hydraColumnName &&
                        // eslint-disable-next-line eqeqeq
                        o.otherInfoLabelToken == fields[index].otherInfoLabelToken
                    )}
                    onChange={(event, value) => {
                      update(index, {
                        ...fields[index],
                        hydraColumnName: value.value,
                        otherInfoLabelToken: value.otherInfoLabelToken,
                      })
                    }}
                    renderOption={(params, option) => (
                      <li {...params}>
                        {option.otherInfoLabelToken ? <BoltOutlined /> : null}
                        {option.label}
                      </li>
                    )}
                  />
                </InputContainer>

                <HbButton
                  className={styles.removeButton}
                  Icon={TrashOutlineIcon}
                  label="Remove"
                  iconOnly
                  size="small"
                  onClick={() => remove(index)}
                />
              </React.Fragment>
            ))}
          </div>

          <div>
            <HbButton
              Icon={AddIcon}
              label="Add mapping"
              size="small"
              onClick={() => append({ datasourceColumnToken: '', hydraColumnName: '' })}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default function CustomizeDataImportAction({ form }: { form: FormSchemaReturnType }) {
  const { watch } = form
  const datasourceToken = watch(`domain.datasourceToken`)

  const { data, loading, error } = useQuery<DataImportActionDataSourceQuery, DataImportActionDataSourceQueryVariables>(
    DATASOURCE_QUERY,
    {
      variables: { token: datasourceToken ?? '' },
      fetchPolicy: 'cache-first',
    }
  )

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <GQLError error={error} />
  }

  const datasource = data?.datasource
  if (!datasource) {
    return null
  }

  return <MappingForm form={form} data={data} />
}
