// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

export const PANEL_WIDTH = 400

export type Gap = 2 | 3 | 4
export const HorizontalOverflowList = styled('ul')<{ gap?: Gap }>(({ theme, gap = 2 }) => {
  return {
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: theme.spacing(gap),
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  }
})

export const AutoGridList = styled('ul')<{ gap?: Gap; minWidthPx?: number }>(({ theme, gap = 3, minWidthPx = 360 }) => {
  return {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${minWidthPx}px, 1fr))`,
    gap: theme.spacing(gap),
  }
})
