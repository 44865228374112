import React, { useRef } from 'react'

import { Edit } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { ButtonBase, styled } from '@mui/material'

import VisuallyHidden from '@reach/visually-hidden'

import { Size } from 'components/HbComponents/HbComponents.types'
import { HbText } from 'components/HbComponents/Text/HbText'
import { useEffectOnce } from 'hooks'

const InputWrap = styled('span')<{ fontSize: Size }>(({ theme, fontSize }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    minWidth: '250px',
    height: 'fit-content',
    ...theme.typography.sizes[fontSize],
    '&:hover > input:not([disabled])': {
      borderBottom: `1px dashed ${theme.palette.styleguide.borderLight}`,
    },
  }
})

const StyledInput = styled('input')<{ alwaysShowUnderline?: boolean; fontSize: Size }>(
  ({ theme, alwaysShowUnderline, fontSize }) => {
    return {
      border: 'none',
      borderBottom: `1px dashed ${alwaysShowUnderline ? theme.palette.styleguide.borderLight : 'transparent'}`,
      ...theme.typography.sizes[fontSize],
      fontWeight: theme.typography.fontWeightMedium,
      fontFamily: theme.typography.fontFamily,
      position: 'absolute',
      width: '100%',
      padding: 0,
      left: 0,
      top: '50%',
      transform: 'translate(0, -50%)',
      '&:focus, &:hover': {
        outline: 'none',
        borderBottom: `1px dashed ${theme.palette.styleguide.borderDark}`,
        cursor: 'text',
      },
      '&:focus + button, &:hover + button': {
        opacity: 1,
      },
      '&:disabled': {
        background: 'none',
        color: 'black',
        borderBottom: `1px dashed ${alwaysShowUnderline ? theme.palette.styleguide.borderDark : 'transparent'}`,
        cursor: 'default',
      },
      '&:disabled + button': {
        opacity: 0,
        cursor: 'default',
      },
    }
  }
)

const WidthMachine = styled('span')<{ fontSize: Size }>(({ theme, fontSize }) => {
  return {
    ...theme.typography.sizes[fontSize],
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
    visibility: 'hidden',
    padding: theme.spacing(0, 2),
  }
})

const EditButton = styled(ButtonBase)(({ theme }) => {
  return {
    position: 'absolute',
    zIndex: 3,
    right: -24,
    bottom: 4,
    color: theme.palette.styleguide.hbBlueMedium,
  }
})

export const Form = styled('form')(() => {
  return { position: 'relative' }
})

export const Error = styled(HbText)(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    color: theme.palette.styleguide.errorMedium,
    fontSize: '14px',
    marginTop: theme.spacing(0.5),
    '& svg': {
      marginRight: theme.spacing(0.5),
      height: 14,
      width: 14,
    },
  }
})

type Props = {
  maxWidth?: number
  onEditClick?: () => void
  hideEditIcon?: boolean
  alwaysShowUnderline?: boolean
  fontSize?: Size
} & Pick<React.HTMLProps<HTMLInputElement>, 'name' | 'value' | 'onChange' | 'onBlur' | 'disabled' | 'placeholder'>

export const HbVariableWidthInput = React.forwardRef(
  (
    {
      name,
      value,
      onChange,
      onBlur,
      disabled,
      placeholder,
      onEditClick,
      hideEditIcon,
      alwaysShowUnderline,
      fontSize = 'md',
      maxWidth = 800,
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const widthMachineRef = useRef<HTMLSpanElement>(null)

    useEffectOnce(() => {
      if (widthMachineRef.current && typeof ref === 'object' && ref?.current?.value) {
        widthMachineRef.current.innerText = ref.current.value
      }
    })

    const handleKeyDown = () => {
      if (widthMachineRef.current && typeof ref === 'object' && ref?.current?.value) {
        const { width } = widthMachineRef.current.getBoundingClientRect()
        if (width < maxWidth || ref.current.value.length < widthMachineRef.current.innerHTML.length) {
          widthMachineRef.current.innerText = ref.current.value
        }
      }
    }

    return (
      <InputWrap fontSize={fontSize}>
        <WidthMachine ref={widthMachineRef} aria-hidden fontSize={fontSize} />
        <StyledInput
          name={name}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          ref={ref}
          autoComplete="off"
          disabled={disabled}
          alwaysShowUnderline={alwaysShowUnderline}
          fontSize={fontSize}
        />
        {!hideEditIcon && (
          <EditButton disableRipple onClick={onEditClick}>
            <VisuallyHidden>Edit</VisuallyHidden>
            <Edit />
          </EditButton>
        )}
      </InputWrap>
    )
  }
)
