import { ChangeEvent, MouseEvent, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Box } from '@mui/system'

import QRCode from 'qrcode.react'

import { fetchProvisioningURI, saveAccount } from 'actions/settingsActions'
import { useDispatch, useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { AccountData } from 'components/settings/user-settings/utils'
import { getCurrentAccount } from 'helpers/stateHelpers'
import { stopEvent } from 'helpers/uiHelpers'
import { MfaMethodEnum } from 'types/api'
import { assertExhaustive } from 'utils/typeAssertions'

type Screen = 'selectMethod' | 'viewQrCode' | 'confirmation'

function confirmationText(mfaMethod: MfaMethodEnum) {
  switch (mfaMethod) {
    case MfaMethodEnum.Authenticator: {
      return 'Click save to enable Two-Factor authentication using an app (e.g. Google Authenticator). You will need to use your authenticator app to retrieve a code every time you sign in.'
    }
    case MfaMethodEnum.Email: {
      return 'Click save to enable Two-Factor authentication using email. A code will be emailed to you whenever you try to sign in. You will need to enter this code in order to complete authentication.'
    }
    case MfaMethodEnum.None: {
      return 'Click save to disable Two-Factor authentication.'
    }
    default:
      return assertExhaustive(mfaMethod)
  }
}

const ButtonBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  marginLeft: 'auto',
  justifyContent: 'flex-end',
  '& > button + button': {
    marginLeft: theme.spacing(1),
  },
}))

const MfaRadioGroup = styled(RadioGroup)(({ theme }) => ({
  marginTop: theme.spacing(),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
}))

const MfaProvisioningBox = styled(Box)({
  margin: '1rem auto',
  textAlign: 'center',
})

const MfaQrCodeBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '1rem',
  minWidth: '200px',
  minHeight: '200px',
})

interface Props {
  beforeSaveHook?: () => void
  afterSaveHook?: () => void
  requireMfaMethod?: boolean
}

function MultiFactorAuthForm(props: Props) {
  const { beforeSaveHook = () => {}, afterSaveHook = () => {}, requireMfaMethod } = props

  const dispatch = useDispatch()
  const currentAccount = useSelector(getCurrentAccount)
  const provisioningURI = useSelector((state) => state.settings.mfaProvisioningURI)

  const getInitialMfaMethod = (mfaMethod: MfaMethodEnum) =>
    mfaMethod === MfaMethodEnum.None ? MfaMethodEnum.Email : mfaMethod

  const [mfaMethod, setMfaMethod] = useState<MfaMethodEnum>(getInitialMfaMethod(currentAccount.mfaMethod))

  const [screen, setScreen] = useState<Screen>('selectMethod')

  const [updating, setUpdating] = useState(false)

  const handleSelectionChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setMfaMethod(value as MfaMethodEnum)

  const handleFetchProvisioningURI = () => {
    dispatch(fetchProvisioningURI())
  }

  const updateField = async (key: keyof AccountData, value: string) => {
    await dispatch(saveAccount({ [key]: value }))
  }

  const handleNext = (event: MouseEvent<HTMLButtonElement>) => {
    stopEvent(event)
    event.currentTarget.blur()

    if (screen === 'selectMethod' && mfaMethod === 'AUTHENTICATOR') {
      handleFetchProvisioningURI()
      setScreen('viewQrCode')
    } else {
      setScreen('confirmation')
    }
  }

  const handleBack = (event: MouseEvent<HTMLButtonElement>) => {
    stopEvent(event)
    setScreen('selectMethod')
  }

  const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
    stopEvent(event)
    beforeSaveHook()
    setUpdating(true)
    event.currentTarget.blur()
    updateField('mfaMethod', mfaMethod)
    setUpdating(false)
    afterSaveHook()
  }

  const primaryAction = screen === 'confirmation' ? handleSave : handleNext
  const primaryText = screen === 'confirmation' ? 'Save' : 'Next'

  return (
    <Box>
      {screen === 'selectMethod' && (
        <FormControl component="fieldset">
          <HbText>Select a new Two-Factor Authentication code delivery method:</HbText>
          <MfaRadioGroup onChange={handleSelectionChange} name="mfaMethod" value={mfaMethod}>
            <FormControlLabel value="EMAIL" control={<Radio color="primary" />} label="Email" />
            <FormControlLabel value="AUTHENTICATOR" control={<Radio color="primary" />} label="Authenticator App" />
            {!requireMfaMethod && <FormControlLabel value="NONE" control={<Radio color="primary" />} label="None" />}
          </MfaRadioGroup>
        </FormControl>
      )}

      {screen === 'viewQrCode' && (
        <>
          <HbText>Please open your authentication app (e.g. Google Authenticator) and scan this QR code:</HbText>
          <MfaProvisioningBox>
            <MfaQrCodeBox>
              {provisioningURI ? <QRCode size={200} value={provisioningURI} /> : <p>Loading...</p>}
            </MfaQrCodeBox>
          </MfaProvisioningBox>
        </>
      )}

      {screen === 'confirmation' && <HbText>{confirmationText(mfaMethod)}</HbText>}
      <ButtonBox>
        {screen !== 'selectMethod' && (
          <HbButton size="small" label="Back" variant="textSecondary" onClick={handleBack} disabled={updating} />
        )}
        <HbButton size="small" label={primaryText} variant="primary" onClick={primaryAction} disabled={updating} />
      </ButtonBox>
    </Box>
  )
}

export default MultiFactorAuthForm
