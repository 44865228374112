// eslint-disable-next-line no-restricted-imports
import { styled, DialogTitle as MuiDialogTitle } from '@mui/material'

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexFlow: 'row wrap',
  padding: theme.spacing(3, 3),
  borderBottom: `1px solid ${theme.palette.styleguide.borderLight}`,
  gap: theme.spacing(1.5),
}))

export const DialogTitle = styled(MuiDialogTitle)(() => ({
  width: 'fit-content',
  padding: 0,
}))
