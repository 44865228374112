import { cloneDeep } from 'lodash'

import { AutomationDomainType } from 'types/api'

import type { Variable, ObjectVariable } from 'components/library/LiquidMarkdownEditor'

function makeAddressesVariable(domain: string): Variable {
  return {
    name: 'addresses',
    type: 'array',
    info: `Addresses attached to the ${domain}`,
    label: 'Addresses',
    members: {
      type: 'object',
      label: 'Address',
      info: `An address attached to the ${domain}`,
      properties: [
        { name: 'country', type: 'value', label: 'Country', info: `The country of an address on the ${domain}` },
        { name: 'locality', type: 'value', label: 'City', info: `The city of an address on the ${domain}` },
        {
          name: 'address_line_1',
          type: 'value',
          label: 'Address Line 1',
          info: `The first line of an address on the ${domain}`,
        },
        {
          name: 'address_line_2',
          type: 'value',
          label: 'Address Line 2',
          info: `The second line of an address on the ${domain}`,
        },
        {
          name: 'administrative_district_level_1',
          label: 'State',
          type: 'value',
          info: `The state of an address on the ${domain}`,
        },
        {
          name: 'postal_code',
          type: 'value',
          label: 'Postal Code',
          info: `The postal_code of an address on the ${domain}`,
        },
        { name: 'address_type', type: 'value', label: 'Address Type', info: `The type of an address on the ${domain}` },
        {
          name: 'address_type_other',
          type: 'value',
          label: 'Other Address Type',
          info: `The other type of an address on the ${domain}`,
        },
      ],
    },
  }
}

function makeTinsVariable(domain: string): Variable {
  return {
    name: 'tins',
    type: 'array',
    label: 'TINs',
    info: `TINs attached to the ${domain}`,
    members: {
      type: 'object',
      label: 'TIN',
      info: `A TIN attached to the ${domain}`,
      properties: [
        { name: 'type', type: 'value', label: 'TIN Type', info: `The type of a TIN on the ${domain}` },
        { name: 'number', type: 'value', label: 'TIN Number', info: `The number of a TIN on the ${domain}` },
      ],
    },
  }
}

function makePhoneNumbersVariable(domain: string): Variable {
  return {
    name: 'phone_numbers',
    type: 'array',
    label: 'Phone Numbers',
    info: `Phone numbers attached to the ${domain}`,
    members: {
      type: 'object',
      label: 'Phone Number',
      info: `A phone number attached to the ${domain}`,
      properties: [
        {
          name: 'type',
          type: 'value',
          label: 'Phone Number Type',
          info: `The type of a phone number on the ${domain}`,
        },
        { name: 'number', type: 'value', label: 'Phone Number', info: `The number of a phone number on the ${domain}` },
        {
          name: 'extension',
          type: 'value',
          label: 'Phone Number Extension',
          info: `The extension of a phone number on the ${domain}`,
        },
      ],
    },
  }
}

function makeOtherInfoVariable(domain: string): Variable {
  return {
    name: 'custom_fields',
    type: 'array',
    label: 'Custom Fields',
    info: `Custom fields attached to the ${domain}`,
    members: {
      type: 'object',
      label: 'Custom Fields',
      info: `A name/value pair for a custom field attached to the ${domain}`,
      properties: [
        {
          name: 'label',
          type: 'value',
          label: 'Name',
          info: `The name of a custom field on the ${domain}`,
        },
        {
          name: 'value',
          type: 'value',
          label: 'Value',
          info: `The value of a custom field on the ${domain}`,
        },
      ],
    },
  }
}

function makeIdentityDocumentsVariable(domain: string): Variable {
  return {
    name: 'identity_documents',
    type: 'array',
    label: 'Identity Documents',
    info: `Identity documents attached to the ${domain}`,
    members: {
      type: 'object',
      label: 'Identity Document',
      info: `An identity document attached to the ${domain}`,
      properties: [
        { name: 'type', type: 'value', label: 'ID Type', info: `The type of an identity document on the ${domain}` },
        {
          name: 'type_other',
          type: 'value',
          label: 'ID Other Type',
          info: `The other type of an identity document on the ${domain}`,
        },
        {
          name: 'number',
          type: 'value',
          label: 'ID Number',
          info: `The number of an identity document on the ${domain}`,
        },
        {
          name: 'issuing_country',
          type: 'value',
          label: 'ID Country',
          info: `The issuing country of an identity document on the ${domain}`,
        },
        {
          name: 'issuing_country_division',
          type: 'value',
          label: 'ID State',
          info: `The issuing state of a country of an identity document on the ${domain}`,
        },
        {
          name: 'expiration_date',
          type: 'value',
          label: 'ID Expiration Date',
          info: `The expiration date of an identity document on the ${domain}`,
        },
      ],
    },
  }
}

const leafReview: ObjectVariable = {
  name: 'hb_review',
  type: 'object',
  label: 'Review',
  info: 'The review that triggered the automation',
  properties: [
    { name: 'type', type: 'value', label: 'Review Type', info: 'The type of the review' },
    {
      name: 'days_until_due_date',
      type: 'value',
      label: 'Review Days Until Due Date',
      info: 'The number of days until the review is due',
    },
    {
      name: 'days_past_due_date',
      type: 'value',
      label: 'Review Days Past Due Date',
      info: 'The number of days past the review is due',
    },
    {
      name: 'days_since_completion_date',
      type: 'value',
      label: 'Review Days Since Completion Date',
      info: 'The number of days since the review was completed',
    },
    {
      name: 'days_since_creation_date',
      type: 'value',
      label: 'Review Days Since Creation Date',
      info: 'The number of days since the review was created',
    },
    { name: 'cancelled', type: 'value', label: 'Review Cancelled', info: 'If the review was cancelled' },
    { name: 'status', type: 'value', label: 'Review Status', info: 'The status of the review' },
    { name: 'net_activity', type: 'value', label: 'Review Net Activity', info: 'The net activity of the review' },
    {
      name: 'assignee',
      type: 'object',
      label: 'Review Assignee',
      info: 'The assignee of the review',
      properties: [
        {
          name: 'name',
          type: 'value',
          label: 'Review Assignee Name',
          info: "The full name of the review's assignee",
        },
        { name: 'email', type: 'value', label: 'Review Assignee Email', info: "The email of the review's assignee" },
        {
          name: 'queue_name',
          type: 'value',
          label: 'Review Queue Assignee Name',
          info: "The name of the review's queue assignee",
        },
      ],
    },
    {
      name: 'latest_action_decisions',
      type: 'array',
      label: 'Review Latest Action Decisions',
      info: 'The latest action decisions associated with the review',
      members: {
        type: 'object',
        label: 'Review Latest Action Decision',
        info: 'An action decision associated with the review',
        properties: [
          {
            name: 'choice',
            type: 'value',
            label: 'Choice made',
            info: 'The choice made in the latest action decision',
          },
        ],
      },
    },
    {
      name: 'case_alert_rules',
      type: 'array',
      label: 'Review Case Alert Rules',
      info: 'The case alert rules associated with the review',
      members: {
        type: 'object',
        label: 'Case Alert Rule',
        info: 'A case alert rule associated with the review',
        properties: [
          {
            name: 'name',
            type: 'value',
            label: 'Case Alert Rule Name',
            info: 'The name of a case alert rule associated with the review',
          },
        ],
      },
    },
    {
      name: 'case_alert_rule_count',
      type: 'value',
      label: 'Review Case Alert Rule Count',
      info: 'The number of case alert rules associated with the review',
    },
  ],
}

const leafCase: ObjectVariable = {
  name: 'hb_case',
  type: 'object',
  label: 'Case',
  info: 'The case that triggered the automation',
  properties: [
    {
      name: 'external_id',
      type: 'value',
      label: 'Case ID',
      info: 'The ID of the case',
    },
    {
      name: 'recently_created',
      type: 'value',
      label: 'Case Recently Created',
      info: 'If the case was recently created',
    },
    {
      name: 'sum_of_all_transactions',
      type: 'value',
      label: 'Case Sum of All Transactions',
      info: 'The sum of all transactions on the case',
    },
    {
      name: 'sum_of_flagged_transactions',
      type: 'value',
      label: 'Case Sum of Flagged Transactions',
      info: 'The sum of all flagged transactions on the case',
    },
  ],
}

const leafFiling: ObjectVariable = {
  name: 'hb_filing',
  type: 'object',
  label: 'Filing',
  info: 'The filing that triggered the automation',
  properties: [
    { name: 'status', type: 'value', label: 'Filing Status', info: 'The status of the filing' },
    {
      name: 'business_days_stale',
      type: 'value',
      label: 'Filing Business Days Stale',
      info: 'The number of business days a filing has been stale',
    },
  ],
}

const leafMiddeskBusiness: ObjectVariable = {
  name: 'middesk_business',
  type: 'object',
  label: 'Middesk Business',
  info: 'The Middesk business that triggered the automation',
  properties: [
    { name: 'status', type: 'value', label: 'Middesk Business Status', info: 'The status of the Middesk business' },
    {
      name: 'was_in_audit',
      type: 'value',
      label: 'Middesk Business Was in Audit',
      info: 'If a Middesk Business was in an audit',
    },
  ],
}

const makeLeafPerson = (): ObjectVariable => ({
  name: 'hb_person',
  type: 'object',
  label: 'Person',
  info: 'The person that triggered the automation',
  properties: [
    { name: 'external_id', type: 'value', label: 'Person ID', info: 'The ID of the person' },
    {
      name: 'name',
      type: 'object',
      label: "Person's Name",
      info: 'The name of the person',
      properties: [
        {
          name: 'first_name',
          type: 'value',
          label: 'Person First Name',
          info: "The first name of the person's name",
        },
        {
          name: 'middle_name',
          type: 'value',
          label: 'Person Middle Name',
          info: "The middle name of the person's name",
        },
        { name: 'last_name', type: 'value', label: 'Person Last Name', info: "The last name of the person's name" },
        { name: 'suffix', type: 'value', label: 'Person Suffix', info: "The suffix of the person's name" },
      ],
    },
    {
      name: 'gender',
      type: 'value',
      label: 'Person Gender',
      info: 'The gender of the person',
    },
    {
      name: 'notes',
      type: 'value',
      label: 'Person Notes',
      info: 'Notes attached to the person',
    },
    {
      name: 'tags',
      type: 'array',
      label: 'Person Tags',
      info: 'Tags attached to the person',
      members: { type: 'value', label: 'Person Tag', info: 'A tag attached to the person' },
    },
    makeAddressesVariable('person'),
    makeTinsVariable('person'),
    makePhoneNumbersVariable('person'),
    makeOtherInfoVariable('person'),
    makeIdentityDocumentsVariable('person'),
    {
      name: 'websites',
      type: 'array',
      label: "Person's Websites",
      info: 'Websites attached to the person',
      members: {
        type: 'value',
        label: "Person's Website",
        info: 'A website on the person',
      },
    },
    {
      name: 'email_addresses',
      type: 'array',
      label: "Person's Email Addresses",
      info: 'Email addresses attached to the person',
      members: {
        type: 'value',
        label: "Person's Email Address",
        info: 'An email address on the person',
      },
    },
    {
      name: 'usernames',
      type: 'array',
      label: "Person's Usernames",
      info: 'Usernames associated with the person',
      members: {
        type: 'value',
        label: "Person's Username",
        info: 'A username associated with the person',
      },
    },
    {
      name: 'alternate_names',
      type: 'array',
      label: "Person's Alternate Names",
      info: 'Alternate names associated with the person',
      members: {
        type: 'value',
        label: 'Alternate Name',
        info: 'Alternative name associated with the person',
      },
    },
    {
      name: 'occupations',
      type: 'array',
      label: "Person's Occupations",
      info: 'Occupations associated with the person',
      members: {
        type: 'object',
        label: "Person's Occupation",
        info: 'An occupation associated with the person',
        properties: [
          {
            name: 'company',
            type: 'value',
            label: 'Occupation Company',
            info: 'The company of an occupation associated with the person',
          },
          {
            name: 'industry',
            type: 'value',
            label: 'Occupation Industry',
            info: 'The industry of an occupation associated with the person',
          },
          {
            name: 'occupation',
            type: 'value',
            label: 'Occupation Title',
            info: 'The title of an occupation associated with the person',
          },
          {
            name: 'company_website',
            type: 'value',
            label: 'Occupation Company Website',
            info: 'The website of the company of an occupation associated with the person',
          },
          {
            name: 'naics_code',
            type: 'value',
            label: 'Occupation NAICS Code',
            info: 'The NAICS code of an occupation associated with the person',
          },
        ],
      },
    },
    {
      name: 'birth_date',
      type: 'value',
      label: "Person's Birth Date",
      info: 'The birth date of the person',
    },
  ],
})

const LEAF_PERSON_W_CUSTOM_FIELDS_RENAME = makeLeafPerson()
const getLeafPerson = () => LEAF_PERSON_W_CUSTOM_FIELDS_RENAME

const makeLeafBusiness = (): ObjectVariable => ({
  name: 'hb_business',
  type: 'object',
  label: 'Business',
  info: 'The business that triggered the automation',
  properties: [
    {
      name: 'external_id',
      type: 'value',
      label: 'Business ID',
      info: 'The ID of the business',
    },
    {
      name: 'notes',
      type: 'value',
      label: 'Business Notes',
      info: 'Notes attached to the business',
    },
    {
      name: 'tags',
      type: 'array',
      label: 'Business Tags',
      info: 'Tags attached to the business',
      members: { type: 'value', label: 'Tag', info: 'A tag attached to the business' },
    },
    {
      name: 'type',
      type: 'value',
      label: 'Business Type',
      info: 'The type of the business',
    },
    makeAddressesVariable('business'),
    makeTinsVariable('business'),
    makePhoneNumbersVariable('business'),
    makeOtherInfoVariable('business'),
    makeIdentityDocumentsVariable('business'),
    {
      name: 'websites',
      type: 'array',
      label: 'Business Websites',
      info: 'Websites attached to the business',
      members: {
        type: 'value',
        label: 'Business Website',
        info: 'A website on the business',
      },
    },
    {
      name: 'email_addresses',
      type: 'array',
      label: 'Business Email Addresses',
      info: 'Email addresses attached to the business',
      members: {
        type: 'value',
        label: 'Business Email Address',
        info: 'An email address on the business',
      },
    },
    {
      name: 'usernames',
      type: 'array',
      label: 'Business Usernames',
      info: 'Usernames associated with the business',
      members: {
        type: 'value',
        label: 'Business Username',
        info: 'A username associated with the business',
      },
    },
    {
      name: 'legal_names',
      type: 'array',
      label: 'Business Legal Names',
      info: 'Legal names associated with the business',
      members: {
        type: 'value',
        label: 'Business Legal Name',
        info: 'A legal name associated with the business',
      },
    },
    {
      name: 'dba_names',
      type: 'array',
      label: 'Business DBA Names',
      info: 'DBA names associated with the business',
      members: {
        label: 'Business DBA Name',
        type: 'value',
        info: 'A DBA name associated with the business',
      },
    },
  ],
})

const LEAF_BUSINESS_W_CUSTOM_FIELDS = makeLeafBusiness()
const getLeafBusiness = () => LEAF_BUSINESS_W_CUSTOM_FIELDS

const makeDomainSmartValues = (): Record<AutomationDomainType, ObjectVariable> => {
  const middeskBusinessConfig = cloneDeep(leafMiddeskBusiness)
  const leafPerson = getLeafPerson()
  const leafBusiness = getLeafBusiness()

  const personConfig = cloneDeep(leafPerson)
  personConfig.properties.push({
    name: 'cases',
    type: 'array',
    label: 'Person Cases',
    info: 'Cases associated with the person.',
    members: leafCase,
  })
  const businessConfig = cloneDeep(leafBusiness)
  businessConfig.properties.push({
    name: 'cases',
    type: 'array',
    label: 'Business Cases',
    info: 'Cases associated with the business.',
    members: leafBusiness,
  })
  const reviewConfig = cloneDeep(leafReview)
  reviewConfig.properties.push(
    {
      name: 'filings',
      type: 'array',
      label: 'Review Filings',
      info: 'Filings associated with the review.',
      members: leafFiling,
    },
    {
      ...leafCase,
      name: 'case',
      info: 'Case associated with the review.',
    }
  )
  const caseConfig = cloneDeep(leafCase)
  caseConfig.properties.push(
    {
      name: 'people',
      type: 'array',
      label: 'Case People',
      info: 'People associated with the case.',
      members: leafPerson,
    },
    {
      name: 'businesses',
      type: 'array',
      label: 'Case Businesses',
      info: 'Businesses associated with the case.',
      members: leafBusiness,
    },
    {
      name: 'reviews',
      type: 'array',
      label: 'Case Reviews',
      info: 'Reviews associated with the case.',
      members: leafReview,
    }
  )
  const filingConfig = cloneDeep(leafFiling)
  filingConfig.properties.push({
    ...leafReview,
    name: 'review',
    info: 'The review associated with the filing.',
  })

  return {
    [AutomationDomainType.Business]: businessConfig,
    [AutomationDomainType.Case]: caseConfig,
    [AutomationDomainType.Datasource]: {
      name: 'hb_datasource',
      type: 'object',
      label: 'Datasource',
      info: 'The datasource that triggered the automation',
      properties: [],
    },
    [AutomationDomainType.Filing]: filingConfig,
    [AutomationDomainType.MiddeskBusiness]: middeskBusinessConfig,
    [AutomationDomainType.Person]: personConfig,
    [AutomationDomainType.Review]: reviewConfig,
  }
}

const DOMAIN_SMART_VALUES_W_CUSTOM_FIELDS_RENAME = makeDomainSmartValues()
export const getDomainSmartValues = () => DOMAIN_SMART_VALUES_W_CUSTOM_FIELDS_RENAME

export const makeNarrativeSmartValueDomain = (): ObjectVariable => {
  const leafPerson = getLeafPerson()
  const leafBusiness = getLeafBusiness()

  return {
    ...leafReview,
    properties: [
      ...leafReview.properties,
      {
        name: 'people',
        type: 'array',
        label: 'Review People',
        info: 'People associated with the review.',
        members: leafPerson,
      },
      {
        name: 'businesses',
        type: 'array',
        label: 'Review Businesses',
        info: 'Businesses associated with the review.',
        members: leafBusiness,
      },
    ],
  }
}

const NARRATIVE_SMART_VALUE_DOMAIN_W_CUSTOM_FIELDS_RENAME = makeNarrativeSmartValueDomain()
export const getNarrativeSmartValueDomain = () => NARRATIVE_SMART_VALUE_DOMAIN_W_CUSTOM_FIELDS_RENAME
