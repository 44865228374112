import moment from 'moment'
import { UseFormReset } from 'react-hook-form'

import { AutomationDomainType, AutomationActionType, AutomationDateUnitEnum, SnoozeTimingPolicyEnum } from 'types/api'

import { defaultScheduleConfig } from './AutomationEditorSteps/utils'

import { FormSchema, strictLogicalExpressionSchema, type FormSchemaReturnType } from './formSchema'
import { strictTransformedExpressionNodeSchema } from './formSchemaTransform'

export function getActionParamsPath(actionType: AutomationActionType | null) {
  switch (actionType) {
    case 'review_digest_notification':
      return 'actionParams.reviewDigestNotificationParams'
    default:
      return 'actionParams.notificationParams'
  }
}

export function transformTriggerFilters(filters: any) {
  if (strictLogicalExpressionSchema.safeParse(filters).success) {
    return filters
  }

  return strictTransformedExpressionNodeSchema.parse(filters)
}

type ActionParamName =
  | 'actionParams.tagParams.tagDefinitionToken'
  | 'actionParams.commentParams.text'
  | 'actionParams.commentParams.smartValuesEnabled'
  | 'actionParams.cancelReviewParams.cancellationReason'
  | 'actionParams.createInvestigationParams.description'
  | 'actionParams.createInvestigationParams.smartValuesEnabled'
  | 'actionParams.createReviewParams.dueDateRelativeIncrementUnit'
  | 'actionParams.createReviewParams.dueDateRelativeIncrementValue'
  | 'actionParams.createReviewParams.openAtRelativeIncrementUnit'
  | 'actionParams.createReviewParams.openAtRelativeIncrementValue'
  | 'actionParams.createReviewParams.assigneeToken'
  | 'actionParams.createReviewParams.queueToken'
  | 'actionParams.createReviewParams.otherInfoEntries'
  | 'actionParams.createReviewParams.smartValuesEnabled'
  | 'actionParams.notificationParams.badgeTokens'
  | 'actionParams.notificationParams.accountTokens'
  | 'actionParams.notificationParams.recipientEmails'
  | 'actionParams.notificationParams.subject'
  | 'actionParams.notificationParams.body'
  | 'actionParams.notificationParams.sendToReviewAssignee'
  | 'actionParams.notificationParams.smartValuesEnabled'
  | 'actionParams.reviewDigestNotificationParams.badgeTokens'
  | 'actionParams.reviewDigestNotificationParams.accountTokens'
  | 'actionParams.reviewDigestNotificationParams.recipientEmails'
  | 'actionParams.snoozeReviewParams.snoozeUntilDateRelativeIncrementUnit'
  | 'actionParams.snoozeReviewParams.snoozeUntilDateRelativeIncrementValue'
  | 'actionParams.snoozeReviewParams.description'
  | 'actionParams.snoozeReviewParams.snoozeTimingPolicy'
  | 'actionParams.assignReviewToQueueParams.queueToken'
  | 'actionParams.assignReviewToQueueParams.unassignAccount'
  | 'actionParams.updateReviewDueDateParams.updateToDateRelativeIncrementUnit'
  | 'actionParams.updateReviewDueDateParams.updateToDateRelativeIncrementValue'
  | 'actionParams.updateReviewDueDateParams.relativeToDueDate'
  | 'actionParams.updateReviewDueDateParams.relativeToCreatedDate'
  | 'actionParams.writeOtherInfoParams.otherInfoEntries'
  | 'actionParams.writeOtherInfoParams.overwrite'
  | 'actionParams.workflowActionReviewParams.canonicalReviewTypeId'
  | 'actionParams.workflowActionReviewParams.choiceKey'
  | 'actionParams.workflowActionReviewParams.actionSlug'
  | 'actionParams.googleSheetsParams.sheetId'

interface ActionParams {
  actionText: string
  actionParams: Array<{
    name: ActionParamName
    value: any
  }>
}

const createActionMapping = (): Record<AutomationActionType, ActionParams> => ({
  [AutomationActionType.Tag]: {
    actionText: 'Tag subject',
    actionParams: [{ name: 'actionParams.tagParams.tagDefinitionToken', value: '' }],
  },
  [AutomationActionType.Comment]: {
    actionText: 'Leave a comment',
    actionParams: [
      { name: 'actionParams.commentParams.text', value: '' },
      { name: 'actionParams.commentParams.smartValuesEnabled', value: false },
    ],
  },
  [AutomationActionType.CancelReview]: {
    actionText: 'Cancel Review',
    actionParams: [{ name: 'actionParams.cancelReviewParams.cancellationReason', value: '' }],
  },
  [AutomationActionType.CompleteReview]: {
    actionText: 'Complete Review',
    actionParams: [],
  },
  [AutomationActionType.LockInvestigation]: {
    actionText: 'Lock Case',
    actionParams: [],
  },
  [AutomationActionType.UnlockInvestigation]: {
    actionText: 'Unlock Case',
    actionParams: [],
  },
  [AutomationActionType.NoOp]: {
    actionText: 'Do Nothing (Record in Activity Log)',
    actionParams: [],
  },
  [AutomationActionType.SnoozeReview]: {
    actionText: 'Snooze Review',
    actionParams: [
      {
        name: 'actionParams.snoozeReviewParams.snoozeUntilDateRelativeIncrementUnit',
        value: AutomationDateUnitEnum.Days,
      },
      { name: 'actionParams.snoozeReviewParams.snoozeUntilDateRelativeIncrementValue', value: 0 },
      { name: 'actionParams.snoozeReviewParams.snoozeTimingPolicy', value: SnoozeTimingPolicyEnum.Reset },
      { name: 'actionParams.snoozeReviewParams.description', value: '' },
    ],
  },
  [AutomationActionType.OpenReview]: {
    actionText: 'Open Review',
    actionParams: [],
  },
  [AutomationActionType.CreateInvestigation]: {
    actionText: 'Create Case',
    actionParams: [
      { name: 'actionParams.createInvestigationParams.description', value: 'Case created' },
      { name: 'actionParams.createInvestigationParams.smartValuesEnabled', value: false },
    ],
  },
  [AutomationActionType.CreateReview]: {
    actionText: 'Create Review',
    actionParams: [
      { name: 'actionParams.createReviewParams.dueDateRelativeIncrementUnit', value: null },
      { name: 'actionParams.createReviewParams.dueDateRelativeIncrementValue', value: null },
      { name: 'actionParams.createReviewParams.openAtRelativeIncrementUnit', value: null },
      { name: 'actionParams.createReviewParams.openAtRelativeIncrementValue', value: null },
      { name: 'actionParams.createReviewParams.assigneeToken', value: null },
      { name: 'actionParams.createReviewParams.queueToken', value: null },
      { name: 'actionParams.createReviewParams.otherInfoEntries', value: [] },
      { name: 'actionParams.createReviewParams.smartValuesEnabled', value: false },
    ],
  },
  [AutomationActionType.Notification]: {
    actionText: 'Send a notification',
    actionParams: [
      { name: 'actionParams.notificationParams.accountTokens', value: [] },
      { name: 'actionParams.notificationParams.badgeTokens', value: [] },
      { name: 'actionParams.notificationParams.recipientEmails', value: [] },
      { name: 'actionParams.notificationParams.body', value: '' },
      { name: 'actionParams.notificationParams.subject', value: '' },
      { name: 'actionParams.notificationParams.sendToReviewAssignee', value: true },
      { name: 'actionParams.notificationParams.smartValuesEnabled', value: false },
    ],
  },
  [AutomationActionType.ReviewDigestNotification]: {
    actionText: 'Send a review digest',
    actionParams: [
      { name: 'actionParams.reviewDigestNotificationParams.accountTokens', value: [] },
      { name: 'actionParams.reviewDigestNotificationParams.badgeTokens', value: [] },
      { name: 'actionParams.reviewDigestNotificationParams.recipientEmails', value: [] },
    ],
  },
  [AutomationActionType.AssignReviewToQueue]: {
    actionText: 'Assign a review to a queue',
    actionParams: [
      { name: 'actionParams.assignReviewToQueueParams.queueToken', value: '' },
      { name: 'actionParams.assignReviewToQueueParams.unassignAccount', value: false },
    ],
  },
  [AutomationActionType.WorkflowActionReview]: {
    actionText: 'Action a review',
    actionParams: [
      { name: 'actionParams.workflowActionReviewParams.canonicalReviewTypeId', value: '' },
      { name: 'actionParams.workflowActionReviewParams.choiceKey', value: '' },
      { name: 'actionParams.workflowActionReviewParams.actionSlug', value: '' },
    ],
  },
  [AutomationActionType.UpdateReviewDueDate]: {
    actionText: 'Update due date on a review',
    actionParams: [
      {
        name: 'actionParams.updateReviewDueDateParams.updateToDateRelativeIncrementUnit',
        value: AutomationDateUnitEnum.Days,
      },
      { name: 'actionParams.updateReviewDueDateParams.updateToDateRelativeIncrementValue', value: 0 },
      { name: 'actionParams.updateReviewDueDateParams.relativeToDueDate', value: true },
      { name: 'actionParams.updateReviewDueDateParams.relativeToCreatedDate', value: false },
    ],
  },
  [AutomationActionType.WriteOtherInfo]: {
    actionText: 'Update custom fields',
    actionParams: [
      {
        name: 'actionParams.writeOtherInfoParams.otherInfoEntries',
        value: [{ label: '', value: '' }],
      },
      {
        name: 'actionParams.writeOtherInfoParams.overwrite',
        value: false,
      },
    ],
  },
  [AutomationActionType.GoogleSheets]: {
    actionText: 'Send data to Google Sheets',
    actionParams: [
      {
        name: 'actionParams.googleSheetsParams.sheetId',
        value: '',
      },
    ],
  },
  [AutomationActionType.DataImport]: {
    actionText: 'Import data from query',
    actionParams: [],
  },
})

const ACTION_MAPPING_W_CUSTOM_FIELDS = createActionMapping()
const getActionMapping = () => ACTION_MAPPING_W_CUSTOM_FIELDS

export function setActionParams({
  newActionType,
  form,
}: {
  newActionType: AutomationActionType
  form: FormSchemaReturnType
}) {
  const { setValue, clearErrors } = form

  const actionMapping = getActionMapping()

  clearErrors('actionParams')
  setValue('actionType', newActionType)
  setValue('actionText', actionMapping[newActionType].actionText, { shouldDirty: true })
  actionMapping[newActionType].actionParams.forEach((param) => {
    setValue(param.name, param.value)
  })
}

export const getDefaultScheduleValues = (timezone: string) => {
  // moment() will use the users local timezone but below we will set it to the org's timezone
  const localMoment = moment()

  return {
    scheduleConfig: defaultScheduleConfig,
    scheduleStartOn: moment
      .tz(
        [localMoment.year(), localMoment.month(), localMoment.date(), defaultScheduleConfig.byhour, 0, 0, 0],
        timezone
      )
      .toISOString(),
  }
}

export const DEFAULT_DOMAIN_TYPE = AutomationDomainType.Review

// A helper to use form.reset but keep the form dirty
export function resetKeepDirty(
  form: FormSchemaReturnType,
  resetAction: Parameters<UseFormReset<FormSchema>>[0],
  keepDefaultValues = false
) {
  const actionText = form.getValues('actionText')

  // Ensure form is dirty and therefore submittable (This is a HACK!)
  // RHF has no way of setting the form to dirty on reset
  form.setValue('actionText', '', { shouldDirty: true })
  form.setValue('actionText', actionText)

  // We want to keep the original default values when switching between
  // domain types, since react-hook-form uses them to compare
  // with the current values to determine if the form is dirty. If we don't keep the default values,
  // the default values will be set to the new values, which messes with the clean/dirty state
  // determination.
  form.reset(resetAction, { keepDirty: true, keepDefaultValues })
}
