import { useEffect } from 'react'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

// eslint-disable-next-line no-restricted-imports
import { Popover, popoverClasses, styled } from '@mui/material'

import {
  COMMAND_PRIORITY_EDITOR,
  LexicalCommand,
  createCommand,
  TextNode,
  NodeKey,
  SerializedTextNode,
  $insertNodes,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
} from 'lexical'

export const INSERT_AI_INSTRUCTION_COMMAND: LexicalCommand<string> = createCommand('INSERT_AI_INSTRUCTION_COMMAND')

export const AiInstructionsPopover = styled(Popover)(({ theme }) => ({
  [`& .${popoverClasses.paper}`]: {
    borderRadius: '8px',
    padding: '0px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    isolation: 'isolate',
    '&>span': {
      padding: '8px 16px 0px',
      gap: '8px',
      color: theme.palette.styleguide.textGreyLight,
    },
    '& ul>div': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '8px 16px',
      gap: '8px',
    },
  },
}))

type AiInstructionOption = {
  value: string
  label: string
}

interface SerializedAiInstruction extends SerializedTextNode {
  label: string
  version: 1
}

const CLASS_AND_TYPE = 'ai-instruction'
const DATA_KEY = `data-${CLASS_AND_TYPE}`
const DATA_VALUE = 'true'
const CUSTOM_PLACEHOLDER = '[[  ]]'

function $convertAiInstructionElement(domNode: HTMLElement): DOMConversionOutput | null {
  const isAiInstruction = domNode.getAttribute(DATA_KEY) === DATA_VALUE
  if (isAiInstruction) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return { node: new AiInstructionNode(domNode.innerText) }
  }
  return null
}

export class AiInstructionNode extends TextNode {
  // __value: string
  // __text: i.e. the label / description
  //  it would be kind of cool to just show the description, but then we have to strip it intelligently i think...

  static getType(): string {
    return CLASS_AND_TYPE
  }

  static clone(node: AiInstructionNode): AiInstructionNode {
    return new AiInstructionNode(node.__text, node.__key)
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(text: string, key?: NodeKey) {
    // TODO: override with display vs value
    super(text, key)
  }

  buildDOMElement = () => {
    const elem = document.createElement('span')
    elem.innerText = this.__text
    elem.setAttribute('class', CLASS_AND_TYPE)
    elem.setAttribute(DATA_KEY, DATA_VALUE)

    return elem
  }

  createDOM(): HTMLElement {
    return this.buildDOMElement()
  }

  exportDOM(): DOMExportOutput {
    return { element: this.buildDOMElement() }
  }

  static importDOM(): DOMConversionMap<HTMLDivElement> | null {
    return {
      span: (domNode: HTMLDivElement) => {
        if (!domNode.hasAttribute(DATA_KEY)) {
          return null
        }

        return {
          conversion: $convertAiInstructionElement,
          priority: 1,
        }
      },
    }
  }

  static importJSON(serializedNode: SerializedAiInstruction): AiInstructionNode {
    return new AiInstructionNode(serializedNode.label)
  }

  exportJSON(): SerializedAiInstruction {
    return {
      ...super.exportJSON(),
      type: this.getType(),
      label: this.__text,
      version: 1,
    }
  }
}

export function aiInstructionOptions(): AiInstructionOption[] {
  // we might want to define these per org on the server at some point...
  // which might include simple smart values. thinking we might use hidden
  // tokens as locators as well so we can keep displaying a pretty value.
  return [
    {
      label: 'Describe all of the subjects of the case',
      value: '[[ Describe all of the subjects of the case ]]',
    },
    {
      label: 'Describe the transactions that occurred',
      value: '[[ Describe the transactions that occurred ]]',
    },
    {
      label: 'Describe the alerts associated with transactions',
      value: '[[ Describe the alerts associated with transactions ]]',
    },
    {
      label: 'Explain why the transactions and activity are suspicious',
      value: '[[ Explain why the transactions and activity are suspicious ]]',
    },
    {
      label: 'Include the filing intent and explain why',
      value: '[[ Include the filing intent and explain why ]]',
    },
    {
      label: '-- Custom Instruction --',
      value: CUSTOM_PLACEHOLDER,
    },
  ]
}

export function AiInstructionsPlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([AiInstructionNode])) {
      throw new Error('AiInstructionsPlugin: AiInstructionNode not registered on editor')
    }

    return editor.registerCommand(
      INSERT_AI_INSTRUCTION_COMMAND,
      (value) => {
        const aiInstNode = new AiInstructionNode(value)
        $insertNodes([aiInstNode])

        return true
      },
      COMMAND_PRIORITY_EDITOR
    )
  }, [editor])

  return null
}
