import { useMemo, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { Box, Skeleton, styled } from '@mui/material'

// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/styles'

import { sampleSize } from 'lodash'

import { useSelector } from 'actions/store'
import CollapseRegion from 'components/HbComponents/CollapseRegion'
import ExpandCollapseToggle from 'components/HbComponents/ExpandCollapseToggle'
import { HbText } from 'components/HbComponents/Text/HbText'
import RecipeCard from 'components/pages/automations/RecipeCard'
import { RecipePreview } from 'components/pages/automations/RecipePreview'
import { RecipePreviewAutomationRuleTemplateFragment as AutomationRuleTemplate } from 'components/pages/automations/__generated__/RecipePreview.generated'
import { getRecipeTags, Tag } from 'components/pages/automations/recipeTags'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { getCurrentOrganization } from 'helpers/stateHelpers'
import { useToggle } from 'hooks'

import { CardCarousel } from './CardCarousel'
import { CustomAutomationCard } from './CustomAutomationCard'
import { useExploreAutomationsQuery } from './ExploreAutomations/ExploreAutomations.hooks'
import { AutoGridList, HorizontalOverflowList } from './LayoutHelpers'

const CARD_WIDTH = 340

const StyledRecipeCard = styled(RecipeCard)(() => {
  return {
    height: '100%',
    width: '100%',
  }
})

const StyledHorizontalOverflowList = styled(HorizontalOverflowList)(({ theme }) => ({
  padding: theme.spacing(3),
}))

const StyledSkeleton = styled(Skeleton)<{ horizontalScrolling?: boolean }>(({ theme, horizontalScrolling }) => {
  return {
    width: horizontalScrolling ? CARD_WIDTH : 'auto',
    height: 160,
    borderRadius: theme.spacing(),
    backgroundColor: theme.palette.styleguide.backgroundDark,
  }
})

const Loader = ({ boxCount, horizontalScrolling }: { boxCount: number; horizontalScrolling?: boolean }) => {
  return (
    <>
      {Array(boxCount)
        .fill(null)
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <StyledSkeleton key={index} variant="rectangular" horizontalScrolling={horizontalScrolling} />
        ))}
    </>
  )
}

type RecommendedAutomationsProps = {
  showTags?: boolean
  horizontalScrolling?: boolean
  numberOfRecipes?: number
} & (
  | {
      horizontalScrolling?: true
      onCreateFromBlank?: never
    }
  | {
      horizontalScrolling?: false
      onCreateFromBlank?: () => void
    }
)

export const RecommendedAutomations = ({
  showTags = true,
  horizontalScrolling = false,
  numberOfRecipes = 6,
  onCreateFromBlank,
}: RecommendedAutomationsProps) => {
  const [currentRecipe, setCurrentRecipe] = useState<{ template: AutomationRuleTemplate; tags: Array<Tag> } | null>(
    null
  )

  const { data, error, loading } = useExploreAutomationsQuery()
  const usage = useUsage()

  const recipeCardData = useMemo(() => {
    if (!data?.automationRuleTemplates) return []
    const randomTemplates = sampleSize(data.automationRuleTemplates, numberOfRecipes)
    return randomTemplates.map((template) => {
      const tags = getRecipeTags(template)
      return { tags, template }
    })
  }, [data, numberOfRecipes])

  if (error) return null

  const recipePreviewProps = {
    template: currentRecipe?.template,
    tags: currentRecipe?.tags,
    onClose: () => setCurrentRecipe(null),
  }

  if (horizontalScrolling) {
    return loading ? (
      <StyledHorizontalOverflowList>
        <Loader horizontalScrolling boxCount={3} />
      </StyledHorizontalOverflowList>
    ) : (
      <>
        <CardCarousel
          cardData={recipeCardData}
          getKey={({ template }, i) => template.slug || i}
          cardWidthPx={CARD_WIDTH}
          renderCard={({ tags, template }) => (
            <StyledRecipeCard
              tags={showTags ? tags : []}
              template={template}
              openPreview={() => {
                usage.logEvent({
                  name: 'automations:recommendedAutomationsRecipe:clicked',
                  data: {
                    recipeName: template.name || '',
                    recipeTags: tags.map((tag) => tag.display),
                    recipeCategory: template.automationTemplateCategory?.name || '',
                  },
                })
                setCurrentRecipe({
                  template,
                  tags,
                })
              }}
            />
          )}
        />
        <RecipePreview {...recipePreviewProps} />
      </>
    )
  }

  return loading ? (
    <AutoGridList>
      <Loader horizontalScrolling={false} boxCount={6} />
    </AutoGridList>
  ) : (
    <>
      <AutoGridList>
        {onCreateFromBlank ? (
          <li>
            <CustomAutomationCard onClick={onCreateFromBlank} />
          </li>
        ) : null}
        {recipeCardData.map(({ tags, template }) => (
          <li key={template.slug}>
            <StyledRecipeCard
              tags={showTags ? tags : []}
              template={template}
              openPreview={() => {
                usage.logEvent({
                  name: 'automations:recommendedAutomationsRecipe:clicked',
                  data: {
                    recipeName: template.name || '',
                    recipeTags: tags.map((tag) => tag.display),
                    recipeCategory: template.automationTemplateCategory?.name || '',
                  },
                })
                setCurrentRecipe({ template, tags })
              }}
            />
          </li>
        ))}
      </AutoGridList>
      <RecipePreview {...recipePreviewProps} />
    </>
  )
}

export const RecommendedAutomationsCard = () => {
  const { value: isExpanded, toggle } = useToggle(true)
  const currentOrganizationName = useSelector((state) => getCurrentOrganization(state)?.name)
  const theme = useTheme()
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.styleguide.backgroundMedium,
        padding: theme.spacing(3, 0, 1, 0),
        marginBottom: theme.spacing(3),
        borderRadius: theme.spacing(),
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 3, mb: 1.5 }}>
        <div>
          <HbText size="lg" bold css={{ marginLeft: theme.spacing(3) }}>
            Recommended recipes for {currentOrganizationName}
          </HbText>
          <HbText size="lg" color="disabled" bold css={{ marginLeft: theme.spacing(3) }}>
            Featured
          </HbText>
        </div>
        <ExpandCollapseToggle isExpanded={isExpanded} toggleId="recommended-recipes" onClick={toggle} />
      </Box>
      <CollapseRegion isExpanded={isExpanded} id="recommended-recipes">
        <RecommendedAutomations showTags={false} horizontalScrolling />
      </CollapseRegion>
    </Box>
  )
}
