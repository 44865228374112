import { useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/styles'

import { GQLError } from 'components/GQLError'
import { HbText } from 'components/HbComponents/Text/HbText'

import Loader from 'components/library/Loader'
import { RecipePreview } from 'components/pages/automations/RecipePreview'
import { RecipePreviewAutomationRuleTemplateFragment as AutomationRuleTemplate } from 'components/pages/automations/__generated__/RecipePreview.generated'

import { useUsage } from 'helpers/SessionTracking/UsageTracker'

import { AutoGridList } from '../LayoutHelpers'
import RecipeCard from '../RecipeCard'

import { Tag } from '../recipeTags'

import { useCategoryAndSearchQueries, useExploreAutomationsData } from './ExploreAutomations.hooks'
import { ExploreAutomationsHeader } from './ExploreAutomationsHeader'

export default function ExploreAutomations() {
  const [currentRecipe, setCurrentRecipe] = useState<{ template: AutomationRuleTemplate; tags: Array<Tag> } | null>(
    null
  )
  const { category, search } = useCategoryAndSearchQueries()
  const { loading, error, filteredData, categoryWithCountBySlug } = useExploreAutomationsData({ category, search })
  const usage = useUsage()

  const theme = useTheme()
  if (loading) {
    return <Loader variant="global" />
  }

  if (error) {
    return <GQLError error={error} />
  }

  return (
    <>
      <ExploreAutomationsHeader category={category} search={search} categoryWithCountBySlug={categoryWithCountBySlug} />
      <HbText bold size="lg" tag="h3" block css={{ marginBottom: theme.spacing(2) }}>
        Recipes
      </HbText>
      {filteredData.length ? (
        <AutoGridList>
          {filteredData.map(({ template, tags }) => (
            <RecipeCard
              key={template.slug}
              template={template}
              tags={tags}
              openPreview={() => {
                usage.logEvent({
                  name: 'automations:exploreAutomationsRecipe:clicked',
                  data: {
                    recipeName: template.name || '',
                    recipeTags: tags.map((tag) => tag.display),
                    recipeCategory: template.automationTemplateCategory?.name || '',
                  },
                })
                setCurrentRecipe({
                  template,
                  tags,
                })
              }}
            />
          ))}
        </AutoGridList>
      ) : (
        <HbText size="lg" block color="secondary">
          {search ? 'No recipes found with &ldquo;{search}&rdquo;' : 'No recipes found.'}
        </HbText>
      )}
      {currentRecipe && (
        <RecipePreview
          template={currentRecipe?.template}
          tags={currentRecipe?.tags}
          onClose={() => setCurrentRecipe(null)}
        />
      )}
    </>
  )
}
