import { useState } from 'react'

import { Edit } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { Box, ButtonBase, styled } from '@mui/material'

import { useForm, SubmitHandler } from 'react-hook-form'

import { z } from 'zod'

import HbRHFTextArea from 'components/HbComponents/Form/HbInputs/HbTextArea/HbRHFTextArea'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { useUpdateAutomationNotes } from 'components/pages/automations/hooks/useUpdateAutomationNotes'

import { COLORS } from 'components/themeRedesign'

import { ViewAutomationInnerAutomationRuleFragment as AutomationRule } from '../__generated__/ViewAutomation.queries.generated'

const EditButton = styled(HbButton)`
  opacity: 0;
  &:focus {
    opacity: 1;
  }
`

const NotesWrapper = styled('section')`
  border-top: 1px solid ${COLORS.borderLight};
  padding: 20px 0;
  &:hover {
    ${EditButton} {
      opacity: 1;
    }
  }
`
const HeadingWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  min-height: 38px;
`

const DescriptionTextButton = styled(ButtonBase)`
  padding-right: 24px;
`

const formSchema = z.object({
  notes: z.string(),
})

type FormInputs = z.infer<typeof formSchema>

export const NotesSection = ({ automationRule }: { automationRule: AutomationRule }) => {
  const [editMode, setEditMode] = useState(false)

  const { updateAutomationNotes, loading } = useUpdateAutomationNotes(automationRule)
  const { control, handleSubmit, formState, reset } = useForm<FormInputs>({
    defaultValues: { notes: automationRule.notes || '' },
  })

  const onSubmit: SubmitHandler<FormInputs> = async (data, e) => {
    e?.preventDefault()
    await updateAutomationNotes(data.notes)
    setEditMode(false)
  }

  const enableEditing = () => {
    setEditMode(true)
  }
  const handleCancel = () => {
    setEditMode(false)
    reset()
  }

  return (
    <NotesWrapper>
      <HeadingWrapper>
        <HbText tag="h3" size="lg" bold block>
          Notes
        </HbText>
        {!editMode && (
          <EditButton label="Edit" variant="textPrimary" size="small" onClick={enableEditing} Icon={Edit} />
        )}
      </HeadingWrapper>
      {editMode ? (
        <form>
          <HbRHFTextArea control={control} name="notes" label="Notes" hideLabel minRows={5} />
          <Box sx={{ display: 'flex', gap: 2, marginTop: 2, justifyContent: 'flex-end' }}>
            <HbButton label="Cancel" variant="textPrimary" size="small" disabled={loading} onClick={handleCancel} />
            <HbButton
              label="Save"
              variant="primary"
              size="small"
              disabled={!formState.isDirty}
              loading={loading}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </form>
      ) : (
        <DescriptionTextButton tabIndex={-1} onClick={enableEditing}>
          <HbText>{automationRule.notes || 'No notes added yet.'}</HbText>
        </DescriptionTextButton>
      )}
    </NotesWrapper>
  )
}
