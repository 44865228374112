import { ClassNames } from '@emotion/react'
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'

import FilterButton from 'dashboards/reviews/components/Header/filtering/FilterButton'
import FilterSummaryBanner from 'dashboards/reviews/components/Header/filtering/FilterSummaryBanner'
import SortButton from 'dashboards/reviews/components/Header/filtering/SortButton'

const FilterBar = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: theme.spacing(0, 2, 3, 3),
}))

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}))

const Root = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const sharedFilterButtonProps = {
  size: 'medium' as const,
  iconOnly: false,
  zIndex: 1300, // zIndex must be 1300 to show filter controls over the ActivityLogModal
  // but not hide the dropdown menus within filters, which are at a zindex of 1300
}

export const MinimalDashboardHeader = ({ title }: { title: string }) => {
  return (
    <Root>
      <FilterBar>
        <HbText bold size="lg">
          {title}
        </HbText>
        <ClassNames>
          {({ css, theme }) => {
            const sharedStyles = {
              buttonActive: css({
                backgroundColor: theme.palette.background.dark,
                color: 'white',
                cursor: 'pointer',
              }),
              popoverShared: css({
                display: 'flex',
                gap: theme.spacing(),
                flexDirection: 'column',
              }),
              controlsContainer: css({ display: 'flex', gap: theme.spacing(0.5), padding: theme.spacing(0, 2) }),
              popoverHeader: css({ padding: theme.spacing(2.5, 0, 1.5, 2.5) }),
              addButtons: css({
                padding: theme.spacing(1, 2.5),
                display: 'flex',
                gap: theme.spacing(0.5),
              }),
            }
            return (
              <ButtonsWrapper>
                <FilterButton {...sharedStyles} {...sharedFilterButtonProps} />
                <SortButton {...sharedStyles} {...sharedFilterButtonProps} />
              </ButtonsWrapper>
            )
          }}
        </ClassNames>
      </FilterBar>
      <FilterSummaryBanner showCustomViewControls={false} />
    </Root>
  )
}
