import React from 'react'

// eslint-disable-next-line no-restricted-imports
import { Collapse, IconButton, styled } from '@mui/material'

import { HbBanner, HbBannerVariant } from 'components/HbComponents/HbBanner'
import { CloseIcon } from 'icons'

interface StyledCollapseProps {
  autoMinHeight: boolean
}

const StyledCollapse = styled(Collapse)<StyledCollapseProps>(() => ({
  minHeight: 'auto !important', // needs to be !important to override inline styling
}))

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  fill: theme.palette.styleguide.white,
  color: theme.palette.styleguide.white,
  width: '1rem',
  height: '1rem',
}))

export interface Props {
  className?: string
  message: string
  open: boolean
  onClose?: () => void
  variant?: HbBannerVariant
  Icon?: React.ComponentType<{ className?: string }>
  hasContentHeight?: boolean
}

function HeaderBanner(props: Props) {
  const { className, message, onClose: handleClose, open, Icon, variant = 'dark', hasContentHeight } = props

  return (
    <StyledCollapse className={className} in={open} appear={false} autoMinHeight={!!hasContentHeight && open}>
      <HbBanner
        actions={
          handleClose && (
            <IconButton aria-label="Close" onClick={handleClose}>
              <StyledCloseIcon />
            </IconButton>
          )
        }
        content={message}
        icon={Icon && <Icon />}
        variant={variant}
      />
    </StyledCollapse>
  )
}

export default HeaderBanner
