/* eslint-disable unused-imports/no-unused-vars, unused-imports/no-unused-imports */
/* global DEBUG */
import 'vite/modulepreload-polyfill' // see https://v3.vitejs.dev/guide/backend-integration.html
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'

import { ConnectedRouter } from 'connected-react-router'
import { set } from 'lodash'

import ms from 'ms'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'

import { initializeApplication, enqueueReduxSnackbar } from 'actions/applicationActions'
import { configureStore, Dispatch } from 'actions/store'
import Routes from 'components/Routes'

import { UsageContext, UsageTracker } from 'helpers/SessionTracking/UsageTracker'

import 'styles/index.scss'
import Pilot, { Context as PilotContext } from 'helpers/pilot'
import { FeatureFlag } from 'types/api'
import { InitialData } from 'types/hb'
import { createApolloClient } from 'utils/apolloClient'
import history from 'utils/history'
import { redactFile, sanitizeUrl } from 'utils/sanitization'

import globalStyles from './styles/global.module.css'
import 'utils/favicons' // FIXME: import has side effects!
import './fonts'

// @ts-expect-error Unfortunate hack to avoid the global CSS module from getting tree-shaken
window.__stylehack = globalStyles.toString()

declare global {
  interface Window {
    Intercom: (arg0: string, arg1?: any) => void
  }
}

smoothscroll.polyfill()

function initApp(initialData: InitialData) {
  if (initialData.currentAccount) {
    Sentry.setUser({
      id: initialData.currentAccount.token,
      email: initialData.currentAccount.email,
      organizationToken: initialData.currentOrganizationToken,
      organizationName: initialData.organizations.find(({ token }) => token === initialData.currentOrganizationToken)
        ?.name,
      intercomId: initialData.currentAccount.intercomId,
    })
  }

  let dispatch: Dispatch | undefined
  const notifyOffline = () => {
    if (dispatch) {
      dispatch(enqueueReduxSnackbar('You are offline.', { key: 'offline' }))
    }
  }
  const client = createApolloClient(initialData, notifyOffline)

  const appContainer = document.getElementById('react-app')
  if (initialData.currentOrganizationToken) {
    appContainer?.classList.add('authenticated')
  }

  // Don't initialize if the root element doesn't exist.
  if (!appContainer) {
    return null
  }

  const usage = new UsageTracker()
  const pilot = new Pilot({ urls: initialData.urls })
  const store = configureStore(initialData, usage, history, client, pilot)
  dispatch = store.dispatch as Dispatch
  dispatch(initializeApplication(initialData))
  usage.initialize(store)

  if (appContainer) {
    ReactDOM.render(
      <UsageContext.Provider value={usage}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <PilotContext.Provider value={pilot}>
                <Routes />
              </PilotContext.Provider>
            </ConnectedRouter>
          </Provider>
        </ApolloProvider>
      </UsageContext.Provider>,
      appContainer
    )
  }

  return true
}

const START_TIMESTAMP = Date.now()

document.addEventListener('DOMContentLoaded', () => {
  const initDataTag = document.getElementById('init-data')
  let initialData: InitialData = {} as InitialData
  if (initDataTag) {
    const json = initDataTag.innerText
    initialData = JSON.parse(json)
  }

  const userAccountsDataTag = document.getElementById('user-accounts-data')
  if (userAccountsDataTag) {
    const json = userAccountsDataTag.innerText
    initialData = { ...initialData, userAccounts: JSON.parse(json) }
  }

  // Hard code pen test account names because pen test account tokens are reset nightly
  const PEN_TEST_ACCOUNTS = ['Pentest Uno Financial', 'Pentest Dos Financial']

  if (['staging', 'sandbox', 'production'].includes(initialData.environment)) {
    // Init Sentry for exception reporting
    Sentry.init({
      dsn: 'https://d4037906a464496c875162486739023d@sentry.io/275847',
      environment: initialData.environment,
      release: `hummingbird-frontend@${initialData.gitSha1}`,
      autoSessionTracking: true, // default is true, but make it explicit.
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/#decluttering-sentry
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
      tracesSampler: () => {
        const organizationEnabled = initialData.organizations.find(
          ({ token }) => token === initialData.currentOrganizationToken
        )?.featureFlags[FeatureFlag.EnablePerformanceMonitoring]
        return organizationEnabled === false ? 0.0 : initialData.sentrySampleRate
      },
      beforeSend: (event) => {
        if (event.message === 'Failed to fetch' && navigator.onLine === false) {
          return null // we don't care about failed fetches when offline
        }

        /* eslint-disable no-param-reassign */
        const currentOrganization = initialData.organizations.find(
          ({ token }) => token === initialData.currentOrganizationToken
        )
        if (currentOrganization && PEN_TEST_ACCOUNTS.includes(currentOrganization.name)) {
          return null // prevent event from being sent to sentry by returning null
        }

        event.message = redactFile(event.message)
        event.exception?.values?.forEach((exception) => {
          exception.value = redactFile(exception.value)
        })

        // Sanitize the URL in the payload to avoid PII
        if (event.request) {
          if (event.request.url) {
            event.request.url = sanitizeUrl(event.request.url)
          }
          if (event.request.headers?.referer) {
            event.request.headers.referer = sanitizeUrl(event.request.headers.referer)
          }
        }

        // Track how long the page has been open
        set(event, ['contexts', 'session', 'sessionDuration'], ms(Date.now() - START_TIMESTAMP))

        return event
        /* eslint-enable no-param-reassign */
      },
      integrations: (defaultIntegrations) => {
        return [
          Sentry.reactRouterV5BrowserTracingIntegration({
            beforeStartSpan: (context) => {
              return {
                ...context,
                name: sanitizeUrl(context.name),
              }
            },
            history,
          }),
          ...defaultIntegrations,
        ].map((integration) => {
          // Modify options for the breadcrumbs integration
          // to limit PII data
          if (integration.name === 'Breadcrumbs') {
            return Sentry.breadcrumbsIntegration({
              console: false,
              dom: true,
              fetch: false,
              history: false,
              sentry: true,
              xhr: false,
            })
          }

          return integration
        })
      },
      // Intentionally excludes AWS/S3 to avoid leaking possibly-sensitive filenames
      tracePropagationTargets: ['localhost', /^\//, /[a-zA-Z0-9-]\.hummingbird.co/],
      // https://docs.sentry.io/clients/javascript/config/
      // filter out errors from being sent to Sentry
      ignoreErrors: [
        // https://sentry.io/organizations/hummingbird-rt/issues/1786699060/?project=275847&query=resizeobserver
        'ResizeObserver loop limit exceeded',
        // https://sentry.io/organizations/hummingbird-rt/issues/3459107538/?project=275847&referrer=slack
        'ResizeObserver loop completed with undelivered notifications',
        // mapbox-gl rendered charts and maps are wrapped with <ErrorBoundary /> to show an error to the user
        'Failed to initialize WebGL.',
        // Almost certainly MSFT Outlook scanning https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
        'Non-Error promise rejection captured with value: Object Not Found Matching Id',
      ],
    })
  }
  if (DEBUG) {
    // set to false to test persisted queries in local development
    initialData.disablePersistedQueries = true
  }
  initApp(initialData)
})

/**
 * HACK: Prevents events from propagating to non-dialog
 * event listeners, probably a better way for doing this.
 * We let through escapes, because there's an event listener
 * listening for escapes in order to close dialog.
 * Tabs should still work within the dialog box
 * since we are not using preventDefault
 */
document.addEventListener('keydown', (event) => {
  if (document.body.className.includes('dialog')) {
    const isEscape = event.key === 'Escape' || event.keyCode === 27
    if (!isEscape) {
      event.stopImmediatePropagation()
    }
  }
})

window.addEventListener('vite:preloadError', (event) => {
  // This is the 'Failed to fetch dynamically imported module' error.
  // If we're failing to load a dynamic module, refresh the app to get the latest code.
  window.location.reload()
})
