import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { styled, useTheme } from '@mui/material'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { Icon } from 'icons'
import { IconName } from 'icons/types'
import { Theme } from 'types/hb'

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 3, 9, 3),
}))

const StyledContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  maxWidth: 700,
  margin: 'auto',
}))

interface ActionButton {
  text: string
  onClick?: () => void
  url?: string
}
interface Props {
  icon: IconName
  status?: 'warning' | 'error' | 'pending'
  title: string
  subtext?: string
  actionButton?: ActionButton
  newTab?: boolean
  children?: ReactNode
}

const getStatusIconColor = (theme: Theme, status?: Props['status']) => {
  switch (status) {
    case 'warning':
      return theme.palette.warning.main
    case 'error':
      return theme.palette.error.main
    case 'pending':
      return theme.palette.grey['300']
    default:
      return theme.palette.primary.main
  }
}

const IconSheet = (props: Props) => {
  const { icon, title, subtext, actionButton, newTab, children, status } = props

  const theme = useTheme()
  const iconColor = getStatusIconColor(theme, status)
  return (
    <StyledRoot>
      <StyledContent>
        <Icon name={icon} css={{ fontSize: '5rem', marginBottom: theme.spacing(4), color: iconColor }} />
        <HbText block size="lg">
          {title}
        </HbText>
        {subtext && (
          <HbText block color="secondary" css={{ whiteSpace: 'pre-line', marginTop: theme.spacing(2) }}>
            {subtext}
          </HbText>
        )}
        {children}
        {actionButton && (
          <HbButton
            css={{ marginTop: theme.spacing(4) }}
            type="submit"
            color="primary"
            variant="secondary"
            // eslint-disable-next-line react/jsx-handler-names
            onClick={actionButton.onClick}
            href={actionButton.url ?? ''}
            target={newTab ? '_blank' : '_self'}
            label={actionButton.text}
          />
        )}
      </StyledContent>
    </StyledRoot>
  )
}

export default IconSheet
