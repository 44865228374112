import {
  // eslint-disable-next-line local-rules/no-restricted-imports-clone
  LinearProgress,
  LinearProgressProps,
  linearProgressClasses,
  // eslint-disable-next-line no-restricted-imports
  styled,
} from '@mui/material'

import { Hue } from 'components/colors'

import { Size as BaseSize } from '../HbComponents.types'

export type Size = Extract<BaseSize, 'xs' | 's' | 'md'>

const sizeMap: Record<Size, number> = {
  xs: 2,
  s: 4,
  md: 8,
}

interface ThemedRoundedLinearProgressProps {
  hue?: Hue
  size?: Size
}

const StyledRoundedLinearProgress = styled(LinearProgress)<ThemedRoundedLinearProgressProps>(
  ({ hue = 'hbBlue', size = 'md', theme }) => ({
    height: sizeMap[size],

    backgroundColor: theme.palette.styleguide[`${hue}Light`],
    borderRadius: theme.shape.largeContainer.borderRadius,

    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: theme.palette.styleguide[`${hue}Medium`],
      borderRadius: theme.shape.largeContainer.borderRadius,
    },
  })
)

export interface Props extends LinearProgressProps {
  hue?: Hue
  size?: Size
}

export const HbLinearProgress = ({ hue = 'hbBlue', variant = 'determinate', size = 'md', ...props }: Props) => (
  <StyledRoundedLinearProgress hue={hue} size={size} variant={variant} {...props} />
)
