import { gql } from '@apollo/client'
import { createAsyncThunk } from '@reduxjs/toolkit'

import moment from 'moment'

import {
  TransactionsActionsCreateTransactionMutation,
  TransactionsActionsCreateTransactionMutationVariables,
  TransactionsActionsUpdateTransactionFlaggedMutation,
  TransactionsActionsUpdateTransactionFlaggedMutationVariables,
} from 'actions/__generated__/transactionsActions.generated'

import { updateVisibleData } from 'actions/investigatingActions'
import { setLoading } from 'actions/viewActions'
import { BaseTransactionColumnKey } from 'components/cases/Tabs/Transactions/types'
import { TransactionFragmentFragment } from 'dashboards/transactions/gql/__generated__/searchTransactions.fragments.generated'
import { TransactionFragment } from 'dashboards/transactions/gql/searchTransactions.fragments'
import { SEARCH_TRANSACTIONS_QUERY } from 'dashboards/transactions/gql/searchTransactions.queries'
import { delayRefetchedQuery } from 'helpers/apolloHelpers'
import { mapTransaction } from 'reducers/dashboards/mappers'
import { HbMoney, OtherInfoEntry, TimeWithParts } from 'types/api'
import { ErrorResult, Result } from 'types/hb'

import { AsyncThunk, ThunkContext } from './store'

const SET_TRANSACTION_ERROR = 'SET_TRANSACTION_ERROR'
const SET_TRANSACTION_EDITING = 'SET_TRANSACTION_EDITING'
const SET_TRANSACTION_FOCUS_FIELD = 'SET_TRANSACTION_FOCUS_FIELD'
const SET_TRANSACTION_FULL_SCREEN = 'SET_TRANSACTION_FULL_SCREEN'
const RESET_TRANSACTION_EDITING_STATE = 'RESET_TRANSACTION_EDITING_STATE'
const RESET = 'RESET'

type Token = string

type AlertExternalId = string
type TransactionAlert = string
type ReviewICN = string

export interface TransactionEvent {
  description: string
  occurredAt: string
  occurredAtParts?: TimeWithParts
}

type TransactionTag = string

export type TransactionDirection = 'credit' | 'debit' | 'transfer'

export interface BaseTransaction {
  alertExternalIds: AlertExternalId[]
  alertRules: TransactionAlert[]
  reviewInternalControlNumbers: ReviewICN[]
  amount: HbMoney
  amountLocal: HbMoney
  completedAtParts: TimeWithParts | null
  createdAt: string
  currencyIssuingCountryCode: string | null
  fincenCtrTransactionType: string | null
  fincenCtrTransactionTypeOther: string | null
  description: string | null
  destinationAmount: HbMoney | null
  destinationName: string | null
  destinationSummary: string | null
  direction: TransactionDirection
  directionDescription: string
  events: TransactionEvent[]
  externalId: string | null
  flaggedAt: string | null
  importToken: string | null
  initiatedAtParts: TimeWithParts | null
  instrumentType: string
  instrumentTypeDescription: string | null
  iso18245MerchantCategoryCode: string | null
  iso8583MessageType: string | null
  iso8583MessageTypeDescription: string | null
  notes: string | null
  otherInfo: OtherInfoEntry[]
  receiverName: string | null
  responseCode: string | null
  senderName: string | null
  sourceAmount: HbMoney | null
  sourceName: string | null
  sourceSummary: string | null
  endpoints: TransactionFragmentFragment['endpoints']
  status: string | null
  tagString: string | null
  tags: TransactionTag[]
  timestamp: string
  timestampParts: TimeWithParts
  token: string
  transactionId: string
  updatedAt: string
}

// one of completedAt or initiatedAt is required
// so at least one will be defined
interface TransactionWithCompletedAt extends BaseTransaction {
  completedAtParts: TimeWithParts
}

interface TransactionWithInitiatedAt extends BaseTransaction {
  initiatedAtParts: TimeWithParts
}

export type Transaction = TransactionWithCompletedAt | TransactionWithInitiatedAt

export interface TimeWithPartsRestApiInput {
  date: string
  time?: string
}

export const editableFieldsList = [
  'amount',
  'amountLocal',
  'amountReceived',
  'amountSent',
  'date',
  'description',
  'direction',
  'externalId',
  'instrumentType',
  'notes',
  'recipient',
  'sourceSummary',
  'currencyIssuingCountryCode',
  'fincenCTRTransactionType',
] as const

export type TransactionEditableField = Extract<BaseTransactionColumnKey, (typeof editableFieldsList)[number]>

export const editableFieldsMap = editableFieldsList.reduce(
  (acc, next) => {
    acc[next] = true
    return acc
  },
  {} as Record<TransactionEditableField, true>
)

const filterableFieldsList = [
  'alertRules',
  'amount',
  'amountLocal',
  'amountReceived',
  'amountSent',
  'currencyIssuingCountryCode',
  'fincenCTRTransactionType',
  'date',
  'description',
  'direction',
  'flagged',
  'externalId',
  'instrumentType',
  'isoMessageType',
  'mcc',
  'notes',
  'responseCode',
  'status',
  'sourceEntity',
  'sourceAccount',
  'destinationEntity',
  'destinationAccount',
] as const

export type TransactionFilterableField = Extract<BaseTransactionColumnKey, (typeof filterableFieldsList)[number]>

export const filterableFieldsMap = filterableFieldsList.reduce(
  (acc, next) => {
    acc[next] = true
    return acc
  },
  {} as Record<TransactionFilterableField, true>
)

interface Action {
  type:
    | 'INIT'
    | 'SET_TRANSACTION_ERROR'
    | 'SET_TRANSACTION_EDITING'
    | 'SET_TRANSACTION_FOCUS_FIELD'
    | 'SET_TRANSACTION_FULL_SCREEN'
    | 'RESET_TRANSACTION_EDITING_STATE'
    | 'RESET'
    | `transactions/${string}/${'pending' | 'fulfilled'}`
  transaction?: Transaction
  transactionError?: ErrorResult | null
  fullScreen?: boolean
  isEditing?: boolean
  editingToken?: string | null
  focusField?: TransactionEditableField | null
  meta?: { requestId: string; arg: any }
}

export type TransactionEntry = Transaction

interface TransactionState {
  transactionError?: ErrorResult | null
  isEditing: boolean
  editingToken: string | null
  focusField: TransactionEditableField | null
  fullScreen: boolean
  updateTransactionFlagPending: Record<string, false | string>
}

export const getInitialState = (): TransactionState => ({
  transactionError: null,
  isEditing: false,
  editingToken: null,
  focusField: null,
  fullScreen: false,
  updateTransactionFlagPending: {},
})

export const reducer = (state = getInitialState(), action: Action): TransactionState => {
  switch (action.type) {
    case 'transactions/updateTransactionFlag/pending': {
      return {
        ...state,
        updateTransactionFlagPending: {
          ...state.updateTransactionFlagPending,
          [action.meta?.requestId as unknown as string]: action.meta?.arg?.token as string,
        },
      }
    }
    case 'transactions/updateTransactionFlag/fulfilled': {
      return {
        ...state,
        updateTransactionFlagPending: {
          ...state.updateTransactionFlagPending,
          [action.meta?.requestId as unknown as string]: false,
        },
      }
    }
    case SET_TRANSACTION_ERROR: {
      return {
        ...state,
        transactionError: action.transactionError,
      }
    }
    case SET_TRANSACTION_EDITING: {
      return {
        ...state,
        isEditing: !!action.isEditing,
        editingToken: action.editingToken ?? null,
        focusField: action.focusField ?? null,
        transactionError: action.isEditing ? state.transactionError : undefined,
      }
    }
    case SET_TRANSACTION_FOCUS_FIELD: {
      return {
        ...state,
        focusField: action.focusField ?? null,
      }
    }
    case SET_TRANSACTION_FULL_SCREEN: {
      return {
        ...state,
        fullScreen: action.fullScreen ?? state.fullScreen,
      }
    }
    case RESET_TRANSACTION_EDITING_STATE: {
      return {
        ...state,
        transactionError: null,
      }
    }
    case RESET: {
      return {
        ...getInitialState(),
      }
    }
    default: {
      return state
    }
  }
}

export const setTransactionFullScreen = (fullScreen: boolean) => ({
  type: SET_TRANSACTION_FULL_SCREEN,
  fullScreen,
})

export function setTransactionError(transactionError: ErrorResult | null): Action {
  return { type: SET_TRANSACTION_ERROR, transactionError }
}

export function setEditing({
  editingToken,
  focusField,
  isEditing,
}: {
  editingToken?: string | null
  focusField?: TransactionEditableField | null
  isEditing: boolean
}): Action {
  return { type: SET_TRANSACTION_EDITING, editingToken, focusField, isEditing }
}

export function setFocusField(focusField: TransactionEditableField | null): Action {
  return { type: SET_TRANSACTION_FOCUS_FIELD, focusField }
}

export function resetTransactionsState() {
  return { type: RESET }
}

type Thunkor<T> = (...args: any[]) => AsyncThunk<T>
function withLoading<T>(name: string, fn: Thunkor<T>): Thunkor<T> {
  return (...args: any[]) =>
    async (dispatch, getState, context) => {
      dispatch(setLoading(true, name))
      try {
        return await fn(...args)(dispatch, getState, context)
      } finally {
        dispatch(setLoading(false, fn.name))
      }
    }
}

type Prefix<T extends string> = `transactions/${T}`
const makeName = <T extends string>(name: T) => `transactions/${name}` as Prefix<T>

type UpdateTransactionPayload = {
  investigationToken: string
  token: string
  flagged: boolean
}

export const UPDATE_TRANSACTION_FLAGGED_MUTATION = gql`
  mutation TransactionsActionsUpdateTransactionFlagged($input: UpdateTransactionFlaggedInput!) {
    updateTransactionFlagged(input: $input) {
      transaction {
        ...TransactionFragment
      }
    }
  }
  ${TransactionFragment}
`

export const updateTransactionFlag = createAsyncThunk<unknown, UpdateTransactionPayload, { extra: ThunkContext }>(
  makeName('updateTransactionFlag'),
  async (payload: UpdateTransactionPayload, thunk) => {
    const {
      dispatch,
      extra: { gqlClient },
    } = thunk

    const { investigationToken, token, flagged } = payload
    const flaggedAt = flagged ? moment().toISOString() : null
    // Update visible data to show the change in the UI before the network call is done
    dispatch(updateVisibleData({ token, flaggedAt }))

    let data = null
    try {
      const result = await gqlClient.mutate<
        TransactionsActionsUpdateTransactionFlaggedMutation,
        TransactionsActionsUpdateTransactionFlaggedMutationVariables
      >({
        mutation: UPDATE_TRANSACTION_FLAGGED_MUTATION,
        variables: {
          input: {
            investigationToken,
            flagged,
            token,
          },
        },
      })
      data = result.data
    } catch (e) {
      const message = e.graphQLErrors?.length > 0 ? e.graphQLErrors[0].message : 'Something went wrong.'
      const error: ErrorResult = {
        success: false,
        error: {
          message,
          type: 'Error',
        },
      }

      dispatch(setTransactionError(error))
      return error
    }
    const updatedTransaction = data?.updateTransactionFlagged?.transaction
    if (!updatedTransaction) {
      const errorResult: ErrorResult = {
        success: false,
        error: {
          type: 'Error',
          message: 'Transaction could not be updated.',
        },
      }
      dispatch(setTransactionError(errorResult))
      return errorResult
    }
    const mappedUpdatedTransactionBase = mapTransaction(updatedTransaction)

    // FIXME: "at least one" of initiatedAtParts and completedAtParts is impossible for
    //  typescript's type inference to figure out so we just have to force it with a cast :(
    const mappedUpsertedTransaction = mappedUpdatedTransactionBase as Transaction

    dispatch(setTransactionError(null))

    return mappedUpsertedTransaction
  }
)

const CREATE_TRANSACTION_MUTATION = gql`
  mutation TransactionsActionsCreateTransaction($input: UpsertTransactionInput!) {
    upsertTransaction(input: $input) {
      transaction {
        ...TransactionFragment
      }
    }
  }
  ${TransactionFragment}
`

export const createTransaction = withLoading(
  'createTransaction',
  (investigationToken: Token, params: any): AsyncThunk<Result<'transactions', Transaction[]>> => {
    return async (dispatch, getState, { gqlClient }) => {
      let data = null
      try {
        const result = await gqlClient.mutate<
          TransactionsActionsCreateTransactionMutation,
          TransactionsActionsCreateTransactionMutationVariables
        >({
          mutation: CREATE_TRANSACTION_MUTATION,
          variables: {
            input: {
              investigationToken,
              transaction: params,
            },
          },
          refetchQueries: [SEARCH_TRANSACTIONS_QUERY],
          onQueryUpdated: (e) => delayRefetchedQuery({ observableQuery: e, query: SEARCH_TRANSACTIONS_QUERY }),
        })
        data = result.data
      } catch (e) {
        const message = e.message || 'Something went wrong.'

        const error: ErrorResult = {
          success: false,
          error: {
            message,
            type: 'Error',
          },
        }

        dispatch(setTransactionError(error))
        return error
      }

      const upsertedTransaction = data?.upsertTransaction?.transaction
      if (!upsertedTransaction) {
        const errorResult: ErrorResult = {
          success: false,
          error: {
            type: 'Error',
            message: 'Transaction could not be created.',
          },
        }
        dispatch(setTransactionError(errorResult))
        return errorResult
      }

      const mappedUpsertedTransactionBase = mapTransaction(upsertedTransaction)

      // FIXME: "at least one" of initiatedAtParts and completedAtParts is impossible for
      //  typescript's type inference to figure out so we just have to force it with a cast :(
      const mappedUpsertedTransaction = mappedUpsertedTransactionBase as Transaction

      dispatch(setTransactionError(null))
      return {
        success: true,
        transactions: [mappedUpsertedTransaction],
      }
    }
  }
)
