import React, { useContext, useMemo, useCallback } from 'react'

import { ClassNames, ClassNamesContent } from '@emotion/react'
import { AddCircle, RemoveCircle } from '@mui/icons-material'
import {
  FormHelperTextProps as MaterialFormHelperTextProps,
  InputProps as MaterialInputProps,
  InputAdornment,
  IconButton,
  TextField as TextFieldBase,
  FormHelperText,
  TextFieldProps,
  ClassNameMap,
} from '@mui/material'

// eslint-disable-next-line no-restricted-imports
import { makeStyles, withStyles } from '@mui/styles'

import classnames from 'classnames'
import {
  connect as formikConnect,
  Field,
  FieldArray,
  FormikContextType,
  getIn,
  useFormikContext,
  FieldValidator,
} from 'formik'
import { includes, isEmpty } from 'lodash'

import { useSelector } from 'actions/store'
import SARFieldAdornment from 'components/library/SARFieldAdornment'
import {
  AlphanumericField,
  FastAlphanumericField,
  FastTextField,
  SelectBase,
  SelectField,
  TextField,
  TextInputV2,
} from 'components/material/Form'
import { formikNameString, getErrorDetails } from 'components/material/Form/formikMaterial'
import { withEmpty } from 'helpers/objHelpers'
import { getCountries, getCurrentFilingInstitutions } from 'helpers/stateHelpers'
import { FIELD_REQUIRED } from 'helpers/validations'
import { Theme, WithPartialStyles, WithStyles } from 'types/hb'

import { CountryInput } from '../../HbComponents/Form/Inputs/CountryAndStateInputs/CountryInput'

import { StateInput } from '../../HbComponents/Form/Inputs/CountryAndStateInputs/StateInput'

import { AutocompleteField } from './Autocomplete'
import { FormConfigurationContext } from './FormConfiguration'

interface Option<T> {
  display: string
  value: T
}

function validateNotEmpty(newValue: string) {
  // Require non-empty
  if (!newValue || !newValue.trim()) {
    return FIELD_REQUIRED
  }

  return undefined
}

const labeledFieldStyles = (theme: Theme) => ({
  root: {
    display: 'flex',
  },
  fullWidth: {
    flexGrow: 1,
  },
  adornment: {
    flex: '0 0 32px',
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    flex: '1 1 auto',
    verticalAlign: 'baseline',
    width: '100%',
    paddingBottom: theme.spacing(2),
  },
  helperText: {
    height: 8,
  },
})

type LabeledFieldWrappedProps = {
  className?: string
  label?: string
  id?: string
  testId?: string
  FormHelperTextProps?: MaterialFormHelperTextProps
  InputProps?: MaterialInputProps
  fullWidth?: boolean
  adornment?: React.ReactNode | 'NONE'
  required?: boolean
}

type PropsType<C> = C extends React.ComponentType<infer P> ? P : never

type LabeledFieldBaseProps<C extends React.ComponentType<unknown>> = {
  name: string
  FieldComponent?: C
} & LabeledFieldWrappedProps &
  PropsType<C>

export type LabeledFieldProps<C extends React.ComponentType<unknown>> = LabeledFieldBaseProps<C> &
  WithPartialStyles<typeof labeledFieldStyles>

function LabeledFieldBase<C extends React.ComponentType<any>>(
  props: LabeledFieldBaseProps<C> & WithStyles<typeof labeledFieldStyles>
) {
  const {
    className,
    classes,
    label,
    placeholder,
    adornment = null,
    FieldComponent = TextField,
    InputProps = {},
    FormHelperTextProps = {},
    id = null,

    fullWidth = false,
    required = false,
    testId,
    ...otherProps
  } = props

  const formConfiguration = useContext(FormConfigurationContext)
  const adornmentRedesign = formConfiguration?.adornmentRedesign

  return (
    <div className={classnames(classes.root, { [classes.fullWidth]: fullWidth })}>
      {!adornmentRedesign && adornment !== 'NONE' && <span className={classes.adornment}>{adornment}</span>}
      <FieldComponent
        className={className}
        label={label}
        testId={testId ?? label ?? placeholder}
        classes={{ root: classes.formControl }}
        id={id}
        FormHelperTextProps={{
          classes: { root: classes.helperText },
          ...FormHelperTextProps,
        }}
        InputProps={{
          id,
          endAdornment: adornmentRedesign && adornment !== 'NONE' && adornment,
          ...InputProps,
        }}
        fullWidth={fullWidth}
        placeholder={placeholder}
        {...otherProps}
        disabled={formConfiguration?.readOnly || otherProps.disabled}
        required={required}
      />
    </div>
  )
}

// Wrap with withStyles and recast to fix TS's prop type inference
export const LabeledField = withStyles(labeledFieldStyles)(LabeledFieldBase) as <
  C extends React.ComponentType<unknown>,
>(
  props: LabeledFieldProps<C>
) => JSX.Element

// Test cases for passing props to LabeledField depending on the
// type of the FieldComponent
if (DEBUG) {
  /* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/ban-ts-comment */
  // eslint-disable-next-line no-inner-declarations
  function LabeledFieldPropTypeTestCases() {
    const a = <LabeledField name="test" FieldComponent={TextField} />
    // @ts-expect-error
    const b = <LabeledField name="test" FieldComponent={TextField} foo="bar" />
    const c = (
      // @ts-expect-error
      <LabeledField name="test" FieldComponent={SelectField} foo="bar" />
    )
    // @ts-expect-error
    const d = <LabeledField name="test" FieldComponent={SelectField} />
    const f = <LabeledField name="test" FieldComponent={SelectField} options={[]} />
    // @ts-expect-error
    const e = <LabeledField />
    const g = <LabeledField name="test" />
    // @ts-expect-error
    const h = <LabeledField name="test" foo="bar" />
  }
  /* eslint-enable no-unused-vars, @typescript-eslint/ban-ts-comment */
}

interface OptionsComparisonProps {
  options: unknown[]
  name: string
  formik: FormikContextType<unknown>
}

const useAlsoCompareOptionsForUpdate = () => {
  return useCallback((nextProps: OptionsComparisonProps, prevProps: OptionsComparisonProps) => {
    if (prevProps.options !== nextProps.options) return true

    // include default Formik FastField shouldUpdate checks
    return (
      nextProps.name !== prevProps.name ||
      getIn(nextProps.formik.values, prevProps.name) !== getIn(prevProps.formik.values, prevProps.name) ||
      getIn(nextProps.formik.errors, prevProps.name) !== getIn(prevProps.formik.errors, prevProps.name) ||
      getIn(nextProps.formik.touched, prevProps.name) !== getIn(prevProps.formik.touched, prevProps.name) ||
      Object.keys(prevProps).length !== Object.keys(nextProps).length ||
      nextProps.formik.isSubmitting !== prevProps.formik.isSubmitting
    )
  }, [])
}

type LabeledSelectFieldProps = LabeledFieldProps<typeof SelectField> & {
  name: string
  options: any[]
}

export const LabeledSelectField = ({ name, options, ...props }: LabeledSelectFieldProps) => (
  <LabeledField FieldComponent={SelectField} options={options} name={name} {...props} />
)

export type SelectWithOtherProps = LabeledFieldProps<typeof SelectBase> & {
  name: string
  otherName?: string
  otherLabel?: string
  label: string
  options: Option<any>[]
  helperText?: string
  otherHelperText?: string
  disabled?: boolean
  multiple?: boolean
  defaultOptionValue?: string | null
}

function SelectWithOther({
  formik,
  name,
  otherName = undefined,
  otherLabel = undefined,
  label,
  options,
  helperText,
  otherHelperText,
  adornment,
  disabled = false,
  multiple = false,
  variant,
  size,
  ...props
}: SelectWithOtherProps & {
  formik: FormikContextType<unknown>
}) {
  const otherSelection = 'OTHER'
  const otherNameWithDefault = otherName || `${name}Other`
  const defaultValue: any[] | '' = multiple ? [] : ''
  const { fieldError, showError } = getErrorDetails({ name }, formik)
  const value = getIn(formik.values, name)

  const { fieldError: otherError, showError: showOtherError } = getErrorDetails({ name: otherNameWithDefault }, formik)
  const otherValue = getIn(formik.values, otherNameWithDefault)
  const isOtherSelected = multiple ? includes(value || [], otherSelection) : value === otherSelection

  return (
    <>
      <LabeledField
        FieldComponent={SelectBase}
        options={options}
        label={label}
        name={name}
        value={value || defaultValue}
        error={showError}
        helperText={fieldError || helperText}
        disabled={formik.isSubmitting || disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (!isEmpty(otherValue) && event.target.value !== 'OTHER') {
            formik.handleChange({
              target: { name: otherNameWithDefault, value: '' },
            })
          }
          formik.handleChange(event)
          formik.setFieldTouched(otherNameWithDefault) // set as touched so validations are shown to the user
        }}
        onBlur={formik.handleBlur}
        multiple={multiple}
        adornment={adornment}
        variant={variant}
        size={size}
        select
        {...props}
      />
      {isOtherSelected && (
        <Field // Wrap with a Field for validation, since TextFieldBase is not wrapped by itself
          name={otherNameWithDefault}
          validate={validateNotEmpty}
        >
          {() => (
            <LabeledField
              FieldComponent={TextFieldBase}
              label={otherLabel || `${label} Other`}
              name={otherNameWithDefault}
              value={otherValue || ''}
              error={showOtherError}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={otherError || otherHelperText}
              adornment={adornment}
              disabled={formik.isSubmitting || disabled}
              variant={variant}
              size={size}
              required
            />
          )}
        </Field>
      )}
    </>
  )
}

export const SelectWithOtherField = formikConnect<SelectWithOtherProps>(SelectWithOther)

const splitFieldStyles = (theme: Theme) => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr',
  },
  child: {
    gridColumn: 1 / 2,
  },
})

export const SplitFieldContainer = withStyles(splitFieldStyles)(({ children, classes }: any) => {
  const newChildren = React.Children.map(children, (child) =>
    React.cloneElement(child, { classes: { root: classes.child } })
  )
  return <div className={classes.root}>{newChildren}</div>
})

export const useIdStyles = makeStyles((theme: Theme) => ({
  fieldWrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  number: {
    flex: '1 1 auto',
  },
  type: {
    flex: '0 0 auto',
    width: '15rem',
    marginRight: theme.spacing(2),
  },
}))

type IdNumberAndTypeFieldProps = {
  typeLabel?: string
  typeName?: string
  typeOptions: Option<any>[]
  numberLabel?: string
  numberName?: string
  numberPlaceholder?: string
  numberValidate?: FieldValidator
  NumberInputProps?: any
  withOther?: boolean
  typeAdornment?: React.ReactNode
  numberAdornment?: React.ReactNode
  defaultTypeOptionValue?: string | null
  allowAsterisk?: boolean
}

export function IDNumberAndTypeField({
  typeOptions,
  typeLabel = 'ID Type',
  typeName = 'identificationType',
  defaultTypeOptionValue = '',
  numberLabel = 'Number',
  numberPlaceholder = undefined,
  numberName = 'identificationNumber',
  numberValidate = undefined,
  NumberInputProps = {},
  withOther = false,
  typeAdornment = null,
  numberAdornment = null,
  allowAsterisk = false,
}: IdNumberAndTypeFieldProps) {
  const classes = useIdStyles()

  return (
    <div className={classes.fieldWrapper}>
      {withOther ? (
        <SelectWithOtherField
          adornment={typeAdornment}
          classes={{
            formControl: classes.type,
            helperText: '',
            root: '',
            adornment: '',
            fullWidth: '',
          }}
          name={typeName}
          label={typeLabel}
          options={typeOptions}
          defaultOptionValue={defaultTypeOptionValue}
        />
      ) : (
        <LabeledField
          adornment={typeAdornment}
          classes={{ formControl: classes.type }}
          name={typeName}
          label={typeLabel}
          FieldComponent={SelectField}
          options={typeOptions}
          placeholder="Select a type..."
          defaultOptionValue={defaultTypeOptionValue}
        />
      )}
      <LabeledField
        classes={{ formControl: classes.number }}
        name={numberName}
        label={numberLabel}
        FieldComponent={FastAlphanumericField}
        allowAsterisk={allowAsterisk}
        placeholder={numberPlaceholder}
        InputProps={NumberInputProps}
        adornment={numberAdornment}
        fullWidth
        validate={numberValidate}
      />
    </div>
  )
}

export const TaxIDAndTypeField = (props: IdNumberAndTypeFieldProps) => (
  <IDNumberAndTypeField typeLabel="Tax ID Type" typeName="tinType" numberLabel="Number" numberName="tin" {...props} />
)

interface CountryAndStateProps {
  classes?: Partial<ClassNameMap<'countryInputContainer' | 'stateInputContainer'>>
  countryName: string
  countryLabel: string
  fieldVariant?: TextFieldProps['variant']
  stateName: string
  stateLabel: string
  countryAndStateName?: string
  countryAdornment?: React.ReactNode
  stateAdornment?: React.ReactNode
  useNewerVersion?: boolean
}

const useCountryOptions = () => {
  const countries = useSelector(getCountries)
  const filingInstitutionCountry = useSelector(getCurrentFilingInstitutions)[0].country

  const countryOptions = useMemo(() => {
    // Move the organization country to the top of the list for easy access
    return [filingInstitutionCountry, ...Object.keys(countries).filter((c) => c !== filingInstitutionCountry)].map(
      (countryCode) => ({
        label: countries[countryCode].name,
        value: countryCode,
      })
    )
  }, [countries, filingInstitutionCountry])

  return { countries, countryOptions }
}

/**
 * @deprecated
 */
export const CountryFieldOld = ({ name, label }: { name: string; label: string }) => {
  const { countryOptions } = useCountryOptions()

  return (
    <LabeledField
      FieldComponent={AutocompleteField}
      name={name}
      label={label}
      placeholder="Start typing to choose country..."
      options={countryOptions}
    />
  )
}

const getFieldVariantStyles = ({
  css,
  theme,
  variant,
}: {
  css: ClassNamesContent['css']
  theme: Theme
  variant: TextFieldProps['variant']
}) => {
  if (variant !== 'outlined') return {}
  return {
    root: css({ padding: theme.spacing(0.75, 1.75) }),
    input: css({ padding: theme.spacing(0.25, 1) }),
  }
}

/**
 * @deprecated
 * Note: this is the only component that uses AutocompleteField.
 *  Whoever removes this component should also remove AutocompleteField.
 */
function CountryAndStateFieldOld(props: CountryAndStateProps) {
  const {
    countryName,
    countryLabel,
    stateName,
    stateLabel,
    countryAdornment,
    stateAdornment,
    countryAndStateName,
    fieldVariant,
  } = props

  const formik = useFormikContext()

  const { countries, countryOptions } = useCountryOptions()

  const currentCountry = countries[getIn(formik.values, countryName)]

  const stateOptions = useMemo(() => {
    if (currentCountry) {
      return currentCountry.subdivisions.map((subdivision: any) => ({
        label: subdivision.display,
        value: subdivision.value,
      }))
    }

    return []
  }, [currentCountry])

  const setCountryValue = (value: string) => {
    formik.setFieldValue(countryName, value)
  }

  const setStateValue = (value: string | null) => {
    formik.setFieldValue(stateName, value)
  }

  const clearStateValue = () => setStateValue(null)

  // Need to clear the state value as it's almost certainly not a valid choice in new country
  const handleCountryChange = (option: any) => {
    clearStateValue()
    setCountryValue(option.value)

    if (!option.value && countryAndStateName) {
      formik.setFieldValue(countryAndStateName, null)
    }

    formik.setFieldValue(stateName, '')
  }

  const isStateInputDisabled = !currentCountry
  const { fieldError, showError } = getErrorDetails({ name: countryName }, formik)
  const shouldUpdate = useAlsoCompareOptionsForUpdate()

  return (
    <ClassNames>
      {({ css, theme }) => (
        <>
          <LabeledField
            FieldComponent={AutocompleteField}
            adornment={countryAdornment}
            name={countryName}
            label={countryLabel}
            TextFieldProps={{
              error: showError,
              helperText: showError ? fieldError : '',
              variant: fieldVariant,
              InputProps: {
                classes: getFieldVariantStyles({ css, theme, variant: fieldVariant }),
              },
            }}
            placeholder="Start typing to choose country..."
            options={countryOptions}
            onChange={handleCountryChange}
          />
          <LabeledField
            FieldComponent={AutocompleteField}
            adornment={stateAdornment}
            name={stateName}
            label={stateLabel}
            options={stateOptions}
            placeholder="Start typing to choose a state..."
            isDisabled={isStateInputDisabled}
            shouldUpdate={shouldUpdate}
            TextFieldProps={{
              variant: fieldVariant,
              InputProps: {
                classes: getFieldVariantStyles({ css, theme, variant: fieldVariant }),
              },
            }}
          />
        </>
      )}
    </ClassNames>
  )
}

function CountryAndStateFieldNew(props: CountryAndStateProps) {
  const {
    classes,
    countryName,
    countryLabel,
    stateName,
    stateLabel,
    countryAdornment,
    stateAdornment,
    countryAndStateName,
    fieldVariant,
  } = props

  return (
    <>
      <CountryInput
        className={classes?.countryInputContainer}
        label={countryLabel}
        startAdornment={countryAdornment}
        countryName={countryName}
        countryAndStateName={countryAndStateName}
        variant={fieldVariant}
      />
      <StateInput
        className={classes?.stateInputContainer}
        label={stateLabel}
        startAdornment={stateAdornment}
        countryName={countryName}
        stateName={stateName}
        variant={fieldVariant}
      />
    </>
  )
}

// TODO: replace with CountryAndStateFieldNew once all address fields
// are updated to use newer input styles
export function CountryAndStateField(props: CountryAndStateProps) {
  const { useNewerVersion, ...rest } = props
  return useNewerVersion ? <CountryAndStateFieldNew {...rest} /> : <CountryAndStateFieldOld {...rest} />
}

interface AddressFieldProps {
  addressLine1Name?: string
  addressLine2Name?: string
  cityName?: string
  stateName?: string
  countryName?: string
  postalCodeName?: string
  addressLine1Label?: string
  addressLine2Label?: string
  cityLabel?: string
  stateLabel?: string
  countryLabel?: string
  postalCodeLabel?: string
  addressAdornmentLabel?: string
  cityAdornmentLabel?: string
  stateAdornmentLabel?: string
  countryAdornmentLabel?: string
  zipAdornmentLabel?: string
}

export function AddressFields({
  addressLine1Name = 'addressLine1',
  addressLine2Name = 'addressLine2',
  cityName = 'locality',
  stateName = 'administrativeDistrictLevel1',
  countryName = 'country',
  postalCodeName = 'postalCode',
  addressLine1Label = 'Address Line 1',
  addressLine2Label = 'Address Line 2',
  cityLabel = 'City',
  stateLabel = 'State',
  countryLabel = 'Country',
  postalCodeLabel = 'Postal Code',
  addressAdornmentLabel,
  cityAdornmentLabel,
  stateAdornmentLabel,
  countryAdornmentLabel,
  zipAdornmentLabel,
  useCleanup,
}: AddressFieldProps & { useCleanup?: boolean }) {
  const addressAdornment = <SARFieldAdornment field={addressAdornmentLabel} />

  if (useCleanup) {
    return (
      <>
        <TextInputV2 adornment={addressAdornment} name={addressLine1Name} label={addressLine1Label} />
        <TextInputV2 adornment={addressAdornment} name={addressLine2Name} label={addressLine2Label} />
        <TextInputV2 adornment={<SARFieldAdornment field={cityAdornmentLabel} />} name={cityName} label={cityLabel} />
        <CountryInput countryName={countryName} label={countryLabel} />
        <StateInput stateName={stateName} label={stateLabel} countryName={countryName} />
        <TextInputV2
          // TODO field validation and/or component wrapping within TextInputV2
          // will be handled separately from these changes
          adornment={<SARFieldAdornment field={zipAdornmentLabel} />}
          name={postalCodeName}
          label={postalCodeLabel}
        />
      </>
    )
  }

  return (
    <>
      <LabeledField adornment={addressAdornment} name={addressLine1Name} label={addressLine1Label} />
      <LabeledField adornment={addressAdornment} name={addressLine2Name} label={addressLine2Label} />
      <LabeledField adornment={<SARFieldAdornment field={cityAdornmentLabel} />} name={cityName} label={cityLabel} />
      <CountryAndStateField
        countryAdornment={<SARFieldAdornment field={countryAdornmentLabel} />}
        stateAdornment={<SARFieldAdornment field={stateAdornmentLabel} />}
        countryLabel={countryLabel}
        countryName={countryName}
        stateLabel={stateLabel}
        stateName={stateName}
      />
      <LabeledField
        adornment={<SARFieldAdornment field={zipAdornmentLabel} />}
        name={postalCodeName}
        label={postalCodeLabel}
        FieldComponent={AlphanumericField}
      />
    </>
  )
}

const adornmentStyles = (theme: Theme) => ({
  icon: {
    width: '1.2rem',
    height: '1.2rem',
  },
  iconBtn: {
    padding: '6px',
    marginRight: '-6px',
    '&:hover': {
      color: theme.palette.accent,
    },
  },
  adornment: {
    maxWidth: '60px',
  },
})

const MultiAdornment = withStyles(adornmentStyles)(({ insert, remove, index, empty, classes }: any) => (
  <InputAdornment position="end" classes={{ root: classes.adornment }}>
    <IconButton onClick={() => remove(index)} classes={{ root: classes.iconBtn }} size="large">
      <RemoveCircle classes={{ root: classes.icon }} />
    </IconButton>
    <IconButton onClick={() => insert(index + 1, empty)} classes={{ root: classes.iconBtn }} size="large">
      <AddCircle classes={{ root: classes.icon }} />
    </IconButton>
  </InputAdornment>
))

type MultiLabeledFieldProps<C extends React.ComponentType<unknown> = typeof TextField> = LabeledFieldProps<C> & {
  list: any[]
  attribute?: string
  nullValue?: any
}

export const MultiLabeledField = <C extends React.ComponentType<any>>(props: MultiLabeledFieldProps<C>) => {
  const { list, name, attribute, label, nullValue = '', InputProps, FieldComponent, helperText, ...rest } = props
  const empty = attribute ? { [attribute]: nullValue } : nullValue

  const formConfiguration = useContext(FormConfigurationContext)
  const readOnly = formConfiguration?.readOnly

  return (
    <>
      <FieldArray name={name}>
        {({ insert, remove }) =>
          withEmpty(list, empty).map((value, index) => (
            <LabeledField
              key={index} // eslint-disable-line react/no-array-index-key
              name={formikNameString(name, index, attribute)}
              label={label}
              value={attribute ? value[attribute] : value}
              FieldComponent={FieldComponent ?? FastTextField}
              InputProps={{
                ...(InputProps || {}),
                endAdornment: !readOnly && (
                  <MultiAdornment insert={insert} remove={remove} index={index} empty={empty} />
                ),
              }}
              {...rest}
            />
          ))
        }
      </FieldArray>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  )
}
