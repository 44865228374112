// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { opacify } from 'helpers/colors'

const DownloadLink = styled('a')(({ theme }) => ({
  color: theme.palette.background.light,
  textDecoration: 'none',
  margin: theme.spacing(0, 1, 0, 2),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  '&:hover': {
    background: opacify(theme.palette.background.light, 0.2),
  },
}))

export function ExportDownloadLink({ handleClose, downloadURL }: { handleClose: () => void; downloadURL: string }) {
  return (
    <DownloadLink href={downloadURL} onClick={handleClose}>
      Download
    </DownloadLink>
  )
}
