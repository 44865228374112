import { ReactElement, useImperativeHandle, useRef, FocusEvent } from 'react'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { Link } from 'react-router-dom'

import { HbVariableWidthInput } from 'components/HbComponents/Form/HbInputs/HbVariableWidthInput'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbDialog } from 'components/HbComponents/HbDialog'
import { HbTag, Props as HbTagProps } from 'components/HbComponents/HbTag'

import { FormSchemaReturnType } from './editor/formSchema'

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  background: 'white',
  flexFlow: 'row wrap',
  gap: theme.spacing(2),
}))

const HeaderLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  flexFlow: 'row wrap',
}))

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'black',
  borderRadius: '50%',
  border: `1px solid ${theme.palette.styleguide.borderLight}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '32px',
  width: '32px',
  '& > svg': {
    width: '12px',
    height: '12px',
    marginLeft: theme.spacing(0.5),
  },
  '&:hover': {
    backgroundColor: theme.palette.styleguide.backgroundLight,
  },
}))

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}))

interface Props {
  name: string | null
  actions: ReactElement
  tagProps: HbTagProps
  form: FormSchemaReturnType
  nameError: boolean
  template?: boolean
  setNameError: React.Dispatch<React.SetStateAction<boolean>>
  onBlur: (event: FocusEvent<HTMLInputElement>) => void
}

export const ViewAutomationHeaderBase = ({
  actions,
  tagProps,
  nameError,
  setNameError,
  form,
  onBlur,
  template = false,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { register } = form
  const { ref, ...nameFieldProps } = register('name', { onBlur })
  useImperativeHandle(ref, () => inputRef.current)

  return (
    <>
      <Header>
        <HeaderLeft>
          <StyledLink to={template ? '/automations/recipes' : '/automations'}>
            <ArrowBackIosIcon />
          </StyledLink>
          <HbVariableWidthInput
            {...nameFieldProps}
            ref={inputRef}
            placeholder="Blank automation"
            disabled={false}
            maxWidth={700}
            hideEditIcon
            alwaysShowUnderline
          />
          <HbTag {...tagProps} />
        </HeaderLeft>
        <Actions>{actions}</Actions>
      </Header>
      <HbDialog
        id="name_error"
        open={nameError}
        onClose={() => setNameError(false)}
        title="Please Add a Title"
        content=""
        actions={<HbButton label="Continue" variant="warning" onClick={() => setNameError(false)} />}
      />
    </>
  )
}
