import { ReactElement, ReactNode, forwardRef } from 'react'

import { Group } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { chipClasses, styled } from '@mui/material'

import { HbTag } from 'components/HbComponents/HbTag'
import { HbTooltip } from 'components/HbComponents/HbTooltip'
import { Queue } from 'types/api'

export const StyledQueueTag = styled(HbTag)(() => ({
  // reset the icon margin, as the label will be hidden conditionally
  [`& .${chipClasses.icon}`]: {
    marginRight: 0,
  },
}))

export interface QueueTagProps {
  icon?: ReactElement
  queue: Queue
}

export const QueueTag = forwardRef<HTMLDivElement, QueueTagProps>(({ icon, queue }, ref) => (
  <StyledQueueTag icon={icon} color={queue.color} label={queue.name} ref={ref} />
))

const QueueWrapper = styled('div')(() => ({
  // This wrapper occupies just the necessary width,
  // thus only showing the tooltip when hovering over the inner visible content
  width: 'fit-content',
  maxWidth: '100%',
}))

interface QueueWithTooltipProps {
  icon?: ReactElement
  queue: Queue
  tooltipDelay?: number
  tooltipTitle?: ReactNode
}

const DEFAULT_QUEUE_TOOLTIP_DELAY = 500

export const QueueWithTooltip = ({
  icon,
  queue,
  tooltipDelay = DEFAULT_QUEUE_TOOLTIP_DELAY,
  tooltipTitle,
}: QueueWithTooltipProps) => {
  return (
    <HbTooltip title={tooltipTitle || queue.name} enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
      {/* This wrapper is needed for the tooltip to render */}
      <QueueWrapper>
        <QueueTag icon={icon} queue={queue} />
      </QueueWrapper>
    </HbTooltip>
  )
}

export const RESPONSIVE_QUEUE_CONTAINER_NAME = 'responsive-queue-container'

export const ICON_ONLY_BREAKPOINT_WIDTH = 36

export const ResponsiveContainer = styled('div')(() => ({
  maxWidth: '100%',
  containerType: 'inline-size',
  containerName: RESPONSIVE_QUEUE_CONTAINER_NAME,

  [`& ${QueueWrapper}`]: {
    [`@container ${RESPONSIVE_QUEUE_CONTAINER_NAME} (width <= ${ICON_ONLY_BREAKPOINT_WIDTH}px)`]: {
      [`& .${chipClasses.label}`]: {
        display: 'none',
      },
    },
    [`@container ${RESPONSIVE_QUEUE_CONTAINER_NAME} (width > ${ICON_ONLY_BREAKPOINT_WIDTH}px)`]: {
      [`& .${chipClasses.icon}`]: {
        display: 'none',
      },
    },
  },
}))

export interface ResponsiveQueueProps {
  icon?: ReactElement
  className?: string
  queue: Queue
}

export const ResponsiveQueue = ({ className, icon = <Group />, queue }: ResponsiveQueueProps) => {
  return (
    <ResponsiveContainer className={className}>
      <QueueWithTooltip icon={icon} queue={queue} />
    </ResponsiveContainer>
  )
}
