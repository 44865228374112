import { useEffect, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { OutlinedInput, styled, outlinedInputClasses } from '@mui/material'

import { startCase } from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

import { useDispatch, useSelector } from 'actions/store'
import Feature from 'components/Feature'
import { useDashboardConfig } from 'dashboards/shared/react/dashboards.config'
import { stopEventPropagation } from 'helpers/uiHelpers'
import { SearchIcon } from 'icons'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { FeatureFlag } from 'types/api'

import SearchInputEndAdornment from '../SearchInputEndAdornment'

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  margin: theme.spacing(0, 1),
}))

export const StyledSearchInput = styled(OutlinedInput)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  padding: 0,
  width: 'min(320px, 24vw)',

  [`& .${outlinedInputClasses.input}`]: {
    padding: theme.spacing(1, 1.5, 1, 0),
  },
}))

export const DashboardSearchInput = () => {
  const dispatch = useDispatch()
  const { filterControls, header } = useDashboardConfig()
  const { isGlobalSearchEnabled } = filterControls
  const { title } = header
  const dashboardsSelectors = useDashboardSelectors()
  const dashboardsActions = useDashboardActions()

  const stateQuery = useSelector(dashboardsSelectors.query)
  const [query, setQuery] = useState(stateQuery || '')

  useEffect(() => {
    setQuery(stateQuery || '')
  }, [stateQuery])

  const handleUpdateState = (queryValue: string) => {
    dispatch(dashboardsActions.query.set.action(queryValue))
  }

  const debouncedHandleUpdateState = useDebouncedCallback(handleUpdateState, 300)

  const handleChange = (value: string) => {
    setQuery(value)
    // If value is truthy user is likely typing. Avoid updating global state and re-triggering search until they stop.
    // If value is not truthy, user is likely clearing value. Update immediately.
    if (value) {
      debouncedHandleUpdateState(value)
    } else {
      // Prevent stale updates from being dispatched after a debounce.
      debouncedHandleUpdateState.cancel()
      handleUpdateState(value)
    }
  }

  return (
    <Feature name={FeatureFlag.EnableDashboardSearch}>
      {isGlobalSearchEnabled && (
        <StyledSearchInput
          aria-label={`${startCase(title)} Dashboard Search Input`}
          value={query}
          startAdornment={<StyledSearchIcon />}
          endAdornment={
            <SearchInputEndAdornment onClearClick={() => handleChange('')} mode={query ? 'clear' : 'none'} />
          }
          placeholder="Search"
          size="medium"
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={stopEventPropagation}
        />
      )}
    </Feature>
  )
}
