import { ReactNode } from 'react'

import { gql } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material'

import { InvestigationReviewStatusEnum, Queue } from 'types/api'

import { Theme } from 'types/hb'

import { QueueWithTooltip, ResponsiveQueue } from '../queues/ResponsiveQueue'

import { queueDetailsFragment } from '../queues/fragments'
import { MIN_RESPONSIVE_QUEUE_WIDTH, useQueueNaturalWidth } from '../queues/useQueueNaturalWidth'

import { ApprovalAssigneeAvatar } from './ApprovalAssigneeAvatar'
import { ApprovalAssignees } from './ApprovalAssignees'
import { AssigneeAvatar } from './AssigneeAvatar'
import { DecisionContext } from './DecisionContext'
import { StatusDirection } from './StatusDirection'
import { hasAssignee, hasQueue } from './helpers'
import { ApprovalAssignmentDetails, AssigneeDetails } from './types'

const MIN_ASSIGNEE_WIDTH = 24

const AssigneeContainer = styled('div')(() => ({
  flex: `0 1 ${MIN_ASSIGNEE_WIDTH}`,
  minWidth: MIN_ASSIGNEE_WIDTH,
}))

export const ResponsiveQueueContainer = styled('div')(() => ({
  flexGrow: 0,
  flexShrink: 1,
}))

interface ResponsiveAssignmentQueueProps {
  queue: Queue
}

/**
 * Note: for responsiveness, this measures
 * the natural width of the tag on mount
 * using a transient render. Ensure that performance
 * is not a concern while using this.
 */
export const ResponsiveAssignmentQueue = ({ queue }: ResponsiveAssignmentQueueProps) => {
  // Use the natural width of the queue to define its flex-basis.
  // Containers that use container queries cannot size themselves based on the
  // width of their content, which clashes with flex styling.
  const { width } = useQueueNaturalWidth({ queue })

  return (
    <ResponsiveQueueContainer style={{ flexBasis: width || 'auto', minWidth: MIN_RESPONSIVE_QUEUE_WIDTH }}>
      <ResponsiveQueue queue={queue} />
    </ResponsiveQueueContainer>
  )
}

interface AssignmentQueueProps {
  queue: Queue
  shouldRenderFullQueue?: boolean
  tooltipTitle?: ReactNode
}

const AssignmentQueue = ({ queue, shouldRenderFullQueue, tooltipTitle }: AssignmentQueueProps) => {
  if (shouldRenderFullQueue) return <QueueWithTooltip queue={queue} tooltipTitle={tooltipTitle} />
  return <ResponsiveAssignmentQueue queue={queue} />
}

interface AssigneeOrQueueProps {
  assignee?: AssigneeDetails | null
  queue?: Queue | null
  shouldRenderFullQueue?: boolean
}

const AssigneeOrQueue = ({ assignee, queue, shouldRenderFullQueue }: AssigneeOrQueueProps) => {
  if (assignee) {
    return (
      <AssigneeContainer>
        <AssigneeAvatar assignee={assignee} />
      </AssigneeContainer>
    )
  }

  if (queue) {
    return <AssignmentQueue queue={queue} shouldRenderFullQueue={shouldRenderFullQueue} />
  }

  return null
}

const ApprovalAssignmentQueue = ({
  decision,
  queue,
  shouldRenderFullQueue,
  showPendingReviewDecisions,
}: {
  decision: string | null | undefined
  queue: Queue
  shouldRenderFullQueue?: boolean
  showPendingReviewDecisions: boolean
}) => (
  <AssignmentQueue
    queue={queue}
    shouldRenderFullQueue={shouldRenderFullQueue}
    tooltipTitle={
      !!decision || showPendingReviewDecisions ? <DecisionContext decision={decision} name={queue.name} /> : queue.name
    }
  />
)

const minApproverAssigneesWidth = (theme: Theme, numAssignees: number) =>
  MIN_ASSIGNEE_WIDTH * Math.min(numAssignees, 2) + (numAssignees > 1 ? theme.hbUnit(0.5) : 0)

interface ApproversContainerStyleProps {
  numAssignees: number
}

const ApproversContainer = styled('div')<ApproversContainerStyleProps>(({ numAssignees, theme }) => ({
  flex: '1 0',
  minWidth: numAssignees ? minApproverAssigneesWidth(theme, numAssignees) : MIN_RESPONSIVE_QUEUE_WIDTH,
}))

export const ApprovalAssigneesOrQueues = ({
  approvalAssignments,
  decisionExists,
  shouldRenderFullQueue,
}: {
  approvalAssignments?: ApprovalAssignmentDetails[]
  decisionExists: boolean
  shouldRenderFullQueue?: boolean
}) => {
  if (!approvalAssignments) return null

  const assignmentsWithAssignees = approvalAssignments.filter(hasAssignee)
  const assignmentsWithQueues = approvalAssignments.filter(hasQueue)

  const numAssignees = assignmentsWithAssignees.length
  const queuesExist = !!assignmentsWithQueues.length

  if (!numAssignees && !queuesExist) return null

  return (
    <ApproversContainer numAssignees={numAssignees}>
      {numAssignees ? (
        <ApprovalAssignees
          approvalAssignments={assignmentsWithAssignees}
          showPendingReviewDecisions={!decisionExists}
        />
      ) : (
        assignmentsWithQueues.map((assignment) => (
          <ApprovalAssignmentQueue
            key={assignment.token}
            queue={assignment.queue}
            decision={assignment.decision}
            showPendingReviewDecisions={!decisionExists}
            shouldRenderFullQueue={shouldRenderFullQueue}
          />
        ))
      )}
    </ApproversContainer>
  )
}

export interface AssignmentDetailsContainerStyleProps {
  isShowingApprovers?: boolean
}

export const AssignmentDetailsContainer = styled('div')<AssignmentDetailsContainerStyleProps>(
  ({ isShowingApprovers, theme }) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: theme.spacing(0.5),
    alignItems: 'center',
    maxWidth: '100%',
    flex: `${isShowingApprovers ? 1 : 0} 1`,
    justifyContent: 'flex-end',

    [`& ${ResponsiveQueueContainer}`]: {
      flexGrow: !isShowingApprovers ? 1 : 0,
    },
  })
)

export const ApproversAndStatusDirectionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1',
  flexFlow: 'row',
  alignItems: 'center',
  columnGap: theme.spacing(0.5),
}))

interface AssignmentDetailsProps {
  approvalAssignments?: ApprovalAssignmentDetails[]
  assignee?: AssigneeDetails | null
  className?: string
  status: InvestigationReviewStatusEnum
  queue?: Queue | null
  shouldRenderFullQueue?: boolean
}

export const isWorkflowInReviewProcess = (status: InvestigationReviewStatusEnum) =>
  [
    InvestigationReviewStatusEnum.ReadyForReview,
    InvestigationReviewStatusEnum.Approved,
    InvestigationReviewStatusEnum.ChangesRequested,
  ].includes(status)

export const AssignmentDetails = ({
  approvalAssignments,
  assignee,
  className,
  status,
  queue,
  shouldRenderFullQueue,
}: AssignmentDetailsProps) => {
  const isInReviewProcess = isWorkflowInReviewProcess(status)

  const assigneeExists = !!assignee
  const queueExists = !!queue
  const eitherAssigneeOrQueueExists = assigneeExists || queueExists
  const approvalAssignmentsExist = !!approvalAssignments?.length

  const isShowingApprovers = isInReviewProcess && approvalAssignmentsExist

  const decisionExists = status !== InvestigationReviewStatusEnum.ReadyForReview

  return (
    <AssignmentDetailsContainer className={className} isShowingApprovers={isShowingApprovers}>
      {eitherAssigneeOrQueueExists && (
        <AssigneeOrQueue assignee={assignee} queue={queue} shouldRenderFullQueue={shouldRenderFullQueue} />
      )}
      {isShowingApprovers && (
        <ApproversAndStatusDirectionWrapper>
          {eitherAssigneeOrQueueExists && status && <StatusDirection status={status} />}
          <ApprovalAssigneesOrQueues
            approvalAssignments={approvalAssignments}
            decisionExists={decisionExists}
            shouldRenderFullQueue={shouldRenderFullQueue}
          />
        </ApproversAndStatusDirectionWrapper>
      )}
    </AssignmentDetailsContainer>
  )
}

AssignmentDetails.fragments = {
  assignmentDetails: gql`
    fragment ReviewsCardAssignmentDetails on Review {
      assignee {
        ...AccountAvatarDetails
      }
      approvalAssignments {
        assignee {
          ...AccountAvatarDetails
        }
        decision
        queue {
          ...QueueDetails
        }
        token
      }
      queue {
        ...QueueDetails
      }
    }
    ${AssigneeAvatar.fragments.assignee}
    ${ApprovalAssigneeAvatar.fragments.assignee}
    ${queueDetailsFragment}
  `,
}
