import { useEffect } from 'react'

import { Box } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useFieldArray, Controller } from 'react-hook-form'
import invariant from 'tiny-invariant'

import { HbCheckbox } from 'components/HbComponents/Form/HbCheckbox'

import HbRHFTextInput from 'components/HbComponents/Form/HbInputs/HbTextInput/HbRHFTextInput'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { RHFOtherInfoLabelInput } from 'components/library/OtherInfoLabelInput'
import { usePrevious } from 'hooks'
import { AddIcon, TrashOutlineIcon } from 'icons'

import { AutomationDomainType, OtherInfoLabelTypeEnum } from 'types/api'
import { Theme } from 'types/hb'

import { useIsAutomationRule } from '../../AutomationRuleOrTemplateContext'
import { FormSchemaReturnType } from '../../formSchema'

type OtherInfoParamPath = 'actionParams.writeOtherInfoParams' | 'actionParams.createReviewParams'

const useStyles = makeStyles((theme: Theme) => ({
  entryRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  entriesContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(),
  },
  entryRowLabelContainer: {
    width: '50%',
  },
  deleteIcon: {
    position: 'relative',
    top: theme.spacing(3.5),
    color: theme.palette.styleguide.textGreyLight,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  options: {
    paddingBottom: theme.spacing(1),
  },
}))

const DOMAIN_TYPE_TO_OTHER_INFO_LABEL_TYPE: Partial<Record<AutomationDomainType, OtherInfoLabelTypeEnum>> = {
  [AutomationDomainType.Review]: OtherInfoLabelTypeEnum.Review,
  [AutomationDomainType.Person]: OtherInfoLabelTypeEnum.Person,
  [AutomationDomainType.Business]: OtherInfoLabelTypeEnum.Business,
  // Case domain allows for creating reviews and the other info will be attached to those reviews
  // instead of the case itself
  [AutomationDomainType.Case]: OtherInfoLabelTypeEnum.Review,
}

export default function OtherInfo({
  paramsPath,
  form,
}: {
  paramsPath: OtherInfoParamPath
  form: FormSchemaReturnType
}) {
  const { control, watch } = form

  const domainType = watch('domain.type')
  const prevDomainType = usePrevious(domainType)

  const otherInfoLabelType = domainType && DOMAIN_TYPE_TO_OTHER_INFO_LABEL_TYPE[domainType]
  invariant(otherInfoLabelType)

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `${paramsPath}.otherInfoEntries`,
  })
  const classes = useStyles()

  useEffect(() => {
    if (prevDomainType && prevDomainType !== domainType) {
      replace([])
    }
  }, [prevDomainType, domainType, replace])

  const isRule = useIsAutomationRule()

  return (
    <div className={classes.entriesContainer}>
      {paramsPath === 'actionParams.writeOtherInfoParams' && (
        <div className={classes.options}>
          <HbText size="s" color="secondary" bold>
            Options
          </HbText>
          <Controller
            control={control}
            name={`${paramsPath}.overwrite`}
            render={({ field }) => (
              <div>
                <HbCheckbox {...field} checked={field.value} />
                <HbText size="s">Overwrite Existing Fields</HbText>
              </div>
            )}
          />
        </div>
      )}
      {fields.map((field, index) => (
        <div key={field.id} className={classes.entryRow}>
          <div className={classes.entryRowLabelContainer}>
            {isRule ? (
              <RHFOtherInfoLabelInput
                name={`${paramsPath}.otherInfoEntries.${index}.label`}
                label="Name"
                type={otherInfoLabelType}
              />
            ) : (
              <Box mb={3}>
                <HbRHFTextInput name={`${paramsPath}.otherInfoEntries.${index}.label`} label="Label" />
              </Box>
            )}
          </div>
          <HbRHFTextInput name={`${paramsPath}.otherInfoEntries.${index}.value`} label="Value" />
          <HbButton
            className={classes.deleteIcon}
            Icon={TrashOutlineIcon}
            label="Remove Field"
            variant="textSecondary"
            onClick={() => remove(index)}
            iconOnly
          />
        </div>
      ))}
      <div className={classes.buttonContainer}>
        <HbButton
          Icon={AddIcon}
          label="Add Field"
          variant="textPrimary"
          size="small"
          onClick={() => append({ label: '', value: '' })}
        />
      </div>
    </div>
  )
}
